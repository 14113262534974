import * as S from './EventProfile.styles'

import { useRef, useMemo, useEffect, useState } from 'react'
import { Heading, Paragraph, Button } from 'applaus-ui-kit'
import moment from 'moment'
import { formatPrice } from '../../../utils/formatPrice'
import { MappedEvent } from '../../EventProfile/Desktop/components/MappedEvent'
import { max, min } from 'lodash'
import { DateInfo } from '../../EventProfile/Components/DateInfo/DateInfo'
import { TabulatedEvent } from '../../EventProfile/TabulatedEvent/TabulatedEvent'

type Props = {
  event: any
}

export const DesktopEventProfile = ({ event }: Props) => {
  const ticketRef = useRef<HTMLDivElement>(null)
  const [data, setData] = useState<undefined | any>(undefined)
  const clicarparavoltar = () => {}
  const renderTickets = useMemo(() => {
    if (data) {
      return data.type === 'MAPPED' ? (
        <MappedEvent eventItem={data.eventItems[0]} />
      ) : (
        <TabulatedEvent
          eventItem={data.eventItems[0]}
          setState={clicarparavoltar}
        />
      )
    } else {
      return undefined
    }
  }, [data])

  useEffect(() => {
    setData({
      ...event,
      ticketPrices: event!.eventItems!.flatMap(
        ({ tickets }: { tickets: any }) =>
          tickets.flatMap(
            ({
              priceCents,
              discountCents
            }: {
              priceCents: number
              discountCents: number
            }) => (priceCents - discountCents) / 100
          )
      )
    })
  }, [])

  return (
    <S.Container>
      {data ? (
        <>
          <S.Spacing />
          <S.Wrapper>
            <S.ImageBox>
              <S.EventImage src={data.banners[0].desktopUrl} />
            </S.ImageBox>
            <S.EventInfo>
              <S.EventData>
                <Heading variant="h5">{data.name}</Heading>
                <DateInfo
                  dates={data.eventItems.flatMap(
                    ({ dates }: { dates: string[] }) => dates
                  )}
                />

                <S.LocationBox>
                  <S.LocationIcon />
                  <div>
                    <Paragraph variant="large" type="semiBold">
                      {data.address.street}
                    </Paragraph>
                    <Paragraph variant="small" type="normal">
                      {data.address.city}, {data.address.state},
                      {data.address.zipCode}
                    </Paragraph>
                  </div>
                </S.LocationBox>
              </S.EventData>
              <S.EventPricing>
                <Paragraph variant="large">Ingressos entre</Paragraph>
                <Paragraph variant="large" type="semiBold">
                  {formatPrice(min(data.ticketPrices) ?? 0)}
                  {' e '}
                  {formatPrice(max(data.ticketPrices) ?? 0)}
                </Paragraph>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="medium"
                  onClick={() => {
                    if (ticketRef && ticketRef.current)
                      ticketRef.current.scrollIntoView({
                        block: 'end',
                        behavior: 'smooth'
                      })
                  }}>
                  Comprar Ingressos
                </Button>
              </S.EventPricing>
            </S.EventInfo>

            <S.EventDetails>
              <Heading variant="h5">Detalhes</Heading>
              <S.Description
                dangerouslySetInnerHTML={{
                  __html: data.description
                }}
              />
            </S.EventDetails>
            <S.EventTickets ref={ticketRef}>
              <S.Dates>
                <Heading variant="h4">Escolha seu assento</Heading>
                <div className="eventItemsBox">
                  {data.eventItems.length > 1 &&
                    data.eventItems.map((eventItem: any) =>
                      eventItem.dates.map((date: string, index: number) => (
                        <S.DatesInfo
                          key={index}
                          isActive={eventItem.id === data.eventItems[0].id}
                          onClick={() => {}}>
                          <Paragraph variant={'small'}>
                            {moment(date).format('ddd')}
                          </Paragraph>
                          <S.Paragraph key={date}>
                            {moment(date).format('DD/MM - LT')}
                          </S.Paragraph>
                        </S.DatesInfo>
                      ))
                    )}
                </div>
              </S.Dates>
              {renderTickets}
            </S.EventTickets>
            <S.EventLocation>
              <Heading variant="h5">Localização</Heading>
              <S.LocationIframe>
                <iframe
                  className="responsive-iframe"
                  src={`https://maps.google.com/maps?q=${data.address.zipCode}, ${data.address.street}, ${data.address.number}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                  frameBorder="0"
                  scrolling="no"
                />
              </S.LocationIframe>
            </S.EventLocation>
            {data.eventItems[0].tickets &&
              data.eventItems[0].tickets?.length > 0 && (
                <>
                  <div style={{ height: '150px' }} />
                  <S.TicketBox>
                    <Heading variant="h4">
                      Total:{' '}
                      {formatPrice(
                        data.eventItems[0].tickets?.reduce(
                          (acc: any, val: any) => {
                            return (
                              acc + (val.priceCents - val.discountCents) / 100
                            )
                          },
                          0
                        )
                      )}
                    </Heading>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth={false}
                      type="button"
                      size="small"
                      style={{ whiteSpace: 'nowrap' }}
                      onClick={() => {}}>
                      Comprar Ingressos
                    </Button>
                  </S.TicketBox>
                </>
              )}
          </S.Wrapper>
        </>
      ) : undefined}
    </S.Container>
  )
}
