/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'

import moment from 'moment'

import {
  Column,
  Container as GridContainer,
  Row
} from '../../components/grid/GridSystem'

import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'

import * as S from './Home.styles'
import { formatPrice } from '../../utils/formatPrice'
import ReactSelect from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../redux/store/rootReducer'
import {
  globalSummaryService,
  SummaryData
} from '../../api/services/user/globalSummaryService'
import { clearLoading, setLoading } from '../../redux/store/Loading/actions'
import { sleep } from '../../utils/sleep'
import { motion } from 'framer-motion'
import { groupBy } from 'lodash'
import { theme } from 'applaus-ui-kit'
import { useWindowSize } from '../../hooks/useWindowSize'
import { AuthenticateTemplate } from '../../components/templates/AuthenticateTemplate'
import { setModalState } from '../../redux/store/Modal/Actions/actionsModal'

const months = [
  {
    label: 'Janeiro',
    value: '01'
  },
  {
    label: 'Fevereiro',
    value: '02'
  },
  {
    label: 'Março',
    value: '03'
  },
  {
    label: 'Abril',
    value: '04'
  },
  {
    label: 'Maio',
    value: '05'
  },
  {
    label: 'Junho',
    value: '06'
  },
  {
    label: 'Julho',
    value: '07'
  },
  {
    label: 'Agosto',
    value: '08'
  },
  {
    month: 'Setembro',
    value: '09'
  },
  {
    label: 'Outubro',
    value: '10'
  },
  {
    label: 'Novembro',
    value: '11'
  },
  {
    label: 'Dezembro',
    value: '12'
  }
]

export const Home = () => {
  const { LoadingReducer } = useSelector((state: ReduxState) => state)
  const [summary, setSummary] = useState<SummaryData | null>(null)
  const [year, setYear] = useState<number | undefined>(undefined)
  const [month, setMonth] = useState<string | undefined>(undefined)
  const [chartData, setChartData] = useState<{ data: string; valor: number }[]>(
    []
  )
  const dispatch = useDispatch()
  const { isMobile } = useWindowSize()

  const years = []
  const yearNow = moment().year()
  years.push({ value: yearNow, label: yearNow.toString() })

  const fetchData = async (year?: string, month?: string) => {
    try {
      dispatch(setLoading(true))
      const globalSummary = await globalSummaryService(year, month)
      setSummary(globalSummary)
      await sleep(3000)
      dispatch(clearLoading())
    } catch (e) {
      dispatch(clearLoading())
      dispatch(
        setModalState({
          open: true,
          variant: 'error',
          message: 'Não foi possível carregar seus dados',
          description: 'Tente novamente mais tarde',
          buttonText: 'Fechar'
        })
      )
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (summary !== null) {
      // const groupedData = summary?.purchaseOrders.map(({ paymentHistory }) => ({

      // }))

      const groupedData = summary.purchaseOrders
        .filter((purchases) => purchases.paymentHistory)
        .map(({ paymentHistory }) => ({
          price: paymentHistory!.totalPrice / 100,
          date: moment(
            paymentHistory!.updatedAt ?? paymentHistory!.createdAt
          ).format('L')
        }))

      const data = Object.entries(groupBy(groupedData, 'date')).flatMap(
        (value) => ({
          date: value[0],
          valor: value[1].reduce((acc, val) => acc + val.price, 0)
        })
      )

      const currentMonthDates = Array.from(
        { length: moment().daysInMonth() },
        (x, i) => moment().startOf('month').add(i, 'days')
      ).map((date) => ({ data: date.format('L'), valor: 0 }))

      const formatedData = currentMonthDates.map((obj) => {
        const findDateIndex = data.findIndex((obj2) => obj2.date === obj.data)
        if (findDateIndex !== -1) {
          return { ...obj, valor: data[findDateIndex].valor }
        } else {
          return obj
        }
      })

      for (let i = 0; i < formatedData.length; i++) {
        const compareValues = formatedData
          .slice(i, i + 5)
          .every(({ valor }) => valor === 0)

        if (compareValues) formatedData.splice(i, 5)
      }

      setChartData(formatedData)
    }
  }, [summary])

  useEffect(() => {
    if (year && month) {
      const dateIsAfter = moment(`01/${month}/${year}`, 'DD/MM/YYYY').diff(
        moment()
      )
      if (dateIsAfter > 0) {
        setMonth(undefined)
      } else {
        fetchData(year.toString(), month)
      }
    }
  }, [year, month])

  return (
    <AuthenticateTemplate>
      {summary !== null ? (
        <S.Container>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}>
            <GridContainer>
              <Row marginTop="xl">
                <Column mobile={4} tablet={8} desktop={12}>
                  <S.Title>Home</S.Title>
                </Column>
              </Row>
              <Row marginTop="xxxs" marginBottom="xl">
                <Column mobile={4} tablet={8} desktop={12}>
                  <S.ChartWrapper>
                    <div style={{ width: '100%', height: '300px' }}>
                      <ResponsiveContainer>
                        <LineChart
                          data={chartData}
                          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <YAxis dataKey="valor" />
                          <XAxis dataKey="data" />
                          <Tooltip />
                          <CartesianGrid
                            stroke={theme.color.brand.primary.nth1}
                          />
                          <Line
                            type="monotone"
                            dataKey="valor"
                            stroke={theme.color.brand.primary.nth1}
                            yAxisId={0}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                    <S.BalanceWrapper>
                      <S.AvailableForWithdrawal>
                        <p>Saldo disponível para saque</p>
                        <span>{formatPrice(summary.availableWithdraw)}</span>
                      </S.AvailableForWithdrawal>
                      <S.BalanceReceivable>
                        <p>Saldo a receber</p>
                        <span>{formatPrice(summary.receivableBalance)}</span>
                      </S.BalanceReceivable>
                    </S.BalanceWrapper>
                  </S.ChartWrapper>
                </Column>
              </Row>
              <Row marginBottom="xxxs">
                <Column
                  mobile={4}
                  tablet={2}
                  desktop={3}
                  style={{
                    marginRight: '8px',
                    marginBottom: isMobile ? '8px' : '0'
                  }}>
                  <ReactSelect
                    placeholder="Ano"
                    options={years}
                    onChange={(e: any) => setYear(e?.value)}
                    styles={S.customStyles}
                  />
                </Column>
                <Column mobile={4} tablet={2} desktop={3}>
                  <ReactSelect
                    placeholder="Mês"
                    value={months.filter(({ value }) => value === month)}
                    options={months}
                    onChange={(e: any) => setMonth(e?.value)}
                    styles={S.customStyles}
                  />
                </Column>
              </Row>
              <Row>
                <Column mobile={4} tablet={4} desktop={6}>
                  <S.MonthlyBilling>
                    <p>Faturamento do mês</p>
                    <span>{formatPrice(summary.confirmedPayments)}</span>
                  </S.MonthlyBilling>
                </Column>
                <Column mobile={4} tablet={4} desktop={6}>
                  <S.PendingBilling>
                    <p>Pagamento pendente</p>
                    <span>{formatPrice(summary.pendingPayments)}</span>
                  </S.PendingBilling>
                </Column>
              </Row>
              <Row marginTop="xxs" marginBottom="xxxl">
                <Column mobile={4} tablet={3} desktop={4}>
                  <S.PaymentInfo className="spacingTop">
                    <p>Ingressos vendidos </p>
                    <span>{summary.ticketsSold}</span>
                  </S.PaymentInfo>
                </Column>
                <Column mobile={2} tablet={2.5} desktop={4}>
                  <S.PaymentInfo className="spacing">
                    <p>Pix</p>
                    <span>{formatPrice(summary.pixSales)}</span>
                  </S.PaymentInfo>
                </Column>
                <Column mobile={2} tablet={2.5} desktop={4}>
                  <S.PaymentInfo className="spacingTop">
                    <p>Cartão</p>
                    <span>{formatPrice(summary.creditSales)}</span>
                  </S.PaymentInfo>
                </Column>
                <Column mobile={4} tablet={3} desktop={4}>
                  <S.PaymentInfo className="spacingTop">
                    <p>Ingressos de cortesia </p>
                    <span>{summary.ticketsCourtesy}</span>
                  </S.PaymentInfo>
                </Column>
              </Row>
            </GridContainer>
          </motion.div>
        </S.Container>
      ) : (
        <></>
      )}
    </AuthenticateTemplate>
  )
}
