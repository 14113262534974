import { Input, Label, Container, Error, IconSvg } from './CurrencyInput.styles'
import { CurrencyInputProps } from 'react-currency-input-field'
import { ErrorIcon } from '../../assets/icons/ErrorIcon'

interface Props extends CurrencyInputProps {
  label: string
  texterror?: string
  error?: boolean
}

export const CurrencyInput = (props: Props) => {
  return (
    <Container>
      <Label htmlFor={props.id}>{props.label}</Label>
      <div className="heightInput">
        <Input
          id={props.id}
          {...props}
          allowNegativeValue={false}
          error={props.error}
        />
        {props.error ? (
          <IconSvg error>
            <ErrorIcon />
          </IconSvg>
        ) : undefined}
      </div>
      <Error>{props.error ? props.texterror : ''}</Error>
    </Container>
  )
}
