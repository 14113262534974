import { AxiosResponse } from 'axios'
import { api } from 'api/api'

export type BackofficeGroupsResponse = {
  BACKOFFICE_1: string
  BACKOFFICE_2: string
  BACKOFFICE_3: string
}

export const backofficeGroupsService = async () => {
  const { data }: AxiosResponse<BackofficeGroupsResponse> = await api.get(
    '/api/backoffice/groups'
  )

  return data
}
