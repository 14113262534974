import styled, { css } from 'styled-components'

const ContainerModifier = {
  left: () =>
    css`
      text-align: left;
    `,
  right: () =>
    css`
      text-align: right;
    `,
  center: () =>
    css`
      text-align: center;
    `
}

export const TableHeader = styled.th<{ align: 'left' | 'center' | 'right' }>`
  ${({ theme, align }) => css`
    color: ${theme.color.brand.tertiary.nth1};
    font-weight: ${theme.font.weight.bold};
    font-size: ${theme.font.size.xs};
    ${ContainerModifier[align]}
    > p {
      > i {
        font-size: ${theme.font.size.md};
        margin-right: ${theme.spacing.inline.small};
      }
      font-size: ${theme.font.size.xs};
    }
  `}
`
