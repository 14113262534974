import styled, { css } from 'styled-components'

import { ReactComponent as EditIconFile } from '../../../assets/icons/edit-alt.svg'
import { ReactComponent as TrashIconFile } from '../../../assets/icons/trash-alt.svg'

export const Container = styled.div``

export const IconBox = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const EditIcon = styled(EditIconFile)`
  ${({ theme }) => css`
    fill: ${theme.color.base.nth1};
    cursor: pointer;
  `}
`

export const ExcludeIcon = styled(TrashIconFile)`
  ${({ theme }) => css`
    fill: ${theme.color.base.nth1};
    cursor: pointer;
  `}
`
