/* eslint-disable no-unused-vars */
export enum ChartActions {
  GET_CHARTS_REQUEST = '@creator/GET_CHARTS_REQUEST',
  GET_CHARTS_SUCCESS = '@creator/GET_CHARTS_SUCCESS',
  CREATE_CHART_REQUEST = '@charts/CREATE_CHART_REQUEST',
  CREATE_CHART_SUCCESS = '@charts/CREATE_CHART_SUCCESS',
  EDIT_CHART_REQUEST = '@charts/EDIT_CHART_REQUEST',
  EDIT_CHART_SUCCESS = '@charts/EDIT_CHART_SUCCESS',
  DELETE_CHART_REQUEST = '@charts/DELETE_CHART_REQUEST',
  DELETE_CHART_SUCCESS = '@charts/DELETE_CHART_SUCCESS'
}

export type Chart = {
  id: string
  name: string
  seatChartId: string
  address: {
    city: string
    country: string
    number: number
    state: string
    street: string
    complement: string
    zipCode: string
  }
  createdAt: string
  updateAt: string | null
}

export type ChartsState = {
  readonly data: {
    items: Chart[]
    total: number
  }
  currentPage: number
  skip: number
  take: number
}
