import produce from 'immer'
import { Reducer } from 'redux'
import { PurchaseOrder, PurchasesActions, PurchasesState } from './types'

const initialState: PurchasesState = {
  step: 'defaultPurchase',
  itemsPerPage: 10,
  purchasesState: {
    items: [],
    total: 0
  }
}

export const PurchasesReducer: Reducer<PurchasesState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PurchasesActions.DEFAULT_PURCHASES:
      return produce(state, (draftState) => {
        draftState.step = 'defaultPurchase'
      })
    case PurchasesActions.SHOW_REQUEST:
      return produce(state, (draftState) => {
        draftState.step = 'showRequest'
      })
    case PurchasesActions.SHOW_TICKET:
      return produce(state, (draftState) => {
        draftState.step = 'showTicket'
      })
    case PurchasesActions.PURCHASE_ORDER_CUSTOMER_SUCCESS:
      return produce(state, (draftState) => {
        draftState.step = 'defaultPurchase'
        draftState.purchasesState.items = action.payload.items
        draftState.purchasesState.total = action.payload.total
        draftState.itemsPerPage = 10
      })
    case PurchasesActions.SET_PURCHASE_ORDER_CUSTOMER:
      return produce(state, (draftState) => {
        const findPurchaseOrder = draftState.purchasesState.items.findIndex(
          (item) => item.id === action.payload
        )

        if (findPurchaseOrder > -1) {
          draftState.selectedPurchaseOrder =
            draftState.purchasesState.items[findPurchaseOrder]
        }
      })

    case PurchasesActions.VIEW_MORE_PURCHASE_ORDER_SUCCESS:
      return produce(state, (draftState) => {
        action.payload.items.map((item: PurchaseOrder) =>
          draftState.purchasesState.items.push(item)
        )
        draftState.itemsPerPage += 10
      })
    case PurchasesActions.CLEAR_ITEMS_PER_PAGE:
      return produce(state, (draftState) => {
        draftState.itemsPerPage = 10
      })
    case PurchasesActions.INITIAL_STATE:
      return produce(state, (draftState) => {
        draftState.step = 'defaultPurchase'
      })
    default:
      return state
  }
}
