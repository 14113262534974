import { AxiosResponse } from 'axios'
import { api } from 'api/api'

export type PurchaseResponse = {
  items: {
    brand: string
    cashBackDiscountCents: number | null
    createdAt: string
    customer: {
      createdAt: string
      customField: {
        externalId: string
        liveApiToken: string
        subAccountId: string
        testApiToken: string
        userToken: string
      }
      document: string
      email: string
      eventItemsOnTicketsEventItemId: null
      eventItemsOnTicketsTicketId: null
      firstName: string
      groups: []
      id: string
      lastName: string
      phone: string
      type: string
      updatedAt: string
      userId: string
    }
    customerId: string
    description: string
    id: string
    installments: number | null
    lastCardNumbers: number
    paymentMethod: string
    purchaseOrderId: string
    status: string
    totalPrice: number
    updatedAt: string
  }[]
  total: number
}

type Request = {
  customerId: string
}

export const getPaymentHistoryService = async ({ customerId }: Request) => {
  const { data }: AxiosResponse<PurchaseResponse> = await api.get(
    `/api/backoffice/customers/${customerId}/payments`
  )

  return data
}
