import { AxiosResponse } from 'axios'
import { Event } from '../../../redux/store/EditEvent/types'
import { api } from '../../api'

type EventPartner = {
  event: Event
  revenuePercent: number
}

export type ListPartnersResponse = {
  id: string
  name: string
  status: 'ACCEPTED' | 'REJECTED' | 'WAITING'
  email: string
  phoneNumber: string
  document: string
  documentType: string
  address: {
    id: string
    country: string
    state: string
    city: string
    street: string
    district: string
    number: number
    zipCode: string
    complement?: string
  }
  bank: string | null
  bankAccountType: string
  bankAg: string
  bankCc: string
  createdAt: string
  updatedAt: string | null
  events: EventPartner[]
  balanceWithdrawal: {
    comments: null
    createdAt: string
    creatorId: null
    eventId: string
    eventPartnerId: string
    id: string
    status: string
    updatedAt: null
    valueCents: number
  }[]
}

export const listPartners = async (creatorId: string) => {
  const { data }: AxiosResponse<ListPartnersResponse[]> = await api.get(
    `/api/backoffice/creators/${creatorId}/partners`
  )

  return data
}
