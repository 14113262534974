import { Customer } from '../../redux/store/Customers/types'
import * as S from './CustomersPurchases.styles'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from '../../redux/store/rootReducer'
import { Hidden } from 'applaus-ui-kit'
import { Purchases as PurchasesMobile } from './Purchases/Mobile/Purchases'
import { Pucharses as PurchasesDesktop } from './Purchases/Desktop/Purchases'
import { ShowRequest } from './Purchases/ShowRequest/ShowRequest'
import { ShowTicket } from './Purchases/ShowTicket/ShowTicket'
import { purchasesOrderCustomerRequest } from '../../redux/store/Purchases/actions'
import { useParams } from 'react-router-dom'

export type ModalState = {
  open: boolean
  type: 'CREATE' | 'EDIT' | 'DELETE'
  customer?: Customer
}

export const CustomerPurchases = () => {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    dispatch(purchasesOrderCustomerRequest(id))
  }, [])

  const { step } = useSelector((state: ReduxState) => state.PurchasesReducer)

  const renderPurchases = () => {
    return (
      <>
        <Hidden mobile tablet>
          {step === 'defaultPurchase' ? (
            <PurchasesDesktop />
          ) : step === 'showRequest' ? (
            <ShowRequest />
          ) : step === 'showTicket' ? (
            <ShowTicket />
          ) : (
            <PurchasesDesktop />
          )}
        </Hidden>
        <Hidden desktop>
          {step === 'defaultPurchase' ? (
            <PurchasesMobile />
          ) : step === 'showRequest' ? (
            <ShowRequest />
          ) : step === 'showTicket' ? (
            <ShowTicket />
          ) : (
            <PurchasesMobile />
          )}
        </Hidden>
      </>
    )
  }

  return (
    <>
      <S.Container>
        <S.Header>
          <div>
            <S.UserIcon />
            <S.Title>Pedidos do cliente</S.Title>
          </div>
        </S.Header>
        {renderPurchases()}
      </S.Container>
    </>
  )
}
