import { AxiosResponse } from 'axios'
import { api } from '../../api'

export type CreateBackofficeRequest = {
  firstName: string
  lastName: string
  email: string
  password: string
  groups: [string]
}

export type CreateBackofficeResponse = {
  id: string
  firstName: string
  lastName: string
  email: string
  userId: string
  type: string
  createdAt: string
  updatedAt: string | null
}

export const createBackofficeService = async (
  dataBackoffice: CreateBackofficeRequest
) => {
  const { data }: AxiosResponse<CreateBackofficeResponse> = await api.post(
    '/api/backoffice',
    dataBackoffice
  )

  return data
}
