import { Button, Heading, Input, Paragraph } from 'applaus-ui-kit'
import { useFormik } from 'formik'
import ContentModal from '../../../components/contentModal/ContentModal'
import { ModalState } from '../Withdrawals'
import * as S from './WithdrawModal.styles'
import ReactSelect from 'react-select'
import { formatPrice } from '../../../utils/formatPrice'
import { useDispatch } from 'react-redux'
import { updateWithdrawRequest } from '../../../redux/store/Withdrawals/actions'

type Props = {
  modalState: ModalState
  closeModal: () => void
}

const WithdrawStatus = [
  { label: 'Rejeitar', value: 'REJECTED' },
  { label: 'Aguardar', value: 'WAITING' },
  { label: 'Aprovar', value: 'APPROVED' }
]

export const WithdrawModal = ({ modalState, closeModal }: Props) => {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      firstName: modalState.withdrawal
        ? modalState.withdrawal.creator
          ? modalState.withdrawal.creator.firstName
          : modalState.withdrawal.eventPartner?.name
        : '',
      email: modalState.withdrawal
        ? modalState.withdrawal.creator
          ? modalState.withdrawal.creator.email
          : modalState.withdrawal.eventPartner?.email
        : '',
      bank: modalState.withdrawal
        ? modalState.withdrawal.creator
          ? modalState.withdrawal.creator.creatorPaymentSetting.bank
          : modalState.withdrawal.eventPartner?.bank
        : '',
      bankAg: modalState.withdrawal
        ? modalState.withdrawal.creator
          ? modalState.withdrawal.creator.creatorPaymentSetting.bankAg
          : modalState.withdrawal.eventPartner?.bankAg
        : '',
      bankCc: modalState.withdrawal
        ? modalState.withdrawal.creator
          ? modalState.withdrawal.creator.creatorPaymentSetting.bankCc
          : modalState.withdrawal.eventPartner?.bankCc
        : '',
      bankAccountType: modalState.withdrawal
        ? modalState.withdrawal.creator
          ? modalState.withdrawal.creator.creatorPaymentSetting.bankAccountType
          : modalState.withdrawal.eventPartner?.bankAccountType
        : '',
      eventName: modalState.withdrawal ? modalState.withdrawal.event.name : '',
      status: modalState.withdrawal ? modalState.withdrawal.status : '',
      comments: modalState.withdrawal ? modalState.withdrawal.comments : ''
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (values.status !== modalState.withdrawal?.status) {
        dispatch(
          updateWithdrawRequest({
            withdrawId: modalState.withdrawal?.id || '',
            status: values.status as 'REJECTED' | 'WAITING' | 'APPROVED',
            comments:
              values.comments !== '' && values.comments !== null
                ? values.comments
                : ''
          })
        )
        closeModal()
      }
    }
  })

  const handleCreatorInfoClick = () => {
    window.open(
      `/creators/${modalState.withdrawal && modalState.withdrawal.creator!.id}`,
      '_blank'
    )
  }

  const handleEventInfoClick = () => {
    window.open(
      `/manageEvent/${
        modalState.withdrawal && modalState.withdrawal.event!.id
      }`,
      '_blank'
    )
  }

  return (
    <ContentModal open={modalState.open}>
      <S.Container>
        <S.ModalTitle>
          <Paragraph variant="large" type="bold">
            Solicitação de saque
          </Paragraph>
          <S.IconExitModal
            onClick={() => {
              closeModal()
              formik.resetForm()
            }}
          />
        </S.ModalTitle>
        <S.Form onSubmit={formik.handleSubmit}>
          <Heading variant="h5">
            {modalState.withdrawal
              ? modalState.withdrawal.creator
                ? 'Produtor'
                : 'Parceiro'
              : ''}
          </Heading>
          <Input
            fullWidth
            name="firstName"
            label="Nome"
            placeholder="Digite o nome"
            texterror={formik.errors.firstName}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            value={formik.values.firstName}
          />

          <Input
            fullWidth
            name="email"
            label="Email"
            placeholder="email"
            texterror={formik.errors.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            value={formik.values.email}
            type="email"
          />

          <Input
            fullWidth
            name="bank"
            label="Banco"
            placeholder="Banco"
            texterror={formik.errors.bank}
            error={formik.touched.bank && Boolean(formik.errors.bank)}
            value={formik.values.bank}
          />

          <Input
            fullWidth
            name="bankAg"
            label="Agência"
            placeholder="agência"
            texterror={formik.errors.bankAg}
            error={formik.touched.bankAg && Boolean(formik.errors.bankAg)}
            value={formik.values.bankAg}
          />

          <Input
            fullWidth
            name="bankAg"
            label="Número da conta"
            placeholder="número da conta"
            texterror={formik.errors.bankCc}
            error={formik.touched.bankCc && Boolean(formik.errors.bankCc)}
            value={formik.values.bankCc}
          />

          <Input
            fullWidth
            name="bankAccountType"
            label="Tipo de conta"
            placeholder="Tipo de conta"
            texterror={formik.errors.bankAccountType}
            error={
              formik.touched.bankAccountType &&
              Boolean(formik.errors.bankAccountType)
            }
            value={formik.values.bankAccountType}
          />

          {modalState.withdrawal ? (
            modalState.withdrawal.creator ? (
              <S.Link onClick={handleCreatorInfoClick}>Ver produtor</S.Link>
            ) : undefined
          ) : undefined}

          <Input
            fullWidth
            name="eventName"
            label="Nome do evento"
            placeholder="Nome do evento"
            texterror={formik.errors.eventName}
            error={formik.touched.eventName && Boolean(formik.errors.eventName)}
            value={formik.values.eventName}
          />

          {modalState.withdrawal ? (
            <S.Link onClick={handleEventInfoClick}>Ver evento</S.Link>
          ) : undefined}

          <S.StatusWrapper>
            <S.PriceText>
              Valor Solicitado:{' '}
              <S.Price>
                {formatPrice((modalState.withdrawal?.valueCents || 0) / 100)}
              </S.Price>
            </S.PriceText>

            <div>
              <S.Label>Status</S.Label>
              <ReactSelect
                onChange={(selectValue) => {
                  if (selectValue && selectValue.value) {
                    formik.setFieldValue('status', selectValue.value)
                  }
                }}
                value={
                  WithdrawStatus.find(
                    (option) => option.value === formik.values.status
                  ) ?? {
                    value: '',
                    label: ''
                  }
                }
                placeholder="Estado"
                styles={S.customStyles}
                options={WithdrawStatus}
                noOptionsMessage={() => 'Status não disponível'}
              />
              <S.Error>
                {formik.touched.status && Boolean(formik.errors.status)
                  ? formik.errors.status
                  : null}
              </S.Error>
            </div>
          </S.StatusWrapper>
          <S.DescriptionWithdrawal>
            <label>Descrição do pedido de saque</label>
            <textarea
              name="comments"
              id=""
              maxLength={300}
              value={formik.values.comments ?? ''}
              onChange={formik.handleChange}></textarea>
          </S.DescriptionWithdrawal>

          <S.ButtonBox>
            <Button
              color="primary"
              size="small"
              variant="text"
              fullWidth
              type="button"
              onClick={() => {
                closeModal()
                formik.resetForm()
              }}>
              Cancelar
            </Button>
            <Button color="primary" size="small" variant="contained" fullWidth>
              Salvar
            </Button>
          </S.ButtonBox>
        </S.Form>
      </S.Container>
    </ContentModal>
  )
}
