import { api } from 'api/api'
import { AxiosResponse } from 'axios'
import { EditEvent } from 'redux/store/EditEvent/types'
import { isEmpty } from 'lodash'

export type Pixels = {
  facebookpixel: string
  googleanalyticspixel: string
  googletagmanagerpixel: string
}

export const updateEventPixelsService = async (
  eventId: string,
  origin: Pixels | null,
  requestData: Pixels
) => {
  const changes: any = {}
  for (const prop in origin) {
    const propName = prop as
      | 'facebookpixel'
      | 'googleanalyticspixel'
      | 'googletagmanagerpixel'
    if (
      propName.indexOf('$') !== 0 &&
      origin[propName] !== requestData[propName]
    ) {
      changes[propName] = requestData[propName]
    }
  }

  if (!isEmpty(changes)) {
    const { data }: AxiosResponse<EditEvent> = await api.patch(
      `/api/backoffice/events/${eventId}/pixels`,
      {
        pixels: {
          ...requestData
        }
      }
    )
    return data.pixels
  }
}
