import styled, { css } from 'styled-components'

import { ReactComponent as User } from '../../assets/icons/user-circle.svg'
import { ReactComponent as SearchFile } from '../../assets/icons/search.svg'

export const Container = styled.div``

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  > div:first-child {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    font-weight: ${theme.font.weight.bold};
  `}
`

export const UserIcon = styled(User)`
  fill: #061728;
  width: 48px;
  height: 48px;
`

export const SearchIcon = styled(SearchFile)`
  position: relative;
  fill: #39383a;
  left: 1.5%;
  top: 48px;
`

export const Searchnput = styled.input`
  ${({ theme }) => css`
    background: ${theme.color.neutral.nth4};
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxxs}
      ${theme.spacing.stack.xxxs} 72px;
    width: 100%;
    font-size: ${theme.font.size.sm};
    border-radius: ${theme.border.radius.medium};
    outline: 0;
    border: 0;
    margin-bottom: ${theme.spacing.stack.xxs};

    &::placeholder {
      font-size: ${theme.font.size.sm};
    }

    :focus {
      border: 1px solid ${theme.color.brand.primary.nth1};
    }
  `}
`
