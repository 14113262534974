import { Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Routes } from './routes/routes'
import history from './routes/services/history'
import { AlertModal, theme } from 'applaus-ui-kit'
import GlobalStyles from './styles/GlobalStyles'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from './redux/store/rootReducer'
import { clearModalState } from './redux/store/Modal/Actions/actionsModal'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { logoutAction, setUser } from './redux/store/User/actions'
import { Loading } from './components/loading/Loading'
import { getBackofficeUserInfoService } from './api/user/getBackofficeUserInfoService'
import { refreshTokenService } from './api/services/refreshToken/refreshTokenService'

function App() {
  const dispatch = useDispatch()
  const { ModalReducer } = useSelector((state: ReduxState) => state)
  // eslint-disable-next-line no-unused-vars
  const [getCookie, _, removeCookie] = useCookies(['applaus-auth-backoffice'])

  const { LoadingReducer, UserReducer } = useSelector(
    (state: ReduxState) => state
  )

  useEffect(() => {
    const getBackOfficeUser = async (refreshToken: string) => {
      const refresh = await refreshTokenService(refreshToken)
      const data = await getBackofficeUserInfoService(refresh.accessToken)
      dispatch(
        setUser({
          name: data.firstName,
          email: data.lastName,
          accessToken: refresh.accessToken,
          refreshToken: refresh.refreshToken
        })
      )
      removeCookie('applaus-auth-backoffice', {
        path: '/',
        domain: process.env.REACT_APP_DOMAIM
      })
    }
    if (getCookie['applaus-auth-backoffice']) {
      getBackOfficeUser(getCookie['applaus-auth-backoffice'].refreshToken)
    } else if (
      UserReducer.accessToken === '' &&
      !process.env.REACT_APP_DOMAIM
    ) {
      console.log('dispatch')
      dispatch(logoutAction())
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      {UserReducer.accessToken && (
        <Router history={history}>
          <Routes />
          <GlobalStyles />
        </Router>
      )}
      <AlertModal
        open={ModalReducer.open}
        variant={ModalReducer.variant}
        message={ModalReducer.message}
        description={ModalReducer.description}
        buttonText="Ok"
        click={
          ModalReducer.click
            ? ModalReducer.click
            : () => dispatch(clearModalState())
        }
      />
      <Loading
        isLoading={LoadingReducer.isLoading}
        blur={LoadingReducer.blur}
      />
    </ThemeProvider>
  )
}

export default App
