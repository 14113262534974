import {
  Paragraph,
  Input,
  RadioButton,
  Button,
  AlertModal
} from 'applaus-ui-kit'
import { useFormik } from 'formik'
import produce from 'immer'
import _ from 'lodash'
import { useState } from 'react'
import { CurrencyInput } from '../../../../../../components/CurrencyInput/CurrencyInput'
import { useDispatch, useSelector } from 'react-redux'
import Select, { MultiValue } from 'react-select'
import { createTicketService } from '../../../../../../api/services/tickets/createTicketService'
import {
  setLoading,
  clearLoading
} from '../../../../../../redux/store/Loading/actions'
import { ReduxState } from '../../../../../../redux/store/rootReducer'
import { createTicketValidation } from '../validationSchema'
import * as S from './TabulatedTicket.styles'
import { addTicketEventItem } from '../../../../../../redux/store/EditEvent/actions'
import { useWindowSize } from '../../../../../../hooks/useWindowSize'

type Props = {
  setCreateTicket: () => void
}

type SessionTickets = {
  value: string
  tickets?: number
}

type AlertModalState = {
  message: string
  buttonText: string
  variant: 'error' | 'success'
  click: () => void
  open: boolean
}

export const TabulatedTicket = ({ setCreateTicket }: Props) => {
  const { editEvent } = useSelector(
    (state: ReduxState) => state.EditEventReducer
  )
  const { eventData } = useSelector(
    (state: ReduxState) => state.ManageEventReducer
  )
  const media = useWindowSize()
  const dispatch = useDispatch()

  const [selectedValue, setSelectedValue] = useState<string[]>([])
  const [ticketInfo, setTicketInfo] = useState<SessionTickets[]>([])
  const [typeTicket, setTypeTicket] = useState('')
  const [isFree, setIsFree] = useState(false)

  const [alertModalState, setAlertModalState] = useState<AlertModalState>({
    open: false,
    buttonText: '',
    variant: 'error',
    click: () => {},
    message: ''
  })

  const handleChange = (e: MultiValue<{ value: string; label: string }>) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : [])

    setTicketInfo(
      produce((draft) => {
        if (draft.length === 0) {
          return e.map((e) => ({ value: e.value, tickets: 1 }))
        } else {
          draft.map((ticketInfo) => {
            const newTicketInfo = _.find(e, function (e) {
              return e.value !== ticketInfo.value
            })

            if (newTicketInfo) {
              return draft.push({ value: newTicketInfo.value, tickets: 1 })
            } else return draft
          })
        }
      })
    )
  }

  const removeSelected = (value: string) => {
    setSelectedValue((prev) => prev.filter((select) => select !== value))
    setTicketInfo((prev) =>
      _.filter(prev, function (o) {
        return o.value !== value
      })
    )
  }

  const options = editEvent!.eventItems!.map(({ id, title }) => ({
    value: id ?? '',
    label: title
  }))

  const clearModal = () => {
    setAlertModalState({
      open: false,
      buttonText: '',
      variant: 'error',
      click: () => {},
      message: ''
    })
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      priceCents: '',
      discountCents: '',
      rule: '',
      type: '',
      ticketInfo: ticketInfo.map(({ tickets, value }) => ({ [value]: tickets }))
    },
    enableReinitialize: true,
    validationSchema: createTicketValidation,
    onSubmit: async (values) => {
      try {
        const eventItemsTicket = values.ticketInfo.map((info) => ({
          eventItemId: Object.keys(info)[0],
          ticketQuantity: Object.values(info)[0]
            ? Number(Object.values(info)[0]) > 0
              ? Number(Object.values(info)[0])
              : 1
            : 1
        }))

        if (eventItemsTicket.length < 1) {
          setAlertModalState({
            open: true,
            click: clearModal,
            variant: 'error',
            message: 'Ticket deve ter pelo menos uma sessão',
            buttonText: 'Fechar'
          })
        } else {
          dispatch(setLoading())
          const dataTicket = []
          for (let i = 0; i < eventItemsTicket.length; i++) {
            const data = await createTicketService(
              {
                category: values.name,
                name: values.name,
                type: typeTicket === 'Outros' ? values.type : typeTicket,
                rule: values.rule !== '' ? values.rule : undefined,
                description: isFree ? 'Gratuito' : 'Pago',
                priceCents: isFree
                  ? undefined
                  : values.priceCents.includes(',')
                  ? values.priceCents.includes('.')
                    ? Number(
                        values.priceCents
                          .replace(/[^0-9]/g, '')
                          .replace(',', '')
                      )
                    : Number(
                        values.priceCents
                          .replace(/[^0-9]/g, '')
                          .replace(',', '')
                      )
                  : Number(values.priceCents.replace(/[^0-9]/g, '')) * 100,
                discountCents: isFree ? undefined : 0,
                isFree: isFree,
                eventItemsTicket: [
                  {
                    eventItemId: eventItemsTicket[i].eventItemId,
                    ticketQuantity: eventItemsTicket[i].ticketQuantity
                  }
                ]
              },
              eventData!.creatorId
            )
            if (data) {
              dataTicket.push(data)
              dispatch(addTicketEventItem(data, 'create'))
            }
          }

          const filteredTicket =
            editEvent?.tickets !== undefined
              ? editEvent.tickets.map(
                  (ticket) =>
                    ticket.type === 'Inteira' || ticket.type === 'Meia'
                )
              : []

          if (
            dataTicket &&
            filteredTicket.length === 0 &&
            (values.type === 'Inteira' || values.type === 'Meia')
          ) {
            const data = await createTicketService(
              {
                category: typeTicket === 'Inteira' ? 'Meia' : 'Inteira',
                name: typeTicket === 'Inteira' ? 'Meia' : 'Inteira',
                type: typeTicket === 'Inteira' ? 'Meia' : 'Inteira',
                rule: values.rule !== '' ? values.rule : undefined,
                description: isFree ? 'Gratuito' : 'Pago',
                priceCents:
                  !isFree && typeTicket === 'Meia'
                    ? values.priceCents.includes(',')
                      ? values.priceCents.includes('.')
                        ? Number(
                            values.priceCents
                              .replace(/[^0-9]/g, '')
                              .replace(',', '')
                          ) * 2
                        : Number(
                            values.priceCents
                              .replace(/[^0-9]/g, '')
                              .replace(',', '')
                          ) * 2
                      : Number(values.priceCents.replace(/[^0-9]/g, '')) * 100
                    : !isFree && typeTicket === 'Inteira'
                    ? values.priceCents.includes(',')
                      ? values.priceCents.includes('.')
                        ? (Number(
                            values.priceCents
                              .replace(/[^0-9]/g, '')
                              .replace(',', '')
                          ) *
                            100) /
                          2
                        : Number(
                            values.priceCents
                              .replace(/[^0-9]/g, '')
                              .replace(',', '')
                          ) / 2
                      : (Number(values.priceCents.replace(/[^0-9]/g, '')) *
                          100) /
                        2
                    : undefined,
                discountCents: isFree ? undefined : 0,
                isFree: isFree,
                eventItemsTicket: eventItemsTicket
              },
              eventData!.creatorId
            )
            if (data) {
              dispatch(addTicketEventItem(data, 'create'))
            }
          }

          setCreateTicket()

          dispatch(clearLoading())
        }
      } catch (e) {
        dispatch(clearLoading())
      }
    }
  })

  const optionsType = [
    {
      value: 'Inteira',
      label: 'Inteira'
    },
    {
      value: 'Meia',
      label: 'Meia'
    },
    {
      value: 'Outros',
      label: 'Outros'
    }
  ]
  return (
    <>
      <S.Container>
        <Paragraph variant="regular" type="semiBold">
          *Selecione sessão
        </Paragraph>
        <Select
          className="dropdown"
          placeholder="Selecione a sessão "
          value={options.filter((obj) => selectedValue.includes(obj.value))}
          options={options}
          onChange={(e) => handleChange(e)}
          styles={S.CustomStyles}
          controlShouldRenderValue={false}
          isMulti
          isClearable
        />
        <S.SelectedSections>
          {options
            .filter((obj) => selectedValue.includes(obj.value))
            .map((selected) => (
              <S.SelectedSectionCard key={selected.value}>
                <Paragraph variant="regular" type="semiBold">
                  {selected.label}
                </Paragraph>
                <S.RemoveIcon onClick={() => removeSelected(selected.value)} />
              </S.SelectedSectionCard>
            ))}
        </S.SelectedSections>

        <S.TicketPerSession>
          <Paragraph variant="regular" type="semiBold">
            Quantidade de ingressos disponíveis
          </Paragraph>
          <div className="sessionInputs">
            {formik.values.ticketInfo.map((info, index) => {
              return (
                <Input
                  key={index}
                  label={
                    _.find(options, function (e) {
                      return e.value === Object.keys(info)[0]
                    })?.label
                  }
                  value={Object.values(info)[0]}
                  name={`ticketInfo.${index}.${Object.keys(info)}`}
                  onChange={formik.handleChange}
                  type="number"
                  min="1"
                />
              )
            })}
          </div>
        </S.TicketPerSession>
        <S.TicketTypeSelection>
          <Paragraph variant="regular" type="semiBold">
            Tipo do ingresso
          </Paragraph>
          <div className="optionsWrapper">
            <S.RadioButtonBox>
              <RadioButton
                checked={!isFree}
                readOnly
                onClick={() => {
                  formik.setFieldValue('priceCents', '')
                  formik.setFieldValue('discountCents', '')
                  setIsFree(false)
                }}
              />
              <Paragraph variant="regular">Ingresso Pago</Paragraph>
            </S.RadioButtonBox>
            <S.RadioButtonBox>
              <RadioButton
                checked={isFree}
                readOnly
                onClick={() => {
                  formik.setFieldValue('priceCents', '100')
                  formik.setFieldValue('discountCents', '100')
                  setIsFree(true)
                }}
              />
              <Paragraph variant="regular">Ingresso Gratuito</Paragraph>
            </S.RadioButtonBox>
          </div>
        </S.TicketTypeSelection>
        <S.TicketInfoBox>
          <div className="inputBox">
            <Input
              name="name"
              label="*Título do ingresso"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              texterror={formik.errors.name}
              fullWidth={!!media.isMobile}
            />
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                width: media.isMobile ? '100%' : '244px'
              }}>
              <div
                style={{
                  width: media.isMobile ? '100%' : '244px',
                  marginRight: '16px',
                  marginBottom: '24px'
                }}>
                <S.Label>Tipo do ingresso</S.Label>
                <Select
                  placeholder="Tipo do ingresso"
                  options={optionsType}
                  onChange={(e: any) => {
                    if (e && e?.value !== 'Outros') {
                      formik.setFieldValue('type', e?.value)
                      setTypeTicket(e?.value)
                    } else if (e && e?.value === 'Outros') {
                      setTypeTicket(e?.value)
                      formik.setFieldValue('type', '')
                    }
                  }}
                  value={
                    typeTicket === 'Outros'
                      ? [
                          {
                            label: 'Outros',
                            value: 'Outros'
                          }
                        ]
                      : formik.values.type
                      ? [
                          {
                            label: formik.values.type,
                            value: formik.values.type
                          }
                        ]
                      : null
                  }
                  styles={S.customStyles}
                />
              </div>
              {typeTicket === 'Outros' ? (
                <div className="inputType">
                  <Input
                    name="type"
                    label="Tipo do ingresso"
                    onChange={formik.handleChange}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                  />
                  {formik.touched.type && Boolean(formik.errors.type) ? (
                    <S.Error>{formik.errors.type}</S.Error>
                  ) : (
                    <S.Error></S.Error>
                  )}
                </div>
              ) : null}
            </div>
          </div>

          {!isFree && (
            <S.PriceBox>
              <CurrencyInput
                className="input"
                label="*Preço"
                decimalsLimit={2}
                intlConfig={{ locale: 'pt-br', currency: 'BRL' }}
                value={formik.values.priceCents}
                onValueChange={(value) =>
                  formik.setFieldValue('priceCents', value)
                }
                texterror={formik.errors.priceCents}
                error={
                  formik.touched.priceCents && Boolean(formik.errors.priceCents)
                }
              />
            </S.PriceBox>
          )}
        </S.TicketInfoBox>
        <Input
          name="rule"
          label="*Em caso de meia-entrada ou descontos explique regras para obter o ingresso:"
          value={formik.values.rule}
          onChange={formik.handleChange}
          error={formik.touched.rule && Boolean(formik.errors.rule)}
          texterror={formik.errors.rule}
        />
        <Button
          variant="contained"
          color="primary"
          size="medium"
          fullWidth={false}
          onClick={formik.submitForm}>
          Criar Ingresso
        </Button>
      </S.Container>
      <AlertModal {...alertModalState} />
    </>
  )
}
