/* eslint-disable no-unused-vars */
import { ReactNode } from 'react'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import { Route, RouteProps } from 'react-router'
import { ReduxState } from '../redux/store/rootReducer'

type PrivateRouteProps = RouteProps & {
  component: ReactNode
}

export const PrivateRoute = ({ component, ...rest }: PrivateRouteProps) => {
  const { accessToken } = useSelector((state: ReduxState) => state.UserReducer)
  const urlLogin = process.env.REACT_APP_AUTH_URL

  const [cookie] = useCookies(['applaus-auth-backoffice'])

  // if (accessToken === '' && !cookie['applaus-auth-backoffice']) {
  //   window.location.href = urlLogin ?? ''
  // }

  return <Route {...rest} component={component} />
}
