import { CheckBox } from 'applaus-ui-kit'
import { ReactNode } from 'react'
import * as S from './TableContainer.styles'

type TableContainerProps = {
  table: ReactNode
  footer?: ReactNode
  excludeItems?: number
  checked?: boolean
  checkboxClick?: (isChecked: boolean) => void
  excludeClick?: () => void
}

export const TableContainer = ({
  footer,
  table,
  excludeItems,
  checkboxClick,
  excludeClick,
  checked
}: TableContainerProps) => {
  return (
    <S.TableWrapper>
      {excludeItems && checkboxClick && excludeItems > 0 ? (
        <S.ExcludeItems>
          <S.CheckboxWrapper>
            <CheckBox
              name="checkbox"
              checked={checked}
              onClick={() => checkboxClick(!checked)}
              readOnly
            />
            <S.ItemsQuantity>{excludeItems} ítens selecionados</S.ItemsQuantity>
          </S.CheckboxWrapper>
          <S.ExcludeIcon onClick={excludeClick} />
        </S.ExcludeItems>
      ) : null}
      <S.TableContainer
        hideTableHeader={excludeItems ? excludeItems > 0 : false}>
        {table}
      </S.TableContainer>
      {footer}
    </S.TableWrapper>
  )
}
