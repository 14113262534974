import { Button, Input, Paragraph, RadioButton } from 'applaus-ui-kit'
import { useEffect, useState } from 'react'
import * as S from './BannerModal.styles'

import PreImage from '../../../assets/icons/pre-image.png'
import { useFormik } from 'formik'
import {
  validationSchemaCreate,
  validationSchemaEdit
} from './validationSchema'
import { messageHelper } from '../../../utils/messageHelper'
import { useDispatch } from 'react-redux'
import {
  createBannerRequest,
  editBannerRequest
} from '../../../redux/store/Banners/Actions/bannerActions'
import ContentModal from '../../../components/contentModal/ContentModal'
import Tab from '../../../components/tab/Tab'
import { ModalState } from '../Banners'
import { EditBannerRequest } from '../../../api/services/banners/editBannerService'

export type BannerModalProps = {
  closeModal: () => void
  modalState: ModalState
}

export const BannerModal = ({ closeModal, modalState }: BannerModalProps) => {
  const dispatch = useDispatch()
  const [checked, setChecked] = useState(true)
  const [linkTarget, setLinkTarget] = useState<{
    target?: 'INTERNAL' | 'EXTERNAL'
    checked: boolean
  }>({ checked: true, target: 'INTERNAL' })
  const [preImage, setPreImage] = useState<{
    desktopUrl?: any
    tabletUrl?: any
    mobileUrl?: any
  }>({ desktopUrl: PreImage, mobileUrl: PreImage, tabletUrl: PreImage })

  const [tabOptionsState, setTabOptionsState] = useState({
    desktop: true,
    tablet: false,
    mobile: false
  })

  const handleResetForm = () => {
    setChecked(true)
    setPreImage({
      desktopUrl: PreImage,
      mobileUrl: PreImage,
      tabletUrl: PreImage
    })
    formik.setValues({
      nameBanner: '',
      descriptionBanner: '',
      desktopUrl: '',
      mobileUrl: '',
      tabletUrl: '',
      link: ''
    })
  }

  const formik = useFormik({
    initialValues: {
      nameBanner: '',
      descriptionBanner: '',
      desktopUrl: '',
      tabletUrl: '',
      mobileUrl: '',
      link: ''
    },
    validationSchema: modalState.banner
      ? validationSchemaEdit
      : validationSchemaCreate,
    onSubmit: (values) => {
      formik.setErrors({})
      closeModal()

      if (modalState.type === 'CREATE') {
        dispatch(
          createBannerRequest({
            name: values.nameBanner,
            description: values.descriptionBanner,
            desktopUrl: values.desktopUrl,
            link: 'www.google.com',
            linkTarget: 'INTERNAL',
            mobileUrl: values.mobileUrl,
            tabletUrl: values.tabletUrl,
            status: checked
          })
        )
      } else if (modalState.type === 'EDIT' && modalState.banner) {
        const requestValue: EditBannerRequest = {
          description:
            modalState.banner.description !== values.descriptionBanner
              ? values.descriptionBanner
              : undefined,
          desktopUrl:
            modalState.banner.desktopUrl !== values.desktopUrl
              ? values.desktopUrl
              : undefined,
          link:
            modalState.banner.link !== values.link ? values.link : undefined,
          linkTarget:
            modalState.banner.linkTarget !== linkTarget.target
              ? linkTarget.target
              : undefined,
          mobileUrl:
            modalState.banner.mobileUrl !== values.mobileUrl
              ? values.mobileUrl
              : undefined,
          name:
            modalState.banner.name !== values.nameBanner
              ? values.nameBanner
              : undefined,
          status: modalState.banner.status !== checked ? checked : undefined,
          tabletUrl:
            modalState.banner.tabletUrl !== values.tabletUrl
              ? values.tabletUrl
              : undefined
        }

        dispatch(
          editBannerRequest(
            Object.fromEntries(
              Object.entries(requestValue).filter(([_, v]) => v !== undefined)
            ),
            modalState.banner.id
          )
        )
      }
    }
  })

  const handleOption = (options: 'desktop' | 'tablet' | 'mobile') => {
    if (options === 'desktop') {
      setTabOptionsState({ desktop: true, tablet: false, mobile: false })
    } else if (options === 'tablet') {
      setTabOptionsState({ desktop: false, tablet: true, mobile: false })
    } else {
      setTabOptionsState({ desktop: false, tablet: false, mobile: true })
    }
  }

  useEffect(() => {
    if (modalState.type === 'EDIT' && modalState.banner) {
      formik.setValues({
        descriptionBanner: modalState.banner.description,
        desktopUrl: modalState.banner.desktopUrl,
        mobileUrl: modalState.banner.mobileUrl,
        nameBanner: modalState.banner.name,
        tabletUrl: modalState.banner.tabletUrl,
        link: modalState.banner.link
      })

      setPreImage({
        desktopUrl: modalState.banner.desktopUrl,
        mobileUrl: modalState.banner.mobileUrl,
        tabletUrl: modalState.banner.tabletUrl
      })
    }
  }, [modalState.banner])

  return (
    <ContentModal open={modalState.open}>
      <S.Container>
        <S.ModalTitle>
          <Paragraph variant="large" type="bold">
            {modalState.type === 'CREATE'
              ? 'Cadastrar banner'
              : 'Editar banner'}
          </Paragraph>
          <S.IconExitModal
            onClick={() => {
              closeModal()
              handleResetForm()
            }}
          />
        </S.ModalTitle>

        <S.Form onSubmit={formik.handleSubmit}>
          <Input
            fullWidth
            name="nameBanner"
            label="Nome"
            placeholder={messageHelper.input.banner.enterBannerName}
            texterror={formik.errors.nameBanner}
            error={
              formik.touched.nameBanner && Boolean(formik.errors.nameBanner)
            }
            value={formik.values.nameBanner}
            onChange={formik.handleChange}
          />

          <Input
            fullWidth
            name="descriptionBanner"
            label="Descrição"
            placeholder={messageHelper.input.banner.enterBannerDescription}
            texterror={formik.errors.descriptionBanner}
            error={
              formik.touched.descriptionBanner &&
              Boolean(formik.errors.descriptionBanner)
            }
            value={formik.values.descriptionBanner}
            onChange={formik.handleChange}
          />

          <S.Status>
            <Paragraph variant="regular" type="semiBold">
              Status
            </Paragraph>
            <div className="boxStatus">
              <S.ActiveStatus>
                <RadioButton
                  checked={checked}
                  readOnly
                  onClick={() => {
                    setChecked(true)
                  }}
                />
                <S.TextStatus>Ativado</S.TextStatus>
              </S.ActiveStatus>

              <S.DisabledStatus>
                <RadioButton
                  checked={!checked}
                  readOnly
                  onClick={() => setChecked(!checked)}
                />
                <S.TextStatus>Desativado</S.TextStatus>
              </S.DisabledStatus>
            </div>
          </S.Status>

          <Input
            name="link"
            label="Link"
            placeholder={messageHelper.input.banner.enterBannerDescription}
            texterror={formik.errors.link}
            error={formik.touched.link && Boolean(formik.errors.link)}
            value={formik.values.link}
            onChange={formik.handleChange}
            fullWidth
          />

          <S.LinkTarget>
            <Paragraph variant="regular" type="semiBold">
              Link target
            </Paragraph>
            <div className="boxStatus">
              <S.LinkInternal>
                <RadioButton
                  checked={linkTarget.checked}
                  readOnly
                  onClick={() => {
                    setLinkTarget({ checked: true, target: 'INTERNAL' })
                  }}
                />
                <S.TextLinkTarget>Internal</S.TextLinkTarget>
              </S.LinkInternal>

              <S.LinkExternal>
                <RadioButton
                  checked={!linkTarget.checked}
                  readOnly
                  onClick={() =>
                    setLinkTarget({ checked: false, target: 'EXTERNAL' })
                  }
                />
                <S.TextLinkTarget>External</S.TextLinkTarget>
              </S.LinkExternal>
            </div>
          </S.LinkTarget>

          <S.Banner>
            <Paragraph variant="large" type="bold">
              Banners
            </Paragraph>
            <S.TabOptions>
              <Tab
                title="Desktop"
                widthTab={{ mobile: 40, tablet: 20, desktop: 15 }}
                click={() => handleOption('desktop')}
                colorTab={tabOptionsState.desktop}
              />
              <Tab
                title="Tablet"
                widthTab={{ mobile: 40, tablet: 20, desktop: 15 }}
                click={() => handleOption('tablet')}
                colorTab={tabOptionsState.tablet}
              />
              <Tab
                title="Mobile"
                widthTab={{ mobile: 40, tablet: 20, desktop: 15 }}
                click={() => handleOption('mobile')}
                colorTab={tabOptionsState.mobile}
              />
            </S.TabOptions>
            <S.BannerInput disable={tabOptionsState.desktop}>
              <S.UploadImage>
                <S.PreviewImage src={preImage.desktopUrl} />
                <S.TextRecommended>
                  Tamanho recomendado 450x350px
                </S.TextRecommended>
                <div className="buttonUploadImage">
                  <S.Label htmlFor="inputFileDesktop">Carregar imagem</S.Label>

                  <S.InputFile
                    id="inputFileDesktop"
                    name="desktopUrl"
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      if (event.target.files) {
                        console.log('oi')

                        formik.setFieldValue(
                          'desktopUrl',
                          event.target.files[0]
                        )
                        const reader = new FileReader()
                        reader.readAsDataURL(event.target.files[0])
                        reader.addEventListener('load', () => {
                          if (typeof reader.result === 'string') {
                            setPreImage((prev) => ({
                              ...prev,
                              desktopUrl: reader.result
                            }))
                          }
                        })
                      }
                    }}
                  />
                  <S.Error>{formik.errors.desktopUrl ?? ''}</S.Error>
                </div>
              </S.UploadImage>
            </S.BannerInput>

            <S.BannerInput disable={tabOptionsState.tablet}>
              <S.UploadImage>
                <S.PreviewImage src={preImage.tabletUrl} />
                <S.TextRecommended>
                  Tamanho recomendado 351x350px
                </S.TextRecommended>
                <div className="buttonUploadImage">
                  <S.Label htmlFor="inputFileTablet">Carregar imagem</S.Label>

                  <S.InputFile
                    id="inputFileTablet"
                    name="tabletUrl"
                    type="file"
                    accept="image/*"
                    className="tttt"
                    onChange={(event) => {
                      if (event.target.files) {
                        formik.setFieldValue('tabletUrl', event.target.files[0])
                        const reader = new FileReader()
                        reader.readAsDataURL(event.target.files[0])
                        reader.addEventListener('load', () => {
                          if (typeof reader.result === 'string') {
                            setPreImage((prev) => ({
                              ...prev,
                              tabletUrl: reader.result
                            }))
                          }
                        })
                      }
                    }}
                  />
                  <S.Error>{formik.errors.tabletUrl ?? ''}</S.Error>
                </div>
              </S.UploadImage>
            </S.BannerInput>

            <S.BannerInput disable={tabOptionsState.mobile}>
              <S.UploadImage>
                <S.PreviewImage src={preImage.mobileUrl} />
                <S.TextRecommended>
                  Tamanho recomendado 250x350px
                </S.TextRecommended>
                <div className="buttonUploadImage">
                  <S.Label htmlFor="inputFileMobile">Carregar imagem</S.Label>

                  <S.InputFile
                    id="inputFileMobile"
                    name="mobileUrl"
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      if (event.target.files) {
                        formik.setFieldValue('mobileUrl', event.target.files[0])
                        const reader = new FileReader()
                        reader.readAsDataURL(event.target.files[0])
                        reader.addEventListener('load', () => {
                          if (typeof reader.result === 'string') {
                            setPreImage((prev) => ({
                              ...prev,
                              mobileUrl: reader.result
                            }))
                          }
                        })
                      }
                    }}
                  />
                  <S.Error>{formik.errors.mobileUrl ?? ''}</S.Error>
                </div>
              </S.UploadImage>
            </S.BannerInput>

            <S.ButtonBox>
              <Button
                color="primary"
                size="large"
                variant="text"
                fullWidth
                type="button"
                onClick={() => {
                  closeModal()
                  handleResetForm()
                }}>
                Cancelar
              </Button>
              <Button
                color="primary"
                size="large"
                variant="contained"
                fullWidth>
                {modalState.type === 'CREATE' ? 'Cadastrar' : 'Editar'}
              </Button>
            </S.ButtonBox>
          </S.Banner>
        </S.Form>
      </S.Container>
    </ContentModal>
  )
}
