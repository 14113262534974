import { ReactNode } from 'react'
import * as S from './TableFooter.styles'

type TableFooterProps = {
  children: ReactNode
}

export const TableFooter = ({ children }: TableFooterProps) => {
  return <S.Container>{children}</S.Container>
}
