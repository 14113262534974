import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const Prev = styled.button`
  ${() => css`
    font-size: 3rem;
    outline: 0;
    border: none;
    background: none;
    padding: 0;
    &:disabled {
      color: gray;
    }
  `}
`

export const Next = styled.button`
  ${() => css`
    font-size: 3rem;
    outline: 0;
    border: none;
    background: none;
    padding: 0;
    &:disabled {
      color: gray;
    }
  `}
`

type PaginationItemProps = {
  active?: boolean
}

export const PaginationItem = styled.p<PaginationItemProps>`
  ${({ theme, active }) => css`
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.semiBold};
    line-height: 3rem;
    color: ${theme.color.base.nth1};
    margin: 0 1rem;
    cursor: pointer;
    ${active
      ? css`
          padding: 3px 12px 5px;
          background: ${theme.color.brand.tertiary.nth1};
          border-radius: ${theme.border.radius.light};
          color: ${theme.color.other.typea};
        `
      : null}
  `}
`
