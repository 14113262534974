import styled, { css } from 'styled-components'

import { Link as LinkRouter } from 'react-router-dom'

import { ReactComponent as UserSolid } from 'assets/icons/user-circle-solid.svg'

import { ReactComponent as BannerSolid } from 'assets/icons/banner-solid.svg'

import { ReactComponent as Menu } from 'assets/icons/menu.svg'

import { ReactComponent as Home } from 'assets/icons/home.svg'
import { ReactComponent as HomeFilled } from 'assets/icons/home-Filled.svg'

import { ReactComponent as BackofficeFile } from 'assets/icons/backoffice.svg'
import { ReactComponent as BannersFile } from 'assets/icons/banners.svg'
import { ReactComponent as CalendarFile } from 'assets/icons/calendar.svg'
import { ReactComponent as CustomerFile } from 'assets/icons/customer.svg'
import { ReactComponent as MapFile } from 'assets/icons/map.svg'
import { ReactComponent as ProducerFile } from 'assets/icons/producer.svg'

import { ReactComponent as CouponFile } from 'assets/icons/coupon.svg'

export const CouponIcon = styled(CouponFile)`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    fill: ${theme.color.base.nth3};
    @media (min-width: 768px) {
      fill: ${theme.color.base.nth3};
      min-width: ${theme.spacing.stack.xs};
      min-height: ${theme.spacing.stack.xs};
    }
  `}
`

export const CouponFilled = styled(CouponFile)`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    fill: ${theme.color.base.nth1};
    @media (min-width: 1171px) {
      fill: ${theme.color.neutral.nth4};
      min-width: ${theme.spacing.stack.xs};
      min-height: ${theme.spacing.stack.xs};
    }
  `}
`

export const Container = styled.div<{ showNavigation: boolean }>`
  ${({ theme, showNavigation }) => css`
    transition: opacity 0.3s ease-in-out;
    opacity: ${showNavigation ? '1' : '0'};
    pointer-events: ${showNavigation ? 'all' : 'none'};
    background: ${theme.color.neutral.nth4};
    position: fixed;
    overflow-y: auto;
    display: block;
    height: 40%;
    width: 100%;
    top: 0;
    padding: ${theme.spacing.stack.xxs};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
    z-index: 1000;
    @media (min-width: 1171px) {
      height: 100%;
      width: 96px;
      opacity: 1;
      pointer-events: all;
      background: #061728;
    }
  `}
`

export const HideIconBox = styled.div`
  display: flex;
  @media (min-width: 1171px) {
    display: none;
  }
`

export const HideIcon = styled(Menu)`
  ${({ theme }) => css`
    fill: ${theme.color.base.nth1};
    cursor: pointer;
  `}
`

export const Ul = styled.ul`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.stack.xxxs};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.stack.small};
    @media (min-width: 1171px) {
      gap: ${theme.spacing.stack.md};
    }
  `}
`

export const Divider = styled.div`
  ${({ theme }) => css`
    border: 1px solid #ebecee;
    margin: ${theme.spacing.stack.nano} 0;
    @media (min-width: 1171px) {
      display: none;
    }
  `}
`

export const Li = styled.li`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
  `}
`

export const Link = styled(LinkRouter)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    text-decoration: none;
    column-gap: ${theme.spacing.stack.small};
    @media (min-width: 1171px) {
      flex-direction: column;
    }
  `}
`

export const UserIcon = styled(CustomerFile)`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    @media (min-width: 768px) {
      fill: ${theme.color.base.nth3};
    }
  `}
`

export const UserIconFilled = styled(CustomerFile)`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    fill: ${theme.color.base.nth1};
    @media (min-width: 1171px) {
      fill: ${theme.color.neutral.nth4};
    }
  `}
`

export const UserSolidIcon = styled(UserSolid)`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    fill: ${theme.color.base.nth1};
    @media (min-width: 1171px) {
      fill: ${theme.color.neutral.nth4};
    }
  `}
`

export const UserBackofficeIcon = styled(BackofficeFile)`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    fill: ${theme.color.base.nth3};
  `}
`

export const UserBackofficeFilledIcon = styled(BackofficeFile)`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    fill: ${theme.color.base.nth1};
    @media (min-width: 1171px) {
      fill: ${theme.color.neutral.nth4};
    }
  `}
`

export const HomeIcon = styled(Home)`
  ${({ theme }) => css`
    width: 32px;
    height: 32px;
    font-size: ${theme.font.size.md};
    fill: ${theme.color.base.nth3};
  `}
`

export const HomeFilledIcon = styled(HomeFilled)`
  ${({ theme }) => css`
    width: 28px;
    height: 28px;
    margin-left: 2px;
    font-size: ${theme.font.size.md};
    fill: ${theme.color.base.nth1};
    @media (min-width: 1171px) {
      fill: ${theme.color.neutral.nth4};
    }
  `}
`

export const BannerIcon = styled(BannersFile)`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    fill: ${theme.color.base.nth3};
  `}
`

export const BannerSolidIcon = styled(BannerSolid)`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    fill: ${theme.color.base.nth1};
    @media (min-width: 1171px) {
      fill: ${theme.color.neutral.nth4};
    }
  `}
`

export const TextLink = styled.p<{ active: boolean }>`
  ${({ theme, active }) => css`
    color: ${active ? theme.color.base.nth1 : theme.color.base.nth3};
    font-size: ${theme.font.size.xs};
    line-height: 18px;
    text-align: center;
    @media (min-width: 1171px) {
      color: ${active ? theme.color.neutral.nth4 : theme.color.base.nth3};
    }
  `}
`

export const ProducerIcon = styled(ProducerFile)`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    @media (min-width: 768px) {
      fill: ${theme.color.base.nth3};
    }
  `}
`

export const ProducerSolidIcon = styled(ProducerFile)`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    fill: ${theme.color.base.nth1};
    @media (min-width: 1171px) {
      fill: ${theme.color.neutral.nth4};
    }
  `}
`

export const CalendarIcon = styled(CalendarFile)`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    fill: ${theme.color.base.nth3};
  `}
`
export const CalendarIconFilled = styled(CalendarFile)`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    fill: ${theme.color.base.nth1};
    @media (min-width: 1171px) {
      fill: ${theme.color.neutral.nth4};
    }
  `}
`

export const MapIcon = styled(MapFile)`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    fill: ${theme.color.base.nth3};
  `}
`

export const MapIconFilled = styled(MapFile)`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    font-size: ${theme.font.size.md};
    fill: ${theme.color.base.nth1};
    @media (min-width: 1171px) {
      fill: ${theme.color.neutral.nth4};
    }
  `}
`
