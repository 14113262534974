import styled, { css } from 'styled-components'

type ContainerProps = {
  open: boolean
}

export const Container = styled.div<ContainerProps>`
  ${({ theme, open }) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    ${open ? `background: ${theme.color.modal.nth1}` : 'display: none'};
  `}
`
