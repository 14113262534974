import { action } from 'typesafe-actions'
import {
  CreateBackofficeRequest,
  CreateBackofficeResponse
} from '../../../api/services/userBackoffice/createBackofficeService'
import {
  EditBackofficeRequest,
  EditBackofficeResponse
} from '../../../api/services/userBackoffice/editBackofficeService'
import { AllUsersResponse } from '../../../api/services/userBackoffice/getBackofficeService'
import { BackofficeActions } from './types'

export const getBackofficeRequest = (skip: number, currentPage: number) =>
  action(BackofficeActions.GET_BACKOFFICE_REQUEST, { skip, currentPage })

export const getBackofficeSuccess = (
  data: AllUsersResponse,
  skip: number,
  currentPage: number
) =>
  action(BackofficeActions.GET_BACKOFFICE_SUCCESS, { data, skip, currentPage })

export const createBackofficeRequest = (data: CreateBackofficeRequest) =>
  action(BackofficeActions.CREATE_BACKOFFICE_REQUEST, data)

export const createBackofficeSuccess = (data: CreateBackofficeResponse) =>
  action(BackofficeActions.CREATE_BACKOFFICE_SUCCESS, data)

export const editBackofficeRequest = (data: EditBackofficeRequest) =>
  action(BackofficeActions.EDIT_BACKOFFICE_REQUEST, data)

export const editBackofficeSuccess = (data: EditBackofficeResponse) =>
  action(BackofficeActions.EDIT_BACKOFFICE_SUCCESS, data)

export const deleteBackofficeRequest = (data: string[]) =>
  action(BackofficeActions.DELETE_BACKOFFICE_REQUEST, data)

export const deleteBackofficeSuccess = (data: string[]) =>
  action(BackofficeActions.DELETE_BACKOFFICE_SUCCESS, data)
