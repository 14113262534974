import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: max-content;
    @media (max-width: 767px) {
      padding: ${theme.spacing.stack.small};
    }
  `}
`

export const ContentStatus = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    background: #f4f4f4;
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxxs};
    margin-bottom: ${theme.spacing.stack.xxxs};
  `}
`

export const StatusApproved = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-right: ${theme.spacing.stack.small};
    div {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background: #25a35a;
      margin-right: ${theme.spacing.stack.small};
    }
    p {
      font-size: ${theme.font.size.xxs};
    }
  `}
`

export const StatusProcess = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-right: ${theme.spacing.stack.small};
    div {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background: #03afcc;
      margin-right: ${theme.spacing.stack.small};
    }
    p {
      font-size: ${theme.font.size.xxs};
    }
  `}
`

export const StatusFailed = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    div {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background: #eb001b;
      margin-right: ${theme.spacing.stack.small};
    }
    p {
      font-size: ${theme.font.size.xxs};
    }
  `}
`

export const Header = styled.div`
  ${({ theme }) => css`
    background: #f4f4f4;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxxs};

    p {
      font-size: ${theme.font.size.xxs};
      font-weight: ${theme.font.weight.semiBold};
      margin-right: ${theme.spacing.stack.xxxs};
    }

    .spacing {
      margin-left: ${theme.spacing.stack.sm};
      margin-right: ${theme.spacing.stack.xs};
    }

    .spacingLeft {
      margin-left: ${theme.spacing.stack.xxs};
    }

    @media (min-width: 768px) {
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
      padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};

      p {
        font-size: ${theme.font.size.sm};
        color: #808080;
      }
    }
  `}
`

export const ContentRows = styled.div`
  ${({ theme }) => css`
    overflow-y: auto;
    height: 100%;
    padding: ${theme.spacing.stack.small};
    .name-wrapper {
      max-width: 90%;
      overflow: hidden;

      @media (max-width: 767px) {
        display: none;
      }
    }
    .event-name {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}
`

export const Row = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.stack.xxxs};
    border-radius: ${theme.border.radius.medium};
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxxs};
    border-bottom: 1px solid #f4f4f4;
    align-items: center;
    p {
      font-size: ${theme.font.size.xxs};
      line-height: ${theme.spacing.stack.xxxs};
    }

    .spacingLeft {
      margin-left: ${theme.spacing.stack.xxxs};
    }

    .spacingRight {
      margin-right: ${theme.spacing.stack.xxxs};
    }

    @media (min-width: 768px) {
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
      box-shadow: 1px 5px 5px 1px rgba(0, 0, 0, 0.2);
      margin-top: ${theme.spacing.stack.xxxs};
      padding: ${theme.spacing.stack.xxs};
      border: none;

      p {
        font-size: ${theme.font.size.sm};
      }
    }
  `}
`

export const Status = styled.div<{ status: string }>`
  ${({ theme, status }) => css`
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background: ${status === 'APPROVED'
      ? theme.color.alert.success.nth1
      : status === 'PROCESSING'
      ? theme.color.alert.info.nth2
      : '#EB001B'};
  `}
`
export const PaymentStatus = styled.p<{
  status: 'APPROVED' | 'PROCESSING' | 'FAILED'
}>`
  ${({ theme, status }) => css`
    color: ${status === 'APPROVED'
      ? theme.color.alert.success.nth1
      : status === 'PROCESSING'
      ? theme.color.alert.info.nth2
      : '#EB001B'};
  `}
`
