import * as S from './CustomerTicket.styles'

import {
  Container as GridContainer,
  Row,
  Column
} from '../../components/grid/GridSystem'

import { BackButton } from '../../components/BackButton/BackButton'
import history from '../../routes/services/history'
import { motion } from 'framer-motion'
import { TicketComponent } from '../../components/Ticket/Ticket'
// import moment from 'moment'
// import { formatPrice } from '../../utils/formatPrice'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../redux/store/rootReducer'
import { Redirect } from 'react-router-dom'
import { useWindowSize } from '../../hooks/useWindowSize'
import { AuthenticateTemplate } from '../../components/templates/AuthenticateTemplate'
// import { Redirect } from 'react-router-dom'

export const CustomerTicket = () => {
  const { customerTicket, eventData } = useSelector(
    (state: ReduxState) => state.ManageEventReducer
  )
  const { isDesktop, isTablet } = useWindowSize()

  return (
    <AuthenticateTemplate>
      {customerTicket ? (
        <S.Container>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}>
            <GridContainer>
              <Row marginTop={isDesktop ? 'xxxl' : isTablet ? 'lg' : 'xs'}>
                <Column desktop={12}>
                  <BackButton
                    onClick={() =>
                      history.push(
                        `/manageEvent/${localStorage.getItem(
                          'eventId'
                        )}/tickets`
                      )
                    }
                  />
                </Column>
              </Row>
              <Row>
                <Column desktop={12}>
                  <S.TitleBox>
                    <p className="title">Ingresso</p>
                  </S.TitleBox>
                </Column>
              </Row>
              <Row>
                <Column mobile={4} tablet={8} desktop={12}>
                  <TicketComponent
                    eventName={customerTicket.eventItem.event.name}
                    address={{
                      street: eventData?.address.street ?? '',
                      city: eventData?.address.city ?? ''
                    }}
                    date={customerTicket.eventItem.dates[0]}
                    ticketName={customerTicket.ticket.name}
                    ticketCategory={customerTicket.ticket.category}
                    eventType={customerTicket.eventItem.event.type}
                    seatId={customerTicket.ticket.id}
                    purchaseDate={customerTicket.createdAt}
                    ticketLocale={customerTicket.seatId}
                    ticketPrice={
                      (customerTicket.ticket.priceCents -
                        customerTicket.ticket.discountCents) /
                      100
                    }
                    ticketInfo={customerTicket.ticketInfo}
                    customerTicketId={customerTicket.customerTicketId}
                  />
                </Column>
              </Row>

              <Row marginBottom="xxxl">
                <p className="title">Usuário</p>
                <Column mobile={1} tablet={2} desktop={4}>
                  <S.UserInfo>
                    <p className="user"> Nome</p>
                    <p className="user-info-text">
                      {customerTicket.customer.firstName}
                    </p>
                  </S.UserInfo>
                </Column>
                <Column mobile={3} tablet={4} desktop={4}>
                  <S.UserInfo>
                    <p className="user">Email</p>
                    <p className="user-info-text">
                      {customerTicket.customer.email}
                    </p>
                  </S.UserInfo>
                </Column>
              </Row>
            </GridContainer>
          </motion.div>
        </S.Container>
      ) : (
        <Redirect to="/events" />
      )}
    </AuthenticateTemplate>
  )
}
