import * as S from './BackButton.styles'

type Props = {
  onClick: () => void
  title?: string
  subTitle?: string
}

export const BackButton = ({ onClick, title, subTitle }: Props) => {
  return (
    <S.BackButton onClick={onClick}>
      <S.BackIcon />
      <S.TextWrapper>
        <p className="back-text">{title || 'Voltar'}</p>
        {subTitle && <p>{subTitle}</p>}
      </S.TextWrapper>
    </S.BackButton>
  )
}
