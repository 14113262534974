import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  Pagination,
  TableBody,
  TableContainer,
  TableData,
  TableFooter,
  TableRow
} from '../../../components/table'
import TableHeader from '../../../components/table/TableHeader/TableHeader'
import { ReduxState } from '../../../redux/store/rootReducer'
import * as S from './WithdrawalsTable.styles'
import { Withdrawals } from '../../../redux/store/Withdrawals/types'
import { formatPrice } from '../../../utils/formatPrice'

export type Headers = {
  name: string
  field: string
  align: 'center' | 'right' | 'left'
  sortable: boolean
}

type EventTableProps = {
  setWithdraw: (withdraw: Withdrawals) => void
}

export const WithdrawalsTable = ({ setWithdraw }: EventTableProps) => {
  const { data } = useSelector((state: ReduxState) => state.WithdrawalsReducer)

  const paginationData = useMemo(() => {
    return data
  }, [data])

  const headers: Headers[] = [
    {
      name: 'Produtor/Parceiro',
      field: 'name',
      align: 'left',
      sortable: false
    },
    { name: 'Nome do evento', field: 'email', align: 'left', sortable: false },
    { name: 'Valor', field: 'value', align: 'left', sortable: false },
    { name: 'Status', field: 'status', align: 'left', sortable: false }
  ]

  return (
    <>
      <S.Container>
        <TableContainer
          table={
            <>
              <TableHeader
                headers={headers}
                onSorting={() => {}}
                type="manage"
              />

              <TableBody>
                {paginationData?.map((withdraw) => {
                  return (
                    <TableRow
                      key={withdraw.id}
                      selected={false}
                      clickable
                      onClick={() => setWithdraw(withdraw)}>
                      <TableData>
                        {withdraw.creator !== null
                          ? withdraw.creator.firstName
                          : withdraw.eventPartner?.name}
                      </TableData>
                      <TableData>{withdraw.event.name}</TableData>
                      <TableData>
                        {formatPrice(withdraw.valueCents / 100)}
                      </TableData>
                      <TableData>
                        <div className={`status ${withdraw.status}`}>
                          {withdraw.status === 'REJECTED'
                            ? 'Rejeitado'
                            : withdraw.status === 'APPROVED'
                            ? 'Aprovado'
                            : withdraw.status === 'WAITING'
                            ? 'Aguardando'
                            : 'Aguardando'}
                        </div>
                      </TableData>
                    </TableRow>
                  )
                })}
              </TableBody>
            </>
          }
          footer={
            <>
              <TableFooter>
                <p>{data.length} Solicitações cadastrados</p>
                <Pagination
                  currentPage={0}
                  itemsPerPage={10}
                  onPageChange={(page) => {}}
                  totalItems={data.length}
                />
              </TableFooter>
            </>
          }
        />
      </S.Container>
    </>
  )
}
