import { Input } from 'applaus-ui-kit'
import styled, { css } from 'styled-components'

import { ReactComponent as IconExit } from '../../../assets/icons/multiply.svg'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 25%;
    max-height: 80vh;
    border-radius: ${theme.border.radius.large};
    background: ${theme.color.neutral.nth4};
    .form-content {
      padding: 0 1rem 1rem 0;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      @media (max-width: 767px) {
        padding: 0;
      }
    }
    @media (min-width: 768px and max-width: 1170px) {
      margin: 0 6.25%;
    }
    @media (max-width: 767px) {
      margin: 0;
      max-height: none;
      height: 100%;
      border-radius: 0;
    }
  `}
`

export const ModalTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing.stack.xxs};
  `}
`

export const IconExitModal = styled(IconExit)`
  cursor: pointer;
`

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.stack.xxs};
    overflow-y: scroll;
  `}
`

export const Status = styled.div`
  display: flex;
  flex-direction: column;
  .boxStatus {
    margin-top: 1rem;
    display: flex;
    column-gap: 3rem;
  }
`

export const ActiveStatus = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`

export const DisabledStatus = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`

export const TextStatus = styled.p`
  font-size: 14px;
  line-height: 18px;
`

export const LinkTarget = styled.div`
  display: flex;
  flex-direction: column;
  .boxStatus {
    margin-top: 1rem;
    display: flex;
    column-gap: 3rem;
  }
`

export const LinkInternal = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`

export const LinkExternal = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`

export const TextLinkTarget = styled.p`
  font-size: 14px;
  line-height: 18px;
`

export const Banner = styled.div`
  margin-top: 3rem;
`

export const TitleBanner = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
`

export const UploadImage = styled.div`
  border: 1px dashed red;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PreviewImage = styled.img`
  margin-bottom: 2rem;
  width: 100px;
  height: 80px;
  border-radius: 1rem;
`

export const TextRecommended = styled.p`
  ${({ theme }) => css`
    margin-bottom: 2rem;
    font-size: ${theme.font.size.xxs};
  `}
`

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xxs};
    padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxs};
    border-radius: 8px;
    border: 1px solid #8601c9;
    cursor: pointer;
  `}
`

export const TabOptions = styled.div`
  display: flex;
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.stack.xxxs};
  `}
`

export const BannerInput = styled.div<{ disable: boolean }>`
  ${({ disable }) => css`
    display: ${disable ? 'auto' : 'none'};
  `}
`

export const InputFile = styled(Input)`
  display: none;
`

export const Error = styled.p`
  ${({ theme }) => css`
    color: #ff0000;
    font-size: 16px;
    min-height: 24px;
  `}
`

export const ButtonBox = styled.div`
  margin-top: 2rem;
  display: flex;
  column-gap: 1rem;
`
