import { AxiosResponse } from 'axios'
import { api } from '../../api'

type Customer = {
  id: string
  userId: string
  firstName: string
  lastName: string
  email: string
  document: string
  password: string
  type: string
  phone: string
  address: string
  zipCode: string
  customField: {
    isPolice: boolean
  }
  createdAt: string
  updatedAt: string
}

export type CustomerResponse = {
  items: Customer[]
  total: number
}

export const getCustomersService = async (skip: number, take: number) => {
  const { data }: AxiosResponse<CustomerResponse> = await api.get(
    `/api/backoffice/customers?skip=${skip}&take=${take}`
  )

  return data
}
