import { api } from 'api/api'
import { AxiosResponse } from 'axios'

export type ListCreatorResponse = {
  id: string
  firstName: string
  lastName: string
  corporateName: string
  document: string
  documentType: string
  email: string
  phone: string
  userId: string
  type: string
  groups: ['CREATOR']
  createdAt: string
  updatedAt: string
  customField: null
  address: {
    id: string
    name: string
    country: string
    state: string
    city: string
    street: string
    number: number
    zipCode: string
    latitude: null
    longitude: null
    complement: null
    district: string
    createdAt: string
    updatedAt: null
    creatorId: string
    customerId: null
    eventId: null
    seatMapId: null
    eventPartnerId: null
  }
  creatorPaymentSetting: {
    id: string
    subAccountId: string
    liveApiToken: string
    testApiToken: string
    userToken: string
    status: string
    bank: string
    bankAg: string
    bankAccountType: string
    bankCc: string
    creditCardFee: number
    pixFee: number
    appFee: number
    softDescriptor: string
    maxInstallments: null
    paymentEmailNotificationReceiver: string
    createdAt: string
    updatedAt: null
    creatorId: string
  }
}

export const listCreatorFilter = async (name: string) => {
  const { data }: AxiosResponse<ListCreatorResponse[]> = await api.get(
    `api/backoffice/creators/filters/search?value=${name}`
  )

  return data
}
