import * as Yup from 'yup'
import { AnyObject } from 'yup/lib/types'
import { cnpjValidate } from '../../../utils/cnpjValidate'
import { cpfValidate } from '../../../utils/cpfValidate'
import { messageHelper } from '../../../utils/messageHelper'
import { phoneValidate } from '../../../utils/phoneValidate'

const requiredIfTyped = (caracters: number, message: string) => ({
  is: (value: string) => value?.length,
  then: (
    rule: Yup.StringSchema<
      string | null | undefined,
      AnyObject,
      string | null | undefined
    >
  ) => rule.min(caracters, message)
})

export const createCreatorSchema = Yup.object().shape(
  {
    firstName: Yup.string().required(messageHelper.yup.creator.nameIsMandatory),
    lastName: Yup.string().required('Sobrenome é obrigatório.'),
    companyName: Yup.string().nullable().notRequired(),
    document: Yup.string()
      .required(messageHelper.yup.creator.documentIsMandatory)
      .test(
        'document validation',
        messageHelper.yup.creator.documentIsInvalid,
        (value) => {
          if (value && value.replace(/[^\d]+/g, '').length <= 11) {
            return cpfValidate(value)
          } else if (value && value.replace(/[^\d]+/g, '').length > 11) {
            return cnpjValidate(value)
          } else return false
        }
      ),
    email: Yup.string()
      .email(messageHelper.yup.creator.emailIsInvalid)
      .required(messageHelper.yup.creator.emailIsMandatory),
    phone: Yup.string()
      .required(messageHelper.yup.creator.phoneIsMandatory)
      .test(
        'phone validation',
        messageHelper.yup.creator.phoneIsInvalid,
        (value) => {
          if (value) {
            return phoneValidate(value)
          } else return false
        }
      ),

    zipCode: Yup.string().nullable().notRequired(),
    street: Yup.string().nullable().notRequired(),
    city: Yup.string()
      .required(messageHelper.yup.creator.cityIsMandatory)
      .min(5, 'Mínimo de 5 caracteres'),
    number: Yup.string().nullable().notRequired(),
    state: Yup.string()
      .required(messageHelper.yup.creator.stateIsMandatory)
      .min(2, 'Mínimo de 2 caracteres'),
    complement: Yup.string()
      .nullable()
      .notRequired()
      .when('complement', requiredIfTyped(3, 'Minímo de 3 caracteres')),
    passwordCreator: Yup.string().required('Senha é obrigatória.')
  },
  [['complement', 'complement']]
)

export const editCreatorSchema = Yup.object().shape(
  {
    firstName: Yup.string().required(messageHelper.yup.creator.nameIsMandatory),
    lastName: Yup.string().required('Sobrenome é obrigatório.'),
    companyName: Yup.string().nullable().notRequired(),
    document: Yup.string()
      .required(messageHelper.yup.creator.documentIsMandatory)
      .test(
        'document validation',
        messageHelper.yup.creator.documentIsInvalid,
        (value) => {
          if (value && value.replace(/[^\d]+/g, '').length <= 11) {
            return cpfValidate(value)
          } else if (value && value.replace(/[^\d]+/g, '').length > 11) {
            return cnpjValidate(value)
          } else return false
        }
      ),
    email: Yup.string()
      .email(messageHelper.yup.creator.emailIsInvalid)
      .required(messageHelper.yup.creator.emailIsMandatory),
    phone: Yup.string()
      .required(messageHelper.yup.creator.phoneIsMandatory)
      .test(
        'phone validation',
        messageHelper.yup.creator.phoneIsInvalid,
        (value) => {
          if (value) {
            return phoneValidate(value)
          } else return false
        }
      ),

    zipCode: Yup.string().nullable().notRequired(),
    street: Yup.string().nullable().notRequired(),
    city: Yup.string()
      .required(messageHelper.yup.creator.cityIsMandatory)
      .min(5, 'Mínimo de 5 caracteres'),
    number: Yup.string().nullable().notRequired(),
    state: Yup.string()
      .required(messageHelper.yup.creator.stateIsMandatory)
      .min(2, 'Mínimo de 2 caracteres'),
    complement: Yup.string()
      .nullable()
      .notRequired()
      .when('complement', requiredIfTyped(3, 'Minímo de 3 caracteres'))
  },
  [['complement', 'complement']]
)
