import _ from 'lodash'

export const validateImageRatio = (
  imageFile: any,
  ratioInterval: number
): Promise<boolean> => {
  return new Promise((resolve) => {
    console.log('aqiiiii')

    if (!_.isUndefined(imageFile)) {
      const img = new Image()
      img.src = URL.createObjectURL(imageFile)
      img.onload = function () {
        const aspectRatio = Number((img.width / img.height).toFixed(2))
        resolve(
          ratioInterval + 0.02 >= aspectRatio &&
            ratioInterval - 0.02 <= aspectRatio
        )
      }
    } else resolve(true)
  })
}
