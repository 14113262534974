import { darken } from 'polished'
import styled, { css } from 'styled-components'

import { ReactComponent as TrashIconFile } from '../../../assets/icons/trash-alt.svg'

export const TableWrapper = styled.div`
  ${({ theme }) => css`
    border-radius: 4px;
    background: ${theme.color.neutral.nth4};
    overflow-x: auto;
    ::-webkit-scrollbar {
      width: 1rem;
      height: 1rem;
    }

    ::-webkit-scrollbar-track {
      background: ${theme.color.brand.tertiary.nth4};
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${theme.color.base.nth1};
      border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${darken(0.5, `${theme.color.base.nth1}`)};
    }
  `}
`

type TableContainerProps = {
  hideTableHeader: boolean
}

export const TableContainer = styled.table<TableContainerProps>`
  ${({ hideTableHeader }) => css`
    ${hideTableHeader &&
    css`
      thead {
        display: none;
      }
    `}
    width: 100%;
    border-collapse: collapse;
    th,
    td {
      padding: 3rem;
    }
  `}
`

export const ExcludeItems = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 3rem;
`

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ItemsQuantity = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xs};
    color: ${theme.color.base.nth1};
  `}
`

export const ExcludeIcon = styled(TrashIconFile)`
  ${({ theme }) => css`
    fill: ${theme.color.base.nth1};
    cursor: pointer;
  `}
`
