import { Address } from '../../../redux/store/EditEvent/types'
import { store } from '../../../redux/store/store'
import { addLocationSuccess } from '../../../redux/store/EditEvent/actions'
import { clearLoading, setLoading } from '../../../redux/store/Loading/actions'
import { api } from '../../api'

export const updateEventAddress = async (request: Address, eventId: string) => {
  const { dispatch } = store
  try {
    dispatch(setLoading())
    await api
      .put(`/api/backoffice/events/${eventId}`, {
        address: { ...request, number: Number(request.number) }
      })
      .then(() =>
        dispatch(
          addLocationSuccess({ ...request, number: Number(request.number) })
        )
      )
    dispatch(clearLoading())
  } catch (err) {
    dispatch(clearLoading())
  }
}
