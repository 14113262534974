import { AlertModal, Button, Paragraph } from 'applaus-ui-kit'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  BackofficeGroupsResponse,
  backofficeGroupsService
} from 'api/services/backofficeGroups/backofficeGroups'
import { AuthenticateTemplate } from '../../components/templates/AuthenticateTemplate'
import { deleteBackofficeRequest } from '../../redux/store/UserBackoffice/actions'
import { Backoffice } from '../../redux/store/UserBackoffice/types'
import { BackofficeModal } from './BackofficeModal/BackofficeModal'

import * as S from './UsersBackoffice.styles'
import { UsersBackofficeTable } from './UsersBackofficeTable/UsersBackofficeTable'

export type ModalState = {
  open: boolean
  type: 'CREATE' | 'EDIT' | 'DELETE'
  backoffice?: Backoffice
}

type AlertModalState = {
  buttonText: string
  click: () => void
  message: string
  open: boolean
  variant: 'error' | 'success'
  cancelClick?: () => void
  description?: string
}

export const UsersBackoffice = () => {
  const dispatch = useDispatch()
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    type: 'CREATE'
  })

  const [backofficeGroups, setBackofficeGroups] =
    useState<BackofficeGroupsResponse>({} as BackofficeGroupsResponse)

  const [alertModalState, setAlertModalState] = useState<AlertModalState>({
    buttonText: '',
    click: () => {},
    message: '',
    open: false,
    variant: 'error'
  })

  const resetAlertModalState = () => {
    setAlertModalState({
      buttonText: '',
      click: () => {},
      message: '',
      open: false,
      variant: 'error'
    })
  }

  const closeModal = () => {
    setModalState({ open: false, type: 'CREATE' })
  }

  const editBackoffice = (backoffice: Backoffice) => {
    setModalState({
      open: true,
      type: 'EDIT',
      backoffice
    })
  }

  const deleteUserBackoffice = (backoffice: Backoffice) => {
    if (backoffice) {
      setAlertModalState({
        buttonText: 'Deletar',
        click: () => {
          dispatch(deleteBackofficeRequest([backoffice.id]))
          resetAlertModalState()
        },
        message: 'Deseja mesmo deletar o usuário?',
        open: true,
        variant: 'error',
        cancelClick: resetAlertModalState
      })
    }
  }

  useEffect(() => {
    try {
      backofficeGroupsService().then((data) => setBackofficeGroups(data))
    } catch (err) {
      console.log(err)
    }
  }, [])

  return (
    <>
      <AuthenticateTemplate>
        <S.Container>
          <S.Header>
            <div>
              <S.UserIcon />
              <Paragraph variant="large" type="bold">
                Backoffice
              </Paragraph>
            </div>

            <div>
              <Button
                color="primary"
                fullWidth
                variant="contained"
                size="large"
                onClick={() => setModalState({ open: true, type: 'CREATE' })}>
                Cadastrar backoffice
              </Button>
            </div>
          </S.Header>
          <div className="inputBox">
            <S.SearchIcon />
            <S.Searchnput placeholder="Procure usuários por nome, sobrenome ou email" />
          </div>
          <UsersBackofficeTable
            editBackoffice={editBackoffice}
            deleteUserBackoffice={deleteUserBackoffice}
          />
        </S.Container>
      </AuthenticateTemplate>
      <BackofficeModal
        modalState={modalState}
        backofficeGroups={backofficeGroups}
        closeModal={closeModal}
      />
      <AlertModal
        buttonText={alertModalState.buttonText}
        click={alertModalState.click}
        message={alertModalState.message}
        open={alertModalState.open}
        variant={alertModalState.variant}
        cancelClick={alertModalState.cancelClick}
        description={alertModalState.description}
      />
    </>
  )
}
