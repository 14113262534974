import { AxiosResponse } from 'axios'
import { api } from '../../api'

type Chart = {
  id: string
  name: string
  address: {
    street: string
    number: number
    city: string
    state: string
    complement?: string
    country: string
    zipCode?: string
  }
  seatChartId: string
  createdAt: string
  updatedAt: string | null
}

export type LoadChartResponse = {
  items: Chart[]
  total: number
}

export const getChartsService = async (skip: number, take: number) => {
  const { data }: AxiosResponse<LoadChartResponse> = await api.get(
    `/api/backoffice/seatMaps?skip=${skip}&take=${take}`
  )

  return data
}
