import * as S from './Publishing.styles'
import { Heading, Paragraph, RadioButton, Button } from 'applaus-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { ReduxState } from '../../../../redux/store/rootReducer'

import { updateEventItemStatusService } from '../../../../api/services/events/updateEventStatusService'
import history from '../../../../routes/services/history'
import { editStepBack } from '../../../../redux/store/EditEvent/editActions'
import { setModalState } from '../../../../redux/store/Modal/Actions/actionsModal'

export const Publishing = () => {
  const { editEvent } = useSelector(
    (state: ReduxState) => state.EditEventReducer
  )
  const dispatch = useDispatch()
  const [isPublic, setIsPublic] = useState(editEvent!.isPublic)
  const statusEvent = editEvent?.status === 'OPEN' ? 'OPEN' : 'CLOSED'
  const [status, setStatus] = useState<'OPEN' | 'CLOSED'>(statusEvent)

  const handlePublish = async () => {
    try {
      if (isPublic !== editEvent!.isPublic || status !== editEvent?.status) {
        await updateEventItemStatusService(editEvent!.id, isPublic, status)
      }
      history.push('/events')
    } catch (e: any) {
      if (e.response.data.message === 'account not verified') {
        dispatch(
          setModalState({
            message: 'Você precisa verificar sua conta bancária',
            buttonText: 'Ok',
            open: true,
            variant: 'error'
          })
        )
      } else if (
        e.response.data.message === 'banners are required for published event'
      ) {
        dispatch(
          setModalState({
            message:
              'O evento precisa ter todos os banners para ser publicado.',
            buttonText: 'Ok',
            open: true,
            variant: 'error'
          })
        )
      } else if (
        e.response.data.message ===
        'description is required for published event'
      ) {
        dispatch(
          setModalState({
            message: 'O evento precisa ter descriação para ser publicado.',
            buttonText: 'Ok',
            open: true,
            variant: 'error'
          })
        )
      } else if (
        e.response.data.message === 'address are required for published event'
      ) {
        dispatch(
          setModalState({
            message: 'O evento precisa ter endereço para ser publicado.',
            buttonText: 'Ok',
            open: true,
            variant: 'error'
          })
        )
      }
    }
  }

  return (
    <S.Container>
      <S.TitleBox>
        <S.BackIcon onClick={() => dispatch(editStepBack())} />
        <Heading variant="h3">Publicar</Heading>
      </S.TitleBox>
      <Paragraph variant="large" type="semiBold">
        Quem pode ver seu evento?
      </Paragraph>
      <S.PrivacySelection>
        <div className="radioButtonBox">
          <RadioButton
            checked={isPublic}
            readOnly
            onClick={() => setIsPublic(true)}
            className="radioButton"
          />
          <div>
            <Paragraph variant="large" type="semiBold">
              Público
            </Paragraph>
            <Paragraph variant="regular">
              Seu evento ficará publico na plataforma da Applaus, todos usuários
              poderão visualizar seu evento
            </Paragraph>
          </div>
        </div>
        <div className="radioButtonBox">
          <RadioButton
            checked={!isPublic}
            readOnly
            onClick={() => setIsPublic(false)}
            className="radioButton"
          />
          <div>
            <Paragraph variant="large" type="semiBold">
              Privado
            </Paragraph>
            <Paragraph variant="regular">
              Evento para uma audiência exclusiva
            </Paragraph>
          </div>
        </div>
      </S.PrivacySelection>

      <S.ChooseEventStatus>
        <Paragraph variant="large" type="bold">
          Status do evento
        </Paragraph>
        <div className="radioButtonWrapper">
          <RadioButton
            checked={status === 'OPEN'}
            onChange={() => setStatus('OPEN')}
            readOnly
          />
          <div className="options-text">
            <Paragraph variant="large" type="semiBold">
              Aberto
            </Paragraph>
            <Paragraph variant="regular">
              O evento ficará aberto para todos os usuários da plataforma
              Applaus
            </Paragraph>
          </div>
        </div>
        <div className="radioButtonWrapper">
          <RadioButton
            checked={status === 'CLOSED'}
            onChange={() => setStatus('CLOSED')}
            readOnly
          />
          <div className="options-text">
            <Paragraph variant="large" type="semiBold">
              Fechado
            </Paragraph>
            <Paragraph variant="regular">
              O Evento ficará restrito para venda, mas poderá ser exibido caso o
              evento esteja público
            </Paragraph>
          </div>
        </div>
      </S.ChooseEventStatus>

      <Button
        className="show-event"
        variant="text"
        color="primary"
        size="medium"
        onClick={() => window.open(`/eventPreview/${editEvent!.id}`)}
        fullWidth={false}>
        Pré-visualização <S.LoginIcon />
      </Button>

      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={handlePublish}
        fullWidth={false}>
        Salvar
      </Button>
    </S.Container>
  )
}
