import { Button, Input, Paragraph } from 'applaus-ui-kit'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { EditBackofficeRequest } from '../../../api/services/userBackoffice/editBackofficeService'
import ContentModal from '../../../components/contentModal/ContentModal'
import {
  createBackofficeRequest,
  editBackofficeRequest
} from '../../../redux/store/UserBackoffice/actions'
import { ModalState } from '../UsersBackoffice'
import * as S from './BackofficeModal.styles'
import { createUserBackoffice, editUserBackoffice } from './validationSchema'

import ReactSelect from 'react-select'
import { BackofficeGroupsResponse } from 'api/services/backofficeGroups/backofficeGroups'

type BackofficeModalProps = {
  modalState: ModalState
  closeModal: () => void
  backofficeGroups: BackofficeGroupsResponse | undefined
}

type PermissionTypes = {
  label: string
  value: string
}

export const BackofficeModal = ({
  modalState,
  closeModal,
  backofficeGroups
}: BackofficeModalProps) => {
  const dispatch = useDispatch()

  const [permissionsState, setPermissionState] = useState<PermissionTypes[]>()

  useEffect(() => {
    if (backofficeGroups) {
      const types = backofficeGroups

      const newTypes = Object.entries(types).map((item) => ({
        label: item[0],
        value: item[0]
      }))
      setPermissionState(newTypes)
    }
  }, [backofficeGroups])

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      permission: ''
    },
    validationSchema:
      modalState.type === 'CREATE' ? createUserBackoffice : editUserBackoffice,
    onSubmit: (values) => {
      formik.setErrors({})
      formik.resetForm()
      closeModal()

      if (modalState.type === 'CREATE') {
        dispatch(
          createBackofficeRequest({
            firstName: values.firstName,
            email: values.email,
            lastName: values.lastName,
            password: values.password,
            groups: [values.permission]
          })
        )
      } else if (modalState.type === 'EDIT') {
        const editRequest: EditBackofficeRequest = {
          id: modalState?.backoffice?.id,
          email:
            values.email !== modalState.backoffice?.email
              ? values.email
              : undefined,
          firstName:
            values.firstName !== modalState.backoffice?.firstName
              ? values.firstName
              : undefined,
          lastName:
            values.lastName !== modalState.backoffice?.lastName
              ? values.lastName
              : undefined,
          groups:
            values.permission !== modalState.backoffice?.groups[0]
              ? [values.permission]
              : undefined
        }
        dispatch(
          editBackofficeRequest(
            Object.fromEntries(
              Object.entries(editRequest).filter(
                ([_, value]) => value !== undefined
              )
            )
          )
        )
      }
    }
  })

  useEffect(() => {
    if (modalState.type === 'EDIT' && modalState.backoffice) {
      formik.setValues({
        email: modalState.backoffice.email,
        firstName: modalState.backoffice.firstName,
        lastName: modalState.backoffice.lastName,
        password: '',
        permission: modalState.backoffice.groups[0]
      })
    }
  }, [modalState.backoffice])

  return (
    <ContentModal open={modalState.open}>
      <S.Container>
        <S.ModalTitle>
          <Paragraph variant="large" type="bold">
            Cadastrar backoffice
          </Paragraph>
          <S.IconExitModal
            onClick={() => {
              closeModal()
              formik.resetForm()
            }}
          />
        </S.ModalTitle>
        <S.Form onSubmit={formik.handleSubmit}>
          <Input
            fullWidth
            name="firstName"
            label="Nome"
            placeholder="Digite o nome"
            texterror={formik.errors.firstName}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            value={formik.values.firstName}
            onChange={formik.handleChange}
          />

          <Input
            fullWidth
            name="lastName"
            label="Sobrenome"
            placeholder="Digite o sobrenome"
            texterror={formik.errors.lastName}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            value={formik.values.lastName}
            onChange={formik.handleChange}
          />

          <Input
            fullWidth
            name="email"
            label="Email"
            placeholder="Digite o email"
            texterror={formik.errors.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            value={formik.values.email}
            onChange={formik.handleChange}
          />

          {modalState.type === 'CREATE' ? (
            <Input
              fullWidth
              type="password"
              name="password"
              label="Senha"
              placeholder="Digite sua senha"
              texterror={formik.errors.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              value={formik.values.password}
              onChange={formik.handleChange}
            />
          ) : null}

          <div>
            <S.Label>Grupo</S.Label>
            <ReactSelect
              onChange={(selectValue) => {
                if (selectValue && selectValue.value) {
                  formik.setFieldValue('permission', selectValue.value)
                }
              }}
              value={
                permissionsState?.find(
                  (option) => option.value === formik.values.permission
                ) ?? {
                  value: '',
                  label: ''
                }
              }
              placeholder="Estado"
              styles={S.customStyles}
              options={permissionsState}
              noOptionsMessage={() => 'Status não disponível'}
            />
            <S.Error>
              {formik.touched.permission && Boolean(formik.errors.permission)
                ? formik.errors.permission
                : null}
            </S.Error>
          </div>

          <S.ButtonBox>
            <Button
              color="primary"
              size="large"
              variant="text"
              fullWidth
              type="button"
              onClick={() => {
                closeModal()
                formik.resetForm()
              }}>
              Cancelar
            </Button>
            <Button
              type="submit"
              color="primary"
              size="large"
              variant="contained"
              fullWidth>
              {modalState.type === 'CREATE' ? 'Cadastrar' : 'Atualzar'}
            </Button>
          </S.ButtonBox>
        </S.Form>
      </S.Container>
    </ContentModal>
  )
}
