import { action } from 'typesafe-actions'
import { PurchaseOrder, PurchasesActions } from './types'

export const defaultPurchase = () => action(PurchasesActions.DEFAULT_PURCHASES)

export const showRequest = () => action(PurchasesActions.SHOW_REQUEST)

export const showTicket = () => action(PurchasesActions.SHOW_TICKET)

export const purchasesOrderCustomerRequest = (customerId: string) =>
  action(PurchasesActions.PURCHASE_ORDER_CUSTOMER_REQUEST, customerId)

export const purchasesOrderCustomerSuccess = (data: {
  items: PurchaseOrder[]
  total: number
}) => action(PurchasesActions.PURCHASE_ORDER_CUSTOMER_SUCCESS, data)

export const setPurchaseOrderCustomer = (id: string) =>
  action(PurchasesActions.SET_PURCHASE_ORDER_CUSTOMER, id)

export const viewMorePurchaseOrderRequest = (id: string) =>
  action(PurchasesActions.VIEW_MORE_PURCHASE_ORDER_REQUEST, id)

export const viewMorePurchaseOrderFailure = () =>
  action(PurchasesActions.VIEW_MORE_PURCHASE_ORDER_FAILURE)

export const viewMorePurchaseOrderSuccess = (data: {
  items: PurchaseOrder[]
  total: number
}) => action(PurchasesActions.VIEW_MORE_PURCHASE_ORDER_SUCCESS, data)

export const clearItemsPerPage = () =>
  action(PurchasesActions.CLEAR_ITEMS_PER_PAGE)

export const purchasesInitialStateAction = () =>
  action(PurchasesActions.INITIAL_STATE)
