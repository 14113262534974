import styled, { css } from 'styled-components'

export const Container = styled.tbody`
  ${({ theme }) => css`
    tr {
      border-bottom: 1px solid ${theme.color.brand.tertiary.nth3};
    }
    tr:first-child {
      border-top: 1px solid ${theme.color.brand.tertiary.nth3};
    }
  `}
`
