/* eslint-disable no-unused-vars */
export enum BackofficeActions {
  GET_BACKOFFICE_REQUEST = '@creator/GET_BACKOFFICE_REQUEST',
  GET_BACKOFFICE_SUCCESS = '@creator/GET_BACKOFFICE_SUCCESS',
  CREATE_BACKOFFICE_REQUEST = '@backoffice/CREATE_BACKOFFICE_REQUEST',
  CREATE_BACKOFFICE_SUCCESS = '@backoffice/CREATE_BACKOFFICE_SUCCESS',
  EDIT_BACKOFFICE_REQUEST = '@backoffice/EDIT_BACKOFFICE_REQUEST',
  EDIT_BACKOFFICE_SUCCESS = '@backoffice/EDIT_BACKOFFICE_SUCCESS',
  DELETE_BACKOFFICE_REQUEST = '@backoffice/DELETE_BACKOFFICE_REQUEST',
  DELETE_BACKOFFICE_SUCCESS = '@backoffice/DELETE_BACKOFFICE_SUCCESS'
}

export type Backoffice = {
  id: string
  firstName: string
  lastName: string
  email: string
  userId: string
  createdAt: string
  groups: [string]
  updatedAt: string | null
  customField: string | null
}

export type BackofficeState = {
  readonly data: {
    items: Backoffice[]
    total: number
  }
  currentPage: number
  skip: number
  take: number
}
