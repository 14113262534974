import styled, { css } from 'styled-components'

import { ReactComponent as SearchFile } from '../../../../assets/icons/search.svg'

export const SearchIcon = styled(SearchFile)`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    left: ${theme.spacing.inline.xxs};
    fill: black;
    transform: translateY(-50%);
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1366px;

    @media (min-width: 1171px) {
      img {
        min-width: 365px;
        max-width: 365px;
        min-height: 245px;
        max-height: 245px;
      }
    }
  `}
`

export const Wrapper = styled.div`
  background: red;
`

export const CardEvent = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 8px;
    width: 100%;
    flex-wrap: wrap;
    @media (min-width: 1171px) {
      img {
        min-width: 365px;
        max-width: 365px;
        min-height: 245px;
        max-height: 245px;
      }
    }
  `}
`

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: #39383a;
    margin-bottom: ${theme.spacing.stack.xs};
  `}
`

export const ContentNoTicket = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${theme.spacing.stack.xxl};
    padding: ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxxl}
      ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxxl};
  `}
`

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin-bottom: ${theme.spacing.stack.xxs};
  `}
`

export const Input = styled.input`
  ${({ theme }) => css`
    width: 100%;
    height: ${theme.spacing.stack.xl};
    background: ${theme.color.base.nth4};
    border-radius: ${theme.border.radius.medium};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    border: 1px solid transparent;
    outline: 0;
    text-indent: ${theme.spacing.stack.xl};
    &:focus {
      background: white;
      border: 1px solid ${theme.color.brand.primary.nth1};
    }
  `}
`

export const TicketImage = styled.img`
  ${({ theme }) => css`
    width: 226px;
    height: 226px;
    margin-bottom: ${theme.spacing.stack.xs};
  `}
`

export const TitleNoTicket = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.semiBold};
    line-height: ${theme.spacing.stack.xxs};
    color: #adadad;
    text-align: center;

    @media (min-width: 768px) and (max-width: 1170px) {
      font-size: ${theme.font.size.lg};
      font-weight: ${theme.font.weight.semiBold};
      line-height: ${theme.spacing.stack.sm};
    }
  `}
`

export const CardsTickets = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing.stack.xxxs};

    img {
      cursor: pointer;
      min-width: 364px;
      max-width: 364px;
      max-height: 247px;
      border-radius: 8px;
    }
  `}
`

export const SeeMore = styled.p`
  ${({ theme }) => css`
    cursor: pointer;
    margin-top: ${theme.spacing.stack.xxs};
    text-align: start;
    font-size: ${theme.font.size.xs};
    font-weight: ${theme.font.weight.semiBold};
    transition: 0.3s ease;
    width: max-content;
    color: #b88bcf;
    &:hover {
      transform: scale(1.1);
      color: #6c01a3;
    }
  `}
`
