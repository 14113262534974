import { api } from 'api/api'
import { AxiosResponse } from 'axios'

export type ListCustomerResponse = {
  id: string
  firstName: string
  lastName: string
  email: string
  document: string
  userId: string
  type: string
  groups: []
  createdAt: string
  updatedAt: string
  phone: string
  customField: {
    externalId: string
    subAccountId: string
    liveApiToken: string
    userToken: string
    testApiToken: string
  }
  address: {
    id: string
    name: null
    country: string
    state: string
    city: string
    street: string
    number: number
    zipCode: string
    latitude: null
    longitude: null
    complement: null
    district: null
    createdAt: string
    updatedAt: null
    creatorId: null
    customerId: string
    eventId: null
    seatMapId: null
    eventPartnerId: null
  }
  eventItemsOnTicketsEventItemId: null
  eventItemsOnTicketsTicketId: null
}

export const listCustomerFilter = async (name: string) => {
  const { data }: AxiosResponse<ListCustomerResponse[]> = await api.get(
    `api/backoffice/customers/filters/search?value=${name}`
  )

  return data
}
