import { AxiosResponse } from 'axios'
import { api } from '../../api'

export type CreatorRequest = {
  id: string
  firstName: string
  lastName: string
  document: string
  documentType: string
  email: string
  phone?: string
  address?: {
    street: string
    number: number
    city: string
    state: string
    complement?: string
    country: string
    zipCode?: string
  }
  zipCode?: string
  userId?: string
  type?: string
  groups?: string[]
  createdAt?: string
  updatedAt?: string
  customField?: string
  creatorPaymentSetting: {
    id: string
    subAccountId: string
    status: string
    bank: string | null
    bankAg: string | null
    bankAccountType: string | null
    bankCc: string | null
    softDescriptor: string | null
    maxInstallments: string | null
    paymentEmailNotificationReceiver: string
    creditCardFee: number
    pixFee: number
    appFee: number
    createdAt: string
    updatedAt: string | null
  }
}

export const findOneCreatorByIdService = async (creatorId: string) => {
  const { data }: AxiosResponse<CreatorRequest> = await api.get(
    `/api/backoffice/creators/${creatorId}`
  )

  return data
}
