import { all, call, delay, put, takeLatest } from '@redux-saga/core/effects'
import _ from 'lodash'
import { PayloadAction } from 'typesafe-actions'
import {
  CreateBannerRequest,
  CreateBannerResponse,
  createBannerService
} from '../../../api/services/banners/createBannerService'
import { deleteBannerService } from '../../../api/services/banners/deleteBannerService'
import {
  EditBannerRequest,
  EditBannerResponse,
  editBannerService
} from '../../../api/services/banners/editBannerService'
import {
  BannersResponse,
  getBannersService
} from '../../../api/services/banners/getBannersService'
import { messageHelper } from '../../../utils/messageHelper'
import { clearLoading, setLoading } from '../Loading/actions'
import { setModalState } from '../Modal/Actions/actionsModal'
import {
  createBannerSuccess,
  deleteBannerSuccess,
  editBannerSuccess,
  getAllBannersSuccess
} from './Actions/bannerActions'
import { BannersActions } from './types'

export function* loadBanner() {
  yield put(setLoading(true))
  try {
    const banners: BannersResponse[] = yield call(getBannersService)
    yield put(getAllBannersSuccess(banners))

    yield delay(Number(process.env.REACT_APP_DELAY_REQUEST))
    yield put(clearLoading())
  } catch (e) {
    yield put(clearLoading())
    yield put(
      setModalState({
        open: true,
        variant: 'error',
        message: messageHelper.modal.banner.error.loadBanner
      })
    )
  }
}

export function* createBanner(
  action: PayloadAction<
    BannersActions.CREATE_BANNER_REQUEST,
    CreateBannerRequest
  >
) {
  yield put(setLoading(true))
  try {
    const data: CreateBannerResponse = yield call(createBannerService, {
      name: action.payload.name,
      description: action.payload.description,
      status: action.payload.status,
      desktopUrl: action.payload.desktopUrl,
      link: action.payload.link,
      linkTarget: action.payload.linkTarget,
      mobileUrl: action.payload.mobileUrl,
      tabletUrl: action.payload.tabletUrl
    })
    yield delay(Number(process.env.REACT_APP_DELAY_REQUEST))
    yield put(createBannerSuccess(data))

    yield delay(Number(process.env.REACT_APP_DELAY_REQUEST))
    yield put(clearLoading())

    yield put(
      setModalState({
        open: true,
        message: messageHelper.modal.banner.success.createBanner,
        variant: 'success'
      })
    )
  } catch (err) {
    yield put(clearLoading())
    yield put(
      setModalState({
        open: true,
        message: messageHelper.modal.banner.error.createBanner,
        variant: 'error'
      })
    )
  }
}

export function* editBanner(
  action: PayloadAction<
    BannersActions.EDIT_BANNER_REQUEST,
    { data: EditBannerRequest; idBanner: string }
  >
) {
  try {
    if (!_.isEmpty(action.payload.data)) {
      const banner: EditBannerResponse = yield call(
        editBannerService,
        action.payload.data,
        action.payload.idBanner
      )
      yield put(editBannerSuccess(banner))
      yield put(
        setModalState({
          open: true,
          message: 'Banner editado com sucesso.',
          variant: 'success'
        })
      )
    }
  } catch (e) {
    yield put(
      setModalState({
        open: true,
        message: 'Não foi possível alterar o banner.',
        variant: 'error'
      })
    )
  }
}

export function* deleteBanner(
  action: PayloadAction<BannersActions.DELETE_BANNER_REQUEST, string[]>
) {
  yield put(setLoading(true))
  try {
    yield call(deleteBannerService, action.payload)

    yield put(deleteBannerSuccess(action.payload))

    yield delay(Number(process.env.REACT_APP_DELAY_REQUEST))
    yield put(clearLoading())

    yield put(
      setModalState({
        open: true,
        message:
          action.payload.length > 1
            ? messageHelper.modal.banner.success.deleteMultiplesBanners
            : messageHelper.modal.banner.success.deleteBanner,
        variant: 'success'
      })
    )
  } catch (e) {
    yield put(clearLoading())

    yield put(
      setModalState({
        open: true,
        message:
          action.payload.length > 1
            ? messageHelper.modal.banner.error.deleteMultiplesBanners
            : messageHelper.modal.banner.error.deleteBanner,
        variant: 'error'
      })
    )
  }
}

export const sagaBanner = all([
  takeLatest(BannersActions.LOAD_BANNERS_REQUEST, loadBanner),
  takeLatest(BannersActions.CREATE_BANNER_REQUEST, createBanner),
  takeLatest(BannersActions.EDIT_BANNER_REQUEST, editBanner),
  takeLatest(BannersActions.DELETE_BANNER_REQUEST, deleteBanner)
])
