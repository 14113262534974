import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
  `}
`

export const BrandIcon = styled.img``

export const Brand = styled.p`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.stack.nano};
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.regular};
    line-height: ${theme.spacing.stack.xxs};

    @media (max-width: 1277px) {
      display: none;
    }
  `}
`

export const LastNumbers = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.regular};
    line-height: ${theme.spacing.stack.xxs};
  `}
`
