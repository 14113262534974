import { setModalState } from '../../../redux/store/Modal/Actions/actionsModal'
import { deleteTicketSuccess } from '../../../redux/store/EditEvent/actions'
import { clearLoading, setLoading } from '../../../redux/store/Loading/actions'
import { store } from '../../../redux/store/store'
import { api } from '../../api'

export const deleteTicketService = async (ticketId: string) => {
  const { dispatch } = store
  try {
    dispatch(setLoading())
    await api.delete(`api/events/eventItems/tickets/${ticketId}`).then(() => {
      dispatch(clearLoading())
      dispatch(deleteTicketSuccess(ticketId))
      dispatch(
        setModalState({
          open: true,
          variant: 'success',
          message: 'Ticket deletado com sucesso',
          buttonText: 'Ok'
        })
      )
    })
  } catch (err) {
    dispatch(clearLoading())
    dispatch(
      setModalState({
        open: true,
        variant: 'error',
        message: 'Não foi possível deletar o ticket',
        buttonText: 'Fechar'
      })
    )
  }
}
