import { action } from 'typesafe-actions'
import { WithdrawalsActions, Withdrawals } from './types'

export const listWithdrawalsRequestAction = () =>
  action(WithdrawalsActions.loadWithdrawalsRequest)

export const listWithdrawalsSuccessAction = (data: Withdrawals[]) =>
  action(WithdrawalsActions.loadWithdrawalsSuccess, data)

export const updateWithdrawRequest = (data: {
  withdrawId: string
  comments: string
  status: 'REJECTED' | 'APPROVED' | 'WAITING'
}) => action(WithdrawalsActions.updateWithdrawsRequest, data)

export const updateWithdrawSuccess = (data: Withdrawals) =>
  action(WithdrawalsActions.updateWithdrawSuccess, data)
