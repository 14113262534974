import { Button, Heading } from 'applaus-ui-kit'
import { Dispatch, SetStateAction } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { balanceWithdrawal } from '../../../../api/services/events/balanceWithdrawal'
import { ListPartnersResponse } from '../../../../api/services/partner/listPartners'
import {
  clearModalState,
  setModalState
} from '../../../../redux/store/Modal/Actions/actionsModal'
import {
  clearLoading,
  setLoading
} from '../../../../redux/store/Loading/actions'
import { ReduxState } from '../../../../redux/store/rootReducer'
import { formatPrice } from '../../../../utils/formatPrice'
import { sleep } from '../../../../utils/sleep'
import * as S from './BalanceWithdrawal.styles'

type AddPartnerProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  eventPartners: ListPartnersResponse[] | undefined
}

export const BalanceWithdrawal = ({
  open,
  setOpen,
  eventPartners
}: AddPartnerProps) => {
  const dispatch = useDispatch()
  const { summaryData, eventData } = useSelector(
    (state: ReduxState) => state.ManageEventReducer
  )

  const distributeBalance = async () => {
    dispatch(setLoading())
    await sleep(2000)
    try {
      const status = await balanceWithdrawal(eventData!.id)

      if (status === 200) {
        dispatch(
          setModalState({
            buttonText: 'Ok',
            open: true,
            message: 'Pedido de distribuição feito com sucesso.',
            variant: 'success',
            click: () => {
              dispatch(clearModalState())
              setOpen(false)
            }
          })
        )
      }
      dispatch(clearLoading())
    } catch (e: any) {
      dispatch(clearLoading())
      if (e.response.data.message === 'ongoing event') {
        setOpen(false)
        dispatch(
          setModalState({
            buttonText: 'Ok',
            open: true,
            message: 'Evento em andamento, aguarde a finalização do evento.',
            variant: 'error',
            click: () => {
              dispatch(clearModalState())
            }
          })
        )
      } else {
        dispatch(
          setModalState({
            buttonText: 'Ok',
            open: true,
            message: 'Ocorreu um erro na distribuição.',
            description: 'Tente novamente.',
            variant: 'error',
            click: () => {
              dispatch(clearModalState())
            }
          })
        )
      }
    }
  }

  const filteredPartners = eventPartners?.map((partner) => {
    const findIndex = partner.events.findIndex(
      (event) => event.event.id === eventData!.id
    )

    if (findIndex > -1) {
      return partner.events[findIndex].revenuePercent
    } else return null
  })

  return (
    <S.Container open={open}>
      <S.Wrapper>
        <S.CloseIcon onClick={() => setOpen(false)} />
        <div className="title">
          <Heading variant="h5">Distribuir ganhos</Heading>
        </div>

        <div className="totalBox">
          <Heading variant="h5" className="purpleColor">
            Total
          </Heading>

          <Heading variant="h5" className="purpleColor">
            {formatPrice(summaryData?.summary.confirmedPayments ?? 0)}
          </Heading>
        </div>

        <div className="partnersBox">
          <div className="partner">
            <Heading variant="h5" className="regular purpleColor">
              Eu
            </Heading>
            <Heading variant="h5" className="regularPercent">
              {filteredPartners && filteredPartners!.length > 0
                ? 100 - filteredPartners!.reduce((p, c) => p! + c!, 0)! + '%'
                : '100%'}
            </Heading>
            <Heading variant="h5" className="purpleColor regularPrice">
              {filteredPartners && filteredPartners!.length > 0
                ? formatPrice(
                    ((100 - filteredPartners.reduce((p, c) => p! + c!, 0)!) *
                      summaryData?.summary.confirmedPayments!) /
                      100
                  )
                : formatPrice(summaryData?.summary.confirmedPayments!)}
            </Heading>
          </div>
          {eventPartners?.map((partner) => (
            <div key={partner.id} className="partner">
              <Heading variant="h5" className="regular">
                {partner.name}
              </Heading>
              <Heading variant="h5" className="regularPercent">
                {partner &&
                  partner.events.filter(
                    (event) => event.event.id === eventData!.id
                  )[0].revenuePercent + '%'}
              </Heading>
              <Heading variant="h5" className="purpleColor regularPrice">
                {formatPrice(
                  (partner.events.filter(
                    (event) => event.event.id === eventData!.id
                  )[0].revenuePercent *
                    summaryData?.summary.confirmedPayments!) /
                    100
                )}
              </Heading>
            </div>
          ))}
        </div>

        <div className="button">
          <Button
            size="large"
            color="primary"
            variant="contained"
            fullWidth={false}
            onClick={distributeBalance}>
            Distribuir
          </Button>
        </div>
      </S.Wrapper>
    </S.Container>
  )
}
