import { action } from 'typesafe-actions'
import {
  CreateBannerRequest,
  CreateBannerResponse
} from '../../../../api/services/banners/createBannerService'
import {
  EditBannerRequest,
  EditBannerResponse
} from '../../../../api/services/banners/editBannerService'
import { BannersResponse } from '../../../../api/services/banners/getBannersService'
import { BannersActions } from '../types'

export const getAllBannersRequest = () =>
  action(BannersActions.LOAD_BANNERS_REQUEST)

export const getAllBannersSuccess = (data: BannersResponse[]) =>
  action(BannersActions.LOAD_BANNERS_SUCCESS, data)

export const createBannerRequest = (data: CreateBannerRequest) =>
  action(BannersActions.CREATE_BANNER_REQUEST, data)

export const createBannerSuccess = (data: CreateBannerResponse) =>
  action(BannersActions.CREATE_BANNER_SUCCESS, data)

export const editBannerRequest = (data: EditBannerRequest, idBanner: string) =>
  action(BannersActions.EDIT_BANNER_REQUEST, { data, idBanner })

export const editBannerSuccess = (data: EditBannerResponse) =>
  action(BannersActions.EDIT_BANNER_SUCCESS, data)

export const deleteBannerRequest = (data: string[]) =>
  action(BannersActions.DELETE_BANNER_REQUEST, data)

export const deleteBannerSuccess = (data: string[]) =>
  action(BannersActions.DELETE_BANNER_SUCCESS, data)
