import { all, call, put, takeLatest, delay } from '@redux-saga/core/effects'
import _ from 'lodash'
import { PayloadAction } from 'typesafe-actions'
import {
  listCustomerFilter,
  ListCustomerResponse
} from '../../../api/services/filter/listCustomerFilter'
import {
  CreateCustomerRequest,
  CreateCustomerResponse,
  createCustomerService
} from '../../../api/services/customers/createCustomerService'
import { deleteCustomerService } from '../../../api/services/customers/deleteCustomerService'
import {
  EditCustomerRequest,
  EditCustomerResponse,
  editCustomerService
} from '../../../api/services/customers/editCustomerService'
import {
  CustomerResponse,
  getCustomersService
} from '../../../api/services/customers/getCustomersService'
import { messageHelper } from '../../../utils/messageHelper'
import { clearLoading, setLoading } from '../Loading/actions'
import { setModalState } from '../Modal/Actions/actionsModal'
import { store } from '../store'
import {
  createCustomerSuccess,
  deleteCustomerSuccess,
  editCustomerSuccess,
  getCustomersSuccess,
  getSearchCustomerSuccess
} from './Actions/customerActions'
import { CustomersActions } from './types'

function* getCustomers(
  action: PayloadAction<
    CustomersActions.GET_CUSTOMERS_REQUEST,
    { skip: number; currentPage: number }
  >
) {
  yield put(setLoading(true))
  try {
    const { getState } = store
    const { take } = getState().Customer

    const data: CustomerResponse = yield call(
      getCustomersService,
      action.payload.skip,
      take
    )
    yield put(
      getCustomersSuccess(data, action.payload.skip, action.payload.currentPage)
    )
    yield delay(Number(process.env.REACT_APP_DELAY_REQUEST))
    yield put(clearLoading())
  } catch (err) {
    yield put(clearLoading())
    yield put(
      setModalState({
        open: true,
        variant: 'error',
        message: messageHelper.modal.customer.error.loadCustomer
      })
    )
  }
}

export function* createCustomer(
  action: PayloadAction<
    CustomersActions.CREATE_CUSTOMER_REQUEST,
    CreateCustomerRequest
  >
) {
  yield put(setLoading(true))
  try {
    const data: CreateCustomerResponse = yield call(
      createCustomerService,
      action.payload
    )

    yield put(createCustomerSuccess(data))

    yield delay(Number(process.env.REACT_APP_DELAY_REQUEST))
    yield put(clearLoading())

    yield put(
      setModalState({
        open: true,
        message: messageHelper.modal.customer.success.createCustomer,
        variant: 'success'
      })
    )
  } catch (err) {
    yield put(clearLoading())

    yield put(
      setModalState({
        open: true,
        message: messageHelper.modal.customer.error.createCustomer,
        variant: 'success'
      })
    )
  }
}

function* editCustomer(
  action: PayloadAction<
    CustomersActions.EDIT_CUSTOMER_REQUEST,
    { data: EditCustomerRequest; customerId: string }
  >
) {
  try {
    if (!_.isEmpty(action.payload.data)) {
      const data: EditCustomerResponse = yield call(
        editCustomerService,
        action.payload.data,
        action.payload.customerId
      )
      yield put(editCustomerSuccess(data))
      yield put(
        setModalState({
          open: true,
          message: 'Cliente alterado com sucesso.',
          variant: 'success'
        })
      )
    }
  } catch (err) {
    yield put(
      setModalState({
        open: true,
        message: 'Não foi possível editar o cliente.',
        variant: 'error'
      })
    )
  }
}

function* deleteCustomer(
  action: PayloadAction<CustomersActions.DELETE_CUSTOMER_REQUEST, string[]>
) {
  yield put(setLoading(true))
  try {
    yield all(
      action.payload.map((customerId) =>
        call(deleteCustomerService, customerId)
      )
    )

    yield put(deleteCustomerSuccess(action.payload))

    yield delay(Number(process.env.REACT_APP_DELAY_REQUEST))
    yield put(clearLoading())

    yield put(
      setModalState({
        open: true,
        message: `${
          action.payload.length > 1 ? 'Clientes deletados' : 'Cliente deletado'
        } com sucesso.`,
        variant: 'success'
      })
    )
  } catch (err) {
    yield put(clearLoading())

    yield put(
      setModalState({
        open: true,
        message: `Não foi possível deletar ${
          action.payload.length > 1 ? 'os clientes' : 'o cliente'
        }.`,
        variant: 'error'
      })
    )
  }
}

function* searchCustomer(
  action: PayloadAction<CustomersActions.GET_SEARCH_CUSTOMER_REQUEST, string>
) {
  yield put(setLoading(true))

  try {
    const data: ListCustomerResponse[] = yield call(
      listCustomerFilter,
      action.payload
    )

    yield put(getSearchCustomerSuccess(data))

    yield put(clearLoading())
  } catch (err) {
    yield put(clearLoading())
    yield put(
      setModalState({
        variant: 'error',
        message:
          'Não foi possivel filtrar os clientes, tente novamante mais tarde',
        open: true
      })
    )
  }
}

export const sagaCustomer = all([
  takeLatest(CustomersActions.GET_CUSTOMERS_REQUEST, getCustomers),
  takeLatest(CustomersActions.CREATE_CUSTOMER_REQUEST, createCustomer),
  takeLatest(CustomersActions.EDIT_CUSTOMER_REQUEST, editCustomer),
  takeLatest(CustomersActions.GET_SEARCH_CUSTOMER_REQUEST, searchCustomer),
  takeLatest(CustomersActions.DELETE_CUSTOMER_REQUEST, deleteCustomer)
])
