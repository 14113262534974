import { Hidden } from 'applaus-ui-kit'
import moment from 'moment'
import { BrandCreditCard } from '../../../../components/brandCreditCard/BrandCreditCard'
import { formatPrice } from '../../../../utils/formatPrice'
import * as S from './Table.styles'

export type TableProps = {
  purchaseHistory?: {
    items: any
    total: number
  }
}

export const Table = ({ purchaseHistory }: TableProps) => {
  console.log(purchaseHistory)
  return (
    <S.Container>
      <Hidden tablet desktop>
        <S.ContentStatus>
          <S.StatusApproved>
            <div />
            <p>Aprovado</p>
          </S.StatusApproved>
          <S.StatusProcess>
            <div />
            <p>Processando</p>
          </S.StatusProcess>
          <S.StatusFailed>
            <div />
            <p>Recusado</p>
          </S.StatusFailed>
        </S.ContentStatus>
      </Hidden>
      <S.Header>
        <Hidden mobile>
          <p>Nome</p>
        </Hidden>
        <Hidden tablet desktop>
          <p>Status</p>
        </Hidden>
        <p>Data</p>
        <p>Método</p>
        <p className="spacingLeft">Valor</p>

        <Hidden mobile>
          <p>Status</p>
        </Hidden>
      </S.Header>

      <S.ContentRows>
        {purchaseHistory?.items.length > 0
          ? purchaseHistory?.items.map((item: any) => (
              <S.Row key={item.id}>
                <div className="name-wrapper">
                  <p className="event-name">{item.description}</p>
                </div>
                <Hidden tablet desktop>
                  <S.Status
                    status={item.status}
                    className="status spacingLeft"
                  />
                </Hidden>
                <p className="spacingRight">
                  {moment(item.createdAt).format('DD/MM/YYYY')}
                </p>
                <BrandCreditCard
                  brandIcon={item.brand}
                  paymentMethod={item.paymentMethod}
                  cashback={
                    item.cashBackDiscountCents !== null &&
                    item.cashBackDiscountCents > 0
                  }
                />
                <p className="spacingLeft">
                  {formatPrice(item.totalPrice / 100)}
                </p>

                <Hidden mobile>
                  <S.PaymentStatus status={item.status}>
                    {item.status === 'APPROVED'
                      ? 'Aprovado'
                      : item.status === 'PROCESSING'
                      ? 'Processando'
                      : 'Recusado'}
                  </S.PaymentStatus>
                </Hidden>
              </S.Row>
            ))
          : null}
      </S.ContentRows>
    </S.Container>
  )
}
