import axios, { AxiosResponse } from 'axios'

type BackOfficeUser = {
  id: string
  email: string
  firstName: string
  lastName: string
}

export const getBackofficeUserInfoService = async (refreshToken: string) => {
  const { data }: AxiosResponse<BackOfficeUser> = await axios.get(
    `${process.env.REACT_APP_API_BACKEND_URL}/api/backoffice/info`,
    {
      headers: {
        Authorization: `Bearer ${refreshToken}`
      }
    }
  )
  return data
}
