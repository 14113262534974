import { action } from 'typesafe-actions'
import { ListCreatorResponse } from '../../../../api/services/filter/listCreatorFilter'
import {
  CreateCreatorRequest,
  CreateCreatorResponse
} from '../../../../api/services/creators/createCreatorService'
import {
  EditCreatorAndPaymentSettingsResponse,
  EditCreatorRequest
} from '../../../../api/services/creators/editCreatorService'
import { CreatorResponse } from '../../../../api/services/creators/getCreatorsService'
import { CreatorsActions } from '../types'

export const findOneCreatorByIdSuccess = (
  creator: EditCreatorAndPaymentSettingsResponse
) => action(CreatorsActions.FIND_ONE_CREATOR_BY_ID_SUCCESS, creator)

export const getCreatorsRequest = (
  skip: number,
  currentPage: number,
  creatorId?: string
) =>
  action(CreatorsActions.GET_CREATORS_REQUEST, { skip, currentPage, creatorId })

export const getCreatorsSuccess = (
  data: CreatorResponse,
  skip: number,
  currentPage: number
) => action(CreatorsActions.GET_CREATORS_SUCCESS, { data, skip, currentPage })

export const createCreatorRequest = (data: CreateCreatorRequest) =>
  action(CreatorsActions.CREATE_CREATOR_REQUEST, data)

export const createCreatorSuccess = (data: CreateCreatorResponse) =>
  action(CreatorsActions.CREATE_CREATOR_SUCCESS, data)

export const editCreatorRequest = (
  data: EditCreatorRequest,
  creatorId: string,
  paymentSettingsId: string,
  paymentSettings?: { appFee?: number; pixFee?: number; creditCatdFee?: number }
) =>
  action(CreatorsActions.EDIT_CREATOR_REQUEST, {
    data,
    creatorId,
    paymentSettingsId,
    paymentSettings
  })

export const editCreatorSuccess = (
  data: EditCreatorAndPaymentSettingsResponse
) => action(CreatorsActions.EDIT_CREATOR_SUCCESS, data)

export const editPaymentSettingsSuccess = (
  data: EditCreatorAndPaymentSettingsResponse,
  creatorId: string
) => action(CreatorsActions.EDIT_PAYMENT_SETTINGS_SUCCESS, { data, creatorId })

export const deleteCreatorRequest = (data: string[]) =>
  action(CreatorsActions.DELETE_CREATOR_REQUEST, data)

export const deleteCreatorSuccess = (data: string[]) =>
  action(CreatorsActions.DELETE_CREATOR_SUCCESS, data)

export const getSearchCreatorRequest = (data: string) =>
  action(CreatorsActions.GET_SEARCH_CREATOR_REQUEST, data)

export const getSearchCreatorSuccess = (data: ListCreatorResponse[]) =>
  action(CreatorsActions.GET_SEARCH_CREATOR_SUCCESS, data)
