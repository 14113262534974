import { AxiosResponse } from 'axios'
import { api } from '../../api'

type Course = {
  id: string
  name: string
  description: string
  coverUrl: string
  priceCents: number
  discountCents: number
  createdAt: string
  updatedAt: string | null
  creatorId: string | null
}

export type LoadCourseResponse = {
  items: Course[]
}

export const getCoursesService = async (skip: number, take: number) => {
  const { data }: AxiosResponse<LoadCourseResponse> = await api.get(
    `/api/backoffice/courses?skip=${skip}&take=${take}`
  )

  return data
}
