import produce from 'immer'
import { Reducer } from 'redux'
import { EventsAction, EventState } from './types'
import { EventActions } from '../EditEvent/types'

const initialState: EventState = {
  eventData: {
    items: [],
    total: 0
  },
  currentPage: 1,
  skip: 0,
  take: 10
}

export const reducerEvent: Reducer<EventState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case EventsAction.loadEventSuccess:
      return produce(state, (draft) => {
        draft.eventData = action.payload.data
        draft.currentPage = action.payload.currentPage
        draft.skip = action.payload.skip
      })
    case EventActions.removeEventSuccess:
      return produce(state, (draft) => {
        draft.eventData.items = draft.eventData.items.filter(
          (item) => item.id !== action.payload
        )
      })
    default:
      return state
  }
}
