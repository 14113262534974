import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    background: white;
    display: flex;
    flex-direction: column;
    .divider {
      border: 1px dashed #adadad;
      margin: ${theme.spacing.stack.xxxs};
      opacity: 0.9;
    }

    .contentTickets {
      margin-top: ${theme.spacing.stack.xs};
    }
  `}
`

export const ContentRequest = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxs};
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (min-width: 1171px) {
      padding: ${theme.spacing.stack.xs} ${theme.spacing.stack.xs};
    }
  `}
`

export const TitleRequest = styled.h1`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.semiBold};
    font-size: ${theme.font.size.md};
    line-height: ${theme.spacing.stack.sm};
    color: #39383a;
    margin-bottom: ${theme.spacing.stack.xs};
  `}
`

export const ContentTicket = styled.div`
  ${({ theme }) => css`
    max-width: 800px;
    display: grid;
    grid-template-columns: 1fr 0.1fr;
    margin-bottom: ${theme.spacing.stack.xxs};
  `}
`

export const TicketBox = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.border.radius.medium};
    border: 2px solid #dfdfdf;
    display: flex;
    width: 100%;
    height: 100%;

    .circleLeft {
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;

      border-top: 2px solid #dfdfdf;
      border-left: 2px solid white;
      border-right: 2px solid #dfdfdf;
      border-bottom: 2px solid white;
      transform: rotate(40deg);
      margin: auto ${theme.spacing.stack.xxxs} auto -20px;
    }
    .circleRight {
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;

      border-top: 2px solid white;
      border-left: 2px solid #dfdfdf;
      border-right: 2px solid white;
      border-bottom: 2px solid #dfdfdf;
      transform: rotate(40deg);
      margin: auto -20px auto ${theme.spacing.stack.xxxs};
    }

    .titleEventBox {
      display: flex;
    }

    .ticketDetached {
      color: #ff6565;
      font-size: ${theme.font.size.sm};
      font-weight: ${theme.font.weight.bold};
      margin-left: ${theme.spacing.inline.nano};
    }
  `}
`

export const Ticket = styled.div`
  ${({ theme }) => css`
    border-left: 1px dashed #dfdfdf;
    border-right: 1px dashed #dfdfdf;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
    width: 100%;
  `}
`

export const TitleEvent = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.bold};
    line-height: ${theme.spacing.stack.xxs};
  `}
`

export const AddressEvent = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xs};
    font-weight: ${theme.font.weight.regular};
    line-height: ${theme.spacing.stack.xxxs};
  `}
`

export const Date = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xs};
    font-weight: ${theme.font.weight.semiBold};
    line-height: ${theme.spacing.stack.xxxs};
  `}
`

export const TicketInfoBox = styled.div`
  ${({ theme }) => css`
    justify-content: space-between;
    margin-top: ${theme.spacing.stack.small};

    .contentTicketInfo {
      display: flex;
      h1 {
        margin-right: ${theme.spacing.stack.nano};
        font-size: ${theme.font.size.xxs};
      }

      p {
        font-size: ${theme.font.size.xxs};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 45px;
      }
    }
  `}
`

export const TicketInfo = styled.div`
  ${({ theme }) => css`
    .contentInfoBox {
      display: flex;
      h1 {
        margin-right: ${theme.spacing.stack.nano};
        font-size: ${theme.font.size.xxs};
      }

      p {
        font-size: ${theme.font.size.xxs};
      }
    }
  `}
`

export const TicketInfo2 = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: ${theme.spacing.stack.small};
    justify-content: space-between;
    align-items: flex-start;
  `}
`

export const PurchaseData = styled.div`
  ${({ theme }) => css`
    h1,
    p {
      font-size: ${theme.font.size.xxs};
    }

    .contentPurchaseData {
      display: flex;
      h1 {
        margin-right: ${theme.spacing.stack.small};
      }
    }
  `}
`

export const QrCode = styled.div`
  ${({ theme }) => css`
    padding: 12px ${theme.spacing.stack.xxs};
    border: 2px solid #dfdfdf;
    border-radius: ${theme.border.radius.light};
    display: flex;

    justify-content: center;

    margin-left: ${theme.spacing.stack.xxs};

    flex-direction: column;
    align-items: center;
    p {
      font-size: 8px;
      font-weight: ${theme.font.weight.semiBold};
    }

    .idQrCode {
      font-size: ${theme.font.size.xxs};
      line-height: ${theme.spacing.stack.xxs};
      color: #000000;
      font-weight: ${theme.font.weight.semiBold};
    }

    .circleLeft {
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;

      border-top: 2px solid #dfdfdf;
      border-left: 2px solid white;
      border-right: 2px solid #dfdfdf;
      border-bottom: 2px solid white;
      transform: rotate(225deg);
      margin: auto -30px auto auto;
    }
    .circleRight {
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 50%;

      border-top: 2px solid white;
      border-left: 2px solid #dfdfdf;
      border-right: 2px solid white;
      border-bottom: 2px solid #dfdfdf;
      transform: rotate(225deg);
      margin: auto auto auto -30px;
    }

    .qrCodeImage {
      width: 120px;
      height: 134px;
    }
  `}
`
