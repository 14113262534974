/* eslint-disable no-unused-vars */
export enum CoursesActions {
  GET_COURSES_REQUEST = '@creator/GET_COURSES_REQUEST',
  GET_COURSES_SUCCESS = '@creator/GET_COURSES_SUCCESS',
  LOAD_COURSES_FAILURE = '@courses/LOAD_COURSES_FAILURE',
  EDIT_COURSE_REQUEST = '@courses/EDIT_COURSE_REQUEST',
  EDIT_COURSE_SUCCESS = '@courses/EDIT_COURSE_SUCCESS',
  DELETE_COURSE_REQUEST = '@courses/DELETE_COURSE_REQUEST',
  DELETE_COURSE_SUCCESS = '@courses/DELETE_COURSE_SUCCESS'
}

export type Course = {
  id: string
  name: string
  description: string
  coverUrl: string
  priceCents: string
  discountCents: string
  createdAt: string
  updatedAt: string
  creatorId: string
}

export type CoursesState = {
  readonly data: {
    items: Course[]
    total: number
  }
  currentPage: number
  skip: number
  take: number
}
