import styled, { css } from 'styled-components'

import { ReactComponent as Menu } from '../../../../assets/icons/menu.svg'
import { ReactComponent as Profile } from '../../../../assets/icons/profileIcon.svg'

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3rem;
    background: ${theme.color.neutral.nth4};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
  `}
`

export const MenuIcon = styled(Menu)`
  ${({ theme }) => css`
    fill: ${theme.color.base.nth1};
    cursor: pointer;
  `}
`

export const ProfileIcon = styled(Profile)`
  ${({ theme }) => css`
    fill: ${theme.color.base.nth1};
    cursor: pointer;
  `}
`
