// import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Pagination,
  TableBody,
  TableContainer,
  TableData,
  TableFooter,
  TableRow
} from '../../../components/table'
import TableHeader from '../../../components/table/TableHeader/TableHeader'
import { getCustomersRequest } from '../../../redux/store/Customers/Actions/customerActions'
import { Customer } from '../../../redux/store/Customers/types'
import { ReduxState } from '../../../redux/store/rootReducer'

import * as S from './CreatorTable.styles'

export type Headers = {
  name: string
  field: string
  align: 'center' | 'right' | 'left'
  sortable: boolean
}

type CustomersTableProps = {
  editCustomer: (customer: Customer) => void
  deleteCustomer: (customer: Customer) => void
  searchInput: string
}

export const CustomersTable = ({
  editCustomer,
  deleteCustomer,
  searchInput
}: CustomersTableProps) => {
  const { data, currentPage, take } = useSelector(
    (state: ReduxState) => state.Customer
  )
  const dispatch = useDispatch()

  const paginationData = useMemo(() => {
    const computedData = data.items

    return computedData
  }, [data, currentPage])

  const headers: Headers[] = [
    { name: 'Nome', field: 'name', align: 'left', sortable: false },
    { name: 'Documento', field: 'document', align: 'left', sortable: false },
    { name: 'Email', field: 'email', align: 'left', sortable: false },
    { name: 'Ações', field: 'actions', align: 'right', sortable: false }
  ]

  useEffect(() => {
    if (searchInput === '') {
      dispatch(getCustomersRequest((currentPage - 1) * take, currentPage))
    }
  }, [searchInput])

  return (
    <>
      <S.Container>
        <TableContainer
          table={
            <>
              <TableHeader
                headers={headers}
                type="manage"
                onSorting={() => {}}
              />

              <TableBody>
                {paginationData.map((customer) => {
                  return (
                    <TableRow
                      key={customer.id}
                      selected={false}
                      clickable
                      onClick={() => {
                        window.open(`/customerInfo/${customer.id}`, '_blank')
                      }}>
                      <TableData>
                        {customer.firstName + ' ' + customer.lastName}
                      </TableData>
                      <TableData>
                        {customer.document.replace(
                          /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                          '$1.$2.$3-$4'
                        )}
                      </TableData>
                      <TableData>{customer.email}</TableData>
                      <TableData align="right">
                        <S.IconBox>
                          <S.EditIcon
                            onClick={(e) => {
                              e.stopPropagation()
                              editCustomer(customer)
                            }}
                          />
                          <S.ExcludeIcon
                            onClick={() => deleteCustomer(customer)}
                          />
                        </S.IconBox>
                      </TableData>
                    </TableRow>
                  )
                })}
              </TableBody>
            </>
          }
          footer={
            <>
              <TableFooter>
                <p>{data.total} clientes cadastrados</p>
                <Pagination
                  currentPage={currentPage}
                  itemsPerPage={take}
                  onPageChange={(page) =>
                    dispatch(getCustomersRequest((page - 1) * take, page))
                  }
                  totalItems={data.total}
                />
              </TableFooter>
            </>
          }
        />
      </S.Container>
    </>
  )
}
