import { AxiosResponse } from 'axios'
import { api } from '../../api'

export type EditChartRequest = {
  name?: string
  address?: {
    street?: string
    number?: number
    city?: string
    state?: string
    complement?: string | null
    country?: string
    zipCode?: string
  }
  seatChartId?: string
}

export type EditChartResponse = {
  id: string
  name: string
  seatChartId: string
  address: {
    city: string
    country: string
    number: number
    state: string
    street: string
    complement: string
    zipCode: string
  }
  createdAt: string
}

export const editChartService = async (
  dataChart: EditChartRequest,
  seatId: string
) => {
  if (dataChart.address?.complement === '') {
    delete dataChart.address.complement
  }
  if (!dataChart.address) {
    delete dataChart.address
  }
  if (dataChart.address?.complement === '') {
    delete dataChart.address?.complement
  }
  if (dataChart.address?.country === '') {
    delete dataChart.address?.country
  }
  if (dataChart.address?.number === 0 || dataChart.address?.number === null) {
    delete dataChart.address?.number
  }
  if (dataChart.address?.state === '') {
    delete dataChart.address?.state
  }
  if (dataChart.address?.street === '') {
    delete dataChart.address?.street
  }
  if (dataChart.address?.zipCode === '') {
    delete dataChart.address.zipCode
  }
  if (dataChart.name === '') {
    delete dataChart.name
  }
  if (dataChart.seatChartId === '') {
    delete dataChart.seatChartId
  }

  const { data }: AxiosResponse<EditChartResponse> = await api.put(
    `/api/backoffice/seatMaps/${seatId}`,
    dataChart
  )

  return data
}
