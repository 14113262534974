import { AxiosResponse } from 'axios'
import { api } from '../../api'
import { Address, EventItems } from '../../../redux/store/Events/types'

export type BalanceWithdrawals = {
  comments: null
  createdAt: string
  creator: null
  creatorId: null
  eventId: string
  eventPartner: {
    bank: string
    bankAccountType: string
    bankAg: string
    bankCc: string
    createdAt: string
    creatorId: string
    document: string
    documentType: string
    email: string
    id: string
    liveApiToken: string
    name: string
    phoneNumber: string
    status: string
    subAccountId: string
    testApiToken: string
    updatedAt: string
    userToken: string
  }
  eventPartnerId: string
  id: string
  status: string
  updatedAt: null
  valueCents: number
}

export type Partners = {
  eventId: string
  eventPartner: {
    bank: string
    bankAccountType: string
    bankAg: string
    bankCc: string
    createdAt: string
    creatorId: string
    document: string
    documentType: string
    email: string
    id: string
    liveApiToken: string
    name: string
    phoneNumber: string
    status: string
    subAccountId: string
    testApiToken: string
    updatedAt: string
    userToken: string
  }
  eventPartnerId: string
  revenuePercent: number
}

export type Event = {
  id: string
  name: string
  type?: 'MAPPED' | 'TABULATED'
  seatMapId?: string
  dates?: string[]
  description?: string
  status?: 'CANCELED' | 'OPEN' | 'CLOSED' | 'DRAFT' | 'SOLD_OUT'
  banners?: {
    desktopUrl?: string
    tabletUrl?: string
    mobileUrl?: string
    cardUrl?: string
  }[]
  address?: Address
  createdAt?: string
  updatedAt?: string | null
  creatorId?: string
  eventItems: EventItems[]
  balanceWithdrawals: BalanceWithdrawals[]
  eventPartners: Partners[]
}

export type ListEventsResponse = {
  items: Event[]
  total: number
}

export const listEventsService = async (request: {
  skip?: number
  take?: number
}) => {
  const { data }: AxiosResponse<ListEventsResponse> = await api.get(
    `/api/backoffice/events?skip=${request.skip}&take=${request.take}`
  )

  return data
}
