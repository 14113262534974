import * as Yup from 'yup'

export const validationSchema = Yup.object().shape(
  {
    name: Yup.string().required('Nome é obrigatório'),
    seatChartId: Yup.string().required('ID da planta é obrigatório.'),
    zipCode: Yup.string()
      .required('Endereço é obrigatório.')
      .test('zipCodeValidate', 'Insira um CEP válido.', (values) => {
        if (values?.replaceAll(/[.-]/g, '').length === 8) {
          return true
        } else return false
      }),
    street: Yup.string().required('Rua é obrigatória'),
    number: Yup.string().required('Número é obrigatório'),
    complement: Yup.string()
      .nullable()
      .notRequired()
      .when('complement', {
        is: (value: string) => value?.length,
        then: (rule) => rule.min(3, 'Mínimo de 3 caracteres')
      }),
    city: Yup.string().required('Cidade é obrigatória'),
    state: Yup.string().required('Estado é obrigatório'),
    country: Yup.string().required('País é obrigatório')
  },
  [['complement', 'complement']]
)
