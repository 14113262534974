import _ from 'lodash'
import { useState } from 'react'

import { CheckBox } from 'applaus-ui-kit'

import * as S from './TableHeader.styles'

export type Headers = {
  name: string
  field: string
  align: 'center' | 'right' | 'left'
  sortable: boolean
}

type HeaderProps = {
  headers: Headers[]
  type?: 'edit' | 'manage'
  handleSelect?: () => void
  handleCheckboxState?: () => boolean
  onSorting: (field: string, order: string) => void
}

const Header = ({
  headers,
  handleCheckboxState,
  handleSelect,
  onSorting,
  type = 'edit'
}: HeaderProps) => {
  const [sortingField, setSortingField] = useState('')
  const [sortingOrder, setSortingOrder] = useState('asc')

  const onSortingChange = (field: string) => {
    const order =
      field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc'
    setSortingField(field)
    setSortingOrder(order)
    onSorting(field, order)
  }
  return (
    <thead>
      <tr>
        {type === 'edit' && (
          <S.TableHeader align="center">
            <CheckBox
              name="checkbox"
              checked={
                _.isUndefined(handleCheckboxState)
                  ? false
                  : handleCheckboxState()
              }
              readOnly
              onClick={handleSelect}
            />
          </S.TableHeader>
        )}
        {headers.map(({ name, align, field, sortable }) => (
          <S.TableHeader
            key={field}
            align={align}
            onClick={() => (sortable ? onSortingChange(field) : null)}>
            <p>
              {sortingField && sortingField === field && (
                <i className="uil uil-sort" />
              )}
              {name}
            </p>
          </S.TableHeader>
        ))}
      </tr>
    </thead>
  )
}

export default Header
