import { api } from 'api/api'
import { Button, Paragraph } from 'applaus-ui-kit'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { AuthenticateTemplate } from 'components/templates/AuthenticateTemplate'
import {
  AddCoupons,
  InitialValues
} from 'components/templates/EventCoupons/AddCoupons/AddCoupons'
import produce from 'immer'
import { isEmpty } from 'lodash'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Coupon } from 'redux/store/EditEvent/types'
import { setModalState } from 'redux/store/Modal/Actions/actionsModal'
import { formatPrice } from 'utils/formatPrice'

import * as S from './Coupons.styles'

type Error = {
  message: string
}

export const Coupons = () => {
  const [coupons, setCoupons] = useState<Coupon[]>([])

  const dispatch = useDispatch()

  const [couponFormState, setCouponFormState] = useState<{
    open: boolean
    coupon?: Coupon
  }>()

  useEffect(() => {
    const getApplausCoupons = async () => {
      const { data } = await api.get('api/backoffice/coupons')
      setCoupons(data)
    }
    getApplausCoupons()
  }, [])

  const handleClickAddCoupon = () => {
    setCouponFormState({ open: true })
  }

  const handleClickEditCoupon = (coupon: Coupon) => {
    setCouponFormState({ open: true, coupon })
  }

  const handleDeleteCoupon = async (couponId: string) => {
    try {
      await api.delete(`api/backoffice/coupons/${couponId}`)
      setCoupons((prev) => prev.filter((coupon) => coupon.id !== couponId))
    } catch (error) {}
  }

  const handleCreateCoupon = async ({
    name,
    description,
    value,
    valueType,
    finalValidateDate,
    startValidateDate,
    quantity,
    amountOfUse
  }: InitialValues) => {
    try {
      console.log('valueType', valueType)

      const formattedValue =
        valueType === 'CENTS' ? Number(value.replace(/[^0-9]+/g, '')) : value
      console.log('formattedValue', formattedValue)

      const { data }: AxiosResponse<Coupon> = await api.post(
        'api/backoffice/coupons',
        {
          name,
          description,
          value: parseInt(formattedValue as string),
          valueType,
          finalValidateDate,
          startValidateDate,
          quantity,
          amountOfUse
        }
      )
      setCouponFormState({ open: false })
      setCoupons(
        produce((draft) => {
          draft.push(data)
        })
      )
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const error = err as AxiosError<Error>
        console.log('mensagem de erro', error.response?.data.message)

        switch (error.response?.data.message) {
          case 'coupon already exists':
            dispatch(
              setModalState({
                open: true,
                message: 'Cupom já existe',
                variant: 'error',
                description: 'Altere o nome do cupom',
                buttonText: 'Fechar'
              })
            )
            break

          default:
            break
        }
      }
    }
  }

  const handleEditCoupon = async (coupon: {
    id: string
    name: string
    description: string
    amountOfUse: number
    value: string
    valueType: 'PERCENT' | 'CENTS'
    quantity?: number | undefined
    startValidateDate?: string | undefined
    finalValidateDate?: string | undefined
  }) => {
    const formattedValue =
      coupon.valueType === 'CENTS'
        ? Number(coupon.value.replace(/[^0-9]+/g, ''))
        : coupon.value
    try {
      const couponIndex = coupons.findIndex(({ id }) => id === coupon.id)
      const changes: any = {}
      for (const prop in coupons[couponIndex]) {
        const propName = prop as
          | 'name'
          | 'description'
          | 'amountOfUse'
          | 'value'
          | 'valueType'
          | 'quantity'
          | 'startValidateDate'
          | 'finalValidateDate'
        if (
          propName.indexOf('$') !== 0 &&
          coupons[couponIndex][propName] !== coupon[propName]
        ) {
          changes[propName] = coupon[propName]
        }
      }
      if (!isEmpty(changes)) {
        const { data }: AxiosResponse<Coupon> = await api.put(
          `api/backoffice/coupons/${coupon.id}`,
          { ...changes, value: formattedValue }
        )
        setCoupons(
          produce((draft) => {
            if (couponIndex > -1) {
              draft[couponIndex] = data
            }
          })
        )
      }

      setCouponFormState({ open: false })
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const error = err as AxiosError<Error>
        console.log('mensagem de erro', error.response?.data.message)

        switch (error.response?.data.message) {
          case 'coupon already exists':
            dispatch(
              setModalState({
                open: true,
                message: 'Cupom já existe',
                variant: 'error',
                description: 'Altere o nome do cupom',
                buttonText: 'Fechar'
              })
            )
            break

          default:
            break
        }
      }
    }
  }

  const [searchInput, setSearchInput] = useState('')

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value)
  }

  const searchCoupon = useMemo(() => {
    if (!searchInput) return coupons

    return coupons.filter((coupon) => {
      return coupon.name.toLowerCase().includes(searchInput.toLowerCase())
    })
  }, [coupons, searchInput])

  return (
    <AuthenticateTemplate active="coupons">
      {couponFormState?.open ? (
        <S.CouponFormWrapper>
          <AddCoupons
            coupon={couponFormState.coupon}
            handleClickBack={() => setCouponFormState({ open: false })}
            handleSubmit={handleCreateCoupon}
            handleEditCoupon={handleEditCoupon}
          />
        </S.CouponFormWrapper>
      ) : (
        <S.Container>
          <S.Header>
            <div>
              <S.CouponIcon />
              <S.Title>Cupons</S.Title>
            </div>
            <Button
              variant="contained"
              size="medium"
              onClick={handleClickAddCoupon}
              color={'primary'}
              fullWidth={false}>
              Adicionar Cupom
            </Button>
          </S.Header>
          <div className="inputBox">
            <S.SearchIcon />
            <S.Searchnput
              placeholder="Procure eventos por nome ou status"
              onChange={handleChangeSearch}
            />
          </div>
          <S.TableHeader>
            <S.TableTitle>Código</S.TableTitle>
            <S.TableTitle>Tipo de cupom</S.TableTitle>
            <S.TableTitle>Desconto</S.TableTitle>
            <S.TableTitle />
          </S.TableHeader>
          {searchCoupon.map((coupon) => (
            <S.TableData key={coupon.id}>
              <Paragraph variant="regular" className="data-info">
                {coupon.name}
              </Paragraph>
              <Paragraph variant="regular" className="data-info">
                {coupon.quantity ? 'Limitado' : 'Ilimitado'}
              </Paragraph>
              <Paragraph variant="regular" className="data-info">
                {coupon.valueType === 'PERCENT'
                  ? `${coupon.value} %`
                  : formatPrice(coupon.value / 100)}
              </Paragraph>

              <div>
                <S.EditIcon
                  onClick={() => {
                    handleClickEditCoupon(coupon)
                  }}
                />
                <S.TrashIcon onClick={() => handleDeleteCoupon(coupon.id)} />
              </div>
            </S.TableData>
          ))}
        </S.Container>
      )}
    </AuthenticateTemplate>
  )
}
