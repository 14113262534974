import { AxiosResponse } from 'axios'
import { api } from '../../api'
import { uploadImagesService } from '../storage/uploadImagesService'

export type EditBannerRequest = {
  name?: string
  description?: string
  link?: string
  linkTarget?: 'INTERNAL' | 'EXTERNAL'
  status?: boolean
  desktopUrl?: File | string
  mobileUrl?: File | string
  tabletUrl?: File | string
}

export type EditBannerResponse = {
  id: string
  name: string
  description: string
  link: string
  linkTarget: 'INTERNAL' | 'EXTERNAL'
  position: number
  status: boolean
  desktopUrl: string
  mobileUrl: string
  tabletUrl: string
  createdAt: string
}

export const editBannerService = async (
  dataBanner: EditBannerRequest,
  idBanner: string
) => {
  for (const field of Object.keys(dataBanner) as Array<
    keyof typeof dataBanner
  >) {
    if (
      field === 'desktopUrl' ||
      field === 'tabletUrl' ||
      field === 'mobileUrl'
    ) {
      if (typeof dataBanner[field] !== 'string') {
        const imageUrl = await uploadImagesService(dataBanner[field]! as File)
        dataBanner[field] = imageUrl
      }
    }
  }
  const { data }: AxiosResponse<EditBannerResponse> = await api.put(
    `api/backoffice/banners/${idBanner}`,
    dataBanner
  )

  return data
}
