import { AxiosResponse } from 'axios'
import { api } from '../../api'

export type UserBackoffice = {
  id: string
  firstName: string
  lastName: string
  email: string
  userId: string
  type: string
  createdAt: string
  updatedAt: string | null
}

export type AllUsersResponse = {
  items: UserBackoffice[]
  total: number
}

export const getUsersService = async (skip: number, take: number) => {
  const { data }: AxiosResponse<AllUsersResponse> = await api.get(
    `/api/backoffice?skip=${skip}&take=${take}`
  )

  return data
}
