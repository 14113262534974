import styled, { css } from 'styled-components'

import { ReactComponent as CloseFile } from '../../../../assets/icons/times-square.svg'

export const CloseIcon = styled(CloseFile)`
  ${({ theme }) => css`
    cursor: pointer;
    position: relative;
    bottom: 10px;
    left: 95%;
    width: 32px;
    height: 32px;
  `}
`

export const Container = styled.div<{ open: boolean }>`
  ${({ theme, open }) => css`
    min-width: 320px;
    min-height: 100%;
    width: 100vw;
    height: 100vh;
    display: ${open ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    background: ${theme.color.modal.nth1};
    position: fixed;
    top: 0;
    left: 0;
    .title {
      width: 418px;
      text-align: center;
      margin-bottom: ${theme.spacing.stack.xxs};
    }
    h5 {
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.sm};
      font-weight: ${theme.font.weight.semiBold};
    }
    @media (max-width: 767px) {
      .title {
        width: 100%;
        text-align: center;
        margin-bottom: ${theme.spacing.stack.xxs};
        margin-top: 0;
      }
    }
  `}
`

export const Form = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    background: ${theme.color.neutral.nth4};
    padding: ${theme.spacing.stack.xxs};
    border-radius: 12px;
    label {
      color: ${theme.color.base.nth1};
      font-size: ${theme.font.size.sm};
      font-weight: ${theme.font.weight.semiBold};
      line-height: 21px;
    }
    .content {
      .error {
        color: #fc3e3e;
        font-size: 12px;
        min-height: 24px;
      }
    }
    .percentage {
      display: flex;
      justify-content: space-between;
      p {
        color: ${theme.color.base.nth2};
        font-size: ${theme.font.size.sm};
        font-weight: ${theme.font.weight.semiBold};
        line-height: 21px;
        margin: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxxs} 0 0;
      }
      input {
        width: 120px;
      }
    }
    @media (max-width: 767px) {
      margin: 0;
      width: 100%;
      height: 100%;
      border-radius: 0px;
      padding: ${theme.spacing.stack.xxs};
    }
  `}
`

export const ButtonBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: ${theme.spacing.stack.sm};
  `}
`

export const customStyles = {
  clearIndicator: () => ({
    border: '1px solid blue'
  }),
  control: (provided: any) => ({
    ...provided,
    marginTop: '4px',
    display: 'flex',
    padding: '2px 16px',
    background: '#F4F4F4',
    outline: 'none',
    border: '1px solid transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: '6px',
    '&:focus-within': {
      borderColor: '#8601C9'
    }
  }),
  valueContainer: (provided: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }),
  indicatorSeparator: (provided: any) => ({
    display: 'none'
  }),
  singleValue: (provided: any) => ({
    color: '#39383A',
    fontSize: '16px',
    maxWidth: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '20px'
  }),
  placeholder: (provided: any) => ({
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    backgroundColor: state.isSelected ? '#F4F4F4' : 'white'
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  })
}
