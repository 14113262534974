import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: 2rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    > p {
      color: ${theme.color.base.nth1};
      font-weight: ${theme.font.weight.bold};
      font-size: ${theme.font.size.xs};
    }
  `}
`
