import { Tickets } from '../EditEvent/types'

/* eslint-disable no-unused-vars */
export enum EventsAction {
  loadEventRequest = '@event/LOAD_EVENT_REQUEST',
  loadEventSuccess = '@event/LOAD_EVENT_SUCCESS',
  removeEventRequest = '@event/REMOVE_EVENT_REQUEST',
  removeEventSuccess = '@event/REMOVE_EVENT_SUCCESS'
}

export type Address = {
  name?: string
  country?: string
  state?: string
  city?: string
  street?: string
  number?: number
  zipCode?: string
  complement?: string | null
}

export type EventItems = {
  id?: string
  title: string
  description: string
  dates: string[]
  maxCustomerTickets: string
  salesFrom?: string
  finalSales?: string
  ticketInfo?: {
    value: string
    isRequired: boolean
  }[]
  tickets: Tickets[]
  eventKey?: string
}

export type Event = {
  id: string
  name: string
  description?: string
  type?: 'MAPPED' | 'TABULATED'
  status?: string
  isPublic?: boolean
  address?: {
    id?: string
    name?: string
    country?: string
    state?: string
    city?: string
    street?: string
    number?: number
    zipCode?: string
    complement?: string | null
    district?: string | null
    createdAt?: string
    updatedAt?: string | null
    seatMapId?: null
  }
  banners?: {
    cardUrl?: string
    mobileUrl?: string
    tabletUrl?: string
    desktopUrl?: string
  }[]
  cashBackPercent?: number
  category?: string
  createdAt?: string
  updatedAt?: string | null
  ageGroup?: string
  minPriceCents?: number
  eventItem?: {
    id?: string
    dates?: string[]
    description?: string
    title?: string
    maxCustomerTickets?: number
    ticketInfo?: {
      value?: string
      isRequired?: boolean
    }[]
    eventKey?: string | null
    salesFrom?: string
    finalSales?: string
    createdAt?: string
    updatedAt?: string | null
  }[]
}

export type Events = {
  items: Event[]
  total: number
}

export type EventState = {
  eventData: Events
  currentPage: number
  skip: number
  take: number
}
