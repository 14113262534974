import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    background: ${theme.color.neutral.nth4};
    padding: ${theme.spacing.stack.xxs};
  `}
`

export const Tab = styled.div`
  ${({ theme }) => css`
    display: flex;

    > p:first-child {
      margin-right: ${theme.spacing.stack.xxs};
    }
  `}
`

export const OptionText = styled.p<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    font-size: ${theme.font.size.md};
    cursor: pointer;
    display: flex;
    flex-direction: column;

    ${isActive
      ? css`
          ::after {
            content: '';
            border-color: ${theme.color.brand.primary.nth1};
            border-style: solid;
          }
        `
      : css``}
  `}
`
