import { action } from 'typesafe-actions'
import {
  EditCourseRequest,
  EditCourseResponse
} from '../../../../api/services/courses/editCourseService'
import { LoadCourseResponse } from '../../../../api/services/courses/getCoursesService'
import { CoursesActions } from '../types'

export const getCoursesRequest = (skip: number, currentPage: number) =>
  action(CoursesActions.GET_COURSES_REQUEST, { skip, currentPage })

export const getCoursesSuccess = (
  data: LoadCourseResponse,
  skip: number,
  currentPage: number
) => action(CoursesActions.GET_COURSES_SUCCESS, { data, skip, currentPage })

export const editCourseRequest = (
  data: EditCourseRequest,
  courseId: string,
  creatorId: string
) => action(CoursesActions.EDIT_COURSE_REQUEST, { data, courseId, creatorId })

export const editCourseSuccess = (data: EditCourseResponse) =>
  action(CoursesActions.EDIT_COURSE_SUCCESS, data)

export const deleteCourseRequest = (
  data: { courseId: string; creatorId: string }[]
) => action(CoursesActions.DELETE_COURSE_REQUEST, data)

export const deleteCourseSuccess = (data: { courseId: string }[]) =>
  action(CoursesActions.DELETE_COURSE_SUCCESS, data)
