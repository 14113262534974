import { Button, Input, Paragraph, InputMask } from 'applaus-ui-kit'
import { useFormik } from 'formik'
import _ from 'lodash'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { EditCustomerRequest } from '../../../api/services/customers/editCustomerService'
import { viaCepService } from '../../../api/services/viaCepService'
import ContentModal from '../../../components/contentModal/ContentModal'
import {
  createCustomerRequest,
  editCustomerRequest
} from '../../../redux/store/Customers/Actions/customerActions'
import { ModalState } from '../Customers'
import * as S from './CustomerModal.styles'
import { createCustomerSchema, editCustomerSchema } from './validationSchema'

type CustomerModalProps = {
  modalState: ModalState
  closeModal: () => void
}

export const CustomerModal = ({
  modalState,
  closeModal
}: CustomerModalProps) => {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      document: '',
      email: '',
      phone: '',
      zipCode: '',
      street: '',
      state: '',
      city: '',
      number: '',
      complement: '',
      passwordCustomer: ''
    },
    validationSchema:
      modalState.type === 'CREATE' ? createCustomerSchema : editCustomerSchema,
    onSubmit: (values) => {
      formik.setErrors({})
      closeModal()

      if (modalState.type === 'CREATE') {
        const addressObject = {
          state: values.state === '' ? undefined : values.state,
          city: values.city === '' ? undefined : values.city,
          country: 'Brasil',
          number:
            Number(values.number) === 0 ? undefined : Number(values.number),
          street: values.street === '' ? undefined : values.street,
          complement: values.complement === '' ? undefined : values.complement,
          zipCode: values.zipCode === '' ? undefined : values.zipCode
        }

        dispatch(
          createCustomerRequest({
            document: values.document.replace(/[^\d]/g, ''),
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.passwordCustomer,
            address: addressObject,
            phone: values.phone
          })
        )
        formik.resetForm()
      } else if (modalState.type === 'EDIT' && modalState.customer) {
        const newAddress = {
          city: values.city,
          street: values.street,
          state: values.state,
          number:
            Number(values.number) === 0 ? undefined : Number(values.number),
          country: 'Brasil',
          complement: values.complement,
          zipCode: values.zipCode
        }

        const customerAddress = {
          street:
            modalState.customer.address!.street === null
              ? ''
              : modalState.customer.address!.street,
          state:
            modalState.customer.address!.state === null
              ? ''
              : modalState.customer.address!.state,
          city:
            modalState.customer.address!.city === null
              ? ''
              : modalState.customer.address!.city,
          number:
            Number(modalState.customer.address!.number) === 0
              ? undefined
              : Number(modalState.customer.address!.number),
          country: modalState.customer.address!.city ? 'Brasil' : undefined,
          complement:
            modalState.customer.address!.complement === null
              ? ''
              : modalState.customer.address!.complement,
          zipCode:
            modalState.customer.address!.zipCode === null
              ? ''
              : modalState.customer.address!.zipCode
        }

        const requestValue: EditCustomerRequest = {
          address:
            _.isEqual(customerAddress, newAddress) || values.city === ''
              ? undefined
              : newAddress,
          document:
            modalState.customer.document !== values.document
              ? values.document
              : undefined,
          email:
            modalState.customer.email !== values.email
              ? values.email
              : undefined,
          firstName:
            modalState.customer.firstName !== values.firstName
              ? values.firstName
              : undefined,
          lastName:
            modalState.customer.lastName !== values.lastName
              ? values.lastName
              : undefined,
          phone:
            values.phone !== ''
              ? values.phone !== modalState.customer.phone
                ? values.phone
                : undefined
              : undefined
        }

        dispatch(
          editCustomerRequest(
            Object.fromEntries(
              Object.entries(requestValue).filter(([_, v]) => v !== undefined)
            ),
            modalState.customer.id
          )
        )
        formik.resetForm()
      }
    }
  })
  useEffect(() => {
    if (modalState.type === 'EDIT' && modalState.customer) {
      formik.setFieldValue(
        'city',
        modalState.customer.address?.city === null
          ? ''
          : modalState.customer.address?.city
      )

      formik.setFieldValue(
        'state',
        modalState.customer.address !== undefined
          ? modalState.customer.address?.state
          : ''
      )
      formik.setFieldValue(
        'street',
        modalState.customer.address?.street === null
          ? ''
          : modalState.customer.address?.street
      )

      formik.setFieldValue(
        'number',
        modalState.customer.address?.number === 0 ||
          modalState.customer.address?.number === null ||
          modalState.customer.address?.number === undefined
          ? ''
          : String(modalState.customer.address?.number)
      )
      formik.setFieldValue(
        'zipCode',
        modalState.customer.address !== undefined
          ? modalState.customer.address?.zipCode !== null
            ? modalState.customer.address?.zipCode
            : ''
          : ''
      )
      formik.setFieldValue(
        'complement',
        modalState.customer.address?.complement === null
          ? ''
          : modalState.customer.address?.complement
      )
      formik.setFieldValue('firstName', modalState.customer.firstName)
      formik.setFieldValue('lastName', modalState.customer.lastName)
      formik.setFieldValue('document', modalState.customer.document)
      formik.setFieldValue('email', modalState.customer.email)
      formik.setFieldValue(
        'phone',
        modalState.customer.phone === undefined ||
          modalState.customer.phone === null
          ? ''
          : modalState.customer.phone
      )
      formik.setFieldValue('passwordCustomer', '')
    }
  }, [modalState.open])

  const handleCep = async (value: string) => {
    const cepFormated = value.replaceAll(/[^\d]/g, '').trim()

    if (cepFormated.length === 8) {
      try {
        const data = await viaCepService(cepFormated)
        if (data.erro !== true) {
          formik.setFieldValue('street', data.logradouro)
          formik.setFieldValue('state', data.uf)
          formik.setFieldValue('city', data.localidade)
        }
      } catch (err) {}
    }
  }

  return (
    <ContentModal open={modalState.open}>
      <S.Container>
        <S.ModalTitle>
          <Paragraph variant="large" type="bold">
            {modalState.type === 'CREATE'
              ? 'Cadastrar cliente'
              : 'Editar cliente'}
          </Paragraph>
          <S.IconExitModal
            onClick={() => {
              closeModal()
              formik.resetForm()
            }}
          />
        </S.ModalTitle>
        <S.Form onSubmit={formik.handleSubmit}>
          <Input
            fullWidth
            name="firstName"
            label="Nome"
            placeholder="Digite o nome"
            value={formik.values.firstName ?? ''}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            texterror={formik.errors.firstName}
            onChange={formik.handleChange}
          />

          <Input
            fullWidth
            name="lastName"
            label="Sobrenome"
            placeholder="Digite o sobrenome"
            value={formik.values.lastName ?? ''}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            texterror={formik.errors.lastName}
            onChange={formik.handleChange}
          />

          <InputMask
            mask="999.999.999-99"
            fullWidth
            name="document"
            label="CPF"
            placeholder="000.000.000-00"
            onChange={formik.handleChange}
            value={formik.values.document ?? ''}
            error={formik.touched.document && Boolean(formik.errors.document)}
            texterror={formik.errors.document}
          />

          <Input
            fullWidth
            name="email"
            label="Email"
            placeholder="Digite o email"
            value={formik.values.email ?? ''}
            error={formik.touched.email && Boolean(formik.errors.email)}
            texterror={formik.errors.email}
            onChange={formik.handleChange}
          />

          <InputMask
            mask="(99) 99999-9999"
            fullWidth
            name="phone"
            label="Telefone"
            placeholder="(00) 0 0000-0000"
            value={formik.values.phone ?? ''}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            texterror={formik.errors.phone}
            onChange={formik.handleChange}
          />

          <InputMask
            fullWidth
            mask="99999-999"
            name="zipCode"
            label="CEP"
            placeholder="Digite o CEP"
            value={formik.values.zipCode ?? ''}
            error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
            texterror={formik.errors.zipCode}
            onChange={(e) => {
              formik.handleChange(e)
              handleCep(e.target.value)
            }}
          />

          <Input
            fullWidth
            name="street"
            label="Endereço"
            placeholder="Digite o endereço"
            value={formik.values.street ?? ''}
            error={formik.touched.street && Boolean(formik.errors.street)}
            texterror={formik.errors.street}
            onChange={formik.handleChange}
          />

          <Input
            fullWidth
            name="number"
            label="Número"
            placeholder="Digite o número"
            value={formik.values.number ?? ''}
            error={formik.touched.number && Boolean(formik.errors.number)}
            texterror={formik.errors.number}
            onChange={formik.handleChange}
          />

          <Input
            fullWidth
            name="city"
            label="Cidade"
            placeholder="Digite a cidade"
            value={formik.values.city ?? ''}
            error={formik.touched.city && Boolean(formik.errors.city)}
            texterror={formik.errors.city}
            onChange={formik.handleChange}
          />

          <Input
            fullWidth
            name="state"
            label="Estado"
            placeholder="Digite o estado"
            value={formik.values.state ?? ''}
            error={formik.touched.state && Boolean(formik.errors.state)}
            texterror={formik.errors.state}
            onChange={formik.handleChange}
          />

          <Input
            fullWidth
            name="complement"
            label="Complemento"
            placeholder="Digite o complemento"
            value={formik.values.complement ?? ''}
            error={
              formik.touched.complement && Boolean(formik.errors.complement)
            }
            texterror={formik.errors.complement}
            onChange={formik.handleChange}
          />

          {modalState.type === 'EDIT' ? null : (
            <Input
              fullWidth
              type="password"
              name="passwordCustomer"
              label="Senha"
              placeholder="Digite sua senha"
              value={formik.values.passwordCustomer ?? ''}
              error={
                formik.touched.passwordCustomer &&
                Boolean(formik.errors.passwordCustomer)
              }
              texterror={formik.errors.passwordCustomer}
              onChange={formik.handleChange}
            />
          )}

          <S.ButtonBox>
            <Button
              color="primary"
              size="large"
              variant="text"
              fullWidth
              type="button"
              onClick={() => {
                closeModal()
                formik.resetForm()
              }}>
              Cancelar
            </Button>
            <Button color="primary" size="large" variant="contained" fullWidth>
              {modalState.type === 'CREATE' ? 'Cadastrar' : 'Editar'}
            </Button>
          </S.ButtonBox>
        </S.Form>
      </S.Container>
    </ContentModal>
  )
}
