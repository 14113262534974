import styled, { css } from 'styled-components'

type ContainerProps = {
  selected: boolean
  clickable?: boolean
}

export const Container = styled.tr<ContainerProps>`
  ${({ theme, selected, clickable }) => css`
    width: 100%;
    ${clickable &&
    css`
      cursor: pointer;
    `};
    ${selected
      ? css`
          background: ${theme.color.brand.tertiary.nth4};
        `
      : null}
  `}
`
