import { ReactNode, useState } from 'react'
import { useDispatch } from 'react-redux'
import { logoutAction } from '../../redux/store/User/actions'
import { Menu } from '../menu/Menu'
import { MenuItem } from '../MenuItem/MenuItem'
import { Navigation } from '../navigation/Navigation'
import * as S from './AuthenticateTemplate.styles'
import { AppBar } from './components/appBar/AppBar'

type Props = {
  children: ReactNode
  active?: 'coupons'
}

export const AuthenticateTemplate = ({ children, active }: Props) => {
  const [showNavigation, setShowNavigation] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const dispatch = useDispatch()

  return (
    <S.Container>
      <AppBar
        setShowNavigation={setShowNavigation}
        setShowMenu={setShowMenu}
        showMenu={showMenu}
      />
      <S.Children>{children}</S.Children>
      <Menu id="menu" open={showMenu}>
        <MenuItem onClick={() => dispatch(logoutAction())} Icon={S.LogoutIcon}>
          Sair
        </MenuItem>
      </Menu>
      <Navigation
        active={active}
        setShowNavigation={setShowNavigation}
        showNavigation={showNavigation}
      />
    </S.Container>
  )
}
