import { ReactNode } from 'react'
import * as S from './TableData.styles'

type TableDataProps = {
  children: ReactNode
  primary?: boolean
  align?: 'right' | 'center' | 'left'
}

export const TableData = ({ children, align = 'left' }: TableDataProps) => {
  return <S.Container align={align}>{children}</S.Container>
}
