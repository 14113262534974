import { Switch } from 'react-router-dom'
import { Banners } from '../pages/Banners/Banners'
import { Charts } from '../pages/Charts/Charts'
import { Events } from '../pages/Events/Events'
import { Creators } from '../pages/Creators/Creators'
import { Customers } from '../pages/Customers/Customers'
import { Home } from '../pages/Home/Home'
import { UsersBackoffice } from '../pages/UsersBackoffice/UsersBackoffice'
import { PrivateRoute } from './PrivateRoutes'
import { EditEvent } from '../pages/EditEvent/EditEvent'
import { ManageEvent } from '../pages/ManageEvent/ManageEvent'
import { Partner } from '../pages/Partner/Partner'
import { WithdrawalsPage } from '../pages/Withdrawals/Withdrawals'
import { CustomerInfo } from '../pages/CustomerInfo/CustomerInfo'
import { CustomerTicket } from '../pages/CustomerTicket/CustomerTicket'
import { PaymentTicket } from '../pages/PaymentTicket/PaymentTicket'
import { Coupons } from 'pages/Coupons/Coupons'

const Routes = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute path="/users" component={UsersBackoffice} />
      <PrivateRoute path="/banners" component={Banners} />
      <PrivateRoute path="/creators/:creatorId?" component={Creators} />
      <PrivateRoute path="/customers" component={Customers} />
      <PrivateRoute path="/customerInfo/:id" component={CustomerInfo} />
      <PrivateRoute path="/events" component={Events} />
      <PrivateRoute path="/charts" component={Charts} />
      <PrivateRoute path="/manageEvent/:id/:tab?" component={ManageEvent} />
      <PrivateRoute path="/editEvent/:id" component={EditEvent} />
      <PrivateRoute path="/partners" component={Partner} />
      <PrivateRoute path="/withdrawals" component={WithdrawalsPage} />
      <PrivateRoute path="/customerTicket" component={CustomerTicket} />
      <PrivateRoute path="/pucharseOrder" component={PaymentTicket} />
      <PrivateRoute path="/partners/:id" component={Partner} />
      <PrivateRoute path="/coupons" component={Coupons} />
    </Switch>
  )
}

export { Routes }
