import { Dispatch, SetStateAction } from 'react'
import * as S from './AppBar.styles'

type AppBarProps = {
  setShowNavigation: Dispatch<SetStateAction<boolean>>
  setShowMenu: Dispatch<SetStateAction<boolean>>
  showMenu: boolean
}

export const AppBar = ({
  setShowNavigation,
  setShowMenu,
  showMenu
}: AppBarProps) => {
  return (
    <S.Container>
      <S.MenuIcon onClick={() => setShowNavigation(true)} />
      <S.ProfileIcon onClick={() => setShowMenu(!showMenu)} />
    </S.Container>
  )
}
