/* eslint-disable no-unused-vars */
export enum CreatorsActions {
  GET_SEARCH_CREATOR_SUCCESS = '@creator/GET_SEARCH_CREATOR_SUCCESS',
  GET_SEARCH_CREATOR_REQUEST = '@creator/GET_SEARCH_CREATOR_REQUEST',
  GET_CREATORS_REQUEST = '@creator/GET_CREATORS_REQUEST',
  GET_CREATORS_SUCCESS = '@creator/GET_CREATORS_SUCCESS',
  SET_PAGINATION = '@creator/SET_PAGINATION',
  SET_PAGINATION_SUCCESS = '@creator/SET_PAGINATION_SUCCESS',
  CREATE_CREATOR_REQUEST = '@creator/CREATE_CREATOR_REQUEST',
  CREATE_CREATOR_SUCCESS = '@creator/CREATE_CREATOR_SUCCESS',
  EDIT_CREATOR_REQUEST = '@creator/EDIT_CREATOR_REQUEST',
  EDIT_CREATOR_SUCCESS = '@creator/EDIT_CREATOR_SUCCESS',
  DELETE_CREATOR_REQUEST = '@creator/DELETE_CREATOR_REQUEST',
  DELETE_CREATOR_SUCCESS = '@creator/DELETE_CREATOR_SUCCESS',
  EDIT_PAYMENT_SETTINGS_SUCCESS = '@creator/EDIT_PAYMENT_SETTINGS_SUCCESS',
  FIND_ONE_CREATOR_BY_ID_REQUEST = '@creator/FIND_ONE_CREATOR_BY_ID_REQUEST',
  FIND_ONE_CREATOR_BY_ID_SUCCESS = '@creator/FIND_ONE_CREATOR_BY_ID_SUCCESS'
}

export type Creator = {
  id: string
  firstName: string
  lastName: string
  corporateName: string | null
  document: string
  documentType: 'CPF' | 'CNPJ'
  email: string
  phone: string | null
  userId: string
  createdAt: string
  updatedAt: string | null
  customField: string | null
  address: {
    country: string
    state: string
    city: string
    street: string
    number: number
    zipCode: string
    complement: string | null
  } | null
  creatorPaymentSetting: {
    id: string
    subAccountId: string
    status: string
    bank: string | null
    bankAg: string | null
    bankAccountType: string | null
    bankCc: string | null
    softDescriptor: string | null
    maxInstallments: number | null
    paymentEmailNotificationReceiver: string
    creditCardFee: number
    pixFee: number
    appFee: number
    createdAt: string
    updatedAt: string | null
  }
}

export type CreatorState = {
  readonly data: {
    items: Creator[]
    total: number
  }
  currentPage: number
  skip: number
  take: number
}
