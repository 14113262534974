import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Pagination,
  TableBody,
  TableContainer,
  TableData,
  TableFooter,
  TableRow
} from '../../../components/table'
import TableHeader from '../../../components/table/TableHeader/TableHeader'
import { listEventsRequestAction } from '../../../redux/store/Events/actions'
import { Event } from '../../../redux/store/Events/types'
import { ReduxState } from '../../../redux/store/rootReducer'
import * as S from './EventsTable.styles'
import history from '../../../routes/services/history'

export type Headers = {
  name: string
  field: string
  align: 'center' | 'right' | 'left'
  sortable: boolean
}

type EventTableProps = {
  setEventEdit: (event: Event) => void
  deleteEvents: (event: string) => void
}

export const EventsTable = ({
  setEventEdit,
  deleteEvents
}: EventTableProps) => {
  const { eventData, currentPage, take } = useSelector(
    (state: ReduxState) => state.reducerEvent
  )

  const dispatch = useDispatch()

  const paginationData = useMemo(() => {
    return eventData?.items
  }, [eventData?.items, currentPage])

  const headers: Headers[] = [
    { name: 'Status', field: 'name', align: 'left', sortable: false },
    { name: 'Evento', field: 'email', align: 'left', sortable: false },
    { name: 'Ingressos', field: 'position', align: 'left', sortable: false },
    { name: 'Ações', field: 'actions', align: 'right', sortable: false }
  ]

  useEffect(() => {
    dispatch(listEventsRequestAction((currentPage - 1) * take, currentPage))
  }, [])

  const handleManageEvent = (id: string) => {
    history.push(`/manageEvent/${id}`)
  }

  const handleEditEvent = (id: string) => {
    history.push(`/editEvent/${id}`)
  }

  return (
    <>
      <S.Container>
        <TableContainer
          table={
            <>
              <TableHeader
                headers={headers}
                type="manage"
                onSorting={() => {}}
              />

              <TableBody>
                {paginationData?.map((event) => {
                  return (
                    <TableRow
                      key={event.id}
                      selected={false}
                      clickable
                      onClick={() => handleManageEvent(event.id)}>
                      <TableData>{event.status}</TableData>
                      <TableData>{event.name}</TableData>
                      <TableData>{}</TableData>
                      <TableData align="right">
                        <S.IconBox>
                          <S.EditIcon
                            onClick={(e) => {
                              e.stopPropagation()
                              handleEditEvent(event.id)
                            }}
                          />
                          <S.ExcludeIcon
                            onClick={() => deleteEvents(event.id)}
                          />
                        </S.IconBox>
                      </TableData>
                    </TableRow>
                  )
                })}
              </TableBody>
            </>
          }
          footer={
            <>
              <TableFooter>
                <p>{eventData!.total} eventos cadastrados</p>
                <Pagination
                  currentPage={currentPage}
                  itemsPerPage={take}
                  onPageChange={(page) =>
                    dispatch(listEventsRequestAction((page - 1) * take, page))
                  }
                  totalItems={eventData!.total}
                />
              </TableFooter>
            </>
          }
        />
      </S.Container>
    </>
  )
}
