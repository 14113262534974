import { useState, ChangeEvent, Dispatch, SetStateAction } from 'react'

import * as S from './CreateSession.styles'
import { Heading, Input, Paragraph, Button, AlertModal } from 'applaus-ui-kit'
import { useFormik } from 'formik'
import produce from 'immer'
import moment from 'moment'
import { useSelector } from 'react-redux'
import {
  createMappedEventSchema,
  createTabulatedEventSchema
} from './validationSchema'
import { ReduxState } from '../../../../../../redux/store/rootReducer'
import { createEventItemService } from '../../../../../../api/services/eventItem/createEventItemService'
import ReactSelect from 'react-select'

type AlertModalProps = {
  open: boolean
  message: string
  variant: 'error' | 'success'
  description: string
}

type OptionsProps = {
  label: string
  value: string
}

type infoField = {
  quantity: number
  info: {
    value: string
    required: boolean
    id?: number
    error?: boolean
  }[]
}

type Props = {
  setCreateSession: Dispatch<SetStateAction<boolean>>
}

export const CreateSession = ({ setCreateSession }: Props) => {
  const { editEvent } = useSelector(
    (state: ReduxState) => state.EditEventReducer
  )

  const [alertModal, setAlertModal] = useState<AlertModalProps>({
    open: false,
    message: '',
    variant: 'error',
    description: ''
  })

  const [dates, setDates] = useState<
    { id: number; date: string; hour: string }[]
  >([])

  const options: OptionsProps[] = [
    {
      label: 'Sim',
      value: 'sim'
    },
    {
      label: 'Não',
      value: 'nao'
    }
  ]
  const [infoField, setInfoField] = useState<infoField>({
    quantity: 0,
    info: []
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      salesFrom: '',
      finalSales: '',
      maxCustomerTickets: 0
    },
    validationSchema:
      editEvent?.type === 'MAPPED'
        ? createMappedEventSchema
        : createTabulatedEventSchema,
    onSubmit: async (values) => {
      try {
        const newInfo = infoField.info.filter((info) => info.value === '')

        if (formik.values.salesFrom === '' || formik.values.finalSales === '') {
          setAlertModal({
            description: '',
            message: 'Data de início e final de vendas são obrigatórios',
            open: true,
            variant: 'error'
          })
          return
        }
        if (newInfo.length > 0) {
          infoField.info.map((info, index) => {
            if (info.value === '') {
              return setInfoField(
                produce((draft) => {
                  draft.info[index].error = true
                })
              )
            } else
              return setInfoField(
                produce((draft) => {
                  draft.info[index].error = false
                })
              )
          })
        } else {
          if (dates.length === 0) {
            setAlertModal({
              open: true,
              message: 'Sessão deve ter pelo menos uma data',
              variant: 'error',
              description: ''
            })
          } else {
            const date = dates.map((date) =>
              moment(date.date + ' ' + date.hour).utc()
            )
            const error = await createEventItemService({
              eventId: editEvent?.id ?? '',
              title: values.name,
              description: 'descrição',
              maxCustomerTickets: values.maxCustomerTickets,
              salesFrom: moment(values.salesFrom).utc() as unknown as string,
              finalSales: moment(values.finalSales).utc() as unknown as string,
              dates: date as unknown as string[],
              ticketInfo:
                infoField.info.length > 0
                  ? infoField.info.map((info) => ({
                      isRequired: info.required,
                      value: info.value
                    }))
                  : undefined
            })

            if (!error) {
              setCreateSession(false)
            }
          }
        }
      } catch (e) {}
    }
  })

  const resetModal = () => {
    setAlertModal({
      description: '',
      variant: 'error',
      message: '',
      open: false
    })
  }

  const createDate = () => {
    setDates(
      produce((draft) => {
        const date = moment().add(1, 'hour')
        draft.push({
          id: draft.length,
          date: date.format('YYYY-MM-DD').toString(),
          hour: date.format('HH:mm').toString()
        })
      })
    )
  }

  const handleHour = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    setDates(
      produce((draft) => {
        const findDate = draft.findIndex((date) => date.id === id)
        draft[findDate].hour = e.target.value
      })
    )
  }

  const handleHDate = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    if (moment(e.target.value).isAfter()) {
      setDates(
        produce((draft) => {
          const findDate = draft.findIndex((date) => date.id === id)
          draft[findDate].date = e.target.value
        })
      )
    } else if (
      moment(moment().format('YYYY-MM-DD')).isSame(moment(e.target.value))
    ) {
      setDates(
        produce((draft) => {
          const findDate = draft.findIndex((date) => date.id === id)
          draft[findDate].date = e.target.value
          if (draft[findDate].date) {
            const date = moment().add(1, 'hour')
            draft[findDate].hour = date.format('HH:mm').toString()
          }
        })
      )
    }
  }

  const deleteDate = (id: number) => {
    setDates(
      produce((draft) => {
        const dateId = draft.findIndex((index) => index.id === id)
        if (dateId > -1) {
          draft.splice(dateId, 1)
        }
      })
    )
  }

  const showInfoField = () => {
    setInfoField(
      produce((draft) => {
        draft.quantity += 1
        draft.info.push({
          required: false,
          value: '',
          id: draft.quantity
        })
      })
    )
  }

  const changeInfoField = (info: {
    value?: string
    required?: boolean
    id?: number
    delete?: boolean
  }) => {
    const findIndex = infoField.info.findIndex((i) => i.id === info.id)

    if (info.required === false || info.required === true) {
      setInfoField(
        produce((draft) => {
          draft.info[findIndex].required = info.required!
        })
      )
    }

    if (info.delete) {
      setInfoField(
        produce((draft) => {
          if (infoField.info.length === 1) {
            draft.quantity = 0
            draft.info.splice(findIndex, 1)
          } else {
            draft.info.splice(findIndex, 1)
          }
        })
      )
    }

    if (info.value === '' || (info.value && info.value!.length < 3)) {
      setInfoField(
        produce((draft) => {
          draft.info[findIndex].value = info.value!
          draft.info[findIndex].error = true
        })
      )
    }

    if (findIndex > -1 && info.value && info.value!.length >= 3) {
      setInfoField(
        produce((draft) => {
          draft.info[findIndex].value = info.value!
          draft.info[findIndex].error = false
        })
      )
    }
  }

  return (
    <S.Wrapper>
      <S.TitleBox>
        <S.BackIcon onClick={() => setCreateSession(false)} />
        <Heading variant="h3">Nova sessão</Heading>
      </S.TitleBox>
      <S.EventInfoForm onSubmit={formik.handleSubmit}>
        <Input
          className="input"
          name="name"
          label="*Nome"
          placeholder="Nome da sessão"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          texterror={formik.errors.name}
          fullWidth
        />

        <S.DateSection>
          <Input
            className="maxTicketsInput"
            name="maxCustomerTickets"
            label="*Quantidade máxima de tickets por usuário"
            placeholder="Número de tickets"
            type="number"
            min="1"
            value={formik.values.maxCustomerTickets}
            onChange={formik.handleChange}
            error={
              formik.touched.maxCustomerTickets &&
              Boolean(formik.errors.maxCustomerTickets)
            }
            texterror={formik.errors.maxCustomerTickets}
            fullWidth={false}
          />
          <S.SalesPeriod>
            <Input
              name="salesFrom"
              label="Data início das vendas"
              type="datetime-local"
              value={formik.values.salesFrom}
              onChange={(e) => {
                if (moment(e.target.value).isAfter()) {
                  formik.setFieldValue('salesFrom', e.target.value)
                }
              }}
              error={
                formik.touched.salesFrom && Boolean(formik.errors.salesFrom)
              }
              texterror={formik.errors.salesFrom}
              fullWidth={false}
            />

            <Input
              className="inputHeight"
              name="finalSales"
              label="Data final das vendas"
              type="datetime-local"
              value={formik.values.finalSales}
              onChange={(e) => {
                if (
                  moment(e.target.value).isAfter(
                    moment(formik.values.salesFrom)
                  )
                ) {
                  formik.setFieldValue('finalSales', e.target.value)
                }
              }}
              error={
                formik.touched.finalSales && Boolean(formik.errors.finalSales)
              }
              texterror={formik.errors.finalSales}
              fullWidth={false}
            />
          </S.SalesPeriod>
          <Heading variant="h3">*DATAS E HORÁRIOS</Heading>
          {dates.map((date, index) => {
            return (
              <S.DateContent key={date.date + index}>
                <Paragraph variant="large" type="bold">
                  Dia {index + 1}
                </Paragraph>
                <S.InputBox>
                  <S.Input>
                    <label htmlFor={`date${index}`}>
                      <Paragraph variant="large" type="bold">
                        Data
                      </Paragraph>
                    </label>

                    <input
                      className="inputHeight"
                      id={`date${index}`}
                      type="date"
                      value={date.date}
                      onChange={(e) => handleHDate(e, date.id)}
                    />
                  </S.Input>
                  <S.Input>
                    <label htmlFor={`hour${index}`}>
                      <Paragraph variant="large" type="bold">
                        Hora
                      </Paragraph>
                    </label>
                    <div>
                      <input
                        className="inputHeight"
                        id={`hour${index}`}
                        type="time"
                        value={date.hour}
                        onChange={(e) => handleHour(e, date.id)}
                      />
                      <S.TrashIcon onClick={() => deleteDate(date.id)} />
                    </div>
                  </S.Input>
                </S.InputBox>
              </S.DateContent>
            )
          })}
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            type="button"
            fullWidth={false}
            onClick={() => createDate()}>
            + Adicionar data
          </Button>
        </S.DateSection>

        <S.RequiredUserInformationBox>
          <Heading variant="h3">FORMULÁRIO DE PEDIDO</Heading>
          <Paragraph variant="regular">
            O que você precisa saber sobre os participantes do evento
          </Paragraph>
          {infoField.info.length > 0 ? (
            <>
              {infoField.info.length > 0
                ? infoField.info?.map((info, index) => (
                    <S.RequiredInfoData key={index}>
                      <div>
                        <Paragraph variant="small" type="semiBold">
                          Pergunta
                        </Paragraph>
                        <Input
                          className="questionBox"
                          onChange={(e) =>
                            changeInfoField({
                              value: e.target.value,
                              id: info.id
                            })
                          }
                          value={infoField.info[index].value ?? ''}
                          error={infoField.info[index].error}
                          texterror={
                            infoField.info[index].error
                              ? 'Campo obrigatório.'
                              : ''
                          }
                          fullWidth
                        />
                      </div>

                      <div className="obrigatoryBox">
                        <div>
                          <Paragraph variant="small" type="semiBold">
                            Obrigatório?
                          </Paragraph>
                          <ReactSelect
                            placeholder="Selecione uma opção"
                            options={options}
                            defaultValue={
                              infoField.info[index].required === true
                                ? options[0]
                                : options[1]
                            }
                            onChange={(e) => {
                              changeInfoField({
                                required: e!.value === 'sim',
                                id: info.id
                              })
                            }}
                            styles={S.customStyles}
                          />
                        </div>
                        <div className="trashOption">
                          <S.TrashIconData
                            onClick={() =>
                              changeInfoField({ id: info.id, delete: true })
                            }
                          />
                        </div>
                      </div>

                      <div className="trash">
                        <S.TrashIconData
                          onClick={() =>
                            changeInfoField({ id: index + 1, delete: true })
                          }
                        />
                      </div>
                    </S.RequiredInfoData>
                  ))
                : null}
            </>
          ) : null}
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            type="button"
            fullWidth={false}
            onClick={() => showInfoField()}>
            + Adicionar campo
          </Button>
        </S.RequiredUserInformationBox>

        <Button
          variant="contained"
          size="medium"
          color="primary"
          type="submit"
          fullWidth={false}>
          Adicionar Seção
        </Button>
      </S.EventInfoForm>

      <AlertModal
        open={alertModal.open}
        message={alertModal.message}
        variant={alertModal.variant}
        description={alertModal.description}
        buttonText="Fechar"
        click={() => resetModal()}
      />
    </S.Wrapper>
  )
}
