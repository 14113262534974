import { all, delay } from 'redux-saga/effects'
import { call, put, takeLatest, select } from '@redux-saga/core/effects'
import { Address, Coupon, EventActions, EventState, Pixels } from './types'
import { PayloadAction } from 'typesafe-actions'
import {
  createCouponSuccessAction,
  editCouponSuccessAction,
  editPixelsSuccessAction,
  Links,
  removeCouponSuccessAction,
  removeEventItemSuccess,
  updateSocialLinksSuccessAction
} from './actions'
import { ReduxState } from '../rootReducer'
import { clearLoading, setLoading } from '../Loading/actions'
import { uploadImagesService } from '../../../api/services/files/uploadImageService'
import { setModalState } from '../Modal/Actions/actionsModal'
import { updateEventAddress } from '../../../api/services/events/updateEventAddress'
import { removeEventIemService } from '../../../api/services/eventItem/removeEventIemService'
import { deleteTicketService } from '../../../api/services/tickets/deleteTicketService'
import {
  EditBasicInformationRequest,
  editBasicInformationSuccessAction,
  editDescriptionSuccessAction,
  editNextStep
} from './editActions'

import { updateEventService } from '../../../api/services/events/updateEventService'

import * as _ from 'lodash'
import { removeCouponService } from 'api/services/events/removeCouponService'
import { editCouponService } from 'api/services/events/editCouponService'
import { createCouponService } from 'api/services/events/createCouponService'
import { updateSocialLinks } from 'api/services/events/updateSocialLinks'
import { updateEventPixelsService } from 'api/services/events/updateEventPixelsService'

export function* removeEventItem(
  action: PayloadAction<EventActions.removeEventItemRequest, string>
) {
  try {
    yield call(removeEventIemService, action.payload)
    yield put(removeEventItemSuccess(action.payload))
    yield put(
      setModalState({
        open: true,
        message: 'Sessão removida com sucesso',
        variant: 'success',
        buttonText: 'Ok'
      })
    )
  } catch (e) {}
}

function* deleteTicket(
  action: PayloadAction<EventActions.deleteTicketRequest, string>
) {
  yield call(deleteTicketService, action.payload)
}

function* editBasicInformation(
  action: PayloadAction<
    EventActions.editBasicInformationRequest,
    EditBasicInformationRequest
  >
) {
  try {
    const { name, seatMapId, banners, category, cashBackPercent, ageGroup } =
      action.payload
    const getState = (state: ReduxState) => state.EditEventReducer
    const { editEvent }: EventState = yield select(getState)

    if (editEvent) {
      yield put(setLoading())
      let cardUrl =
        banners && banners[0].cardUrl === ''
          ? editEvent?.banners && editEvent?.banners[0].cardUrl
            ? editEvent?.banners[0].cardUrl
            : banners[0].cardUrl
          : banners && banners[0].cardUrl
          ? banners[0].cardUrl
          : undefined
      let mobileUrl =
        banners && banners[0].mobileUrl === ''
          ? editEvent?.banners && editEvent?.banners[0].mobileUrl
            ? editEvent?.banners[0].mobileUrl
            : banners[0].mobileUrl
          : banners && banners[0].mobileUrl
          ? banners[0].mobileUrl
          : undefined
      let tabletUrl =
        banners && banners[0].tabletUrl === ''
          ? editEvent?.banners && editEvent?.banners[0].tabletUrl
            ? editEvent?.banners[0].tabletUrl
            : banners[0].tabletUrl
          : banners && banners[0].tabletUrl
          ? banners[0].tabletUrl
          : undefined
      let desktopUrl =
        banners && banners[0].desktopUrl === ''
          ? editEvent?.banners && editEvent?.banners[0].desktopUrl
            ? editEvent?.banners[0].desktopUrl
            : banners[0].desktopUrl
          : banners && banners[0].desktopUrl
          ? banners[0].desktopUrl
          : undefined

      if (typeof cardUrl !== 'string' && cardUrl !== undefined) {
        cardUrl = yield call(uploadImagesService, cardUrl as File)
      }
      if (typeof mobileUrl !== 'string' && mobileUrl !== undefined) {
        mobileUrl = yield call(uploadImagesService, mobileUrl as File)
      }
      if (typeof tabletUrl !== 'string' && tabletUrl !== undefined) {
        tabletUrl = yield call(uploadImagesService, tabletUrl as File)
      }
      if (typeof desktopUrl !== 'string' && desktopUrl !== undefined) {
        desktopUrl = yield call(uploadImagesService, desktopUrl as File)
      }

      const editBanners = [
        {
          desktopUrl: desktopUrl as string,
          tabletUrl: tabletUrl as string,
          mobileUrl: mobileUrl as string,
          cardUrl: cardUrl as string
        }
      ]

      const emptyBanners = Object.entries(editBanners[0]).filter(
        (value) => value[1] !== undefined
      )

      if (
        name !== editEvent?.name ||
        seatMapId !== editEvent?.seatMapId ||
        cashBackPercent !== editEvent?.cashBackPercent ||
        !_.isEmpty(emptyBanners)
      ) {
        const body = {
          name: name !== editEvent.name ? name : undefined,
          ageGroup: ageGroup !== editEvent.ageGroup ? ageGroup : undefined,
          category: category !== editEvent.category ? category : undefined,
          cashBackPercent:
            cashBackPercent !== editEvent.cashBackPercent
              ? cashBackPercent
              : undefined,
          seatMapId: seatMapId !== editEvent.seatMapId ? seatMapId : undefined,
          banners:
            !_.isEmpty(emptyBanners) &&
            emptyBanners.length === 4 &&
            !_.isEqual(editBanners[0], editEvent?.banners![0])
              ? editBanners
              : undefined
        }

        const request = _.omitBy(body, _.isUndefined)

        if (!_.isEmpty(request)) {
          const data: {
            name: string
            category: string
            ageGroup: string
            cashBackPercent: number
            seatMapId: string
            banners: {
              id: string
              desktopUrl: string
              tabletUrl: string
              mobileUrl: string
              cardUrl: string
            }[]
          } = yield call(updateEventService, { body, eventId: editEvent.id })
          yield put(
            editBasicInformationSuccessAction({
              name: data.name,
              category: data.category,
              cashBackPercent: data.cashBackPercent,
              seatMapId: data.seatMapId,
              banners: data.banners,
              ageGroup: data.ageGroup
            })
          )
        }
      }
      yield put(clearLoading())
      yield put(editNextStep())
    }
  } catch (error: any) {
    yield put(clearLoading())
  }
}

function* editDescription(
  action: PayloadAction<EventActions.editDescriptionRequest, string>
) {
  try {
    const descriptionPayload = action.payload
    const getState = (state: ReduxState) => state.EditEventReducer
    const { editEvent }: EventState = yield select(getState)
    if (descriptionPayload !== editEvent?.description) {
      yield put(setLoading())
      yield call(updateEventService, {
        body: {
          description: descriptionPayload
        },
        eventId: editEvent!.id
      })
      yield put(editDescriptionSuccessAction(descriptionPayload))
    }
    yield put(clearLoading())
    yield put(editNextStep())
  } catch (error) {
    yield put(clearLoading())
  }
}

function* editAddress(
  action: PayloadAction<EventActions.editAddressRequest, Address>
) {
  try {
    const addressPayload = action.payload
    const getState = (state: ReduxState) => state.EditEventReducer
    const { editEvent }: EventState = yield select(getState)

    if (editEvent) {
      const {
        city,
        complement,
        country,
        number,
        state,
        street,
        zipCode,
        name
      } = editEvent.address

      if (
        !_.isEqual(
          _.omitBy(addressPayload, _.isNil),
          _.omitBy(
            { city, complement, country, number, state, street, zipCode, name },
            _.isNil || _.isUndefined
          )
        )
      ) {
        yield put(setLoading())
        yield call(updateEventAddress, addressPayload, editEvent.id)
      }
      yield put(clearLoading())
      yield put(editNextStep())
    }
  } catch (error) {
    yield put(clearLoading())
  }
}
function* editPixels(
  action: PayloadAction<EventActions.editPixelsRequest, Links>
) {
  const getState = (state: ReduxState) => state.EditEventReducer
  const { editEvent }: EventState = yield select(getState)
  const eventId = editEvent?.id ?? ''
  const pixels = editEvent?.pixels ?? null
  const existingSocialLinks = editEvent?.socialLinks ?? null

  const socialLink = {
    facebookLink: action.payload.facebookLink,
    youtubeLink: action.payload.youtubeLink,
    instagramLink: action.payload.instagramLink,
    tiktokLink: action.payload.tiktokLink
  }

  const data: Pixels | null = yield call(
    updateEventPixelsService,
    eventId,
    pixels,
    {
      facebookpixel: action.payload.facebookpixel,
      googleanalyticspixel: action.payload.googleanalyticspixel,
      googletagmanagerpixel: action.payload.googletagmanagerpixel
    }
  )

  const links: {
    facebookLink: string
    youtubeLink: string
    instagramLink: string
    tiktokLink: string
  } | null = yield call(
    updateSocialLinks,
    eventId,
    existingSocialLinks,
    socialLink
  )
  yield put(updateSocialLinksSuccessAction(links!))
  yield put(editPixelsSuccessAction(data!))
  yield put(editNextStep())
}

function* createCoupon(
  action: PayloadAction<
    EventActions.createCouponRequest,
    {
      name: string
      description: string
      amountOfUse: number
      value: string
      valueType: 'PERCENT' | 'CENTS'
      quantity?: number
      startValidateDate?: string
      finalValidateDate?: string
      type: 'limited' | 'unlimited'
    }
  >
) {
  try {
    const getState = (state: ReduxState) => state.EditEventReducer
    const { editEvent }: EventState = yield select(getState)
    const eventId = editEvent?.id ?? ''
    yield put(setLoading())
    const data: Coupon = yield call(
      createCouponService,
      eventId,
      action.payload
    )
    yield put(createCouponSuccessAction(data))
    yield put(clearLoading())
    yield put(
      setModalState({
        open: true,
        message: 'Cupom criado com sucesso',
        buttonText: 'Fechar',
        variant: 'success'
      })
    )
  } catch (error) {
    yield put(clearLoading())
    console.error(error)
  }
}

function* editCoupon(
  action: PayloadAction<
    EventActions.editCouponRequest,
    {
      id: string
      name: string
      description: string
      amountOfUse: number
      value: string | number
      valueType: 'PERCENT' | 'CENTS'
      quantity?: number
      startValidateDate?: string
      finalValidateDate?: string
    }
  >
) {
  try {
    yield put(setLoading())
    const getState = (state: ReduxState) => state.EditEventReducer
    const { editEvent }: EventState = yield select(getState)
    if (editEvent) {
      const couponIndex = editEvent.coupons?.findIndex(
        ({ id }) => id === action.payload.id
      )
      const data: Coupon = yield call(
        editCouponService,
        action.payload.id,
        editEvent.coupons![couponIndex!],
        {
          ...action.payload,
          value:
            typeof action.payload.value === 'number'
              ? action.payload.value
              : Number(action.payload.value.replace(/[^\d]/g, ''))
        }
      )
      yield put(editCouponSuccessAction(data))
      yield delay(1000)
      yield put(clearLoading())
      yield put(
        setModalState({
          open: true,
          message: 'Cupom editado com sucesso',
          buttonText: 'Fechar',
          variant: 'success'
        })
      )
    }
  } catch (e) {
    yield put(clearLoading())
    yield put(
      setModalState({
        open: true,
        message: 'Não foi possivel criar o cupom',
        buttonText: 'Fechar',
        variant: 'error'
      })
    )
  }
}

function* removeCoupon(
  action: PayloadAction<EventActions.removeCouponRequest, string>
) {
  try {
    yield put(setLoading())
    yield call(removeCouponService, action.payload)
    yield put(removeCouponSuccessAction(action.payload))
    yield delay(1000)
    yield put(clearLoading())
    yield put(
      setModalState({
        open: true,
        message: 'Cupom deletado com sucesso',
        buttonText: 'Fechar',
        variant: 'success'
      })
    )
    yield put(clearLoading())
  } catch (e) {
    yield put(clearLoading())
    yield put(
      setModalState({
        open: true,
        message: 'Não foi possivel deletar o cupom',
        buttonText: 'Fechar',
        variant: 'error'
      })
    )
    console.error(e)
  }
}

export const EventSaga = all([
  takeLatest(EventActions.removeEventItemRequest, removeEventItem),
  takeLatest(EventActions.deleteTicketRequest, deleteTicket),
  takeLatest(EventActions.editBasicInformationRequest, editBasicInformation),
  takeLatest(EventActions.editDescriptionRequest, editDescription),
  takeLatest(EventActions.editAddressRequest, editAddress),
  takeLatest(EventActions.editPixelsRequest, editPixels),
  takeLatest(EventActions.createCouponRequest, createCoupon),
  takeLatest(EventActions.editCouponRequest, editCoupon),
  takeLatest(EventActions.removeCouponRequest, removeCoupon)
])
