import produce from 'immer'
import { Reducer } from 'redux'
import history from '../../../routes/services/history'
import { EditBasicInformationSuccess } from './editActions'
import {
  Address,
  Coupon,
  EventActions,
  EventItems,
  EventState,
  Tickets
} from './types'

const INITIAL_STATE: EventState = {
  editSteps: [
    {
      name: 'Informações básicas',
      position: 1,
      completed: false,
      isActive: true
    },
    {
      name: 'Descrição do Evento',
      position: 2,
      completed: false,
      isActive: false
    },
    {
      name: 'Localização',
      position: 3,
      completed: false,
      isActive: false
    },
    {
      name: 'Sessões',
      position: 4,
      completed: false,
      isActive: false
    },
    {
      name: 'Ingressos',
      position: 5,
      completed: false,
      isActive: false
    },
    {
      name: 'Cupons',
      position: 6,
      completed: false,
      isActive: false
    },
    {
      name: 'Marketing',
      position: 7,
      completed: false,
      isActive: false
    },
    {
      name: 'Publicar',
      position: 8,
      completed: false,
      isActive: false
    }
  ]
}

export const EditEventReducer: Reducer<EventState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case EventActions.clearState:
      return produce(state, (draft) => {
        draft.editSteps = INITIAL_STATE.editSteps
        draft.editEvent = undefined
      })
    case EventActions.listEventsSuccess:
      return produce(state, (draft) => {
        draft.editEvent = undefined
        draft.editSteps = INITIAL_STATE.editSteps
      })
    case EventActions.createEventItemSuccess:
      return produce(state, (draft) => {
        if (draft.editEvent!.eventItems) {
          draft.editEvent!.eventItems!.push(action.payload)
        } else {
          draft.editEvent!.eventItems = [action.payload]
        }
      })
    case EventActions.removeEventItemSuccess:
      return produce(state, (draft) => {
        const eventItemId: string = action.payload
        if (history.location.pathname.includes('editEvent')) {
          const evenItemIndex = draft.editEvent!.eventItems?.findIndex(
            (eventItem) => eventItem.id === eventItemId
          )
          if (evenItemIndex !== -1) {
            draft.editEvent!.eventItems = draft.editEvent!.eventItems?.filter(
              (eventItem) => eventItem.id !== eventItemId
            )
          }
        }
      })
    case EventActions.addLocationSuccess:
      return produce(state, (draft) => {
        if (draft.editEvent) {
          draft.editEvent.address = action.payload
        }
      })
    case EventActions.addTicketSuccess:
      return produce(state, (draft) => {
        const ticket: Tickets = action.payload
        if (history.location.pathname.includes('editEvent')) {
          if (!draft.editEvent!.tickets) {
            draft.editEvent!.tickets = [ticket]
          } else {
            draft.editEvent!.tickets.push(ticket)
          }
        }
      })
    case EventActions.addTicket:
      return produce(state, (draft) => {
        if (action.payload.actionTicket === 'create') {
          const indexItem = draft.editEvent?.eventItems?.findIndex(
            (eventItem) =>
              eventItem.id === action.payload.ticket.eventItems[0].eventItem.id
          )

          if (indexItem! > -1 && draft.editEvent) {
            draft.editEvent?.eventItems![indexItem!].tickets.push({
              ...action.payload.ticket,
              eventItems: [{ ...action.payload.ticket.eventItems[0].eventItem }]
            })
          }
        } else if (action.payload.actionTicket === 'edit') {
          const indexItemUpdate = draft.editEvent?.eventItems?.findIndex(
            (eventItem) => eventItem.id === action.payload.eventItemId
          )

          if (indexItemUpdate! > -1 && draft.editEvent) {
            const indexTicket = draft.editEvent?.eventItems![
              indexItemUpdate!
            ].tickets.findIndex(
              (ticket) => ticket.id === action.payload.ticket.id
            )

            if (indexTicket && indexTicket > -1) {
              draft.editEvent!.eventItems![indexItemUpdate!].tickets[
                indexTicket
              ] = {
                ...action.payload.ticket,
                eventItems: [
                  ...draft.editEvent!.eventItems![indexItemUpdate!].tickets[
                    indexTicket
                  ].eventItems
                ]
              }
            }
          }
        }
      })
    case EventActions.deleteTicket:
      return produce(state, (draft) => {
        const indexItem = draft.editEvent?.eventItems?.findIndex(
          (eventItem) => eventItem.id === action.payload.eventItems[0].id
        )

        if (indexItem! > -1 && draft.editEvent) {
          const indexTicket = draft.editEvent?.eventItems![
            indexItem!
          ].tickets.findIndex((ticket) => ticket.id === action.payload.id)

          if (indexTicket! > -1) {
            draft.editEvent!.eventItems![indexItem!].tickets.splice(
              indexTicket!,
              1
            )
          }
        }
      })
    case EventActions.deleteTicketSuccess:
      return produce(state, (draft) => {
        if (draft.editEvent) {
          const ticketId: string = action.payload
          draft.editEvent.tickets = draft.editEvent.tickets?.filter(
            (ticket) => ticket.id !== ticketId
          )
        }
      })
    case EventActions.publishEvent:
      return produce(state, (draft) => {
        draft.editSteps = INITIAL_STATE.editSteps
        draft.editEvent = undefined
        history.push('/events')
      })
    case EventActions.editStepBack:
      return produce(state, (draft) => {
        const actualStepIndex = draft.editSteps.findIndex(
          (step) => step.isActive === true
        )
        if (actualStepIndex !== -1) {
          draft.editSteps[actualStepIndex].isActive = false
          draft.editSteps[actualStepIndex - 1].isActive = true
        }
      })
    case EventActions.editNextStep:
      return produce(state, (draft) => {
        const actualStepIndex = draft.editSteps.findIndex(
          (step) => step.isActive === true
        )
        if (
          actualStepIndex !== -1 &&
          actualStepIndex <= draft.editSteps.length - 1
        ) {
          draft.editSteps[actualStepIndex].isActive = false
          draft.editSteps[actualStepIndex + 1].isActive = true
        }
      })
    case EventActions.editBasicInformationSuccess:
      return produce(state, (draft) => {
        const {
          name,
          banners,
          seatMapId,
          category,
          cashBackPercent,
          ageGroup
        }: EditBasicInformationSuccess = action.payload
        if (draft.editEvent) {
          if (name) {
            draft.editEvent.name = name
          }
          if (ageGroup) {
            draft.editEvent.ageGroup = ageGroup
          }
          if (name) {
            draft.editEvent.name = name
          }
          if (banners) {
            draft.editEvent.banners = banners
          }
          if (category) {
            draft.editEvent.category = category
          }
          if (seatMapId) {
            draft.editEvent.seatMapId = seatMapId
          }
          if (cashBackPercent) {
            draft.editEvent.cashBackPercent = cashBackPercent
          }
        }
      })
    case EventActions.editDescriptionSuccess:
      return produce(state, (draft) => {
        const description: string = action.payload
        if (draft.editEvent) {
          draft.editEvent.description = description
        }
      })
    case EventActions.editAddressSuccess:
      return produce(state, (draft) => {
        const address: Address = action.payload
        if (draft.editEvent) {
          draft.editEvent.address = address
        }
      })
    case EventActions.editEventItemSuccess:
      return produce(state, (draft) => {
        const eventItemPayload: EventItems = action.payload
        if (history.location.pathname.includes('editEvent')) {
          const eventItemIndex = draft.editEvent!.eventItems!.findIndex(
            (eventItem) => eventItem.id === eventItemPayload.id!
          )
          if (eventItemIndex !== -1) {
            draft.editEvent!.eventItems![eventItemIndex] = {
              ...eventItemPayload,
              tickets: draft.editEvent!.eventItems![eventItemIndex].tickets
            }
          }
        }
      })
    case EventActions.editTicketSuccess:
      return produce(state, (draft) => {
        const ticketPayload: Tickets = action.payload

        if (history.location.pathname.includes('editEvent' || 'createEvent')) {
          const ticketIndex = draft.editEvent!.tickets.findIndex(
            (ticket) => ticket.id === ticketPayload.id
          )
          if (ticketIndex !== -1) {
            draft.editEvent!.tickets[ticketIndex] = ticketPayload
          }
        }
      })
    case EventActions.loadEventSuccess:
      return produce(state, (draft) => {
        draft.editEvent = action.payload
        draft.editSteps = INITIAL_STATE.editSteps
      })
    case EventActions.goToStep:
      return produce(state, (draft) => {
        const position: number = action.payload
        const stepIndex = draft.editSteps.findIndex(
          (step) => step.position === position
        )
        draft.editSteps.forEach((step) => (step.isActive = false))
        draft.editSteps[stepIndex].isActive = true
      })
    case EventActions.createCouponSuccess:
      return produce(state, (draft) => {
        const coupon = action.payload as Coupon
        if (draft.editEvent) draft.editEvent.coupons.unshift(coupon)
      })
    case EventActions.editCouponSuccess:
      return produce(state, (draft) => {
        const coupon = action.payload as Coupon
        if (draft.editEvent)
          draft.editEvent.coupons[
            draft.editEvent.coupons.findIndex(({ id }) => id === coupon.id)
          ] = coupon
      })
    case EventActions.removeCouponSuccess:
      return produce(state, (draft) => {
        const couponId = action.payload as string
        if (draft.editEvent) {
          draft.editEvent.coupons = draft.editEvent.coupons.filter(
            ({ id }) => id !== couponId
          )
        }
      })
    case EventActions.editSocialLinksSuccess:
      return produce(state, (draft) => {
        if (draft.editEvent) {
          draft.editEvent.socialLinks = action.payload
        }
      })
    default:
      return state
  }
}
