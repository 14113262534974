import styled, { css } from 'styled-components'

import { ReactComponent as BackFile } from '../../../../assets/icons/backIcon.svg'

export const BackIcon = styled(BackFile)`
  cursor: pointer;
  transition: 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
`

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxs};
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    > div:first-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: ${theme.spacing.stack.xxs};
      > p {
        font-size: ${theme.font.size.sm};
      }
    }
    .backbutton {
      margin: 0 ${theme.spacing.stack.small} 0 0;
    }
  `}
`
export const EventItems = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    > p {
      text-align: center;
      background: #f4f4f4;
      padding: ${theme.spacing.stack.small} ${theme.spacing.inline.xxs};
      border-radius: ${theme.border.radius.medium};
    }
  `}
`

export const Dates = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    justify-content: space-between;
  `}
`

export const Paragraph = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    line-height: ${theme.font.size.xl};
    font-weight: ${theme.font.weight.semiBold};
    padding: ${theme.spacing.inline.nano} ${theme.spacing.inline.xxs};
    border: 2px solid #8601c9;
    border-radius: ${theme.border.radius.medium};
    margin: ${theme.spacing.stack.xxs} 0;
    cursor: pointer;
    transition: background ease-in-out 0.5s;
    :hover {
      background: #8601c9;
      color: white;
    }
  `}
`
