import styled, { css } from 'styled-components'

import { ReactComponent as User } from '../../assets/icons/user-circle.svg'
import { Input } from 'applaus-ui-kit'

export const Container = styled.div`
  ${({ theme }) => css`
    @media (min-width: 1171px) {
      padding: ${theme.spacing.stack.xs};
    }
  `}
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  > div:first-child {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > div:first-child {
      margin-bottom: 2rem;
    }
  }
`

export const Searchnput = styled(Input)`
  width: 100%;
  > input {
    width: 100%;
  }
`

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    font-weight: ${theme.font.weight.bold};
  `}
`

export const UserIcon = styled(User)`
  fill: #061728;
  width: 48px;
  height: 48px;
`
