import produce from 'immer'
import { Reducer } from 'redux'
import { CreatorsActions, CreatorState } from './types'

const initialState: CreatorState = {
  data: {
    items: [],
    total: 0
  },
  currentPage: 1,
  skip: 0,
  take: 5
}

export const Creator: Reducer<CreatorState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CreatorsActions.GET_CREATORS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.data = action.payload.data
        draftState.skip = action.payload.skip
        draftState.currentPage = action.payload.currentPage
      })
    case CreatorsActions.GET_SEARCH_CREATOR_SUCCESS:
      return produce(state, (draftState) => {
        draftState.data.items = action.payload
        draftState.data.total = action.payload.length
      })
    case CreatorsActions.CREATE_CREATOR_SUCCESS:
      return produce(state, (draftState) => {
        draftState.data.items.unshift(action.payload)
      })
    case CreatorsActions.EDIT_CREATOR_SUCCESS:
      return produce(state, (draftState) => {
        const index = draftState.data.items.findIndex(
          (creator) => creator.id === action.payload.id
        )

        if (index > -1) {
          draftState.data.items[index] = {
            ...state.data.items[index],
            ...action.payload
          }
        }
      })
    case CreatorsActions.EDIT_PAYMENT_SETTINGS_SUCCESS:
      return produce(state, (draftState) => {
        const index = draftState.data.items.findIndex(
          (creator) => creator.id === action.payload.creatorId
        )

        if (index > -1) {
          draftState.data.items[index] = {
            ...state.data.items[index],
            ...action.payload.data
          }
        }
      })
    case CreatorsActions.DELETE_CREATOR_SUCCESS:
      return produce(state, (draftState) => {
        action.payload.forEach((creatorId: string) => {
          const index = draftState.data.items.findIndex(
            (creator) => creator.id === creatorId
          )

          if (index > -1) {
            draftState.data.items.splice(index, 1)
          }
        })
      })
    default:
      return state
  }
}
