/* eslint-disable no-unused-vars */
export enum LoadingActions {
  SET_LOADING = '@loading/ SET_LOADING',
  CLEAR_LOADING = '@loading/CLEAR_LOADING'
}

export type LoadingState = {
  isLoading: boolean
  blur?: boolean
}
