import { all, call, delay, put, takeLatest } from '@redux-saga/core/effects'
import _ from 'lodash'
import { PayloadAction } from 'typesafe-actions'
import {
  createCreatorService,
  CreateCreatorRequest,
  CreateCreatorResponse
} from '../../../api/services/creators/createCreatorService'
import { deleteCreatorService } from '../../../api/services/creators/deleteCreatorService'
import {
  EditCreatorRequest,
  EditCreatorAndPaymentSettingsResponse,
  editCreatorService
} from '../../../api/services/creators/editCreatorService'
import {
  CreatorResponse,
  getCreatorsService
} from '../../../api/services/creators/getCreatorsService'
import { messageHelper } from '../../../utils/messageHelper'
import { clearLoading, setLoading } from '../Loading/actions'
import { setModalState } from '../Modal/Actions/actionsModal'
import { store } from '../store'
import {
  createCreatorSuccess,
  deleteCreatorSuccess,
  editCreatorSuccess,
  getCreatorsSuccess,
  editPaymentSettingsSuccess,
  getSearchCreatorSuccess
} from './Actions/creatorActions'
import { CreatorsActions } from './types'
import {
  CreatorRequest,
  findOneCreatorByIdService
} from '../../../api/services/creators/findOneCreatorByIdService'
import {
  listCreatorFilter,
  ListCreatorResponse
} from '../../../api/services/filter/listCreatorFilter'

function* getCreators(
  action: PayloadAction<
    CreatorsActions.GET_CREATORS_REQUEST,
    { skip: number; currentPage: number; creatorId?: string }
  >
) {
  yield put(setLoading(true))
  try {
    const { getState } = store
    const { take } = getState().Creator

    const data: CreatorResponse = yield call(
      getCreatorsService,
      action.payload.skip,
      take
    )

    if (action.payload.creatorId) {
      const findCreatorIndex = data.items.findIndex(
        (creator) => creator.id === action.payload.creatorId
      )
      if (findCreatorIndex === -1) {
        const creator: CreatorRequest = yield call(
          findOneCreatorByIdService,
          action.payload.creatorId
        )
        data.items.push(creator)
      }
    }

    yield put(
      getCreatorsSuccess(data, action.payload.skip, action.payload.currentPage)
    )
    yield delay(Number(process.env.REACT_APP_DELAY_REQUEST))
    yield put(clearLoading())
  } catch (err) {
    yield put(clearLoading())
    yield put(
      setModalState({
        open: true,
        variant: 'error',
        message: messageHelper.modal.creator.error.loadCreator
      })
    )
  }
}

function* createCreator(
  action: PayloadAction<
    CreatorsActions.CREATE_CREATOR_REQUEST,
    CreateCreatorRequest
  >
) {
  yield put(setLoading(true))
  try {
    const data: CreateCreatorResponse = yield call(
      createCreatorService,
      action.payload
    )

    yield put(createCreatorSuccess(data))

    yield delay(Number(process.env.REACT_APP_DELAY_REQUEST))
    yield put(clearLoading())

    yield put(
      setModalState({
        open: true,
        message: messageHelper.modal.creator.success.createCreator,
        variant: 'success'
      })
    )
  } catch (err) {
    yield put(clearLoading())
    yield put(
      setModalState({
        open: true,
        message: messageHelper.modal.creator.error.createCreator,
        variant: 'error'
      })
    )
  }
}

function* editCreator(
  action: PayloadAction<
    CreatorsActions.EDIT_CREATOR_REQUEST,
    {
      data: EditCreatorRequest
      creatorId: string
      paymentSettingsId: string
      paymentSettings?: {
        appFee?: number
        pixFee?: number
        creditCardFee?: number
      }
    }
  >
) {
  try {
    if (
      !_.isEmpty(action.payload.data) ||
      !_.isEmpty(action.payload.paymentSettings)
    ) {
      const data: EditCreatorAndPaymentSettingsResponse = yield call(
        editCreatorService,
        action.payload.data,
        action.payload.creatorId,
        action.payload.paymentSettingsId,
        action.payload.paymentSettings
      )

      if (_.isEmpty(data.creatorPaymentSetting)) {
        yield put(editCreatorSuccess(data))
      } else {
        yield put(editPaymentSettingsSuccess(data, action.payload.creatorId))
      }

      yield put(
        setModalState({
          open: true,
          message: messageHelper.modal.creator.success.editedCreator,
          variant: 'success'
        })
      )
    }
  } catch (err) {
    yield put(
      setModalState({
        open: true,
        message: messageHelper.modal.creator.error.editedCreator,
        variant: 'error'
      })
    )
  }
}

function* deleteCreator(
  action: PayloadAction<CreatorsActions.DELETE_CREATOR_REQUEST, string[]>
) {
  yield put(setLoading(true))
  try {
    yield all(
      action.payload.map((creatorId) => call(deleteCreatorService, creatorId))
    )

    yield put(deleteCreatorSuccess(action.payload))

    yield delay(Number(process.env.REACT_APP_DELAY_REQUEST))
    yield put(clearLoading())

    yield put(
      setModalState({
        open: true,
        message: `${
          action.payload.length > 1
            ? 'Produtores deletados'
            : 'Produtor deletado'
        } com sucesso.`,
        variant: 'success'
      })
    )
  } catch (err) {
    yield put(clearLoading())

    yield put(
      setModalState({
        open: true,
        message: `Não foi possível deletar ${
          action.payload.length > 1 ? 'os produtores' : 'o produtor'
        }.`,
        variant: 'error'
      })
    )
  }
}

function* findCreatorById(
  action: PayloadAction<CreatorsActions.FIND_ONE_CREATOR_BY_ID_REQUEST, string>
) {
  try {
    yield put(setLoading())
    console.log(action.payload)
    yield put(clearLoading())
  } catch (e) {
    yield put(clearLoading())
  }
}

function* searchCreator(
  action: PayloadAction<CreatorsActions.GET_SEARCH_CREATOR_REQUEST, string>
) {
  yield put(setLoading(true))

  try {
    const data: ListCreatorResponse[] = yield call(
      listCreatorFilter,
      action.payload
    )

    yield put(getSearchCreatorSuccess(data))

    yield put(clearLoading())
  } catch (err) {
    yield put(clearLoading())
    yield put(
      setModalState({
        variant: 'error',
        message:
          'Não foi possivel filtrar os produtores, tente novamante mais tarde',
        open: true
      })
    )
  }
}

export const sagaCreator = all([
  takeLatest(CreatorsActions.GET_CREATORS_REQUEST, getCreators),
  takeLatest(CreatorsActions.CREATE_CREATOR_REQUEST, createCreator),
  takeLatest(CreatorsActions.EDIT_CREATOR_REQUEST, editCreator),
  takeLatest(CreatorsActions.DELETE_CREATOR_REQUEST, deleteCreator),
  takeLatest(CreatorsActions.GET_SEARCH_CREATOR_REQUEST, searchCreator),
  takeLatest(CreatorsActions.FIND_ONE_CREATOR_BY_ID_REQUEST, findCreatorById)
])
