import { ReactNode } from 'react'
import * as S from './TableBody.styles'

type TableBodyProps = {
  children: ReactNode
}

export const TableBody = ({ children }: TableBodyProps) => {
  return <S.Container>{children}</S.Container>
}
