import { api } from 'api/api'
import { Coupon } from 'redux/store/EditEvent/types'
import { AxiosResponse } from 'axios'

type RequestBody = {
  name: string
  description: string
  amountOfUse: number
  value: string | number
  valueType: 'PERCENT' | 'CENTS'
  quantity?: number
  startValidateDate?: string
  finalValidateDate?: string
}

export const createCouponService = async (
  eventId: string,
  requestBody: RequestBody
) => {
  const { data }: AxiosResponse<Coupon> = await api.post(
    `/api/backoffice/coupons/events/${eventId}`,
    {
      ...requestBody,
      value:
        typeof requestBody.value === 'number'
          ? requestBody.value
          : Number(requestBody.value.replace(/[^\d]/g, ''))
    }
  )

  return data
}
