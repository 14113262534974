import { EventItems } from '../../../redux/store/EditEvent/types'
import { AxiosResponse } from 'axios'
import { api } from '../../api'

export const updateEventItemStatusService = async (
  eventId: string,
  isPublic: boolean,
  status: 'OPEN' | 'CLOSED'
) => {
  const { data }: AxiosResponse<EventItems> = await api.put(
    `/api/events/${eventId}`,
    {
      isPublic,
      status
    }
  )

  return data
}
