import { BackButton } from '../../../../../components/BackButton/BackButton'
import * as S from './ShowTicket.styles'
import { useDispatch, useSelector } from 'react-redux'
import { showRequest } from '../../../../../redux/store/Purchases/actions'
import { Hidden } from 'applaus-ui-kit'
import { formatPrice } from '../../../../../utils/formatPrice'
import { ReduxState } from '../../../../../redux/store/rootReducer'
import moment from 'moment'
import { useState, useEffect } from 'react'
import QRCode from 'qrcode'

export const ShowTicket = () => {
  const dispatch = useDispatch()

  const { selectedPurchaseOrder } = useSelector(
    (state: ReduxState) => state.PurchasesReducer
  )

  const [qrCodeImage, setQrCodeImage] = useState<string[]>([])

  useEffect(() => {
    selectedPurchaseOrder?.customerTickets.map((ticket) =>
      QRCode.toDataURL(ticket.qrCode).then((response) =>
        setQrCodeImage((prev) => [...prev, response])
      )
    )
  }, [])

  return (
    <S.Container>
      <S.ContentRequest>
        <Hidden tablet>
          <S.TitleRequest>Ingressos</S.TitleRequest>
        </Hidden>
        <BackButton
          title={
            'Pedido: ' + selectedPurchaseOrder?.orderId.toLocaleUpperCase()
          }
          subTitle={
            'Comprado em ' +
            moment(
              selectedPurchaseOrder?.updatedAt ||
                selectedPurchaseOrder?.createdAt
            ).format('DD/MM/YYYY') +
            ' às ' +
            moment(
              selectedPurchaseOrder?.updatedAt ||
                selectedPurchaseOrder?.createdAt
            ).format('LT')
          }
          onClick={() => dispatch(showRequest())}
        />

        <div className="contentTickets">
          {selectedPurchaseOrder?.customerTickets.map(
            (customerTicket, index) => (
              <S.ContentTicket key={customerTicket.id}>
                <S.TicketBox>
                  <div className="circleLeft" />
                  <S.Ticket>
                    <div className="titleEventBox">
                      <S.TitleEvent>
                        {selectedPurchaseOrder?.eventItem.event.name}
                      </S.TitleEvent>
                      {selectedPurchaseOrder.customerTickets[index]
                        .detachedAt && (
                        <p className="ticketDetached"> (Utilizado)</p>
                      )}
                    </div>
                    <S.AddressEvent>
                      {selectedPurchaseOrder!.eventItem.event.address.street +
                        ', ' +
                        selectedPurchaseOrder!.eventItem.event.address.city}
                    </S.AddressEvent>
                    <S.Date>
                      {moment(selectedPurchaseOrder?.eventItem.dates[0]).format(
                        'LLLL'
                      )}
                    </S.Date>

                    <S.TicketInfoBox>
                      <S.TicketInfo>
                        <div className="contentInfoBox">
                          <h1>Ingresso:</h1> <p>{customerTicket.ticket.name}</p>
                        </div>
                        {selectedPurchaseOrder.eventItem.event.type ===
                        'MAPPED' ? (
                          <div className="contentInfoBox">
                            <h1>Setor:</h1>{' '}
                            <p>{customerTicket.ticket.category}</p>
                          </div>
                        ) : null}
                        {selectedPurchaseOrder.eventItem.event.type ===
                        'MAPPED' ? (
                          <div className="contentInfoBox">
                            <h1>Lugar:</h1> <p>{customerTicket.seatId}</p>
                          </div>
                        ) : null}
                      </S.TicketInfo>

                      <S.TicketInfo2>
                        {selectedPurchaseOrder.eventItem.ticketInfo.length >
                          0 && customerTicket.ticketInfo !== null ? (
                          <div>
                            {selectedPurchaseOrder.eventItem.ticketInfo.map(
                              (ticket, index) =>
                                index < 3 ? (
                                  <div
                                    key={index}
                                    className="contentTicketInfo">
                                    <h1>{ticket.value}:</h1>{' '}
                                    <p>
                                      {customerTicket.ticketInfo[ticket.value]}
                                    </p>
                                  </div>
                                ) : null
                            )}
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <S.PurchaseData>
                          <div className="contentPurchaseData">
                            <h1>Data da compra:</h1>{' '}
                            <p>
                              {moment(
                                selectedPurchaseOrder?.updatedAt ||
                                  selectedPurchaseOrder?.createdAt
                              ).format('L') +
                                ' às ' +
                                moment(
                                  selectedPurchaseOrder?.updatedAt ||
                                    selectedPurchaseOrder?.createdAt
                                ).format('LT')}
                            </p>
                          </div>
                          <div className="contentPurchaseData">
                            <h1>Valor:</h1>{' '}
                            <p>
                              {formatPrice(
                                (customerTicket.ticket.priceCents -
                                  customerTicket.ticket.discountCents) /
                                  100
                              )}
                            </p>
                          </div>
                        </S.PurchaseData>
                      </S.TicketInfo2>
                    </S.TicketInfoBox>
                  </S.Ticket>
                  <div className="circleRight" />
                </S.TicketBox>

                <S.QrCode>
                  <p>
                    {index + 1} de{' '}
                    {selectedPurchaseOrder.customerTickets.length} ingressos
                  </p>
                  <img
                    src={qrCodeImage[index]}
                    alt="QRCode"
                    className="qrCodeImage"
                  />
                  <p className="idQrCode">
                    {selectedPurchaseOrder.customerTickets[
                      index
                    ].customerTicketId.toUpperCase()}
                  </p>
                </S.QrCode>
              </S.ContentTicket>
            )
          )}
        </div>
      </S.ContentRequest>
    </S.Container>
  )
}
