import { AlertModal, Button } from 'applaus-ui-kit'
import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AuthenticateTemplate } from '../../components/templates/AuthenticateTemplate'
import {
  deleteCustomerRequest,
  getSearchCustomerRequest
} from '../../redux/store/Customers/Actions/customerActions'
import { Customer } from '../../redux/store/Customers/types'
import { CustomerModal } from './CustomerModal/CustomerModal'
import * as S from './Customers.styles'
import { CustomersTable } from './CustomersTable/CustomersTable'
import * as _ from 'lodash'

export type ModalState = {
  open: boolean
  type: 'CREATE' | 'EDIT' | 'DELETE'
  customer?: Customer
}

type AlertModalState = {
  buttonText: string
  click: () => void
  message: string
  open: boolean
  variant: 'error' | 'success'
  cancelClick?: () => void
  description?: string
}

export const Customers = () => {
  const [searchInput, setSearchInput] = useState('')
  const dispatch = useDispatch()
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    type: 'CREATE'
  })
  const [alertModalState, setAlertModalState] = useState<AlertModalState>({
    buttonText: '',
    click: () => {},
    message: '',
    open: false,
    variant: 'error'
  })

  const resetAlertModalState = () => {
    setAlertModalState({
      buttonText: '',
      click: () => {},
      message: '',
      open: false,
      variant: 'error'
    })
  }

  const closeModal = () => {
    setModalState({
      open: false,
      type: 'CREATE'
    })
  }

  const editCustomer = (customer: Customer) => {
    setModalState({
      open: true,
      type: 'EDIT',
      customer
    })
  }

  const deleteCustomer = (customer: Customer) => {
    if (customer) {
      setAlertModalState({
        buttonText: 'Deletar',
        click: () => {
          dispatch(deleteCustomerRequest([customer.id]))
          resetAlertModalState()
        },
        message: 'Deseja mesmo deletar o cliente?',
        open: true,
        variant: 'error',
        cancelClick: resetAlertModalState
      })
    }
  }

  useEffect(() => {
    if (searchInput.trim().length > 2) {
      dispatch(
        getSearchCustomerRequest(
          searchInput.replaceAll('.', '').replace('-', '')
        )
      )
    }
  }, [searchInput])

  const debounceSearch = _.debounce(function (param: string) {
    setSearchInput(param)
  }, 2000)

  const handleCustomerSearch = (event: ChangeEvent<HTMLInputElement>) => {
    debounceSearch(event.target.value)
  }

  return (
    <>
      <AuthenticateTemplate>
        <S.Container>
          <S.Header>
            <div>
              <S.UserIcon />
              <S.Title>Clientes</S.Title>
            </div>

            <div>
              <Button
                color="primary"
                fullWidth
                variant="contained"
                size="large"
                onClick={() => setModalState({ open: true, type: 'CREATE' })}>
                Cadastrar customer
              </Button>
            </div>
          </S.Header>
          <div className="inputBox">
            <S.SearchIcon />
            <S.Searchnput
              placeholder="Procure clientes por nome, documento ou email"
              onChange={handleCustomerSearch}
            />
          </div>
          <CustomersTable
            searchInput={searchInput}
            editCustomer={editCustomer}
            deleteCustomer={deleteCustomer}
          />
        </S.Container>
      </AuthenticateTemplate>
      <CustomerModal closeModal={closeModal} modalState={modalState} />
      <AlertModal
        buttonText={alertModalState.buttonText}
        click={alertModalState.click}
        message={alertModalState.message}
        open={alertModalState.open}
        variant={alertModalState.variant}
        cancelClick={alertModalState.cancelClick}
        description={alertModalState.description}
      />
    </>
  )
}
