import moment from 'moment'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Pagination,
  TableBody,
  TableContainer,
  TableData,
  TableFooter,
  TableRow
} from '../../../components/table'
import TableHeader from '../../../components/table/TableHeader/TableHeader'
import { Banner } from '../../../redux/store/Banners/types'
import { ReduxState } from '../../../redux/store/rootReducer'
import * as S from './BannersTable.styles'

export type Headers = {
  name: string
  field: string
  align: 'center' | 'right' | 'left'
  sortable: boolean
}

type BannersTableProps = {
  editBanner: (banner: Banner) => void
  deleteBanner: (banner: Banner) => void
}

export const BannersTable = ({
  editBanner,
  deleteBanner
}: BannersTableProps) => {
  const { data } = useSelector((state: ReduxState) => state.Banner)
  const [totalBanners, setTotalBanners] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 25

  const paginationData = useMemo(() => {
    const computedData = data
      .slice()
      .sort((a, b) => moment(b.name).diff(moment(a.name)))

    setTotalBanners(computedData.length)
    return computedData.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    )
  }, [data, currentPage])

  const headers: Headers[] = [
    { name: 'Nome', field: 'name', align: 'left', sortable: false },
    { name: 'Descrição', field: 'email', align: 'left', sortable: false },
    { name: 'Ativo', field: 'position', align: 'left', sortable: false },
    { name: 'Ações', field: 'actions', align: 'left', sortable: false }
  ]

  return (
    <>
      <S.Container>
        <TableContainer
          table={
            <>
              <TableHeader
                headers={headers}
                type="manage"
                onSorting={() => {}}
              />

              <TableBody>
                {paginationData.map((banner) => {
                  return (
                    <TableRow key={banner.id} selected={false}>
                      <TableData>{banner.name}</TableData>
                      <TableData>{banner.description}</TableData>
                      <TableData>
                        {banner.status ? 'Ativo' : 'Desativado'}
                      </TableData>
                      <TableData>
                        <S.IconBox>
                          <S.EditIcon onClick={() => editBanner(banner)} />
                          <S.ExcludeIcon onClick={() => deleteBanner(banner)} />
                        </S.IconBox>
                      </TableData>
                    </TableRow>
                  )
                })}
              </TableBody>
            </>
          }
          footer={
            <>
              <TableFooter>
                <p>{data.length} banners cadastrados</p>
                <Pagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  onPageChange={(page) => setCurrentPage(page)}
                  totalItems={totalBanners}
                />
              </TableFooter>
            </>
          }
        />
      </S.Container>
    </>
  )
}
