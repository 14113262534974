/* eslint-disable no-unused-vars */
export enum WithdrawalsActions {
  loadWithdrawalsRequest = '@withdrawals/LOAD_WITHDRAWALS_REQUEST',
  loadWithdrawalsSuccess = '@withdrawals/LOAD_WITHDRAWALS_SUCCESS',
  updateWithdrawSuccess = '@withdrawals/UPDATE_WITHDRAWAL_SUCCESS',
  updateWithdrawsRequest = '@withdrawals/UPDATE_WITHDRAWAL_REQUEST'
}

export type Withdrawals = {
  id: string
  status: 'WAITING' | 'REJECTED' | 'APPROVED'
  comments: null | string
  valueCents: number
  createdAt: string
  updatedAt: string | null
  creatorId: string | null
  eventPartnerId: null | string
  eventId: string
  creator: null | {
    id: string
    firstName: string
    lastName: string
    corporateName: string
    document: string
    documentType: 'CPF' | 'CNPJ'
    email: string
    phone: string
    userId: string
    type: string
    groups: string[]
    createdAt: string
    updatedAt: string | null
    customField: null
    creatorPaymentSetting: {
      id: string
      subAccountId: string
      liveApiToken: string
      testApiToken: string
      userToken: string
      status: string
      bank: string
      bankAg: string
      bankAccountType: 'Poupança' | 'Corrente'
      bankCc: string
      creditCardFee: number
      pixFee: number
      appFee: number
      softDescriptor: string
      maxInstallments: null | number
      paymentEmailNotificationReceiver: string
      createdAt: string
      updatedAt: null | string
      creatorId: string
    }
  }
  eventPartner: null | {
    id: string
    name: string
    email: string
    phoneNumber: string
    subAccountId: string
    liveApiToken: string
    testApiToken: string
    userToken: string
    document: string
    documentType: 'CPF' | 'CNPJ'
    status: 'ACCEPTED'
    bank: string
    bankAg: string
    bankAccountType: string
    bankCc: string
    createdAt: string
    updatedAt: string | null
    creatorId: string
  }
  event: {
    id: string
    name: string
    type: 'MAPPED' | 'TABULATED'
    status: string
    isPublic: boolean
    cashBackPercent: null | number
    description: string
    category: string
    banners: {
      cardUrl: string
      mobileUrl: string
      tabletUrl: string
      desktopUrl: string
    }[]
    createdAt: string
    updatedAt: string | null
    appFee: null | number
    creatorId: string
    seatMapId: string | null
    deletedAt: null
    ageGroup: string
  }
}

export type WithdrawalsState = {
  data: Withdrawals[]
}
