export const BankOptions = {
  '001': 'Banco do Brasil',
  '033': 'Santander',
  '104': 'Caixa Econômica',
  '237': 'Bradesco',
  '341': 'Itaú',
  '041': 'Banrisul',
  '748': 'Sicredi',
  '756': 'Sicoob',
  '077': 'Inter',
  '070': 'BRB',
  '085': 'Via Credi',
  '655': 'Neon/Votorantim',
  '260': 'Nubank',
  '290': 'Pagseguro',
  '212': 'Banco Original',
  '422': 'Safra',
  '746': 'Modal',
  '021': 'Banestes',
  '136': 'Unicred',
  '274': 'Money Plus',
  '389': 'Mercantil do Brasil',
  '376': 'JP Morgan',
  '364': 'Gerencianet Pagamentos do Brasil',
  '336': 'Banco C6',
  '218': 'BS2',
  '082': 'Banco Topazio',
  '099': 'Uniprime',
  '197': 'Stone',
  '707': 'Banco Daycoval',
  '633': 'Rendimento',
  '004': 'Banco do Nordeste',
  '745': 'Citibank',
  '301': 'PJBank',
  '97': 'Cooperativa Central de Credito Noroeste Brasileiro',
  '084': 'Uniprime Norte do Paraná',
  '384': 'Global SCM',
  '403': 'Cora',
  '323': 'Mercado Pago',
  '003': 'Banco da Amazonia',
  '752': 'BNP Paribas Brasil',
  '383': 'Juno',
  '133': 'Cresol',
  '173': ' BRL Trust DTVM',
  '047': 'Banco Banese',
  '208': 'Banco BTG Pactual',
  '613': 'Banco Omni',
  '332': 'Acesso Soluções de Pagamento',
  '273': ' CCR de São Miguel do Oeste',
  '093': 'Polocred',
  '355': 'Ótimo',
  '121': 'Agibank',
  '037': 'Banpará',
  '380': 'Picpay',
  '125': 'Banco Genial',
  '412-x': 'Banco Capital S.A',
  '741': 'Banco Ribeirão Preto'
}
