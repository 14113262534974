import * as Yup from 'yup'

export const createUserBackoffice = Yup.object({
  firstName: Yup.string().required('Nome é obrigatório.'),
  lastName: Yup.string().required('Sobrenome é obrigatório'),
  email: Yup.string()
    .email('Digite um email válido')
    .required('Email é obrigatório'),
  password: Yup.string().required('Senha é obrigatória')
})

export const editUserBackoffice = Yup.object({
  firstName: Yup.string().required('Nome é obrigatório.'),
  lastName: Yup.string().required('Sobrenome é obrigatório'),
  email: Yup.string()
    .email('Digite um email válido')
    .required('Email é obrigatório')
})
