import { all, call, put, takeLatest, delay } from 'redux-saga/effects'

import { listWithdrawalsSuccessAction, updateWithdrawSuccess } from './actions'
import { Withdrawals, WithdrawalsActions } from './types'
import { findManyWithdrawalsService } from '../../../api/services/withdrawals/findManyWithdrawals'
import { PayloadAction } from 'typesafe-actions'
import { setModalState } from '../Modal/Actions/actionsModal'
import { updateWithdrawService } from '../../../api/services/withdrawals/updateWithdrawService'
import { clearLoading, setLoading } from '../Loading/actions'

export function* listEventsRequest() {
  yield put(setLoading(true))
  try {
    const withdrawals: Withdrawals[] = yield call(findManyWithdrawalsService)
    yield put(listWithdrawalsSuccessAction(withdrawals))
    yield delay(Number(process.env.REACT_APP_DELAY_REQUEST))
    yield put(clearLoading())
  } catch (e) {
    yield put(clearLoading())
    yield put(
      setModalState({
        open: true,
        variant: 'error',
        message: 'Não foi possível carregar as solicitações se saque'
      })
    )
  }
}

export function* updateWithdraw(
  action: PayloadAction<
    WithdrawalsActions.updateWithdrawsRequest,
    {
      withdrawId: string
      comments: string
      status: 'REJECTED' | 'APPROVED' | 'WAITING'
    }
  >
) {
  try {
    const data: Withdrawals = yield call(
      updateWithdrawService,
      action.payload.withdrawId,
      action.payload.comments,
      action.payload.status
    )
    yield put(updateWithdrawSuccess(data))
    yield put(
      setModalState({
        open: true,
        message: 'Solicitação alterada com sucesso',
        buttonText: 'Fechar',
        variant: 'success'
      })
    )
  } catch (e) {}
}

export const WithdrawalsSaga = all([
  takeLatest(WithdrawalsActions.loadWithdrawalsRequest, listEventsRequest),
  takeLatest(WithdrawalsActions.updateWithdrawsRequest, updateWithdraw)
])
