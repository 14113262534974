import produce from 'immer'
import { Reducer } from 'redux'
import { BannersActions, BannerState } from './types'

const initialState: BannerState = {
  data: []
}

export const Banner: Reducer<BannerState> = (state = initialState, action) => {
  switch (action.type) {
    case BannersActions.LOAD_BANNERS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.data = action.payload
      })
    case BannersActions.CREATE_BANNER_SUCCESS:
      return produce(state, (draftState) => {
        draftState.data.push(action.payload)
      })
    case BannersActions.EDIT_BANNER_SUCCESS:
      return produce(state, (draftState) => {
        const index = draftState.data.findIndex(
          (d) => d.id === action.payload.id
        )
        if (index > -1) {
          draftState.data[index] = action.payload
        }
      })
    case BannersActions.DELETE_BANNER_SUCCESS:
      return produce(state, (draftState) => {
        action.payload.forEach((bannerId: string) => {
          const index = draftState.data.findIndex(
            (banner) => banner.id === bannerId
          )
          if (index > -1) {
            draftState.data.splice(index, 1)
          }
        })
      })
    default:
      return state
  }
}
