import { AxiosResponse } from 'axios'
import { api } from '../../api'

export type CreateCustomerRequest = {
  firstName: string
  lastName: string
  email: string
  document: string
  password: string
  phone?: string
  address?: {
    street?: string
    state?: string
    city?: string
    number?: number
    country?: string
    complement?: string
    zipCode?: string
  }
}

export type CreateCustomerResponse = {
  id: string
  firstName: string
  lastName: string
  email: string
  document: string
  userId: string
  type: string
  groups: string[]
  createdAt: string
  updatedAt: string
  phone: string
  address: string
  zipCode: string
  customField: {
    isPolice: boolean
  }
}

export const createCustomerService = async (
  dataCustomer: CreateCustomerRequest
) => {
  const { data }: AxiosResponse<CreateCustomerResponse> = await api.post(
    '/api/backoffice/customers',

    dataCustomer
  )

  return data
}
