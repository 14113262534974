import * as Yup from 'yup'
import { messageHelper } from '../../../../../../utils/messageHelper'

export const createTabulatedEventSchema = Yup.object({
  name: Yup.string()
    .min(2, messageHelper.yup.creator.eventShortName)
    .max(128, messageHelper.yup.creator.eventLongName)
    .required(messageHelper.yup.creator.nameIsMandatory),
  maxCustomerTickets: Yup.string().test(
    'maxTickets',
    messageHelper.yup.creator.maxTicketsNoZero,
    (value) => (Number(value) ? Number(value) > 0 : false)
  )
})

export const createMappedEventSchema = Yup.object({
  name: Yup.string()
    .min(2, messageHelper.yup.creator.eventShortName)
    .max(128, messageHelper.yup.creator.eventLongName)
    .required(messageHelper.yup.creator.nameIsMandatory),
  salesFrom: Yup.string().required('Início das vendas é obrigatório.'),
  finalSales: Yup.string().required('Início das vendas é obrigatório.')
})
