import styled, { css } from 'styled-components'

import { ReactComponent as QrCodeFile } from '../../assets/icons/qrCode.svg'

export const TitleBox = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacing.stack.xs} 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .title {
      font-size: 27px;
      font-style: normal;
      font-weight: 600;
      line-height: 41px;
      letter-spacing: 0em;
      text-align: left;
    }
  `}
`

export const QrCodeIcon = styled(QrCodeFile)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.stack.xxxs};
  `}
`

export const CheckinButton = styled.button`
  ${({ theme }) => css`
    outline: 0;
    border: 0;
    background: ${theme.color.brand.primary.nth1};
    display: flex;
    align-items: center;
    padding: 12px ${theme.spacing.inline.xxxs};
    border-radius: 12px;
    transition: 0.3s;
    &:hover {
      transform: scale(1.05);
    }
  `}
`

export const CheckinButtonText = styled.p`
  ${({ theme }) => css`
    color: ${theme.color.neutral.nth4};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    overflow: auto;
    background: ${theme.color.neutral.nth4};
    .title {
      font-size: 27px;
      font-style: normal;
      font-weight: 600;
      line-height: 41px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: ${theme.spacing.stack.xs};
    }
  `}
`

export const PaymentInfo = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
    background: ${theme.color.base.nth4};
    border-radius: 6px;
    margin-right: ${theme.spacing.inline.xxs};

    > div {
      display: flex;
    }
    .payment {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
    }

    .status {
      margin-left: ${theme.spacing.inline.small};
      color: ${theme.color.alert.success.nth1};
    }
    > span {
      margin-top; ${theme.spacing.stack.nano};
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0em;
      text-align: left;
    }

  `}
`

export const PaymentPricing = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
    background: ${theme.color.base.nth4};
    border-radius: 6px;
    margin-right: ${theme.spacing.inline.xxs};
    max-height: 73px;
    > p {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
    > span {
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      display: flex;
      align-items: center;

      img {
        width: 32px;
        height: 25px;
      }
    }
  `}
`

export const UserInfo = styled.div`
  ${({ theme }) => css`
    .user {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: ${theme.color.neutral.nth3};
    }

    .user-info-text {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  `}
`
