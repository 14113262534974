import { all, call, put, takeLatest, delay } from '@redux-saga/core/effects'
import _ from 'lodash'
import { PayloadAction } from 'typesafe-actions'
import {
  CreateChartRequest,
  CreateChartResponse,
  createChartService
} from '../../../api/services/charts/createChartService'
import { deleteChartService } from '../../../api/services/charts/deleteChartService'
import {
  EditChartRequest,
  EditChartResponse,
  editChartService
} from '../../../api/services/charts/editChartService'
import {
  LoadChartResponse,
  getChartsService
} from '../../../api/services/charts/getChartsService'
import { messageHelper } from '../../../utils/messageHelper'
import { clearLoading, setLoading } from '../Loading/actions'
import { setModalState } from '../Modal/Actions/actionsModal'
import { store } from '../store'
import {
  createChartSuccess,
  deleteChartSuccess,
  editChartSuccess,
  getChartsSuccess
} from './actions'
import { ChartActions } from './types'

function* getCharts(
  action: PayloadAction<
    ChartActions.GET_CHARTS_REQUEST,
    { skip: number; currentPage: number }
  >
) {
  yield put(setLoading(true))
  try {
    const { getState } = store
    const { take } = getState().Charts

    const data: LoadChartResponse = yield call(
      getChartsService,
      action.payload.skip,
      take
    )
    yield put(
      getChartsSuccess(
        {
          items: data.items.map((d) => {
            if (d.address.complement === null) {
              d.address.complement = undefined
            }
            return {
              ...d,
              address: {
                city: d.address.city,
                country: d.address.country,
                number: d.address.number,
                state: d.address.state,
                street: d.address.street,
                complement: d.address.complement,
                zipCode: d.address.zipCode
              }
            }
          }),
          total: data.total
        },
        action.payload.skip,
        action.payload.currentPage
      )
    )
    yield delay(Number(process.env.REACT_APP_DELAY_REQUEST))
    yield put(clearLoading())
  } catch (err) {
    yield put(clearLoading())
    yield put(
      setModalState({
        open: true,
        variant: 'error',
        message: messageHelper.modal.charts.error.loadCharts
      })
    )
  }
}

function* createChart(
  action: PayloadAction<ChartActions.CREATE_CHART_REQUEST, CreateChartRequest>
) {
  yield put(setLoading(true))
  try {
    const { address, name, seatChartId } = action.payload
    const data: CreateChartResponse = yield call(createChartService, {
      address,
      name,
      seatChartId
    })

    yield put(createChartSuccess(data))

    yield delay(Number(process.env.REACT_APP_DELAY_REQUEST))
    yield put(clearLoading())

    yield put(
      setModalState({
        open: true,
        message: messageHelper.modal.charts.success.createCharts,
        variant: 'success'
      })
    )
  } catch (err) {
    yield put(clearLoading())

    yield put(
      setModalState({
        open: true,
        message: messageHelper.modal.charts.error.createCharts,
        variant: 'error'
      })
    )
  }
}

function* editChart(
  action: PayloadAction<
    ChartActions.EDIT_CHART_REQUEST,
    { data: EditChartRequest; seatId: string }
  >
) {
  try {
    if (!_.isEmpty(action.payload.data)) {
      const dataChart: EditChartResponse = yield call(
        editChartService,
        action.payload.data,
        action.payload.seatId
      )

      yield put(editChartSuccess(dataChart))
      yield put(
        setModalState({
          open: true,
          message: 'Mapa de assentos editado com sucesso.',
          variant: 'success'
        })
      )
    }
  } catch (e) {
    yield put(
      setModalState({
        open: true,
        message: 'Não foi possível alterar o mapa de assentos.',
        variant: 'error',
        description: 'Tente novamente.'
      })
    )
  }
}

function* deleteChart(
  action: PayloadAction<ChartActions.DELETE_CHART_REQUEST, string[]>
) {
  yield put(setLoading(true))
  try {
    yield all(
      action.payload.map((seatMapId) => call(deleteChartService, seatMapId))
    )
    yield put(deleteChartSuccess(action.payload))

    yield delay(Number(process.env.REACT_APP_DELAY_REQUEST))
    yield put(clearLoading())

    yield put(
      setModalState({
        open: true,
        message: messageHelper.modal.charts.success.deleteChart,
        variant: 'success'
      })
    )
  } catch (e) {
    yield put(clearLoading())

    yield put(
      setModalState({
        open: true,
        message: messageHelper.modal.charts.error.deleteChart,
        variant: 'error'
      })
    )
  }
}

export const sagaCharts = all([
  takeLatest(ChartActions.GET_CHARTS_REQUEST, getCharts),
  takeLatest(ChartActions.CREATE_CHART_REQUEST, createChart),
  takeLatest(ChartActions.EDIT_CHART_REQUEST, editChart),
  takeLatest(ChartActions.DELETE_CHART_REQUEST, deleteChart)
])
