import { AxiosResponse } from 'axios'
import { api } from '../../api'

export type EditPartnertRequest = {
  name?: string
  document?: string
  documentType?: string
  email?: string
  phoneNumber?: string
  address?: {
    zipCode: string
    complement?: string
    country: string
    state: string
    city: string
    street: string
    district: string
    number: number
  }
  bankInfo?: {
    bank: string
    bankAg: string
    bankCc: string
    bankAccountType: string
  }
}

export type EditPartnertResponse = {
  name?: string
  document?: string
  documentType?: 'CPF' | 'CNPJ'
  email?: string
  phoneNumber?: string
  address?: {
    zipCode: string
    complement?: string
    country: string
    state: string
    city: string
    street: string
    district: string
    number: number
  }
  bankInfo?: {
    bank: string
    bankAg: string
    bankCc: string
    bankAccountType: string
  }
}

export const editPartnertService = async (
  request: EditPartnertRequest,
  partnerId: string
) => {
  const filteredRequest = Object.entries(request).filter(
    (value) => value[1] !== undefined
  )

  if (filteredRequest.length > 0) {
    const { data }: AxiosResponse<EditPartnertResponse> = await api.put(
      `/api/backoffice/partners/${partnerId}`,
      request
    )
    return data
  } else return 'noResponse'
}
