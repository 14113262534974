import styled, { css } from 'styled-components'

import { ReactComponent as IconExit } from '../../../assets/icons/multiply.svg'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 25%;
    max-height: 80vh;
    border-radius: ${theme.border.radius.large};
    background: ${theme.color.neutral.nth4};
    .form-content {
      padding: 0 1rem 1rem 0;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      @media (max-width: 767px) {
        padding: 0;
      }
    }
    @media (min-width: 768px and max-width: 1170px) {
      margin: 0 6.25%;
    }
    @media (max-width: 767px) {
      margin: 0;
      max-height: none;
      height: 100%;
      border-radius: 0;
    }
  `}
  #chart {
    height: 400px;
  }
`
export const ModalTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing.stack.xxs};
  `}
`

export const IconExitModal = styled(IconExit)`
  cursor: pointer;
`

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.stack.xxs};
    overflow-y: scroll;
  `}
`

export const ButtonBox = styled.div`
  display: flex;
  column-gap: 1rem;
  margin-top: 2rem;
`
