import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { ReactComponent as ChevronDownFile } from '../../../../../../../../assets/icons/chevron-down.svg'
import { ReactComponent as EditFile } from '../../../../../../../../assets/icons/edit-alt.svg'

export const EditIcon = styled(EditFile)`
  fill: #b88bcf;
  transition: 0.3s ease;
  cursor: pointer;
  &:hover {
    fill: #8601c9;
    transform: scale(1.3);
  }
`

export const ChevronIcon = styled(ChevronDownFile)`
  fill: black;
  transition: 0.3s ease;
  &:hover {
    transform: scale(1.3);
  }
`

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: ${theme.spacing.stack.xxs} 0;

    .radioButtonBox {
      display: flex;
      margin: ${theme.spacing.stack.xxs} 0;

      > div:first-child {
        margin-right: ${theme.spacing.inline.md};
      }
    }

    .currencyInput {
      max-height: 43px;
      margin: 0;
      max-width: 244px;
    }

    .inputType {
      span {
        display: none;
      }
    }

    .inputBox {
      display: flex;
      gap: ${theme.spacing.stack.xxxs};
    }

    .priceBox {
      display: flex;
      gap: ${theme.spacing.stack.xxxs};
    }
  `}
`

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    padding: ${theme.spacing.stack.xxxs};
    border-radius: ${theme.border.radius.medium};
    margin-bottom: ${theme.spacing.stack.xxxs};
  `}
`

export const IconBox = styled(motion.div)`
  cursor: pointer;
`

export const TicketsBox = styled(motion.div)`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxxs};
    > p {
      margin: 1rem 0 1rem 1rem;
    }
  `}
`

export const TicketCard = styled(motion.div)`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing.stack.xxxs};
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    border-radius: ${theme.border.radius.medium};
    margin-bottom: ${theme.spacing.stack.xxxs};
  `}
`

export const AccordionInfo = styled(motion.form)`
  ${({ theme }) => css`
    > div {
      width: 100%;
    }
    > p {
      margin: 1rem 0 1rem 1rem;
    }
  `}
`
export const Actions = styled(motion.div)`
  ${({ theme }) => css`
    margin: 3rem 0;
    display: flex;
    justify-content: space-between;
  `}
`

export const customStyles = {
  clearIndicator: () => ({
    border: '1px solid blue'
  }),
  control: (provided: any) => ({
    ...provided,
    display: 'flex',
    padding: '2px 16px',
    background: '#F4F4F4',
    outline: 'none',
    border: '1px solid transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: '6px',
    '&:focus-within': {
      borderColor: '#8601C9'
    }
  }),
  valueContainer: (provided: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }),
  indicatorSeparator: (provided: any) => ({
    display: 'none'
  }),
  singleValue: (provided: any) => ({
    color: '#39383A',
    fontSize: '16px',
    lineHeight: '20px'
  }),
  placeholder: (provided: any) => ({
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    backgroundColor: state.isSelected ? '#F4F4F4' : 'white'
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  })
}

export const Label = styled.p`
  ${({ theme }) => css`
    color: #39383a;
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.regular};
    line-height: 24px;
    margin-bottom: 2px;
  `}
`

export const Error = styled.p`
  color: #fc3e3e;
  font-size: 12px;
  min-height: 24px;
`
