import { AxiosResponse } from 'axios'
import { api } from '../../api'

export type ListSeatMapResponse = {
  items: {
    id: string
    name: string
  }[]
  total: 1
}

export const listSeatMapService = async () => {
  const { data }: AxiosResponse<ListSeatMapResponse> = await api.get(
    '/api/backoffice/seatMaps'
  )

  return data.items
}
