import produce from 'immer'
import { Reducer } from 'redux'
import { CustomersActions, CustomerState } from './types'

const initalState: CustomerState = {
  data: {
    items: [],
    total: 0
  },
  currentPage: 1,
  skip: 0,
  take: 5
}

export const Customer: Reducer<CustomerState> = (
  state = initalState,
  action
) => {
  switch (action.type) {
    case CustomersActions.GET_CUSTOMERS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.data = action.payload.data
        draftState.skip = action.payload.skip
        draftState.currentPage = action.payload.currentPage
      })
    case CustomersActions.GET_SEARCH_CUSTOMER_SUCCESS:
      return produce(state, (draftState) => {
        draftState.data.items = action.payload
        draftState.data.total = action.payload.length
      })
    case CustomersActions.CREATE_CUSTOMER_SUCCESS:
      return produce(state, (draftState) => {
        draftState.data.items.unshift(action.payload)
      })
    case CustomersActions.EDIT_CUSTOMER_SUCCESS:
      return produce(state, (draftState) => {
        const index = draftState.data.items.findIndex(
          (customer) => customer.id === action.payload.id
        )

        if (index > -1) {
          draftState.data.items[index] = action.payload
        }
      })
    case CustomersActions.DELETE_CUSTOMER_SUCCESS:
      return produce(state, (draftState) => {
        action.payload.forEach((customerId: string) => {
          const index = draftState.data.items.findIndex(
            (customer) => customer.id === customerId
          )

          if (index > -1) {
            draftState.data.items.splice(index, 1)
          }
        })
      })
    default:
      return state
  }
}
