import { AxiosResponse } from 'axios'
import { api } from '../../api'

export type EventSummaryResponse = {
  id: string
  name: string
  description?: string
  isPublic: boolean
  createdAt: string
  updatedAt?: string
  creatorId?: string
  summary: {
    confirmedPayments: number
    pendingPayments: number
    creditSales: number
    pixSales: number
  }
  customerStatics: {
    total: number
    totalAttendence: number
    totalPayers: number
  }
}

export const eventSummaryService = async (eventId: string) => {
  const { data }: AxiosResponse<EventSummaryResponse> = await api.get(
    `/api/backoffice/events/${eventId}/summary`
  )

  data.summary.confirmedPayments /= 100
  data.summary.creditSales /= 100
  data.summary.pixSales /= 100
  data.summary.pendingPayments /= 100

  return data
}
