import { api } from '../../api'
import { AxiosResponse } from 'axios'
import { Withdrawals } from '../../../redux/store/Withdrawals/types'

export const findManyWithdrawalsService = async () => {
  const { data }: AxiosResponse<Withdrawals[]> = await api.get(
    'api/backoffice/balance/withdrawals'
  )

  return data
}
