import { DetachResponse } from '../../../api/services/tickets/ticketDetach'
import {
  EventPurchaseOrder,
  PurchaseOrdersByEventResponse
} from '../../../api/services/events/listEventPurchasesOrder'
import {
  CustomerTicketByEventResponse,
  EventCustomerTickets
} from '../../../api/services/events/listEventCustomerTickets'
import { EventSummaryResponse } from '../../../api/services/events/eventSummaryService'
import { EventResponse } from '../../../api/services/events/listEventService'

/* eslint-disable no-unused-vars */
export enum ManageEventsActions {
  SET_CUSTOMER_TICKET = '@manageEvent/SET_CUSTOMER_TICKET',
  LOAD_EVENT_DATA_REQUEST = '@manageEvent/LOAD_EVENT_DATA_REQUEST',
  LOAD_EVENT_DATA_SUCCESS = '@manageEvent/LOAD_EVENT_DATA_SUCCESS',
  CHANGE_CUSTOMER_TICKET_PAGINATION_STEP_REQUEST = '@manageEvent/CHANGE_CUSTOMER_TICKET_PAGINATION_STEP_REQUEST',
  CHANGE_CUSTOMER_TICKET_PAGINATION_STEP_SUCCESS = '@manageEvent/CHANGE_CUSTOMER_TICKET_PAGINATION_STEP_SUCCESS',
  CUSTOMER_TICKET_NEXT_STEP_REQUEST = '@manageEvent/CUSTOMER_TICKET_NEXT_STEP_REQUEST',
  CUSTOMER_TICKET_STEP_BACK_REQUEST = '@manageEvent/CUSTOMER_TICKET_STEP_BACK_REQUEST',
  CHANGE_PURCHASES_PAGINATION_STEP_REQUEST = '@manageEvent/CHANGE_PURCHASE_PAGINATION_STEP_REQUEST',
  CHANGE_PURCHASES_PAGINATION_STEP_SUCCESS = '@manageEvent/CHANGE_PURCHASE_PAGINATION_STEP_SUCCESS',
  PURCHASE_NEXT_STEP_REQUEST = '@manageEvent/PURCHASE_NEXT_STEP_REQUEST',
  PURCHASE_STEP_BACK_REQUEST = '@manageEvent/PURCHASE_STEP_BACK_REQUEST',
  LOAD_CUSTOMER_TICKET = '@manageEvent/LOAD_CUSTOMER_TICKET',
  LOAD_PURCHASE_ORDER = '@manageEvent/LOAD_PURCHASE_ORDER'
}

export type EventData = {
  eventData: EventResponse
  summary: EventSummaryResponse
  customerTickets?: CustomerTicketByEventResponse
  purchasesOrders?: PurchaseOrdersByEventResponse
}

export type ManageCustomerTicket = {
  data: DetachResponse | null
  customerTicket: EventCustomerTickets | null
  paymentTicket: EventPurchaseOrder | null
  eventData: EventResponse | null
  purchaseOrders: {
    items: EventPurchaseOrder[]
    total: number
    take: number
    skip: number
  }
  customerTickets: {
    items: EventCustomerTickets[]
    total: number
    take: number
    skip: number
  }
  summaryData: EventSummaryResponse | null
}
