import { Button, Heading, Input } from 'applaus-ui-kit'
import { useFormik } from 'formik'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import ContentModal from '../../../components/contentModal/ContentModal'
import * as S from './ChartModal.styles'
import { validationSchema } from './validationSchema'
// @ts-ignore
import { SeatsioDesigner } from '@seatsio/seatsio-react'
import { useDispatch } from 'react-redux'
import {
  createChartRequest,
  editChartRequest
} from '../../../redux/store/Charts/actions'
import { ModalState } from '../Charts'
import { viaCepService } from '../../../api/services/viaCepService'
import { EditChartRequest } from '../../../api/services/charts/editChartService'
import _ from 'lodash'
// import { EditChartRequest } from '../../../api/services/charts/editChartService'

type CourseModalProps = {
  modalState: ModalState
  closeModal: () => void
}

export const ChartModal = ({ modalState, closeModal }: CourseModalProps) => {
  const dispatch = useDispatch()
  const [chartId, setChartId] = useState('')

  const formik = useFormik({
    initialValues: {
      name: '',
      seatChartId: '',
      city: '',
      country: '',
      number: '',
      state: '',
      street: '',
      complement: '',
      zipCode: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      formik.setErrors({})
      closeModal()
      if (modalState.type === 'CREATE') {
        dispatch(
          createChartRequest({
            name: values.name,
            address: {
              city: values.city,
              country: values.country,
              number: Number(values.number),
              state: values.state,
              street: values.street,
              complement:
                values.complement === '' ? undefined : values.complement,
              zipCode: values.zipCode
            },
            seatChartId: values.seatChartId
          })
        )
        formik.resetForm()
      } else if (modalState.type === 'EDIT' && modalState.chart) {
        const newAddress = {
          street: values.street,
          state: values.state,
          city: values.city,
          number: Number(values.number),
          country: values.state ?? 'Brasil',
          complement: values.complement === '' ? undefined : values.complement,
          zipCode: values.zipCode
        }

        const requestValue: EditChartRequest = {
          name: values.name !== modalState.chart.name ? values.name : undefined,
          seatChartId:
            values.seatChartId !== modalState.chart.seatChartId
              ? values.seatChartId
              : undefined,
          address:
            _.isEqual(modalState.chart.address, newAddress) ||
            values.city === ''
              ? undefined
              : newAddress
        }

        dispatch(
          editChartRequest(
            Object.fromEntries(
              Object.entries(requestValue).filter(
                ([_, v]) => v !== undefined && v !== null
              )
            ),
            modalState.chart.id
          )
        )
      }
    }
  })

  const handleZipCode = async (zipCode: ChangeEvent<HTMLInputElement>) => {
    if (zipCode.target.value.replaceAll(/[-_]/g, '').length === 8) {
      const address = await viaCepService(
        zipCode.target.value.replaceAll(/[-_]/g, '')
      )
      if (address.erro === undefined) {
        formik.setFieldValue('city', address.localidade)
        formik.setFieldValue('state', address.uf)
        formik.setFieldValue('street', address.logradouro)
      }
    }
  }

  useEffect(() => {
    if (modalState.type === 'EDIT' && modalState.chart) {
      formik.setValues({
        name: modalState.chart.name,
        city: modalState.chart.address.city,
        country: modalState.chart.address.country,
        number: String(modalState.chart.address.number),
        state: modalState.chart.address.state,
        street: modalState.chart.address.street,
        complement:
          modalState.chart.address.complement === null
            ? ''
            : modalState.chart.address.complement,
        zipCode: modalState.chart.address.zipCode ?? '',
        seatChartId: modalState.chart.seatChartId
      })
      setChartId(modalState.chart.seatChartId)
    }
  }, [modalState.chart])

  const renderChartMap = useMemo(() => {
    return formik.values.seatChartId.length === 36 ? (
      <SeatsioDesigner
        secretKey="0855f43f-5d67-480a-b1b0-81bed0c8c201"
        chartKey={chartId}
        region="sa"
        mode="readOnly"
        features={{
          disabled: [
            'nodes',
            'labeling',
            'tables',
            'booths',
            'areas',
            'shapes',
            'texts',
            'sections',
            'icons',
            'images',
            'viewFromYourSeat',
            'firstTimeTutorial',
            'backgroundImage',
            'categoryList',
            'objectProperties',
            'contextActions',
            'publishedSectionLabel',
            'publishing'
          ]
        }}
      />
    ) : undefined
  }, [chartId])

  return (
    <ContentModal open={modalState.open}>
      <S.Container>
        <S.ModalTitle>
          <Heading variant="h5">
            {modalState.type === 'CREATE'
              ? 'Cadastrar mapa de assentos'
              : 'Editar mapa de assentos'}
          </Heading>
          <S.IconExitModal
            onClick={() => {
              closeModal()
              formik.resetForm()
              setChartId('')
            }}
          />
        </S.ModalTitle>
        <S.Form onSubmit={formik.handleSubmit}>
          <Input
            name="name"
            label="Nome"
            placeholder="Digite o nome da planta"
            texterror={formik.errors.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            value={formik.values.name ?? ''}
            onChange={formik.handleChange}
            fullWidth
          />

          <Input
            name="zipCode"
            label="CEP"
            placeholder="Digite o CEP"
            texterror={formik.errors.zipCode}
            error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
            value={formik.values.zipCode ?? ''}
            onChange={(e) => {
              formik.handleChange(e)
              handleZipCode(e)
            }}
            fullWidth
          />

          <Input
            name="street"
            label="Rua"
            placeholder="Digite a rua"
            texterror={formik.errors.street}
            error={formik.touched.street && Boolean(formik.errors.street)}
            value={formik.values.street ?? ''}
            onChange={formik.handleChange}
            fullWidth
          />

          <Input
            name="number"
            label="Número"
            placeholder="Digite o número"
            texterror={formik.errors.number}
            error={formik.touched.number && Boolean(formik.errors.number)}
            value={formik.values.number ?? ''}
            onChange={formik.handleChange}
            fullWidth
          />

          <Input
            name="complement"
            label="Complemento"
            placeholder="Digite o completo: ex: Apto 01"
            texterror={formik.errors.complement}
            error={
              formik.touched.complement && Boolean(formik.errors.complement)
            }
            value={formik.values.complement ?? ''}
            onChange={formik.handleChange}
            fullWidth
          />

          <Input
            name="city"
            label="Cidade"
            placeholder="Digite a cidade"
            texterror={formik.errors.city}
            error={formik.touched.city && Boolean(formik.errors.city)}
            value={formik.values.city ?? ''}
            onChange={formik.handleChange}
            fullWidth
          />

          <Input
            name="state"
            label="Estado"
            placeholder="Digite o estado"
            texterror={formik.errors.state}
            error={formik.touched.state && Boolean(formik.errors.state)}
            value={formik.values.state ?? ''}
            onChange={formik.handleChange}
            fullWidth
          />

          <Input
            name="country"
            label="País"
            placeholder="Digite o país"
            texterror={formik.errors.country}
            error={formik.touched.country && Boolean(formik.errors.country)}
            value={formik.values.country ?? ''}
            onChange={formik.handleChange}
            fullWidth
          />

          <Input
            name="seatChartId"
            label="ID da planta (seatsio)"
            placeholder="Digite o ID da planta"
            texterror={formik.errors.seatChartId}
            error={
              formik.touched.seatChartId && Boolean(formik.errors.seatChartId)
            }
            value={formik.values.seatChartId ?? ''}
            onChange={(e) => {
              formik.handleChange(e)
              setChartId(e.target.value)
            }}
            fullWidth
          />

          <div>{renderChartMap}</div>

          <S.ButtonBox>
            <Button
              color="primary"
              size="large"
              variant="text"
              fullWidth
              type="button"
              onClick={() => {
                closeModal()
                formik.resetForm()
                setChartId('')
              }}>
              Cancelar
            </Button>
            <Button color="primary" size="large" variant="contained" fullWidth>
              {modalState.type === 'CREATE' ? 'Cadastrar' : 'Editar dados'}
            </Button>
          </S.ButtonBox>
        </S.Form>
      </S.Container>
    </ContentModal>
  )
}
