import styled, { css } from 'styled-components'

import { ReactComponent as BackFile } from '../../../assets/icons/backIcon.svg'

export const BackIcon = styled(BackFile)`
  cursor: pointer;
  transition: 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
`

export const Container = styled.div`
  ${({ theme }) => css`
    > h4 {
      font-size: ${theme.font.size.md};
      line-height: ${theme.font.size.xl};
      background: #540074;
      text-align: center;
      color: white;
      padding: ${theme.spacing.stack.small} 0;
      border-radius: ${theme.border.radius.medium};
      margin-bottom: ${theme.spacing.stack.xxxs};
    }
    .backbuttonWrapper {
      margin: 0 0 ${theme.spacing.stack.xxs};
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        > p {
          font-size: ${theme.font.size.sm};
        }
        > button {
          margin: 0 1rem 0 0;
        }
      }
    }
  `}
`

export const TicketSection = styled.section`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 3rem;
    grid-column-gap: 2rem;
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
  `}
`

export const TicketCard = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #f4f4f4;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.inline.xxs};
    border-radius: ${theme.border.radius.medium};
    @media (max-width: 767px) {
      padding: ${theme.spacing.stack.xxxs} ${theme.spacing.inline.small};
    }
  `}
`
export const SelectTickets = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    > p {
      margin: 0 ${theme.spacing.inline.xxs};

      @media (max-width: 767px) {
        margin: 0 ${theme.spacing.inline.small};
      }
    }

    > button {
      outline: 0;
      font-weight: ${theme.font.weight.semiBold};
      font-size: ${theme.font.size.sm};
      line-height: ${theme.font.size.md};
      border-radius: 100%;
      border: 1px solid #8601c9;
      width: 32px;
      height: 32px;

      :disabled {
        border: 1px solid gray;
      }
    }
  `}
`
