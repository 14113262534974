import styled, { css } from 'styled-components'

import { ReactComponent as IconExit } from '../../../assets/icons/multiply.svg'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 25%;
    max-height: 80vh;
    border-radius: ${theme.border.radius.large};
    background: ${theme.color.neutral.nth4};
    .form-content {
      padding: 0 1rem 1rem 0;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      @media (max-width: 767px) {
        padding: 0;
      }
    }
    @media (min-width: 768px and max-width: 1170px) {
      margin: 0 6.25%;
    }
    @media (max-width: 767px) {
      margin: 0;
      max-height: none;
      height: 100%;
      border-radius: 0;
    }
  `}
`

export const ModalTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing.stack.xxs};
  `}
`

export const IconExitModal = styled(IconExit)`
  cursor: pointer;
`

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.stack.xxs};
    overflow-y: scroll;
  `}
`

export const ButtonBox = styled.div`
  display: flex;
  column-gap: 1rem;
`

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 48px 0;
  > div {
    width: 50%;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`

export const PriceText = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  position: relative;
  transition: 0.3s ease;
  @media (max-width: 767px) {
    text-align: left;
  }
`

export const Price = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  text-align: center;
  position: relative;
  transition: 0.3s ease;
  @media (max-width: 767px) {
    text-align: left;
  }
`

export const Link = styled.a`
  ${({ theme }) => css`
    margin: 1rem 0 1rem auto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    position: relative;
    transition: 0.3s ease;
    cursor: pointer;

    :after {
      transition: 0.3s ease;
      position: absolute;
      width: 0;
      top: 100%;
      transform: translateY(-100%);
      left: 0;
      content: '';
      height: 2px;
      background: ${theme.color.brand.primary.nth1};
    }

    &:hover {
      color: ${theme.color.brand.primary.nth2};
      :after {
        width: 100%;
      }
    }
  `}
`

export const DescriptionWithdrawal = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.stack.xxs};

    > label {
      color: #39383a;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 4px;
    }

    > textarea {
      min-height: 150px;
      max-height: 150px;
      min-width: 200px;
      max-width: 740px;

      color: #39383a;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
    }
  `}
`

export const Label = styled.p`
  ${({ theme }) => css`
    color: #39383a;
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.regular};
    line-height: 24px;
    margin-bottom: 2px;
  `}
`

export const customStyles = {
  clearIndicator: () => ({
    border: '1px solid blue'
  }),
  control: (provided: any) => ({
    ...provided,
    display: 'flex',
    padding: '2px 16px',
    background: '#F4F4F4',
    outline: 'none',
    border: '1px solid transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: '6px',
    '&:focus-within': {
      borderColor: '#8601C9'
    }
  }),
  valueContainer: (provided: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }),
  indicatorSeparator: (provided: any) => ({
    display: 'none'
  }),
  singleValue: (provided: any) => ({
    color: '#39383A',
    fontSize: '16px',
    lineHeight: '20px'
  }),
  placeholder: (provided: any) => ({
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    backgroundColor: state.isSelected ? '#F4F4F4' : 'white'
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  })
}

export const Error = styled.p`
  ${({ theme }) => css`
    color: #fc3e3e;
    font-size: ${theme.font.size.xxs};
    min-height: ${theme.spacing.stack.xxs};
  `}
`
