import styled, { css } from 'styled-components'

import { ReactComponent as DateFile } from '../../../../../assets/icons/clock.svg'
import { ReactComponent as LocationFile } from '../../../../../assets/icons/location.svg'
import { ReactComponent as CashbackFile } from '../../../../../assets/icons/cashback.svg'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: white;
    @media (min-width: 1171px) {
      padding: ${theme.spacing.stack.xs} ${theme.spacing.stack.xs};
    }
  `}
`

export const ContentRequest = styled.div`
  ${({ theme }) => css`
    @media (min-width: 768px) and (max-width: 1170px) {
      padding: 0 ${theme.spacing.stack.xs} ${theme.spacing.stack.xs};
    }
  `}
`

export const TitleRequest = styled.h1`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.semiBold};
    font-size: ${theme.font.size.md};
    line-height: ${theme.spacing.stack.sm};
    color: #39383a;
    margin-bottom: ${theme.spacing.stack.xs};
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.stack.sm};
    display: flex;
    justify-content: start;
  `}
`

export const Title = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.bold};
    font-size: ${theme.font.size.md};
    line-height: ${theme.spacing.stack.sm};
    margin-bottom: ${theme.spacing.stack.xxxs};
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  `}
`

export const CoverUrl = styled.img`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.stack.xxs};
    min-width: 340px;
    max-width: 340px;
    height: 481px;
    border-radius: 8px;

    @media (min-width: 1170px) {
      min-width: 415px;
      max-width: 415px;
      height: 456px;
    }
  `}
`

export const InfoRequest = styled.div`
  ${() => css`
    @media (min-width: 768px) and (max-width: 1170px) {
      width: 100%;
    }
  `}
`

export const InfoBox = styled.div`
  ${({ theme }) =>
    css`
      margin-bottom: ${theme.spacing.stack.xs};
    `}
`

export const DateBox = styled.div`
  ${({ theme }) =>
    css`
      display: flex;
      flex-direction: column;

      margin-bottom: ${theme.spacing.stack.xxxs};
    `}
`

export const IconDate = styled(DateFile)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.sm};
    height: ${theme.spacing.stack.sm};
    margin-right: ${theme.spacing.stack.small};
    fill: #39383a;
  `}
`

export const TextDate = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.semiBold};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
    white-space: nowrap;
  `}
`

export const SubTextDate = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.regular};
    font-size: ${theme.font.size.xxs};
    line-height: ${theme.spacing.stack.xxxs};
  `}
`

export const LocationBox = styled.div`
  ${() => css`
    display: flex;
  `}
`

export const IconLocation = styled(LocationFile)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.sm};
    height: ${theme.spacing.stack.sm};
    fill: #39383a;
    margin-right: ${theme.spacing.stack.small};
  `}
`

export const TextLocation = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.semiBold};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
  `}
`

export const SubTextLocation = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.regular};
    font-size: ${theme.font.size.xxs};
    line-height: ${theme.spacing.stack.xxxs};
  `}
`

export const PaymentStatus = styled.div`
  ${({ theme }) => css`
    background: #f4f4f4;
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
    margin-top: ${theme.spacing.stack.xxxs};
  `}
`

export const TextStatus = styled.h1<{ status: string }>`
  ${({ theme, status }) => css`
    display: flex;
    font-weight: ${theme.font.weight.bold};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
    .approvedText {
      margin-left: ${theme.spacing.stack.small};
      font-weight: ${theme.font.weight.bold};
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.xxs};
      color: ${status === 'SUCCESS' ? '#25a35a' : '#03AFCC'};
    }
  `}
`

export const PaymentData = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.regular};
    line-height: ${theme.spacing.stack.xxxs};
    font-size: 12px;
    white-space: nowrap;
  `}
`

export const PaymentValue = styled.div`
  ${({ theme }) => css`
    background: #f4f4f4;
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
    margin: ${theme.spacing.stack.xxxs} 0 ${theme.spacing.stack.xxs};
    .creditCard {
      display: flex;
      align-items: center;
    }
  `}
`

export const TextValue = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.bold};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
  `}
`

export const CashBackReceived = styled.div`
  ${({ theme }) => css`
    background: #f4f4f4;
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacing.stack.xxxs};
    margin-bottom: ${theme.spacing.stack.xxxs};

    .textCashback {
      font-weight: ${theme.font.weight.bold};
      margin-bottom: ${theme.spacing.stack.small};
    }

    > p {
      color: ${theme.color.brand.primary.nth1};
    }
  `}
`

export const CashBackPaid = styled.div`
  ${({ theme }) => css`
    background: #f4f4f4;
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacing.stack.xxxs};
    margin-bottom: ${theme.spacing.stack.xxxs};
    p {
      font-size: ${theme.font.size.sm};
      color: #39383a;
    }
    .bold {
      font-weight: ${theme.font.weight.bold};
    }
    .divider {
      border: 1px solid #5c5c5c;
      margin: ${theme.spacing.stack.small} 0;
    }
    .cashbackBox {
      display: flex;
      align-items: center;
      margin-bottom: ${theme.spacing.stack.small};
      > div {
        display: flex;
        align-items: center;
      }
    }

    .paymentBox {
      display: flex;
      align-items: center;
      > div:nth-child(2) {
        img {
          width: 24px;
          height: 24px;
          margin: 0 ${theme.spacing.stack.nano} 0 ${theme.spacing.stack.small};
        }
      }
    }
  `}
`

export const IconCashback = styled(CashbackFile)`
  ${({ theme }) => css`
    fill: ${theme.color.brand.primary.nth1};
    margin: 0 ${theme.spacing.stack.small};
  `}
`
