import { action } from 'typesafe-actions'
import { User, UserActions } from './types'

export const setUser = (data: User) => action(UserActions.SET_USER, data)

export const refreshTokenAction = (data: {
  accessToken: string
  refreshToken: string
}) => action(UserActions.REFRESH_TOKEN, data)

export const logoutAction = () => action(UserActions.LOGOUT)
