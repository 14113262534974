import { AlertModal, Button } from 'applaus-ui-kit'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { AuthenticateTemplate } from '../../components/templates/AuthenticateTemplate'
import { deleteChartRequest } from '../../redux/store/Charts/actions'
import { Chart } from '../../redux/store/Charts/types'
import { ChartModal } from './ChartModal/ChartModal'
import * as S from './Charts.styles'
import { ChartTable } from './ChartTable/ChartTable'

export type ModalState = {
  open: boolean
  type: 'CREATE' | 'EDIT' | 'DELETE'
  chart?: Chart
}

type AlertModalState = {
  buttonText: string
  click: () => void
  message: string
  open: boolean
  variant: 'error' | 'success'
  cancelClick?: () => void
  description?: string
}

export const Charts = () => {
  const dispatch = useDispatch()
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    type: 'CREATE'
  })
  const [alertModalState, setAlertModalState] = useState<AlertModalState>({
    buttonText: '',
    click: () => {},
    message: '',
    open: false,
    variant: 'error'
  })

  const resetAlertModalState = () => {
    setAlertModalState({
      buttonText: '',
      click: () => {},
      message: '',
      open: false,
      variant: 'error'
    })
  }

  const editChart = (chart: Chart) => {
    setModalState({ open: true, type: 'EDIT', chart })
  }

  const deleteChart = (chart: Chart) => {
    if (chart) {
      setAlertModalState({
        buttonText: 'Deletar',
        click: () => {
          dispatch(deleteChartRequest([chart.id]))
          resetAlertModalState()
        },
        message: 'Deseja mesmo deletar o mapa de assentos?',
        open: true,
        variant: 'error',
        cancelClick: resetAlertModalState
      })
    }
  }

  const closeModal = () => {
    setModalState({
      open: false,
      type: 'CREATE'
    })
  }

  return (
    <>
      <AuthenticateTemplate>
        <S.Container>
          <S.Header>
            <div>
              <S.CourseIcon />
              <S.Title>Plantas de eventos</S.Title>
            </div>

            <div>
              <Button
                color="primary"
                fullWidth
                variant="contained"
                size="large"
                onClick={() => setModalState({ open: true, type: 'CREATE' })}>
                Cadastrar planta de evento
              </Button>
            </div>
          </S.Header>
          <div className="inputBox">
            <S.SearchIcon />
            <S.Searchnput placeholder="Procure plantas por nome, seatId ou endereço" />
          </div>
          <ChartTable setEditChart={editChart} deleteChart={deleteChart} />
        </S.Container>
      </AuthenticateTemplate>
      <ChartModal modalState={modalState} closeModal={closeModal} />
      <AlertModal
        buttonText={alertModalState.buttonText}
        click={alertModalState.click}
        message={alertModalState.message}
        open={alertModalState.open}
        variant={alertModalState.variant}
        cancelClick={alertModalState.cancelClick}
        description={alertModalState.description}
      />
    </>
  )
}
