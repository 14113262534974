import { all, call, delay, put, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from 'typesafe-actions'
import {
  ListEventsResponse,
  listEventsService
} from 'api/services/events/listEventsService'
import { clearLoading, setLoading } from '../Loading/actions'
import { setModalState } from '../Modal/Actions/actionsModal'
import { store } from '../store'
import { listEventsSuccessAction, removeEventSuccess } from './actions'
import { EventsAction } from './types'
import { removeEventService } from 'api/services/events/removeEventService'

export function* listEventsRequest(
  action: PayloadAction<
    EventsAction.loadEventRequest,
    { currentPage: number; skip: number }
  >
) {
  yield put(setLoading(true))
  try {
    const { getState } = store
    const { take } = getState().reducerEvent

    const data: ListEventsResponse = yield call(listEventsService, {
      skip: action.payload.skip,
      take: take
    })

    const transformStatus = (
      status: 'CANCELED' | 'OPEN' | 'CLOSED' | 'DRAFT' | 'SOLD_OUT'
    ): string => {
      switch (status) {
        case 'OPEN':
          return 'Publicado'
        case 'CANCELED':
          return 'Cancelado'
        case 'DRAFT':
          return 'Rascunho'
        case 'CLOSED':
          return 'Fechado'
        case 'SOLD_OUT':
          return 'Esgotado'
      }
    }

    const formatedData = {
      total: data.total,
      items: data.items.map((event) => ({
        ...event,
        status: transformStatus(event.status!)
      }))
    }

    yield put(
      listEventsSuccessAction(
        formatedData,
        action.payload.skip,
        action.payload.currentPage
      )
    )
    yield delay(Number(process.env.REACT_APP_DELAY_REQUEST))
    yield put(clearLoading())
  } catch (e) {
    yield put(clearLoading())
    yield put(
      setModalState({
        open: true,
        variant: 'error',
        message: 'Não foi possivel carregar seus eventos',
        description: 'Tente novamente mais tarde',
        buttonText: 'Fechar'
      })
    )
  }
}

function* removeEvent(
  action: PayloadAction<EventsAction.removeEventRequest, string>
) {
  try {
    yield call(removeEventService, action.payload)
    yield put(removeEventSuccess(action.payload))
  } catch (e) {
    yield put(
      setModalState({
        open: true,
        message: 'Não foi possível deletar o evento',
        variant: 'error',
        buttonText: 'Fechar'
      })
    )
  }
}

export const ContentsSaga = all([
  takeLatest(EventsAction.loadEventRequest, listEventsRequest),
  takeLatest(EventsAction.removeEventRequest, removeEvent)
])
