import { AxiosResponse } from 'axios'
import { api } from 'api/api'

export type EventCustomerTickets = {
  id: string
  seatId: string
  qrCode: string
  ticketInfo: Record<string, string>[] | null
  detachedAt: string | null
  isValid: boolean
  customerId: string
  createdAt: string
  customerTicketId: string
  updatedAt: string | null
  eventItemId: string
  ticketId: string
  purchaseOrderId: string
  eventItem: {
    id: string
    dates: string[]
    description: string
    title: string
    maxCustomerTickets: number
    ticketInfo: Record<string, string>[] | null
    createdAt: string
    eventKey: string
    salesFrom: string | null
    finalSales: string | null
    updatedAt: string | null
    eventId: string
    event: {
      id: string
      name: string
      type: 'TABULATED' | 'MAPPED'
      status: string
      isPublic: boolean
      description: string
      banners: {
        cardUrl: string
        mobileUrl: string
        tabletUrl: string
        desktopUrl: string
      }[]

      createdAt: string
      updatedAt: string | null
      creatorId: string
      seatMapId: string
      address: {
        id: string
        name: string
        country: string
        state: string
        city: string
        street: string
        number: number
        zipCode: string
        latitude: null | string
        longitude: null | string
        complement: null | string
        district: null | string
        createdAt: string
        updatedAt: null | string
        creatorId: null | string
        customerId: null | string
        eventId: string
        seatMapId: null | string
      }
      Creator: {
        id: string
        firstName: string
        lastName: string
        corporateName: string | null
        document: string
        documentType: string
        email: string
        phone: string
        userId: string
        type: string
        groups: string[]
        createdAt: string
        updatedAt: null | string
        customField: null | string
      }
    }
  }
  ticket: {
    id: string
    name: string
    description: string
    priceCents: number
    discountCents: number
    type: string
    category: string
    rule: string
    createdAt: string
    updatedAt: null | string
    customField: null | string
  }
  customer: {
    id: string
    firstName: string
    lastName: string
    email: string
    document: string
    userId: string
    type: string
    groups: string[]
    createdAt: string
    updatedAt: string | string
    phone: string
    customField: {
      externalId: string
    }
  }
}

export type CustomerTicketByEventResponse = {
  items: EventCustomerTickets[]
  total: number
}

export const listCustomerTickets = async (eventId: string) => {
  const { data }: AxiosResponse<CustomerTicketByEventResponse> = await api.get(
    `api/backoffice/customerTickets/events/${eventId}`
  )

  return data
}
