import styled, { css } from 'styled-components'

const ContainerModifier = {
  left: () =>
    css`
      text-align: left;
    `,
  right: () =>
    css`
      text-align: right;
    `,
  center: () =>
    css`
      text-align: center;
    `
}

export const Container = styled.td<{ align: 'right' | 'center' | 'left' }>`
  ${({ theme, align }) => css`
    ${ContainerModifier[align]}  
    color: ${theme.color.neutral.nth2};
    font-weight: ${theme.font.weight.regular};
    font-size: ${theme.font.size.xs};
    width: fit-content;
    }
  `}
`
