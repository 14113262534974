import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Pagination,
  TableBody,
  TableContainer,
  TableData,
  TableFooter,
  TableRow
} from '../../../components/table'
import TableHeader from '../../../components/table/TableHeader/TableHeader'
import { getChartsRequest } from '../../../redux/store/Charts/actions'
import { Chart } from '../../../redux/store/Charts/types'
import { ReduxState } from '../../../redux/store/rootReducer'
import * as S from './ChartTable.styles'

export type Headers = {
  name: string
  field: string
  align: 'center' | 'right' | 'left'
  sortable: boolean
}

export type ChartTableProps = {
  setEditChart: (chart: Chart) => void
  deleteChart: (chart: Chart) => void
}

export const ChartTable = ({ setEditChart, deleteChart }: ChartTableProps) => {
  const { data, currentPage, take } = useSelector(
    (state: ReduxState) => state.Charts
  )
  const dispatch = useDispatch()
  const itemsPerPage = 25

  const paginationData = useMemo(() => {
    const computedData = data.items

    return computedData
  }, [data, currentPage])

  const headers: Headers[] = [
    { name: 'Nome', field: 'name', align: 'left', sortable: false },
    { name: 'Seat ID', field: 'email', align: 'left', sortable: false },
    { name: 'Endereço', field: 'position', align: 'left', sortable: false },
    { name: 'Ações', field: 'actions', align: 'left', sortable: false }
  ]

  useEffect(() => {
    dispatch(getChartsRequest((currentPage - 1) * take, currentPage))
  }, [])

  return (
    <>
      <S.Container>
        <TableContainer
          table={
            <>
              <TableHeader
                headers={headers}
                type="manage"
                onSorting={() => {}}
              />

              <TableBody>
                {paginationData.map((chart) => {
                  return (
                    <TableRow key={chart.id} selected={false}>
                      <TableData>{chart.name}</TableData>
                      <TableData>{chart.seatChartId}</TableData>
                      <TableData>{chart.address.street}</TableData>
                      <TableData>
                        <S.IconBox>
                          <S.EditIcon onClick={() => setEditChart(chart)} />
                          <S.ExcludeIcon onClick={() => deleteChart(chart)} />
                        </S.IconBox>
                      </TableData>
                    </TableRow>
                  )
                })}
              </TableBody>
            </>
          }
          footer={
            <>
              <TableFooter>
                <p>{data.total} mapas de assentos cadastrados</p>
                <Pagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  onPageChange={(page) =>
                    dispatch(getChartsRequest((page - 1) * take, page))
                  }
                  totalItems={data.total}
                />
              </TableFooter>
            </>
          }
        />
      </S.Container>
    </>
  )
}
