import { AlertModal, Button } from 'applaus-ui-kit'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { AuthenticateTemplate } from '../../components/templates/AuthenticateTemplate'
import {
  deleteBannerRequest,
  getAllBannersRequest
} from '../../redux/store/Banners/Actions/bannerActions'
import { Banner } from '../../redux/store/Banners/types'
import { BannerModal } from './BannerModal/BannerModal'
import * as S from './Banners.styles'
import { BannersTable } from './BannersTable/BannersTable'

export type ModalState = {
  open: boolean
  type: 'CREATE' | 'EDIT' | 'DELETE'
  banner?: Banner
}

type AlertModalState = {
  buttonText: string
  click: () => void
  message: string
  open: boolean
  variant: 'error' | 'success'
  cancelClick?: () => void
  description?: string
}

export const Banners = () => {
  const dispatch: Dispatch<any> = useDispatch()
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    type: 'CREATE'
  })
  const [alertModalState, setAlertModalState] = useState<AlertModalState>({
    buttonText: '',
    click: () => {},
    message: '',
    open: false,
    variant: 'error'
  })

  useEffect(() => {
    dispatch(getAllBannersRequest())
  }, [])

  const closeModal = () => {
    setModalState({
      open: false,
      type: 'CREATE'
    })
  }

  const resetAlertModalState = () => {
    setAlertModalState({
      buttonText: '',
      click: () => {},
      message: '',
      open: false,
      variant: 'error'
    })
  }

  const editBanner = (banner: Banner) => {
    setModalState({
      open: true,
      type: 'EDIT',
      banner
    })
  }

  const deleteBanner = (banner: Banner) => {
    if (banner) {
      setAlertModalState({
        buttonText: 'Deletar',
        click: () => {
          dispatch(deleteBannerRequest([banner.id]))
          resetAlertModalState()
        },
        message: 'Deseja mesmo excluir?',
        open: true,
        variant: 'error',
        cancelClick: resetAlertModalState
      })
    }
  }

  return (
    <>
      <AuthenticateTemplate>
        <S.Container>
          <S.Header>
            <div>
              <S.BannerIcon />
              <S.Title>Banners</S.Title>
            </div>

            <div>
              <Button
                color="primary"
                fullWidth
                variant="contained"
                size="large"
                onClick={() =>
                  setModalState({
                    open: true,
                    type: 'CREATE'
                  })
                }>
                Cadastrar banner
              </Button>
            </div>
          </S.Header>
          <div className="inputBox">
            <S.SearchIcon />
            <S.Searchnput placeholder="Procure banners por nome, descrição ou status" />
          </div>
          <BannersTable editBanner={editBanner} deleteBanner={deleteBanner} />
        </S.Container>
      </AuthenticateTemplate>
      <BannerModal closeModal={closeModal} modalState={modalState} />
      <AlertModal
        buttonText={alertModalState.buttonText}
        click={alertModalState.click}
        message={alertModalState.message}
        open={alertModalState.open}
        variant={alertModalState.variant}
        cancelClick={alertModalState.cancelClick}
        description={alertModalState.description}
      />
    </>
  )
}
