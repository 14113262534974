import produce from 'immer'
import { Reducer } from 'redux'
import { User, UserActions } from './types'

const initialState: User = {
  email: '',
  name: '',
  accessToken: '',
  refreshToken: ''
}

export const UserReducer: Reducer<User> = (state = initialState, action) => {
  switch (action.type) {
    case UserActions.SET_USER:
      return produce(state, (draftState) => ({ ...action.payload }))
    case UserActions.REFRESH_TOKEN:
      return produce(state, (draft) => {
        draft.accessToken = action.payload.accessToken
        draft.refreshToken = action.payload.refreshToken
      })
    case UserActions.LOGOUT:
      document.cookie = `applaus-auth-backoffice=; path=/; domain=${
        process.env.REACT_APP_DOMAIN
      }; expires= 
        ${new Date(0).toUTCString()}`
      window.location.href = process.env.REACT_APP_AUTH_URL ?? ''

      return initialState

    default:
      return state
  }
}
