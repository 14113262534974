/* eslint-disable no-unused-vars */
import { Button, Heading, Input } from 'applaus-ui-kit'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactSelect from 'react-select'
import { addPartnerInEvent } from '../../../../api/services/partner/addPartnerInEvent'
import {
  listPartners,
  ListPartnersResponse
} from '../../../../api/services/partner/listPartners'
import { loadEventDataRequestAction } from '../../../../redux/store/ManageEvent/actions'
import {
  clearModalState,
  setModalState
} from '../../../../redux/store/Modal/Actions/actionsModal'
import { ReduxState } from '../../../../redux/store/rootReducer'
import history from '../../../../routes/services/history'
import * as S from './AddPartner.styles'

type AddPartnerProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  eventPartners: ListPartnersResponse[] | undefined
}

export const AddPartner = ({
  open,
  setOpen,
  eventPartners
}: AddPartnerProps) => {
  const dispatch = useDispatch()
  const [partners, setPartners] = useState<ListPartnersResponse[]>()
  const [optionsPartners, setOptionsPartners] =
    useState<{ label: string; value: string }[]>()
  const [selectedPartner, setSelectedPartner] = useState('')
  const [percentage, setPercentage] = useState('')
  const [submit, setSubmit] = useState(false)
  const { eventData } = useSelector(
    (state: ReduxState) => state.ManageEventReducer
  )

  useEffect(() => {
    if (eventPartners) {
      setPartners(eventPartners)
      setOptionsPartners(
        eventPartners.map((partner) => ({
          label: partner.name,
          value: partner.name
        }))
      )
    }
  }, [eventPartners])

  const cleanAddPartner = () => {
    setOpen(false)
    setSelectedPartner('')
    setPercentage('')
  }

  const addPartner = async () => {
    setSubmit(true)
    try {
      if (selectedPartner !== '' && percentage !== '') {
        setSubmit(false)
        const status = await addPartnerInEvent({
          email: partners!.filter(
            (partner) => partner.name === selectedPartner
          )[0].email,
          events: [
            {
              eventId: eventData!.id,
              revenuePercent: Number(percentage)
            }
          ]
        })

        if (status === 200) {
          dispatch(
            setModalState({
              buttonText: 'Ok',
              open: true,
              message: 'Parceiro adicionado com sucesso.',
              variant: 'success',
              click: () => {
                dispatch(clearModalState())
                cleanAddPartner()
                dispatch(loadEventDataRequestAction(eventData!.id))
              }
            })
          )
        }
      }
    } catch (e) {
      dispatch(
        setModalState({
          buttonText: 'Ok',
          open: true,
          message: 'Ocorreu um erro ao adicionar o parceiro.',
          description: 'Tente novamente.',
          variant: 'error'
        })
      )
    }
  }

  return (
    <S.Container open={open}>
      <S.Form>
        <S.CloseIcon onClick={() => cleanAddPartner()} />
        <div className="title">
          <Heading variant="h5">Adicione o parceiro</Heading>
        </div>

        <div className="content">
          <label>Nome</label>
          <ReactSelect
            options={optionsPartners}
            value={optionsPartners?.filter(
              (partner) => partner.label === selectedPartner
            )}
            onChange={(e: any) => setSelectedPartner(e.value)}
            noOptionsMessage={() => 'Parceiro não encontrado'}
            placeholder="Escolha o parceiro"
            styles={S.customStyles}
          />
          <p className="error">
            {submit && selectedPartner === '' ? 'Selecione um parceiro' : ''}
          </p>
        </div>

        {selectedPartner ? (
          <div className="percentage">
            <p>{selectedPartner}</p>
            <div>
              <Input
                name="percentage"
                placeholder="00 %"
                value={percentage}
                onChange={(e) =>
                  e && Number(e.target.value)
                    ? setPercentage(e.target.value)
                    : e.target.value.length === 0
                    ? setPercentage('')
                    : null
                }
                error={submit && percentage === ''}
                texterror={
                  submit && percentage === '' ? 'Campo obrigatório' : ''
                }
              />
            </div>
          </div>
        ) : null}

        <Button
          size="small"
          color="primary"
          variant="outlined"
          fullWidth={false}
          onClick={() => history.push(`/partners/${eventData?.creatorId}`)}>
          + Criar novo parceiro
        </Button>

        <S.ButtonBox>
          <Button
            size="small"
            color="primary"
            variant="text"
            fullWidth
            onClick={() => cleanAddPartner()}>
            Cancelar
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => addPartner()}>
            Adicionar
          </Button>
        </S.ButtonBox>
      </S.Form>
    </S.Container>
  )
}
