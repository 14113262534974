import { AxiosResponse } from 'axios'
import { api } from '../../api'

export type Response = {
  originalName: string
  fileKey: string
  url: string
}

export const uploadImagesService = async (image: File) => {
  const files = new FormData()
  files.append('files', image)
  const { data }: AxiosResponse<Response[]> = await api.post(
    '/api/backoffice/storage/files',
    files
  )
  return data[0].url
}
