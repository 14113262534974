import { all, call, put, takeLatest } from '@redux-saga/core/effects'

import _ from 'lodash'
import { PayloadAction } from 'typesafe-actions'
import { deleteCourseService } from '../../../api/services/courses/deleteCourseService'
// import { deleteCourseService } from '../../../api/services/courses/deleteCourseService'
import {
  EditCourseRequest,
  EditCourseResponse,
  editCourseService
} from '../../../api/services/courses/editCourseService'
import {
  getCoursesService,
  LoadCourseResponse
} from '../../../api/services/courses/getCoursesService'
import { setModalState } from '../Modal/Actions/actionsModal'
import { store } from '../store'
import {
  deleteCourseSuccess,
  editCourseSuccess,
  getCoursesSuccess
} from './Actions/coursesActions'
import { CoursesActions } from './types'

function* getCourses(
  action: PayloadAction<
    CoursesActions.GET_COURSES_REQUEST,
    { skip: number; currentPage: number }
  >
) {
  try {
    const { getState } = store
    const { take } = getState().Courses

    const data: LoadCourseResponse = yield call(
      getCoursesService,
      action.payload.skip,
      take
    )
    yield put(
      getCoursesSuccess(data, action.payload.skip, action.payload.currentPage)
    )
  } catch (err) {}
}

function* editCourse(
  action: PayloadAction<
    CoursesActions.EDIT_COURSE_REQUEST,
    { data: EditCourseRequest; courseId: string; creatorId: string }
  >
) {
  try {
    const { data, courseId, creatorId } = action.payload

    if (!_.isEmpty(data)) {
      const dataCourse: EditCourseResponse = yield call(
        editCourseService,
        data,
        courseId,
        creatorId
      )

      yield put(editCourseSuccess(dataCourse))

      yield put(
        setModalState({
          open: true,
          message: 'Curso editado com sucesso.',
          variant: 'success'
        })
      )
    }
  } catch (e) {
    yield put(
      setModalState({
        open: true,
        message: 'Não foi possível alterar o curso.',
        variant: 'error',
        description: 'Tente novamente.'
      })
    )
  }
}

function* deleteCourses(
  action: PayloadAction<
    CoursesActions.DELETE_COURSE_REQUEST,
    { courseId: string; creatorId: string }[]
  >
) {
  try {
    yield all(
      action.payload.map(({ courseId, creatorId }) =>
        call(deleteCourseService, courseId, creatorId)
      )
    )

    yield put(deleteCourseSuccess(action.payload))

    yield put(
      setModalState({
        open: true,
        message: 'Cursos deletados com sucesso.',
        variant: 'success'
      })
    )
  } catch (e) {
    yield put(
      setModalState({
        open: true,
        message: 'Não foi possível deletar os cursos.',
        variant: 'error',
        description: 'Tente novamente.'
      })
    )
  }
}

export const sagaCourses = all([
  takeLatest(CoursesActions.GET_COURSES_REQUEST, getCourses),
  takeLatest(CoursesActions.EDIT_COURSE_REQUEST, editCourse),
  takeLatest(CoursesActions.DELETE_COURSE_REQUEST, deleteCourses)
])
