import { Dispatch, SetStateAction } from 'react'
import { useLocation } from 'react-router-dom'
import * as S from './Navigation.styles'

type NavigationProps = {
  setShowNavigation: Dispatch<SetStateAction<boolean>>
  showNavigation: boolean
  active?: 'coupons'
}

export const Navigation = ({
  setShowNavigation,
  showNavigation,
  active
}: NavigationProps) => {
  const location = useLocation()
  return (
    <S.Container showNavigation={showNavigation}>
      <S.HideIconBox>
        <S.HideIcon onClick={() => setShowNavigation(false)} />
      </S.HideIconBox>
      <S.Ul>
        <S.Li>
          <S.Link to="/">
            {location.pathname === '/' ? <S.HomeFilledIcon /> : <S.HomeIcon />}
            <S.TextLink active={location.pathname === '/' ?? false}>
              Home
            </S.TextLink>
          </S.Link>
        </S.Li>

        <S.Divider />

        <S.Li>
          <S.Link to="/events">
            {location.pathname === '/events' ||
            location.pathname === '/partners' ||
            location.pathname.includes('/manageEvent') ? (
              <S.CalendarIconFilled />
            ) : (
              <S.CalendarIcon />
            )}
            <S.TextLink active={location.pathname === '/events' ?? false}>
              Eventos
            </S.TextLink>
          </S.Link>
        </S.Li>

        <S.Divider />

        <S.Li>
          <S.Link to="/users">
            {location.pathname === '/users' ? (
              <S.UserBackofficeFilledIcon />
            ) : (
              <S.UserBackofficeIcon />
            )}
            <S.TextLink active={location.pathname === '/users' ?? false}>
              Backoffice
            </S.TextLink>
          </S.Link>
        </S.Li>

        <S.Divider />

        <S.Li>
          <S.Link to="/banners">
            {location.pathname === '/banners' ? (
              <S.BannerSolidIcon />
            ) : (
              <S.BannerIcon />
            )}
            <S.TextLink active={location.pathname === '/banners' ?? false}>
              Banners
            </S.TextLink>
          </S.Link>
        </S.Li>

        <S.Divider />

        <S.Li>
          <S.Link to="/creators">
            {location.pathname === '/creators' ? (
              <S.ProducerSolidIcon />
            ) : (
              <S.ProducerIcon />
            )}
            <S.TextLink active={location.pathname === '/creators' ?? false}>
              Produtores
            </S.TextLink>
          </S.Link>
        </S.Li>

        <S.Divider />

        <S.Li>
          <S.Link to="/customers">
            {location.pathname === '/customers' ? (
              <S.UserIconFilled />
            ) : (
              <S.UserIcon />
            )}
            <S.TextLink active={location.pathname === '/customers' ?? false}>
              Clientes
            </S.TextLink>
          </S.Link>
        </S.Li>

        <S.Divider />

        <S.Li>
          <S.Link to="/charts">
            {location.pathname === '/charts' ? (
              <S.MapIconFilled />
            ) : (
              <S.MapIcon />
            )}
            <S.TextLink active={location.pathname === '/charts' ?? false}>
              Plantas
            </S.TextLink>
          </S.Link>
        </S.Li>

        <S.Li>
          <S.Link to="/coupons">
            {active === 'coupons' ? <S.CouponFilled /> : <S.CouponIcon />}
            <S.TextLink active={active === 'coupons'}>Cupons</S.TextLink>
          </S.Link>
        </S.Li>

        <S.Li>
          <S.Link to="/withdrawals">
            {location.pathname === '/withdrawals' ? (
              <S.HomeFilledIcon />
            ) : (
              <S.HomeIcon />
            )}
            <S.TextLink active={location.pathname === '/' ?? false}>
              Solicitações de saque
            </S.TextLink>
          </S.Link>
        </S.Li>
      </S.Ul>
    </S.Container>
  )
}
