import * as S from './EventCoupons.styles'
import { Button, Heading, Paragraph } from 'applaus-ui-kit'
import { useState } from 'react'
import { AddCoupons } from './AddCoupons/AddCoupons'
import { Coupon } from 'redux/store/EditEvent/types'
import { formatPrice } from 'utils/formatPrice'

type FormValues = {
  name: string
  description: string
  amountOfUse: number
  value: string
  valueType: 'PERCENT' | 'CENTS'
  quantity?: number
  startValidateDate?: string
  finalValidateDate?: string
  type: 'limited' | 'unlimited'
}

type Props = {
  handleClickBack: () => void
  handleClickNextStep: () => void
  handleCreateCoupon: (values: FormValues) => void
  handleEditCoupon: (values: {
    id: string
    name: string
    description: string
    amountOfUse: number
    value: string
    valueType: 'PERCENT' | 'CENTS'
    quantity?: number
    startValidateDate?: string
    finalValidateDate?: string
  }) => void
  handleDeleteCoupon: (couponId: string) => void
  coupons: Coupon[]
}

type CreateCouponState = {
  open: boolean
  coupon?: Coupon
}

export const EventCoupons = ({
  handleClickBack,
  handleClickNextStep,
  handleCreateCoupon,
  handleEditCoupon,
  handleDeleteCoupon,
  coupons
}: Props) => {
  const [createCoupon, setCreateCoupon] = useState<CreateCouponState>({
    open: false
  })

  const handleClickEditCoupon = async (coupon: Coupon) => {
    setCreateCoupon({ open: true, coupon })
  }

  const handleSubmit = (values: FormValues) => {
    handleCreateCoupon(values)
    setCreateCoupon({ open: false })
  }

  const handleEdit = (values: {
    id: string
    name: string
    description: string
    amountOfUse: number
    value: string
    valueType: 'PERCENT' | 'CENTS'
    quantity?: number
    startValidateDate?: string
    finalValidateDate?: string
  }) => {
    handleEditCoupon(values)
    setCreateCoupon({ open: false })
  }

  return (
    <>
      {!createCoupon.open ? (
        <S.Container>
          <S.TitleBox>
            <S.BackIcon onClick={handleClickBack} />
            <Heading variant="h3">Cupons</Heading>
          </S.TitleBox>
          <S.Subtitle>Configure cupons do seu evento</S.Subtitle>
          <Button
            color="primary"
            size="medium"
            variant="outlined"
            fullWidth={false}
            onClick={() => setCreateCoupon({ open: true })}>
            + Adicionar cupom
          </Button>
          <S.TableHeader>
            <S.TableTitle>Código</S.TableTitle>
            <S.TableTitle>Tipo de cupom</S.TableTitle>
            <S.TableTitle>Desconto</S.TableTitle>
            <S.TableTitle />
          </S.TableHeader>
          {coupons.map((coupon) => (
            <S.TableData key={coupon.id}>
              <Paragraph variant="regular" className="data-info">
                {coupon.name}
              </Paragraph>
              <Paragraph variant="regular" className="data-info">
                {coupon.quantity ? 'Limitado' : 'Ilimitado'}
              </Paragraph>
              <Paragraph variant="regular" className="data-info">
                {coupon.valueType === 'PERCENT'
                  ? `${coupon.value} %`
                  : formatPrice(coupon.value / 100)}
              </Paragraph>

              <div>
                <S.EditIcon
                  onClick={() => {
                    handleClickEditCoupon(coupon)
                  }}
                />
                <S.TrashIcon onClick={() => handleDeleteCoupon(coupon.id)} />
              </div>
            </S.TableData>
          ))}
          <Button
            color="primary"
            size="medium"
            variant="contained"
            fullWidth={false}
            onClick={handleClickNextStep}>
            Continuar
          </Button>
        </S.Container>
      ) : (
        <AddCoupons
          handleClickBack={() => setCreateCoupon({ open: false })}
          handleSubmit={handleSubmit}
          coupon={createCoupon.coupon}
          handleEditCoupon={handleEdit}
        />
      )}
    </>
  )
}
