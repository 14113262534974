/* eslint-disable no-unused-vars */
export enum UserActions {
  SET_USER = '@user/SET_USER',
  REFRESH_TOKEN = '@user/REFRESH_TOKEN',
  LOGOUT = '@user/LOGOUT'
}

export type User = {
  email: string
  name: string
  accessToken: string
  refreshToken: string
}
