import { useState } from 'react'
import { AuthenticateTemplate } from '../../components/templates/AuthenticateTemplate'
import { CustomerPurchases } from '../CustomersPurchases/CustomersPurchases'
import { PurchaseHistory } from '../PurchasesHistory/PurchasesHistory'
import * as S from './CustomerInfo.styles'

export const CustomerInfo = () => {
  const [option, setOption] = useState('Pedidos')
  return (
    <AuthenticateTemplate>
      <S.Container>
        <S.Tab>
          <S.OptionText
            isActive={option === 'Pedidos'}
            onClick={() => {
              setOption('Pedidos')
            }}>
            Pedidos
          </S.OptionText>
          <S.OptionText
            isActive={option === 'Histórico'}
            onClick={() => {
              setOption('Histórico')
            }}>
            Histórico
          </S.OptionText>
        </S.Tab>
        {option === 'Pedidos' ? <CustomerPurchases /> : <PurchaseHistory />}
      </S.Container>
    </AuthenticateTemplate>
  )
}
