import { api } from '../../api'
import { AxiosResponse } from 'axios'

export type SummaryData = {
  confirmedPayments: number
  pendingPayments: number
  ticketsSold: number
  creditSales: number
  pixSales: number
  availableWithdraw: number
  receivableBalance: number
  ticketsCourtesy: number
  purchaseOrders: {
    paymentHistory?: {
      id: string
      status: 'APPROVED' | 'PROCESSING' | 'DECLINED'
      totalPrice: number
      description: string
      lastCardNumbers: number
      brand: string
      paymentMethod: 'CREDIT_CARD' | 'PIX' | 'FREE'
      installments: number | null
      createdAt: string
      updatedAt: string
      customerId: string
      purchaseOrderId: string
    }
  }[]
}

export const globalSummaryService = async (year?: string, month?: string) => {
  const { data }: AxiosResponse<SummaryData> = await api.get(
    `/api/backoffice/orders/summary${
      year !== undefined && month !== undefined
        ? `?year=${year}&month=${month}`
        : ''
    }`
  )

  data.confirmedPayments /= 100
  data.pendingPayments /= 100
  data.creditSales /= 100
  data.pixSales /= 100
  data.availableWithdraw /= 100
  data.receivableBalance /= 100

  return data
}
