import * as S from './ShowTicket.styles'
import EventImage from '../../../../../assets/mock/eventTicketMobile.png'
import { BackButton } from '../../../../../components/BackButton/BackButton'
import { useDispatch, useSelector } from 'react-redux'
import { showRequest } from '../../../../../redux/store/Purchases/actions'
import { formatPrice } from '../../../../../utils/formatPrice'
import { ReduxState } from '../../../../../redux/store/rootReducer'
import moment from 'moment'
import { useMemo, useState } from 'react'
import QRCode from 'qrcode'

export const ShowTicket = () => {
  const dispatch = useDispatch()

  const { selectedPurchaseOrder } = useSelector(
    (state: ReduxState) => state.PurchasesReducer
  )

  const [qrCodeImage, setQrCodeImage] = useState<string[]>([])

  useMemo(() => {
    selectedPurchaseOrder?.customerTickets.forEach(async (ticket) => {
      const qrCode = await QRCode.toDataURL(ticket.qrCode)

      setQrCodeImage((prev) => [...prev, qrCode])
    })
  }, [])

  return (
    <S.Container animate={{ opacity: [0, 1], transition: { duration: 0.5 } }}>
      <S.ContentImage>
        <div className="buttonBack">
          <BackButton title="" onClick={() => dispatch(showRequest())} />
        </div>
      </S.ContentImage>

      <S.CoverUrl src={EventImage} />

      <S.Wrapper>
        <S.ContentTickets>
          <S.Title>Meus ingressos</S.Title>

          {selectedPurchaseOrder?.customerTickets.map(
            (customerTicket, index) => (
              <S.TicketBox key={customerTicket.id}>
                <div className="circleTop" />
                <S.Ticket>
                  <div className="titleEventBox">
                    <S.TitleEvent>
                      {selectedPurchaseOrder?.eventItem.event.name}
                    </S.TitleEvent>
                    {selectedPurchaseOrder.customerTickets[index]
                      .detachedAt && (
                      <p className="ticketDetached"> (Utilizado)</p>
                    )}
                  </div>
                  <S.AddressEvent>
                    {selectedPurchaseOrder!.eventItem.event.address.street +
                      ', ' +
                      selectedPurchaseOrder!.eventItem.event.address.city}
                  </S.AddressEvent>
                  <S.Date>
                    {moment(selectedPurchaseOrder?.eventItem.dates[0]).format(
                      'LLLL'
                    )}
                  </S.Date>
                  <S.TicketInfoBox>
                    <S.TicketInfo>
                      <div className="contentInfoBox">
                        <h1>Ingresso:</h1> <p>{customerTicket.ticket.name}</p>
                      </div>
                      {selectedPurchaseOrder.eventItem.event.type ===
                      'MAPPED' ? (
                        <div className="contentInfoBox">
                          <h1>Setor:</h1>{' '}
                          <p>{customerTicket.ticket.category}</p>
                        </div>
                      ) : null}
                      {selectedPurchaseOrder.eventItem.event.type ===
                      'MAPPED' ? (
                        <div className="contentInfoBox">
                          <h1>Lugar:</h1> <p>{customerTicket.seatId}</p>
                        </div>
                      ) : null}
                    </S.TicketInfo>
                    <S.PurchaseData>
                      <div className="purchaseDate">
                        <h1>Data da compra:</h1>{' '}
                        <p>
                          {moment(
                            selectedPurchaseOrder?.updatedAt ||
                              selectedPurchaseOrder?.createdAt
                          ).format('L') +
                            ' às ' +
                            moment(
                              selectedPurchaseOrder?.updatedAt ||
                                selectedPurchaseOrder?.createdAt
                            ).format('LT')}
                        </p>
                      </div>
                      <div className="contentPurchaseData">
                        <h1>Valor:</h1>{' '}
                        <p>
                          {formatPrice(
                            Number(customerTicket.ticket.priceCents) / 100
                          )}
                        </p>
                      </div>
                    </S.PurchaseData>
                  </S.TicketInfoBox>
                  <S.InfoCustomer>
                    {selectedPurchaseOrder.eventItem.ticketInfo.length > 0 &&
                    customerTicket.ticketInfo !== null
                      ? selectedPurchaseOrder.eventItem.ticketInfo.map(
                          (ticket, index) =>
                            index < 3 ? (
                              <div key={index} className="contentInfoCustomer">
                                <h1>{ticket.value}:</h1>{' '}
                                <p>{customerTicket.ticketInfo[ticket.value]}</p>
                              </div>
                            ) : null
                        )
                      : undefined}
                  </S.InfoCustomer>

                  <S.QrCode>
                    <div className="circleTop" />
                    <p className="contentTicket">
                      {index + 1} de{' '}
                      {selectedPurchaseOrder.customerTickets.length} ingressos
                    </p>
                    <img src={qrCodeImage[index]} />
                    <p className="idQrCode">
                      {
                        selectedPurchaseOrder.customerTickets[index]
                          .customerTicketId
                      }
                    </p>
                    <div className="circleBottom" />
                  </S.QrCode>
                </S.Ticket>
                <div className="circleBottom" />
              </S.TicketBox>
            )
          )}
        </S.ContentTickets>
      </S.Wrapper>
    </S.Container>
  )
}
