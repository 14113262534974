import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    min-width: 320px;
    overflow-y: auto;
    .titleBox {
      display: flex;
      justify-content: center;
    }
  `}
`

export const ContentNoTicket = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${theme.spacing.stack.xxs};
  `}
`

export const TicketImage = styled.img`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.stack.xxs};
    @media (min-width: 768px) and (max-width: 1170px) {
      width: 226px;
      height: 226px;
      margin-bottom: ${theme.spacing.stack.xs};
    }
  `}
`

export const TitleNoTicket = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.semiBold};
    line-height: ${theme.spacing.stack.xxs};
    color: #adadad;
    text-align: center;

    @media (min-width: 768px) and (max-width: 1170px) {
      font-size: ${theme.font.size.lg};
      font-weight: ${theme.font.weight.semiBold};
      line-height: ${theme.spacing.stack.sm};
    }
  `}
`

export const Cards = styled.div`
  @media (min-width: 768px) and (max-width: 1170px) {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    img {
      width: 340px;
      height: 280px;
      border-radius: 12px;
    }
  }
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.stack.xxs};
    @media (min-width: 768px) and (max-width: 1170px) {
      text-align: center;
    }
  `}
`

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #39383a;
    margin-bottom: ${theme.spacing.stack.xs};
  `}
`

export const TotalRequest = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    background: #f4f4f4;
    padding: ${theme.spacing.stack.small};
    margin: ${theme.spacing.stack.xxxs} 0;
    border-radius: 6px;

    @media (min-width: 768px) and (max-width: 1170px) {
      display: flex;
      justify-content: space-between;
      padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
      background: #f4f4f4;
      border-radius: ${theme.border.radius.medium};
    }
  `}
`

export const TextRequest = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xxs};
    font-weight: ${theme.font.weight.semiBold};
    line-height: ${theme.spacing.stack.xxxs};
    color: #39383a;

    @media (min-width: 768px) and (max-width: 1170px) {
      font-weight: ${theme.font.weight.semiBold};
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.xxs};
    }
  `}
`

export const TextTotalRequest = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xxs};
    font-weight: ${theme.font.weight.semiBold};
    line-height: ${theme.spacing.stack.xxxs};
    color: #808080;

    @media (min-width: 768px) and (max-width: 1170px) {
      font-weight: ${theme.font.weight.semiBold};
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.xxs};
      color: #808080;
    }
  `}
`

export const CardsTickets = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-top: ${theme.spacing.stack.xxxs};

    @media (min-width: 768px) and (max-width: 1170px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: ${theme.spacing.stack.xxs};
      margin-top: ${theme.spacing.stack.xxxs};

      img {
        min-width: 340px;
        max-width: 340px;
        height: 283px;
        border-radius: ${theme.border.radius.medium};
      }
    }
  `}
`

export const TotalRequestClosed = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    background: #f4f4f4;
    padding: ${theme.spacing.stack.small};
    margin-top: ${theme.spacing.stack.xxxs};
    border-radius: ${theme.border.radius.medium};

    @media (min-width: 768px) and (max-width: 1170px) {
      margin-top: ${theme.spacing.stack.xxs};
      display: flex;
      justify-content: space-between;
      padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
      background: #f4f4f4;
      border-radius: ${theme.border.radius.medium};
    }
  `}
`

export const TextRequestClosed = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xxs};
    font-weight: ${theme.font.weight.semiBold};
    line-height: ${theme.spacing.stack.xxxs};
    color: #39383a;

    @media (min-width: 768px) and (max-width: 1170px) {
      font-weight: ${theme.font.weight.semiBold};
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.xxs};
    }
  `}
`

export const TextTotalRequestClosed = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xxs};
    font-weight: ${theme.font.weight.semiBold};
    line-height: ${theme.spacing.stack.xxxs};
    color: #808080;

    @media (min-width: 768px) and (max-width: 1170px) {
      font-weight: ${theme.font.weight.semiBold};
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.xxs};
      color: #808080;
    }
  `}
`

export const SeeMore = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    text-align: end;
    font-size: ${theme.font.size.xs};
    font-weight: ${theme.font.weight.semiBold};
    line-height: ${theme.spacing.stack.xxxs};
    color: #6c01a3;
  `}
`
