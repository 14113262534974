import { all, call, put, takeLatest, delay } from '@redux-saga/core/effects'
import { PayloadAction } from 'typesafe-actions'
import {
  CreateBackofficeRequest,
  CreateBackofficeResponse,
  createBackofficeService
} from '../../../api/services/userBackoffice/createBackofficeService'
import { deleteBackofficeService } from '../../../api/services/userBackoffice/deleteBackofficeService'
import {
  EditBackofficeRequest,
  EditBackofficeResponse,
  editBackofficeService
} from '../../../api/services/userBackoffice/editBackofficeService'
import {
  AllUsersResponse,
  getUsersService
} from '../../../api/services/userBackoffice/getBackofficeService'
import { messageHelper } from '../../../utils/messageHelper'
import { clearLoading, setLoading } from '../Loading/actions'
import { setModalState } from '../Modal/Actions/actionsModal'
import { store } from '../store'
import {
  createBackofficeSuccess,
  deleteBackofficeSuccess,
  editBackofficeSuccess,
  getBackofficeSuccess
} from './actions'
import { BackofficeActions } from './types'

function* getUsersBackoffice(
  action: PayloadAction<
    BackofficeActions.GET_BACKOFFICE_REQUEST,
    { skip: number; currentPage: number }
  >
) {
  yield put(setLoading(true))
  try {
    const { getState } = store
    const { take } = getState().Backoffice

    const data: AllUsersResponse = yield call(
      getUsersService,
      action.payload.skip,
      take
    )
    yield put(
      getBackofficeSuccess(
        data,
        action.payload.skip,
        action.payload.currentPage
      )
    )
    yield delay(Number(process.env.REACT_APP_DELAY_REQUEST))
    yield put(clearLoading())
  } catch (err) {
    yield put(clearLoading())
    yield put(
      setModalState({
        open: true,
        message: messageHelper.modal.userBackoffice.error.loadUserBackoffice,
        variant: 'error'
      })
    )
  }
}

function* createBackoffice(
  action: PayloadAction<
    BackofficeActions.CREATE_BACKOFFICE_REQUEST,
    CreateBackofficeRequest
  >
) {
  yield put(setLoading(true))
  try {
    const data: CreateBackofficeResponse = yield call(createBackofficeService, {
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      email: action.payload.email,
      password: action.payload.password,
      groups: action.payload.groups
    })

    yield put(createBackofficeSuccess(data))

    yield delay(Number(process.env.REACT_APP_DELAY_REQUEST))
    yield put(clearLoading())

    yield put(
      setModalState({
        open: true,
        message:
          messageHelper.modal.userBackoffice.success.createUserBackoffice,
        variant: 'success'
      })
    )
  } catch (err) {
    yield put(clearLoading())
    yield put(
      setModalState({
        open: true,
        message: messageHelper.modal.userBackoffice.error.createUserBackoffice,
        variant: 'error'
      })
    )
  }
}

function* editBackoffice(
  action: PayloadAction<
    BackofficeActions.EDIT_BACKOFFICE_REQUEST,
    EditBackofficeRequest
  >
) {
  try {
    const data: EditBackofficeResponse = yield call(editBackofficeService, {
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      email: action.payload.email,
      groups: action.payload.groups
    })
    yield put(editBackofficeSuccess(data))
    yield put(
      setModalState({
        open: true,
        message: 'Backoffice editado com sucesso.',
        variant: 'success'
      })
    )
  } catch (err) {
    yield put(
      setModalState({
        open: true,
        message: 'Não foi possível alterar o backoffice.',
        variant: 'error'
      })
    )
  }
}

function* deleteBackoffice(
  action: PayloadAction<BackofficeActions.DELETE_BACKOFFICE_REQUEST, string[]>
) {
  yield put(setLoading(true))
  try {
    yield Promise.allSettled(
      action.payload.map((backofficeId) =>
        deleteBackofficeService(backofficeId)
      )
    )

    yield put(deleteBackofficeSuccess(action.payload))

    yield delay(Number(process.env.REACT_APP_DELAY_REQUEST))
    yield put(clearLoading())

    yield put(
      setModalState({
        open: true,
        message: `${
          action.payload.length > 1 ? 'Usuários deletados' : 'Usuário deletado'
        } com sucesso.`,
        variant: 'success'
      })
    )
  } catch (err) {
    yield put(clearLoading())

    yield put(
      setModalState({
        open: true,
        message: `Não foi possível deletar ${
          action.payload.length > 1 ? 'os usuários' : 'o usuário'
        }.`,
        variant: 'error'
      })
    )
  }
}

export const sagaBackoffice = all([
  takeLatest(BackofficeActions.GET_BACKOFFICE_REQUEST, getUsersBackoffice),
  takeLatest(BackofficeActions.CREATE_BACKOFFICE_REQUEST, createBackoffice),
  takeLatest(BackofficeActions.EDIT_BACKOFFICE_REQUEST, editBackoffice),
  takeLatest(BackofficeActions.DELETE_BACKOFFICE_REQUEST, deleteBackoffice)
])
