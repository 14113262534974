import { AlertModal, Button } from 'applaus-ui-kit'
import _ from 'lodash'
import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AuthenticateTemplate } from '../../components/templates/AuthenticateTemplate'
import {
  deleteCreatorRequest,
  getSearchCreatorRequest
} from '../../redux/store/Creators/Actions/creatorActions'
import { Creator } from '../../redux/store/Creators/types'
import { CreatorModal } from './CreatorModal/CreatorModal'

import * as S from './Creators.styles'
import { CreatorTable } from './CreatorTable/CreatorTable'

export type ModalState = {
  open: boolean
  type: 'CREATE' | 'EDIT' | 'DELETE'
  creator?: Creator
}

type AlertModalState = {
  buttonText: string
  click: () => void
  message: string
  open: boolean
  variant: 'error' | 'success'
  cancelClick?: () => void
  description?: string
}

export const Creators = () => {
  const dispatch = useDispatch()
  const [searchInput, setSearchInput] = useState('')
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    type: 'CREATE'
  })
  const [alertModalState, setAlertModalState] = useState<AlertModalState>({
    buttonText: '',
    click: () => {},
    message: '',
    open: false,
    variant: 'error'
  })

  const resetAlertModalState = () => {
    setAlertModalState({
      buttonText: '',
      click: () => {},
      message: '',
      open: false,
      variant: 'error'
    })
  }

  const setEditCreator = (creator: Creator) => {
    setModalState({
      open: true,
      type: 'EDIT',
      creator
    })
  }

  const deleteCreator = (creator: Creator) => {
    if (creator) {
      setAlertModalState({
        buttonText: 'Deletar',
        click: () => {
          dispatch(deleteCreatorRequest([creator.id]))
          resetAlertModalState()
        },
        message: 'Deseja mesmo deletar o produtor?',
        open: true,
        variant: 'error',
        cancelClick: resetAlertModalState
      })
    }
  }

  useEffect(() => {
    if (searchInput.trim().length > 2) {
      dispatch(
        getSearchCreatorRequest(
          searchInput.replaceAll('.', '').replace('-', '').replace('/', '')
        )
      )
    }
  }, [searchInput])

  const debounceSearch = _.debounce(function (param: string) {
    setSearchInput(param)
  }, 2000)

  const handleCustomerSearch = (event: ChangeEvent<HTMLInputElement>) => {
    debounceSearch(event.target.value)
  }

  const closeModal = () => {
    setModalState({
      open: false,
      type: 'CREATE'
    })
  }

  return (
    <>
      <AuthenticateTemplate>
        <S.Container>
          <S.Header>
            <div>
              <S.UserIcon />
              <S.Title>Produtores</S.Title>
            </div>

            <div>
              <Button
                color="primary"
                fullWidth
                variant="contained"
                size="large"
                onClick={() => setModalState({ open: true, type: 'CREATE' })}>
                Cadastrar produtor
              </Button>
            </div>
          </S.Header>
          <div className="inputBox">
            <S.SearchIcon />
            <S.Searchnput
              placeholder="Procure produtores por nome, documento ou email"
              onChange={handleCustomerSearch}
            />
          </div>
          <CreatorTable
            searchInput={searchInput}
            setEditCreator={setEditCreator}
            deleteCreator={deleteCreator}
          />
        </S.Container>
      </AuthenticateTemplate>
      <CreatorModal modalState={modalState} closeModal={closeModal} />
      <AlertModal
        buttonText={alertModalState.buttonText}
        click={alertModalState.click}
        message={alertModalState.message}
        open={alertModalState.open}
        variant={alertModalState.variant}
        cancelClick={alertModalState.cancelClick}
        description={alertModalState.description}
      />
    </>
  )
}
