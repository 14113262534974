import styled, { css } from 'styled-components'

export type MenuProps = {
  open: boolean
}

export const Menu = styled.menu<MenuProps>`
  ${({ open, theme }) => css`
    transition: opacity 0.3s ease-in-out;
    opacity: ${open ? '1' : '0'};
    pointer-events: ${open ? 'all' : 'none'};
    background: ${theme.color.neutral.nth4};
    position: fixed;
    overflow-y: auto;
    display: block;
    height: 95px;
    width: 125px;
    top: 0;
    right: 20px;
    margin-top: 70px;
    padding: ${theme.spacing.stack.xxs};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
    z-index: 1000;
    border-radius: 0 0 8px 8px;
  `}
`
