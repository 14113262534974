/* eslint-disable no-unused-vars */
export enum CustomersActions {
  GET_SEARCH_CUSTOMER_SUCCESS = '@creator/GET_SEARCH_CUSTOMER_SUCCESS',
  GET_SEARCH_CUSTOMER_REQUEST = '@creator/GET_SEARCH_CUSTOMER_REQUEST',
  GET_CUSTOMERS_REQUEST = '@creator/GET_CUSTOMERS_REQUEST',
  GET_CUSTOMERS_SUCCESS = '@creator/GET_CUSTOMERS_SUCCESS',
  LOAD_CUSTOMERS_FAILURE = '@customer/LOAD_CUSTOMERS_FAILURE',
  CREATE_CUSTOMER_REQUEST = '@customer/CREATE_CUSTOMER_REQUEST',
  CREATE_CUSTOMER_SUCCESS = '@customer/CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_FAILURE = '@customer/CREATE_CUSTOMER_FAILURE',
  EDIT_CUSTOMER_REQUEST = '@creator/EDIT_CUSTOMER_REQUEST',
  EDIT_CUSTOMER_SUCCESS = '@creator/EDIT_CUSTOMER_SUCCESS',
  DELETE_CUSTOMER_REQUEST = '@creator/DELETE_CUSTOMER_REQUEST',
  DELETE_CUSTOMER_SUCCESS = '@creator/DELETE_CUSTOMER_SUCCESS'
}

export type Customer = {
  id: string
  userId?: string
  firstName: string
  lastName: string
  email: string
  document: string
  password: string
  phone?: string
  address?: {
    street: string
    state: string
    city: string
    number: number
    country: string
    complement?: string
    zipCode?: string
  }
  createdAt?: string
  updatedAt?: string
}

export type CustomerState = {
  readonly data: {
    items: Customer[]
    total: number
  }
  currentPage: number
  skip: number
  take: number
}
