import produce from 'immer'
import { Reducer } from 'redux'
import { CoursesActions, CoursesState } from './types'

const initialState: CoursesState = {
  data: {
    items: [],
    total: 0
  },
  currentPage: 1,
  skip: 0,
  take: 5
}

export const Courses: Reducer<CoursesState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CoursesActions.GET_COURSES_SUCCESS:
      return produce(state, (draftState) => {
        draftState.data = action.payload.data
        draftState.skip = action.payload.skip
        draftState.currentPage = action.payload.currentPage
      })
    case CoursesActions.EDIT_COURSE_SUCCESS:
      return produce(state, (draftState) => {
        const index = draftState.data.items.findIndex(
          (a) => a.id === action.payload.id
        )
        if (index > -1) {
          draftState.data.items[index] = action.payload
        }
      })
    case CoursesActions.DELETE_COURSE_SUCCESS:
      return produce(state, (draftState) => {
        action.payload.forEach(({ courseId }: { courseId: string }) => {
          const index = draftState.data.items.findIndex(
            (a) => a.id === courseId
          )

          if (index > -1) {
            draftState.data.items.splice(index, 1)
          }
        })
      })
    default:
      return state
  }
}
