import styled, { css } from 'styled-components'

import { ReactComponent as BackFile } from '../../assets/icons/backIcon.svg'

export const BackIcon = styled(BackFile)``

export const BackButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;

    .back-text {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      text-align: left;
    }
  `}
`
export const TextWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: ${theme.spacing.inline.small};
  `}
`
