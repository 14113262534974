import * as S from './EventDescription.styles'
import { Heading, Paragraph, Button } from 'applaus-ui-kit'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setModalState } from '../../../../redux/store/Modal/Actions/actionsModal'
import { ReduxState } from '../../../../redux/store/rootReducer'
import { RichText } from '../../../../components/RichText/RichText'
import {
  editDescriptionRequestAction,
  editStepBack
} from '../../../../redux/store/EditEvent/editActions'
import {
  clearLoading,
  setLoading
} from '../../../../redux/store/Loading/actions'

export const EventDescription = () => {
  const dispatch = useDispatch()
  const { editEvent } = useSelector(
    (state: ReduxState) => state.EditEventReducer
  )
  const editorRef = useRef<any>(null)
  const onSubmit = () => {
    const characterCount =
      editorRef.current.plugins.wordcount.body.getCharacterCountWithoutSpaces()
    if (characterCount > 0 && characterCount < 3) {
      dispatch(
        setModalState({
          message: 'Descrição deve ter pelo menos 3 caracteres',
          variant: 'error',
          open: true,
          buttonText: 'Fechar'
        })
      )
    } else if (characterCount > 0 && characterCount > 255) {
      dispatch(
        setModalState({
          message: 'Descrição deve ter no máximo 255 caracteres',
          variant: 'error',
          open: true,
          buttonText: 'Fechar'
        })
      )
    } else {
      dispatch(editDescriptionRequestAction(editorRef.current.getContent()))
    }
  }

  const onLoadRichText = () => {
    dispatch(clearLoading())
  }

  useEffect(() => {
    dispatch(setLoading())
  }, [])

  return (
    <S.Container>
      <S.Wrapper>
        <S.TitleBox>
          <S.BackIcon onClick={() => dispatch(editStepBack())} />
          <Heading variant="h3">Descrição do evento</Heading>
        </S.TitleBox>
        <Paragraph variant="large">
          Chegou o momento de falar sobre seu evento. Conte todos os <br />
          detalhes, como a programação, regras e classificação.
        </Paragraph>

        <Paragraph variant="large">Máximo de caracteres: 250</Paragraph>

        <RichText
          editorRef={editorRef}
          initialValue={editEvent?.description}
          onLoadContent={onLoadRichText}
        />
        <Button
          onClick={onSubmit}
          variant="contained"
          fullWidth={false}
          type="button"
          size="medium"
          color="primary">
          Editar
        </Button>
      </S.Wrapper>
    </S.Container>
  )
}
