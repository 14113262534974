import * as Yup from 'yup'
import { messageHelper } from '../../../utils/messageHelper'
import { validateImageRatio } from '../../../utils/validateImageRatio'

export const validationSchemaCreate = Yup.object({
  nameBanner: Yup.string().required(messageHelper.yup.banner.nameIsMandatory),
  descriptionBanner: Yup.string().required(
    messageHelper.yup.banner.descriptionIsMandatory
  ),
  link: Yup.string().required('Link é obrigatório'),
  desktopUrl: Yup.mixed()
    .required('Banner obrigatório')
    .test('aspectRatio', 'Imagem inválida', (value) => {
      return validateImageRatio(value, 1336 / 598)
    }),
  tabletUrl: Yup.mixed()
    .required('Banner obrigatório')
    .test('aspectRatio', 'Imagem inválida', (value) => {
      return validateImageRatio(value, 768 / 375)
    }),
  mobileUrl: Yup.mixed()
    .required('Banner obrigatório')
    .test('aspectRatio', 'Imagem inválida', (value) => {
      return validateImageRatio(value, 320 / 210)
    })
})

export const validationSchemaEdit = Yup.object({
  nameBanner: Yup.string().required(messageHelper.yup.banner.nameIsMandatory),
  descriptionBanner: Yup.string().required(
    messageHelper.yup.banner.descriptionIsMandatory
  ),
  desktopUrl: Yup.mixed()
    .required('Banner obrigatório')
    .test('aspectRatio', 'Imagem inválida', (value) => {
      console.log(value, 'value')

      if (typeof value === 'string') {
        return true
      } else {
        return validateImageRatio(value, 1336 / 598)
      }
    }),
  tabletUrl: Yup.mixed()
    .required('Banner obrigatório')
    .test('aspectRatio', 'Imagem inválida', (value) => {
      if (typeof value === 'string') {
        return true
      } else {
        return validateImageRatio(value, 768 / 375)
      }
    }),
  mobileUrl: Yup.mixed()
    .required('Banner obrigatório')
    .test('aspectRatio', 'Imagem inválida', (value) => {
      if (typeof value === 'string') {
        return true
      } else {
        return validateImageRatio(value, 320 / 210)
      }
    })
})
