import { combineReducers } from 'redux'

import { Banner } from './Banners/reducer'
import { ModalReducer } from './Modal/reducer'
import { Customer } from './Customers/reducer'
import { Creator } from './Creators/reducer'
import { Courses } from './Courses/reducer'
import { UserReducer } from './User/reducer'
import { Charts } from './Charts/reducer'
import { Backoffice } from './UserBackoffice/reducer'
import { reducerEvent } from './Events/reducer'
import { LoadingReducer } from './Loading/reducer'
import { EditEventReducer } from './EditEvent/reducer'
import { ManageEventReducer } from './ManageEvent/reducer'
import { PurchasesReducer } from './Purchases/reducer'
import { WithdrawalsReducer } from './Withdrawals/reducer'

export const rootReducer = combineReducers({
  Banner,
  EditEventReducer,
  ModalReducer,
  Customer,
  Creator,
  Courses,
  UserReducer,
  Charts,
  Backoffice,
  reducerEvent,
  LoadingReducer,
  ManageEventReducer,
  PurchasesReducer,
  WithdrawalsReducer
})

export type ReduxState = ReturnType<typeof rootReducer>
