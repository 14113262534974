import * as S from './PaymentTicket.styles'

import {
  Container as GridContainer,
  Row,
  Column
} from '../../components/grid/GridSystem'

import { BackButton } from '../../components/BackButton/BackButton'
import history from '../../routes/services/history'
import { motion } from 'framer-motion'
import { TicketComponent } from '../../components/Ticket/Ticket'
import moment from 'moment'
import { formatPrice } from '../../utils/formatPrice'
import { useSelector } from 'react-redux'
import { ReduxState } from '../../redux/store/rootReducer'
import { Redirect } from 'react-router-dom'
import DinersFile from '../../assets/icons/diners.svg'
import MastercardFile from '../../assets/icons/mastercard.svg'
import EloFile from '../../assets/icons/elo.svg'
import AmexFile from '../../assets/icons/amex.svg'
import VisaFile from '../../assets/icons/visa.svg'
import PixFile from '../../assets/icons/pix.svg'
import CashbackFile from '../../assets/icons/cashback.svg'

import { AuthenticateTemplate } from '../../components/templates/AuthenticateTemplate'

export const PaymentTicket = () => {
  const { paymentTicket } = useSelector(
    (state: ReduxState) => state.ManageEventReducer
  )

  const handleClickBack = () => {
    history.push(`manageEvent/${localStorage.getItem('eventId')}/payments`)
  }

  const iconBrand =
    paymentTicket?.paymentHistory?.brand === 'Visa'
      ? VisaFile
      : paymentTicket?.paymentHistory?.brand === 'Master'
      ? MastercardFile
      : paymentTicket?.paymentHistory?.brand === 'Elo'
      ? EloFile
      : paymentTicket?.paymentHistory?.brand === 'Diners'
      ? DinersFile
      : paymentTicket?.paymentHistory?.brand === 'Amex'
      ? AmexFile
      : paymentTicket?.paymentHistory?.brand === 'PIX'
      ? PixFile
      : paymentTicket?.paymentHistory?.paymentMethod === 'CASH_BACK'
      ? CashbackFile
      : paymentTicket?.paymentHistory?.paymentMethod === 'FREE'
      ? ''
      : ''

  return (
    <AuthenticateTemplate>
      {paymentTicket ? (
        <S.Container>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}>
            <GridContainer>
              <Row marginTop="xxxl" marginBottom="xs">
                <Column tablet={8} desktop={12}>
                  <BackButton onClick={handleClickBack} />
                </Column>
              </Row>
              <Row marginBottom="sm">
                <p className="title">Usuário</p>
                <Column tablet={4} desktop={4}>
                  <S.UserInfo>
                    <p className="user"> Nome</p>
                    <p className="user-info-text">
                      {paymentTicket.customer.firstName}
                    </p>
                  </S.UserInfo>
                </Column>
                <Column tablet={4} desktop={4}>
                  <S.UserInfo>
                    <p className="user">Email</p>
                    <p className="user-info-text">
                      {paymentTicket.customer.email ?? ''}
                    </p>
                  </S.UserInfo>
                </Column>
              </Row>
              <Row marginBottom="md">
                <p className="title">Pagamento</p>
                <Column tablet={4} desktop={6}>
                  <S.PaymentInfo>
                    <div>
                      <p className="payment">Pagamento:</p>
                      <p className="payment status">
                        {paymentTicket.status === 'SUCCESS'
                          ? 'Aprovado'
                          : paymentTicket.status === 'WAITING'
                          ? 'Em processamento'
                          : 'Recusado'}
                      </p>
                    </div>
                    <span>
                      Realizado em{' '}
                      {moment(
                        paymentTicket.paymentHistory!.updatedAt
                          ? paymentTicket.paymentHistory!.updatedAt
                          : paymentTicket.paymentHistory!.createdAt
                      ).format('ll')}
                    </span>
                  </S.PaymentInfo>
                </Column>
                <Column tablet={4} desktop={6}>
                  <S.PaymentPricing>
                    <p>
                      {paymentTicket.paymentHistory?.paymentMethod === 'FREE'
                        ? 'Gratuito'
                        : paymentTicket.paymentHistory?.paymentMethod ===
                          'CREDIT_CARD'
                        ? formatPrice(
                            paymentTicket.paymentHistory!.totalPrice / 100
                          )
                        : paymentTicket.paymentHistory?.installments !== null
                        ? ` em ${paymentTicket.paymentHistory?.installments}x`
                        : ' à vista'}
                    </p>
                    <span>
                      {iconBrand === '' ? null : <img src={iconBrand} />}
                      {paymentTicket.paymentHistory!.paymentMethod === 'FREE'
                        ? 'Gratuito'
                        : paymentTicket.paymentHistory!.paymentMethod === 'PIX'
                        ? 'PIX'
                        : paymentTicket.paymentHistory!.paymentMethod ===
                          'CASH_BACK'
                        ? 'Cashback'
                        : `${paymentTicket.paymentHistory!.brand} - final ${
                            paymentTicket.paymentHistory!.lastCardNumbers
                          }`}
                    </span>
                  </S.PaymentPricing>
                </Column>
              </Row>
              <Row>
                {paymentTicket.customerTickets.map((ticket) => (
                  <Column key={ticket.id} tablet={8} desktop={12}>
                    <TicketComponent
                      eventName={ticket.ticket.name}
                      address={{
                        street: ticket.eventItem.event.address.street,
                        city: ticket.eventItem.event.address.city
                      }}
                      date={ticket.eventItem.dates[0]}
                      ticketName={ticket.ticket.name}
                      ticketCategory={ticket.ticket.category}
                      eventType={'TABULATED'}
                      seatId={ticket.seatId}
                      purchaseDate={paymentTicket.paymentHistory!.createdAt}
                      ticketLocale={ticket.ticket.type}
                      ticketPrice={
                        (ticket.ticket.priceCents -
                          ticket.ticket.discountCents) /
                        100
                      }
                      ticketInfo={ticket.ticketInfo}
                      customerTicketId={ticket.customerTicketId}
                    />
                  </Column>
                ))}
              </Row>
            </GridContainer>
          </motion.div>
        </S.Container>
      ) : (
        <Redirect to="/events" />
      )}
    </AuthenticateTemplate>
  )
}
