import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { TabProps } from './Tab'

export const Tab = styled.div<TabProps>`
  ${({ widthTab }) => css`
    width: ${widthTab?.mobile}%;
    height: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin: 3rem 0 0 0;
    cursor: pointer;
    ${media.greaterThan('small')`width: ${widthTab?.tablet}%`}
    ${media.greaterThan('large')`width: ${widthTab?.desktop}%`}
  `}
`

export const Title = styled.h2<TabProps>`
  ${({ theme, colorTab }) => css`
    font-weight: ${colorTab
      ? `${theme.font.weight.bold}`
      : `${theme.font.weight.regular}`};
    font-size: ${theme.font.size.sm};
    color: ${colorTab
      ? `${theme.color.brand.tertiary.nth1}`
      : theme.color.base.nth3};
  `}
`

export const Line = styled.div<TabProps>`
  ${({ theme, colorTab }) => css`
    border-bottom: ${colorTab
      ? `${theme.border.width.thin} solid ${theme.color.brand.tertiary.nth1}`
      : 'none'};
    width: 100%;
    background: ${theme.color.brand.tertiary.nth4};
    margin-top: 1.5rem;
  `}
`
