/* eslint-disable no-unused-vars */
export enum BannersActions {
  LOAD_BANNERS_REQUEST = '@banners/LOAD_BANNERS_REQUEST',
  LOAD_BANNERS_SUCCESS = '@banners/ LOAD_BANNERS_SUCCESS',
  CREATE_BANNER_REQUEST = '@banners/CREATE_BANNER_REQUEST',
  CREATE_BANNER_SUCCESS = '@banners/CREATE_BANNER_SUCCESS',
  EDIT_BANNER_REQUEST = '@banners/EDIT_BANNER_REQUEST',
  EDIT_BANNER_SUCCESS = '@banners/EDIT_BANNER_SUCCESS',
  DELETE_BANNER_REQUEST = '@banners/DELETE_BANNER_REQUEST',
  DELETE_BANNER_SUCCESS = '@banners/DELETE_BANNER_SUCCESS'
}

export type Banner = {
  id: string
  name: string
  description: string
  link: string
  position: number
  status: boolean
  linkTarget: 'INTERNAL' | 'EXTERNAL'
  desktopUrl: string
  mobileUrl: string
  tabletUrl: string
  createdAt: string
  updatedAt: string
}

export type BannerState = {
  readonly data: Banner[]
}
