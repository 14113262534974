import { useEffect, useMemo, useState } from 'react'
import * as _ from 'lodash'
// @ts-ignore
import { SeatsioSeatingChart } from '@seatsio/seatsio-react'
import { formatPrice } from '../../../../utils/formatPrice'
import { EventItems } from '../../../../redux/store/EditEvent/types'
import produce from 'immer'

type ChartCategories = {
  label: string
}

type ChartSeats = {
  seatId: string
  category: {
    label: string
  }
  selectedTicketType: string
  pricing: {
    ticketTypes: [
      {
        price: number
        ticketType: string
      }
    ]
  }
  labels: {
    own: string
    parent: string
    section: string
    displayedLabel: string
  }
}

type Chart = {
  listCategories: () => Promise<ChartCategories[]>
  listSelectedObjects: () => Promise<ChartSeats[]>
  destroy: () => void
}

type Props = {
  eventItem: EventItems
}

export const MappedEvent = ({ eventItem }: Props) => {
  let chart: Chart | null = null

  const [categories, setCategories] = useState<string[]>([])
  const [unavailableCategories, setUnavailableCategories] = useState<string[]>(
    []
  )

  const generatePrice = useMemo(() => {
    const groupedTickets = _.groupBy(
      eventItem?.tickets,
      (ticket) => ticket.category
    )
    const ticketTypes = _.mapValues(groupedTickets, function (value, key) {
      return value.flatMap((ticket) => ({
        ticketType: ticket.type,
        price: formatPrice((ticket.priceCents - ticket.discountCents) / 100)
      }))
    })

    for (const category of categories) {
      if (ticketTypes[category] === undefined) {
        ticketTypes[category] = [
          { ticketType: 'Adulto', price: formatPrice(0) }
        ]

        setUnavailableCategories(
          produce((draft) => {
            draft.push(category)
          })
        )
      }
    }
    return categories.map((category, index) => ({
      category: index + 1,
      ticketTypes: _.values(_.pick(ticketTypes, category))[0]
    }))
  }, [categories])

  useEffect(() => {
    if (chart) {
      // @ts-ignore
      chart.changeConfig({
        unavailableCategories: unavailableCategories
      })
    }
  }, [chart, unavailableCategories])

  return (
    <SeatsioSeatingChart
      mode="static"
      workspaceKey={process.env.REACT_APP_PUBLIC_KEY_WORKSPACE}
      event={eventItem.eventKey}
      onRenderStarted={async (createdChart: any) => {
        chart = createdChart
        if (chart !== null) {
          const getCategories = await chart.listCategories()
          setCategories(getCategories.map(({ label }) => label))
        }
      }}
      maxSelectedObjects={eventItem?.maxCustomerTickets}
      region="sa"
      language="pt"
      multiSelectEnabled={true}
      showSectionPricingOverlay={true}
      pricing={[...generatePrice]}
    />
  )
}
