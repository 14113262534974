import { useEffect, useState } from 'react'
import { AuthenticateTemplate } from '../../components/templates/AuthenticateTemplate'
import * as S from './Withdrawals.styles'
import { WithdrawalsTable } from './WithdrawalsTable/WithdrawalsTable'
import { useDispatch } from 'react-redux'
import { Withdrawals } from '../../redux/store/Withdrawals/types'
import { listWithdrawalsRequestAction } from '../../redux/store/Withdrawals/actions'
import { WithdrawModal } from './WithdrawModal/WithdrawModal'

export type ModalState = {
  open: boolean
  withdrawal?: Withdrawals
}

export const WithdrawalsPage = () => {
  const dispatch = useDispatch()

  const [modalState, setModalState] = useState<ModalState>({ open: false })

  const withdrawalsEdit = (withdrawal: Withdrawals) => {
    setModalState({ open: true, withdrawal })
  }

  useEffect(() => {
    dispatch(listWithdrawalsRequestAction())
  }, [])

  const handleCloseModal = () => {
    setModalState({ open: false, withdrawal: undefined })
  }

  return (
    <>
      <AuthenticateTemplate>
        <S.Container>
          <S.Header>
            <div>
              <S.CourseIcon />
              <S.Title>Solicitações de saque</S.Title>
            </div>
          </S.Header>
          <div className="inputBox">
            <S.SearchIcon />
            <S.Searchnput placeholder="Procure clientes por nome, id ou email" />
          </div>
          <WithdrawalsTable setWithdraw={withdrawalsEdit} />
        </S.Container>
      </AuthenticateTemplate>
      <WithdrawModal modalState={modalState} closeModal={handleCloseModal} />
    </>
  )
}
