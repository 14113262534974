import { EventData, ManageCustomerTicket, ManageEventsActions } from './types'
import { Reducer } from 'redux'
import produce from 'immer'
import { ChangeCustomerPagination, ChangePurchasePagination } from './actions'

const INITIAL_STATE: ManageCustomerTicket = {
  data: null,
  customerTicket: null,
  paymentTicket: null,
  purchaseOrders: {
    items: [],
    total: 0,
    skip: 0,
    take: 10
  },
  customerTickets: {
    items: [],
    total: 0,
    skip: 0,
    take: 10
  },
  summaryData: null,
  eventData: null
}

export const ManageEventReducer: Reducer<ManageCustomerTicket> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case ManageEventsActions.LOAD_EVENT_DATA_SUCCESS:
      return produce(state, (draft) => {
        const {
          customerTickets,
          summary,
          purchasesOrders,
          eventData
        }: EventData = action.payload

        draft.customerTickets = {
          ...draft.customerTickets,
          items: customerTickets?.items ?? [],
          total: customerTickets?.total ?? 0
        }
        draft.purchaseOrders = {
          ...draft.purchaseOrders,
          items: purchasesOrders?.items ?? [],
          total: purchasesOrders?.total ?? 0
        }
        draft.eventData = eventData
        draft.summaryData = summary
      })

    case ManageEventsActions.CHANGE_CUSTOMER_TICKET_PAGINATION_STEP_SUCCESS:
      return produce(state, (draft) => {
        const { skip, items }: ChangeCustomerPagination = action.payload
        draft.customerTickets = {
          ...draft.customerTickets,
          skip,
          items
        }
      })
    case ManageEventsActions.CHANGE_PURCHASES_PAGINATION_STEP_SUCCESS:
      return produce(state, (draft) => {
        const { skip, items }: ChangePurchasePagination = action.payload
        draft.purchaseOrders = {
          ...draft.purchaseOrders,
          skip,
          items
        }
      })
    case ManageEventsActions.LOAD_CUSTOMER_TICKET:
      return produce(state, (draft) => {
        draft.customerTicket = action.payload
      })
    case ManageEventsActions.SET_CUSTOMER_TICKET:
      return produce(state, (draft) => {
        draft.data = action.payload
      })
    case ManageEventsActions.LOAD_PURCHASE_ORDER:
      return produce(state, (draft) => {
        draft.paymentTicket = action.payload
      })
    default:
      return state
  }
}
