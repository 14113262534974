import { AxiosResponse } from 'axios'
import { api } from '../../api'

export type AddPartnerRequest = {
  email: string
  events: {
    eventId: string
    revenuePercent: number
  }[]
}

export const addPartnerInEvent = async (request: AddPartnerRequest) => {
  const { status }: AxiosResponse = await api.patch(
    '/api/backoffice/partners/events',
    request
  )

  return status
}
