import { AxiosResponse } from 'axios'
import { api } from '../../api'

export type EditBackofficeRequest = {
  id?: string
  firstName?: string | undefined
  lastName?: string | undefined
  email?: string | undefined
  groups?: [string]
}

export type EditBackofficeResponse = {
  id: string
  firstName: string
  lastName: string
  email: string
  userId: string
  type: string
  createdAt: string
  updatedAt: string | null
}

export const editBackofficeService = async (
  dataBackoffice: EditBackofficeRequest
) => {
  const { data }: AxiosResponse<EditBackofficeResponse> = await api.put(
    `/api/backoffice/${dataBackoffice.id}`,
    dataBackoffice
  )

  return data
}
