/* eslint-disable no-use-before-define */

/* eslint-disable no-unused-vars */
export enum EventActions {
  registerEventRequest = '@editEvent/REGISTER_EVENT_REQUEST',
  registerEventSuccess = '@editEvent/REGISTER_EVENT_SUCCESS',
  addDescription = '@editEvent/ADD_DESCRIPTION',
  addLocation = '@editEvent/ADD_LOCATION',
  addLocationSuccess = '@event/ADD_LOCATION_SUCCESS',
  createEventSuccess = '@editEvent/CREATE_EVENT_SUCCESS',
  removeEventSuccess = '@editEvent/REMOVE_EVENT_SUCCESS',
  listEventsRequest = '@editEvent/LIST_EVENTS_REQUEST',
  listEventsSuccess = '@editEvent/LIST_EVENTS_SUCCESS',
  createEventItemRequest = '@editEvent/CREATE_EVENT_ITEM_REQUEST',
  createEventItemSuccess = '@editEvent/CREATE_EVENT_ITEM_SUCCESS',
  loadEventItemsRequest = '@editEvent/LOAD_EVENT_ITEM_REQUEST',
  loadEventItemsSuccess = '@editEvent/LOAD_EVENT_ITEM_SUCCESS',
  addTicketSuccess = '@editEvent/ADD_TICKET_SUCCESS',
  addTicket = '@event/ADD_TICKET',
  deleteTicket = '@event/DELETE_TICKET',
  deleteTicketRequest = '@editEvent/DELETE_TICKET_REQUEST',
  deleteTicketSuccess = '@editEvent/DELETE_TICKET_SUCCESS',
  removeEventItemRequest = '@editEvent/REMOVE_EVENT_ITEM_REQUEST',
  removeEventItemSuccess = '@editEvent/REMOVE_EVENT_ITEM_SUCCESS',
  publishEvent = '@editEvent/PUBLISH_EVENT',
  nextStep = '@editEvent/NEXT_STEP',
  stepBack = '@editEvent/STEP_BACK',
  loadEventRequest = '@editEvent/LOAD_EVENT_REQUEST',
  loadEventSuccess = '@editEvent/LOAD_EVENT_SUCCESS',
  editNextStep = '@editEvent/EDIT_NEXT_STEP',
  editStepBack = '@editEvent/EDIT_STEP_BACK',
  editBasicInformationRequest = '@editEvent/EDIT_BASIC_INFORMATION_REQUEST',
  editBasicInformationSuccess = '@editEvent/EDIT_BASIC_INFORMATION_SUCCESS',
  editDescriptionRequest = '@editEvent/EDIT_DESCRIPTION_REQUEST',
  editDescriptionSuccess = '@editEvent/EDIT_DESCRIPTION_SUCCESS',
  editAddressRequest = '@editEvent/EDIT_ADDRESS_REQUEST',
  editAddressSuccess = '@editEvent/EDIT_ADDRESS_SUCCESS',
  editEventItemRequest = '@editEvent/EDIT_EVENT_ITEM_REQUEST',
  editEventItemSuccess = '@editEvent/EDIT_EVENT_ITEM_SUCCESS',
  editTicketSuccess = '@editEvent/EDIT_TICKET_SUCCESS',
  goToStep = '@editEvent/GO_TO_STEP',
  clearState = '@editEvent/CLEAR_STATE',
  createCouponRequest = '@event/CREATE_COUPON_REQUEST',
  createCouponSuccess = '@event/CREATE_COUPON_SUCCESS',
  editCouponRequest = '@event/EDIT_COUPON_REQUEST',
  editCouponSuccess = '@event/EDIT_COUPON_SUCCESS',
  removeCouponRequest = '@event/REMOVE_COUPON_REQUEST',
  removeCouponSuccess = '@event/REMOVE_COUPON_SUCCESS',
  editPixelsRequest = '@event/EDIT_PIXELS_REQUEST',
  editPixelsSuccess = '@event/EDIT_PIXELS_SUCCESS',
  editSocialLinksSuccess = '@event/SOCIAL_LINKS_SUCCESS'
}

export type Coupon = {
  id: string
  name: string
  description: string
  amountOfUse: number
  value: number
  valueType: 'PERCENT' | 'CENTS'
  quantity?: number
  startValidateDate?: string
  finalValidateDate?: string
}

export type Pixels = {
  facebookpixel: string
  googleanalyticspixel: string
  googletagmanagerpixel: string
}

export type SocialLinks = {
  facebookLink: string
  youtubeLink: string
  instagramLink: string
  tiktokLink: string
}

export type Address = {
  name?: string
  country?: string
  state?: string
  city?: string
  street?: string
  number?: string | number
  zipCode?: string
  complement?: string | null
}

export type EventItems = {
  id?: string
  title: string
  description?: string
  dates?: string[]
  maxCustomerTickets?: string
  salesFrom?: string
  finalSales?: string
  ticketInfo?: {
    value: string
    isRequired: boolean
  }[]
  tickets: Tickets[]
  eventKey?: string
}

export type Tickets = {
  id: string
  name: string
  description: string
  totalPrice?: number
  priceCents: number
  isFree?: boolean
  discountCents: number
  maxCustomerTickets: number
  type: string
  category?: string
  rule: string
  eventItems: EventItems[]
  createdAt: string
}

export type EditEvent = {
  isPublic: boolean
  id: string
  ageGroup: string
  name: string
  category: string
  cashBackPercent?: number
  status: 'OPEN' | 'CLOSED' | 'DRAFT' | 'CANCELED' | 'SOLD_OUT'
  type: 'MAPPED' | 'TABULATED'
  seatMapId?: string
  dates?: string[]
  description: string
  banners: {
    id: string
    desktopUrl: string
    tabletUrl: string
    mobileUrl: string
    cardUrl: string
  }[]
  address: Address
  eventItems?: EventItems[]
  tickets: Tickets[]
  socialLinks: SocialLinks | null
  coupons: Coupon[]
  pixels: Pixels
}

export type Event = {
  id?: string
  name: string
  category?: string
  type?: 'MAPPED' | 'TABULATED'
  status?: 'OPEN' | 'CLOSED' | 'DRAFT' | 'CANCELED' | 'SOLD_OUT'
  seatMapId?: string
  dates?: string[]
  description?: string
  banners?: {
    desktopUrl?: string | File
    tabletUrl?: string | File
    mobileUrl?: string | File
    cardUrl?: string | File
  }[]
  address?: Address
  createdAt?: string
  updatedAt?: string
  creatorId?: string
  eventItems?: EventItems[]
  tickets?: {
    eventItemId?: string
    ticketId?: string
    ticketQuantity?: number
  }[]
}

export type Steps = {
  name: string
  position: number
  completed: Boolean
  isActive: boolean
}

export type EventState = {
  editEvent?: EditEvent
  editSteps: Steps[]
}
