import { api } from 'api/api'
import { AxiosResponse } from 'axios'
import { Coupon } from 'redux/store/EditEvent/types'

export const listCouponsByEventIdService = async (eventId: string) => {
  const { data }: AxiosResponse<Coupon[]> = await api.get(
    `/api/backoffice/coupons/events/${eventId}`
  )
  return data
}
