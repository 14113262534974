import { AxiosResponse } from 'axios'
import { api } from '../../api'
import { uploadImagesService } from '../storage/uploadImagesService'

export type EditCourseRequest = {
  name?: string
  description?: string
  coverUrl?: File | string
  priceCents?: string
  discountCents?: string
}

export type EditCourseResponse = {
  id: string
  name: string
  description: string
  priceCents: number
  discountCents: number
  coverUrl: string
  createdAt: string
  updatedAt: string
  creatorId: string
}

export const editCourseService = async (
  dataCourse: EditCourseRequest,
  courseId: string,
  creatorId: string
) => {
  for (const field of Object.keys(dataCourse) as Array<
    keyof typeof dataCourse
  >) {
    if (field === 'coverUrl') {
      if (typeof dataCourse[field] !== 'string') {
        const imageUrl = await uploadImagesService(dataCourse[field]! as File)
        dataCourse[field] = imageUrl
      }
    }
  }
  const { data }: AxiosResponse<EditCourseResponse> = await api.put(
    `/api/backoffice/courses/${courseId}?creator=${creatorId}`,
    dataCourse
  )

  return data
}
