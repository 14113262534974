import { ReactNode } from 'react'
import * as S from './TableRow.styles'

type TableRowProps = {
  children: ReactNode
  selected: boolean
  clickable?: boolean
  onClick?: () => void
}

export const TableRow = ({
  children,
  selected,
  clickable,
  onClick
}: TableRowProps) => {
  return (
    <S.Container selected={selected} clickable={clickable} onClick={onClick}>
      {children}
    </S.Container>
  )
}
