import { ShowTicket as ShowTicketMobile } from './Mobile/ShowTicket'
import { ShowTicket as ShowTicketTablet } from './Tablet/ShowTicket'
import { Hidden } from 'applaus-ui-kit'

export const ShowTicket = () => {
  return (
    <>
      <Hidden mobile>
        <ShowTicketTablet />
      </Hidden>
      <Hidden desktop tablet>
        <ShowTicketMobile />
      </Hidden>
    </>
  )
}
