import { AxiosResponse } from 'axios'
import { api } from '../../api'

export type EditCustomerRequest = {
  firstName?: string
  lastName?: string
  email?: string
  document?: string
  password?: string
  phone?: string
  address?: {
    street?: string
    state?: string
    city?: string
    number?: number
    country?: string
    complement?: string
    zipCode?: string
  }
}

export type EditCustomerResponse = {
  id: string
  firstName: string
  lastName: string
  email: string
  document: string
  userId: string
  type: string
  groups: string[]
  createdAt: string
  updatedAt: string
  phone: string
  address: {}
  zipCode: string
  customField: {
    isPolice: boolean
  }
}

export const editCustomerService = async (
  dataCustomer: EditCustomerRequest,
  customerId: string
) => {
  if (dataCustomer.address?.city === '') {
    delete dataCustomer.address?.city
  }
  if (dataCustomer.address?.complement === '') {
    delete dataCustomer.address?.complement
  }
  if (dataCustomer.address?.country === '') {
    delete dataCustomer.address?.country
  }
  if (
    dataCustomer.address?.number === 0 ||
    dataCustomer.address?.number === null
  ) {
    delete dataCustomer.address?.number
  }
  if (dataCustomer.address?.state === '') {
    delete dataCustomer.address?.state
  }
  if (dataCustomer.address?.street === '') {
    delete dataCustomer.address?.street
  }
  if (dataCustomer.address?.zipCode === '') {
    delete dataCustomer.address?.zipCode
  }
  if (dataCustomer.document === '') {
    delete dataCustomer.document
  }
  if (dataCustomer.email === '') {
    delete dataCustomer.email
  }
  if (dataCustomer.firstName === '') {
    delete dataCustomer.firstName
  }
  if (dataCustomer.lastName === '') {
    delete dataCustomer.lastName
  }
  if (dataCustomer.password === '') {
    delete dataCustomer.password
  }
  if (dataCustomer.phone === '') {
    delete dataCustomer.phone
  }
  const { data }: AxiosResponse<EditCustomerResponse> = await api.put(
    `/api/backoffice/customers/${customerId}`,
    dataCustomer
  )

  return data
}
