import { all, call, delay } from 'redux-saga/effects'
import { put, select, takeLatest } from '@redux-saga/core/effects'
import { ManageCustomerTicket, ManageEventsActions } from './types'
import { PayloadAction } from 'typesafe-actions'
import {
  EventSummaryResponse,
  eventSummaryService
} from '../../../api/services/events/eventSummaryService'
import {
  CustomerTicketByEventResponse,
  listEventCustomerTickets
} from '../../../api/services/events/listEventCustomerTickets'
import {
  listEventPurchaseOrder,
  PurchaseOrdersByEventResponse
} from '../../../api/services/events/listEventPurchasesOrder'
import {
  customerTicketsPaginationChangeSuccess,
  loadEventDataSuccessAction,
  purchasePaginationChangeSuccess
} from './actions'
import {
  EventResponse,
  listEventService
} from '../../../api/services/events/listEventService'
import { ReduxState } from '../rootReducer'
import { setModalState } from '../Modal/Actions/actionsModal'
import { clearLoading, setLoading } from '../Loading/actions'
import history from '../../../routes/services/history'
import { listCustomerTickets } from 'api/services/events/listCustomerTickets'
import { listPurchaseOrder } from 'api/services/events/listPurchaseOrders'

function* loadEventData(
  action: PayloadAction<ManageEventsActions.LOAD_EVENT_DATA_REQUEST, string>
) {
  try {
    yield put(setLoading())
    const [summary, eventData, customerTickets, purchasesOrders]: [
      EventSummaryResponse,
      EventResponse,
      CustomerTicketByEventResponse,
      PurchaseOrdersByEventResponse
    ] = yield all([
      call(eventSummaryService, action.payload),
      call(listEventService, action.payload),
      call(listCustomerTickets, action.payload),
      call(listPurchaseOrder, action.payload)
    ])

    yield put(
      loadEventDataSuccessAction({
        summary,
        eventData,
        customerTickets,
        purchasesOrders
      })
    )
    yield delay(2000)
    yield put(clearLoading())
  } catch (err: any) {
    yield put(clearLoading())
    yield put(
      setModalState({
        open: true,
        buttonText: 'Fechar',
        message: 'Evento não encontrado',
        variant: 'error'
      })
    )
    history.push('/events')
  }
}

function* changeCustomerTicketStep(
  action: PayloadAction<
    ManageEventsActions.CHANGE_CUSTOMER_TICKET_PAGINATION_STEP_REQUEST,
    number
  >
) {
  const getState = (state: ReduxState) => state.ManageEventReducer.eventData
  const eventState: EventResponse = yield select(getState)
  const customerTicketByEvent: CustomerTicketByEventResponse = yield call(
    listEventCustomerTickets,
    eventState.id,
    action.payload
  )

  yield put(
    customerTicketsPaginationChangeSuccess({
      skip: action.payload,
      items: customerTicketByEvent.items
    })
  )
}

function* customerTicketNextStep() {
  const getState = (state: ReduxState) => state.ManageEventReducer
  const eventState: ManageCustomerTicket = yield select(getState)
  const customerTicketByEvent: CustomerTicketByEventResponse = yield call(
    listEventCustomerTickets,
    eventState.eventData!.id,
    eventState.customerTickets.skip + 1
  )

  yield put(
    customerTicketsPaginationChangeSuccess({
      skip: eventState.customerTickets.skip + 1,
      items: customerTicketByEvent.items
    })
  )
}

function* customerTicketStepBack() {
  const getState = (state: ReduxState) => state.ManageEventReducer
  const eventState: ManageCustomerTicket = yield select(getState)
  const customerTicketByEvent: CustomerTicketByEventResponse = yield call(
    listEventCustomerTickets,
    eventState.eventData!.id,
    eventState.customerTickets.skip - 1
  )

  yield put(
    customerTicketsPaginationChangeSuccess({
      skip: eventState.customerTickets.skip - 1,
      items: customerTicketByEvent.items
    })
  )
}

function* changePurchaseStep(
  action: PayloadAction<
    ManageEventsActions.CHANGE_PURCHASES_PAGINATION_STEP_REQUEST,
    number
  >
) {
  const getState = (state: ReduxState) => state.ManageEventReducer.eventData
  const eventState: EventResponse = yield select(getState)
  const purchasesByEvent: PurchaseOrdersByEventResponse = yield call(
    listEventPurchaseOrder,
    eventState.id,
    action.payload
  )

  yield put(
    purchasePaginationChangeSuccess({
      skip: action.payload,
      items: purchasesByEvent.items
    })
  )
}

function* purchasesNextStep() {
  const getState = (state: ReduxState) => state.ManageEventReducer
  const eventState: ManageCustomerTicket = yield select(getState)
  const purchasesByEvent: PurchaseOrdersByEventResponse = yield call(
    listEventPurchaseOrder,
    eventState.eventData!.id,
    eventState.purchaseOrders.skip + 1
  )

  yield put(
    purchasePaginationChangeSuccess({
      skip: eventState.purchaseOrders.skip + 1,
      items: purchasesByEvent.items
    })
  )
}

function* purchaseStepBack() {
  const getState = (state: ReduxState) => state.ManageEventReducer
  const eventState: ManageCustomerTicket = yield select(getState)
  const purchasesByEvent: PurchaseOrdersByEventResponse = yield call(
    listEventPurchaseOrder,
    eventState.eventData!.id,
    eventState.purchaseOrders.skip - 1
  )

  yield put(
    purchasePaginationChangeSuccess({
      skip: eventState.purchaseOrders.skip - 1,
      items: purchasesByEvent.items
    })
  )
}

export const ManageEvent = all([
  takeLatest(ManageEventsActions.LOAD_EVENT_DATA_REQUEST, loadEventData),
  takeLatest(
    ManageEventsActions.CHANGE_CUSTOMER_TICKET_PAGINATION_STEP_REQUEST,
    changeCustomerTicketStep
  ),
  takeLatest(
    ManageEventsActions.CUSTOMER_TICKET_NEXT_STEP_REQUEST,
    customerTicketNextStep
  ),
  takeLatest(
    ManageEventsActions.CUSTOMER_TICKET_STEP_BACK_REQUEST,
    customerTicketStepBack
  ),
  takeLatest(
    ManageEventsActions.CHANGE_PURCHASES_PAGINATION_STEP_REQUEST,
    changePurchaseStep
  ),
  takeLatest(ManageEventsActions.PURCHASE_NEXT_STEP_REQUEST, purchasesNextStep),
  takeLatest(ManageEventsActions.PURCHASE_STEP_BACK_REQUEST, purchaseStepBack)
])
