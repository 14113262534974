import { action } from 'typesafe-actions'
import {
  CreateChartRequest,
  CreateChartResponse
} from '../../../api/services/charts/createChartService'
import {
  EditChartRequest,
  EditChartResponse
} from '../../../api/services/charts/editChartService'
import { LoadChartResponse } from '../../../api/services/charts/getChartsService'
import { ChartActions } from './types'

export const getChartsRequest = (skip: number, currentPage: number) =>
  action(ChartActions.GET_CHARTS_REQUEST, { skip, currentPage })

export const getChartsSuccess = (
  data: LoadChartResponse,
  skip: number,
  currentPage: number
) => action(ChartActions.GET_CHARTS_SUCCESS, { data, skip, currentPage })

export const createChartRequest = (data: CreateChartRequest) =>
  action(ChartActions.CREATE_CHART_REQUEST, data)

export const createChartSuccess = (data: CreateChartResponse) =>
  action(ChartActions.CREATE_CHART_SUCCESS, data)

export const editChartRequest = (data: EditChartRequest, seatId: string) =>
  action(ChartActions.EDIT_CHART_REQUEST, { data, seatId })

export const editChartSuccess = (data: EditChartResponse) =>
  action(ChartActions.EDIT_CHART_SUCCESS, data)

export const deleteChartRequest = (data: string[]) =>
  action(ChartActions.DELETE_CHART_REQUEST, data)

export const deleteChartSuccess = (data: string[]) =>
  action(ChartActions.DELETE_CHART_SUCCESS, data)
