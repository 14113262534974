import { Hidden } from 'applaus-ui-kit'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getPaymentHistoryService,
  PurchaseResponse
} from '../../api/services/paymentHistory/paymentHistory'
import { clearLoading, setLoading } from '../../redux/store/Loading/actions'
import { setModalState } from '../../redux/store/Modal/Actions/actionsModal'
import { ButtonBack } from '../EventProfile/Components/backButton/BackButton.styles'
import { Table } from './components/Table/Table'
import * as S from './PurchaseHistory.styles'

export const PurchaseHistory = () => {
  const [purchaseHistory, setPurchaseHistory] = useState<PurchaseResponse>()

  const dispatch = useDispatch()

  const params: { id: string } = useParams()

  useEffect(() => {
    async function run() {
      dispatch(setLoading(true))
      try {
        const data = await getPaymentHistoryService({
          customerId: params.id
        })
        setPurchaseHistory(data)

        setTimeout(() => {
          dispatch(clearLoading())
        }, 3000)
      } catch (err) {
        dispatch(clearLoading())
        dispatch(
          setModalState({
            open: true,
            variant: 'error',
            message: 'Não foi possivel carregar o histórico de compras',
            buttonText: 'Fechar'
          })
        )
      }
    }

    run()
  }, [])

  return (
    <S.Container>
      <Hidden tablet desktop>
        <div className="buttonBack">
          <ButtonBack title="Histórico de Compras" />
        </div>
      </Hidden>

      <S.Wrapper>
        <Hidden mobile>
          <S.Title>Histórico de Compras</S.Title>
        </Hidden>
        <Table purchaseHistory={purchaseHistory} />
        {purchaseHistory &&
          (purchaseHistory?.items.length > 0 &&
          purchaseHistory.items.length < purchaseHistory.total ? (
            <S.SeeMore onClick={() => {}}>Ver mais</S.SeeMore>
          ) : null)}
      </S.Wrapper>
    </S.Container>
  )
}
