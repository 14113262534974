import styled, { css } from 'styled-components'

import { ReactComponent as QrCodeFile } from '../../assets/icons/qrCode.svg'
import { ReactComponent as EditFile } from '../../assets/icons/edit-alt.svg'
import { ReactComponent as SearchFile } from '../../assets/icons/search.svg'
import { ReactComponent as PenFile } from '../../assets/icons/pen.svg'
import { ReactComponent as TrashFile } from '../../assets/icons/trash.svg'

import { ReactComponent as AgeFreeFile } from '../../assets/icons/ageGroup/ageFree.svg'
import { ReactComponent as Age10File } from '../../assets/icons/ageGroup/age10.svg'
import { ReactComponent as Age12File } from '../../assets/icons/ageGroup/age12.svg'
import { ReactComponent as Age14File } from '../../assets/icons/ageGroup/age14.svg'
import { ReactComponent as Age16File } from '../../assets/icons/ageGroup/age16.svg'
import { ReactComponent as Age18File } from '../../assets/icons/ageGroup/age18.svg'

export const AgeFreeIcon = styled(AgeFreeFile)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.xs};
    height: ${theme.spacing.stack.xs};
    margin-right: ${theme.spacing.inline.xxxs};
  `}
`

export const Age10Icon = styled(Age10File)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.xs};
    height: ${theme.spacing.stack.xs};
    margin-right: ${theme.spacing.inline.xxxs};
  `}
`

export const Age12Icon = styled(Age12File)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.xs};
    height: ${theme.spacing.stack.xs};
    margin-right: ${theme.spacing.inline.xxxs};
  `}
`

export const Age14Icon = styled(Age14File)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.xs};
    height: ${theme.spacing.stack.xs};
    margin-right: ${theme.spacing.inline.xxxs};
  `}
`

export const Age16Icon = styled(Age16File)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.xs};
    height: ${theme.spacing.stack.xs};
    margin-right: ${theme.spacing.inline.xxxs};
  `}
`

export const Age18Icon = styled(Age18File)`
  ${({ theme }) => css`
    width: ${theme.spacing.stack.xs};
    height: ${theme.spacing.stack.xs};
    margin-right: ${theme.spacing.inline.xxxs};
  `}
`

export const EditIcon = styled(EditFile)`
  cursor: pointer;
`
export const TrashIcon = styled(TrashFile)`
  cursor: pointer;
`

export const PenIcon = styled(PenFile)`
  width: 25px;
  height: 25px;
  margin-left: 1rem;
`

export const QrCodeIcon = styled(QrCodeFile)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.stack.xxxs};
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: ${theme.spacing.stack.xxl} 0;
    overflow: auto;
    background: ${theme.color.neutral.nth4};

    .details {
      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        text-align: left;
        color: #39383a;
      }
    }

    .buttonEdit {
      position: relative;
      top: 100px;
    }

    @media (max-width: 767px) {
      padding-top: ${theme.spacing.stack.xxs};

      .buttonEdit {
        position: relative;
        top: 24px;
      }
    }
  `}
`

export const Title = styled.h1`
  ${({ theme }) => css`
    max-width: 400px;
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 41px;
    text-align: left;
    word-break: break-all;
  `}
`

export const EventDataBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: start;
  }
`

export const EventCreationDate = styled.h1`
  ${({ theme }) => css`
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: ${theme.color.base.nth2};
  `}
`

export const CheckinButton = styled.button`
  ${({ theme }) => css`
    outline: 0;
    border: 0;
    background: ${theme.color.brand.primary.nth1};
    display: flex;
    align-items: center;
    padding: 12px ${theme.spacing.inline.xxxs};
    border-radius: 12px;
    transition: 0.3s;
    &:hover {
      transform: scale(1.05);
    }

    @media (max-width: 767px) {
      margin-top: ${theme.spacing.stack.xxs};
    }
  `}
`

export const CheckinButtonText = styled.p`
  ${({ theme }) => css`
    color: ${theme.color.neutral.nth4};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
  `}
`

export const Tab = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.stack.xl};
    flex-direction: row;
    margin-bottom: ${theme.spacing.stack.xs};
    overflow-y: auto;
    padding: 0 0 ${theme.spacing.stack.xxxs} 0;
  `}
`

export const MenuTab = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacing.stack.sm};

    @media (max-width: 768px) {
      display: flex;
      height: 28px;
    }
  `}
`

export const TabText = styled.p<{ activeTab: boolean }>`
  ${({ theme, activeTab }) => css`
    cursor: pointer;
    white-space: nowrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: ${activeTab
      ? theme.color.brand.primary.nth1
      : theme.color.neutral.nth3};
    position: relative;
    transition: 0.3s ease;
    &::after {
      transition: 0.3s ease;
      top: 100%;
      left: 0;
      position: absolute;
      content: '';
      height: 2px;
      width: ${activeTab ? '100%' : '0%'};

      background: ${theme.color.brand.primary.nth1};
    }
    &:hover {
      color: ${theme.color.brand.primary.nth1};
      &::after {
        width: 100%;
      }
    }
  `}
`

export const EditButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
  `}
`

export const EventDetails = styled.div`
  ${({ theme }) => css`
    box-shadow: ${theme.shadow.level2} 0px #00000040;
    width: 100%;
    margin-right: ${theme.spacing.inline.xxxs};
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.inline.xxs}
      ${theme.spacing.inline.xxs};

    .details-title {
      color: ${theme.color.base.nth2};
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: ${theme.spacing.stack.xxxs};
    }

    .subtitle {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: ${theme.color.neutral.nth3};
    }

    .text {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: ${theme.spacing.stack.xxxs};
    }
    .open {
      color: ${theme.color.alert.success.nth1};
    }

    .closed {
      color: ${theme.color.alert.error.nth1};
    }

    .event-type-text {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  `}
`

export const PublicDetails = styled.div`
  ${({ theme }) => css`
    box-shadow: ${theme.shadow.level2} 0px #00000040;
    width: 100%;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.inline.xxs}
      ${theme.spacing.inline.xxs};

    .public-title {
      color: ${theme.color.base.nth2};
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: ${theme.spacing.stack.xxxs};
    }

    .subtitle {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: ${theme.color.neutral.nth3};
    }

    .public-total-box {
      display: flex;
      justify-content: space-between;
    }

    .payers-content {
      display: flex;
      justify-content: space-between;
    }

    .text {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: ${theme.spacing.stack.xxxs};
    }
    .event-payers {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }

    @media (max-width: 767px) {
      margin-top: ${theme.spacing.stack.small};
    }
  `}
`

export const PriceDetails = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.inline.xxs}
      ${theme.spacing.inline.xxs};
    margin-top: ${theme.spacing.stack.small};
    box-shadow: ${theme.shadow.level2} 0px #00000040;
    .content {
      margin-top: ${theme.spacing.stack.xxxs};
      display: flex;
      justify-content: space-between;
    }
    .title {
      color: ${theme.color.base.nth2};
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
    }

    .subtitle {
      color: ${theme.color.base.nth3};
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
    }

    .total-price {
      color: ${theme.color.brand.primary.nth1};

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }

    .success-payment {
      color: ${theme.color.alert.success.nth1};

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: ${theme.spacing.stack.xxxs};
    }

    .credit-payment {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }

    .pending-payment {
      color: ${theme.color.alert.warning.nth1};
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      margin-bottom: ${theme.spacing.stack.xxxs};
    }

    @media (max-width: 600px) {
      .content {
        display: flex;
        flex-direction: column;

        > div:nth-child(1n + 1) {
          margin-top: ${theme.spacing.stack.small};
        }
      }
    }
  `}
`

export const Partners = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
    box-shadow: ${theme.shadow.level2} 0px #00000040;
    .box {
      display: none;
    }
    .title {
      color: ${theme.color.base.nth2};
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
    }

    .header {
      margin-top: ${theme.spacing.stack.xxxs};
      display: grid;
      grid-template-columns: 3fr 3fr 1fr 2fr;

      @media (max-width: 550px) {
        grid-template-columns: 5fr 2fr 2fr;
      }

      @media (max-width: 350px) {
        grid-template-columns: 5fr 2fr;
      }
    }

    .partnersInformation {
      display: grid;
      grid-template-columns: 3fr 3fr 1fr 2fr;

      @media (max-width: 550px) {
        grid-template-columns: 5fr 2fr 2fr;
      }

      @media (max-width: 350px) {
        grid-template-columns: 5fr 2fr;
      }
    }

    .subTitle {
      color: ${theme.color.neutral.nth3};
      font-size: 16px;
      font-style: normal;
      font-weight: ${theme.font.weight.semiBold};
      line-height: 24px;
      letter-spacing: 0em;
      margin-bottom: ${theme.spacing.stack.small};
    }

    .partnerName,
    .partnerEmail,
    .percentage {
      color: ${theme.color.base.nth1};
      font-size: 16px;
      font-style: normal;
      font-weight: ${theme.font.weight.regular};
      line-height: 24px;
      letter-spacing: 0em;
      margin-bottom: ${theme.spacing.stack.nano};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 180px;
    }

    .actionsTitle,
    .actions {
      display: flex;
      justify-content: flex-end;
    }

    .actions {
      svg:first-child {
        margin-right: ${theme.spacing.stack.small};
      }
    }

    .addPartner {
      width: max-content;
      cursor: pointer;
      color: ${theme.color.brand.primary.nth2};
      font-size: 16px;
      font-style: normal;
      font-weight: ${theme.font.weight.semiBold};
      line-height: 24px;
      letter-spacing: 0em;
      margin-top: ${theme.spacing.stack.xxxs};
    }

    @media (max-width: 767px) {
      .partnerName,
      .partnerEmail,
      .percentage {
        max-width: 120px;
      }
    }

    @media (max-width: 550px) {
      .partnerEmail,
      .email {
        display: none;
      }
    }

    @media (max-width: 350px) {
      .percent {
        display: none;
      }
    }

    .actions {
      svg:first-child {
        margin-right: ${theme.spacing.stack.small};
      }

      .statusBalance {
        font-size: 16px;
        font-weight: ${theme.font.weight.regular};
        line-height: 24px;
      }

      .Pendente {
        color: ${theme.color.alert.warning.nth1};
      }

      .Rejeitado {
        color: ${theme.color.alert.error.nth1};
      }

      .Confirmado {
        color: ${theme.color.alert.success.nth1};
      }
    }
  `}
`

export const ButtonActions = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    margin-top: ${theme.spacing.stack.sm};

    > button:first-child {
      margin-right: ${theme.spacing.stack.xxxs};
    }
  `}
`

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin-right: ${theme.spacing.stack.xxxs};
    width: 100%;
  `}
`

export const SearchIcon = styled(SearchFile)`
  position: absolute;
  fill: #39383a;
  top: 50%;
  transform: translateY(-50%);
  left: 3rem;
`

export const Input = styled.input`
  ${({ theme }) => css`
    background: #f4f4f4;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxxs}
      ${theme.spacing.stack.xxxs} 72px;
    width: 100%;
    font-size: ${theme.font.size.sm};
    border-radius: ${theme.border.radius.medium};
    outline: 0;
    border: 1px solid transparent;
    transition: 0.3s ease;

    &:focus {
      border-color: ${theme.color.brand.primary.nth1};
    }

    &::placeholder {
      font-size: ${theme.font.size.sm};
    }
  `}
`
export const TableHeader = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 2fr 2fr 3fr;
    background-color: ${theme.color.base.nth4};
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.inline.xxs};
    border-radius: 6px;
    > p {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: ${theme.color.base.nth2};
    }
    > p:first-child {
      padding-left: ${theme.spacing.stack.xxs};
    }

    @media (max-width: 767px) {
      grid-template-columns: 3fr 2fr 2fr;
    }
  `}
`

export const TableBody = styled.div`
  ${({ theme }) => css`
    display: grid;

    box-shadow: ${theme.shadow.level2} 0px #00000040;
    grid-template-columns: 2fr 2fr 3fr;
    padding: ${theme.spacing.stack.xxxs} 0;
    padding-right: ${theme.spacing.stack.xxs};
    border-radius: 6px;
    align-items: center;
    margin-bottom: ${theme.spacing.stack.xxxs};
    > p:first-child {
      padding-left: ${theme.spacing.stack.xxs};
    }
    > p {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }

    @media (max-width: 767px) {
      grid-template-columns: 3fr 2fr 2fr;
    }

    @media (max-width: 510px) {
      p {
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  `}
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > p {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
`

export const Steps = styled.p<{ activeIndex: boolean }>`
  ${({ theme, activeIndex }) => css`
    font-size: 16px;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border-radius: ${theme.border.radius.tiny};
    background: ${activeIndex ? theme.color.brand.primary.nth1 : 'transparent'};
    color: ${activeIndex ? theme.color.base.nth4 : 'black'};
    padding: 0 8px;
  `}
`

export const StepBack = styled.button`
  ${({ theme }) => css`
    cursor: pointer;
    background: ${theme.color.brand.primary.nth1};
    padding: 1px 8px;
    border-radius: ${theme.border.radius.tiny};
    outline: 0;
    border: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${theme.color.base.nth4};
    margin: 0 8px;

    &:disabled {
      background: gray;
    }
  `}
`

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

export const PurchaseTableHeader = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1.2fr 1fr 1fr 1fr;
    background-color: ${theme.color.base.nth4};
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.inline.xxs};
    border-radius: 6px;
    > p {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: ${theme.color.base.nth2};
    }
    > p:first-child {
      padding-left: ${theme.spacing.stack.xxs};
    }

    > div {
      > p {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: ${theme.color.base.nth2};
      }
    }

    @media (max-width: 767px) {
      grid-template-columns: 1.2fr 1fr 1fr;
    }
  `}
`

export const PurchaseTableBody = styled.div`
  ${({ theme }) => css`
    display: grid;

    box-shadow: ${theme.shadow.level2} 0px #00000040;
    grid-template-columns: 1.2fr 1fr 1fr 1fr;
    padding: ${theme.spacing.stack.xxxs} 0;
    padding-right: ${theme.spacing.stack.xxs};
    border-radius: 6px;
    align-items: center;
    margin-bottom: ${theme.spacing.stack.xxxs};
    > p:first-child {
      padding-left: ${theme.spacing.stack.xxs};
    }
    > p {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }

    > div {
      > p {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    @media (max-width: 767px) {
      grid-template-columns: 1.2fr 1fr 1fr;
    }

    @media (max-width: 550px) {
      p {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  `}
`

export const BasicInformation = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacing.inline.sm};

    @media (max-width: 767px) {
      flex-direction: column;
      word-break: break-all;
    }
  `}
`

export const AddresInformation = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacing.inline.sm};
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
      word-break: break-all;
    }
  `}
`

export const SectionsInformation = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacing.inline.sm};
    justify-content: space-between;
    > div {
      display: flex;
      justify-content: space-between;

      @media (max-width: 767px) {
        word-break: break-all;
        gap: ${theme.spacing.inline.xxs};
        border-left: none;
        justify-content: start;
        align-items: top;

        .circle {
          margin-top: ${theme.spacing.stack.small};
          width: ${theme.spacing.stack.small};
          height: ${theme.spacing.stack.small};
          border-radius: 50%;
          background: #8601c9;
        }

       
          
        }

        .dividerBox {
          margin-bottom: ${theme.spacing.stack.small};
        }
      }

      @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
      }
    }
  `}
`

export const EventInfoTitle = styled.h4`
  ${({ theme }) => css`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${theme.color.base.nth2};
  `}
`

export const EventInfoText = styled.h4`
  ${({ theme }) => css`
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: ${theme.color.base.nth1};
  `}
`

export const TicketInformation = styled.div`
  ${({ theme }) => css`
    > div {
      display: flex;
      justify-content: space-between;
      border-left: 5px solid ${theme.color.brand.primary.nth1};
      margin-bottom: 3rem;
      @media (min-width: 768px) {
        padding-left: ${theme.spacing.inline.small};
      }

      @media (max-width: 767px) {
        word-break: break-all;
        gap: ${theme.spacing.inline.xxs};
        border-left: none;
        justify-content: start;
        align-items: top;

        .circle {
          margin-top: ${theme.spacing.stack.small};
          width: ${theme.spacing.stack.small};
          height: ${theme.spacing.stack.small};
          border-radius: 50%;
          background: #8601c9;
        }

        .dividerBox {
          margin-bottom: ${theme.spacing.stack.small};
        }
      }

      .dividerName {
        margin-bottom: ${theme.spacing.stack.small};
      }

      .ticketDescription {
        display: flex;
        justify-content: space-between;
        width: 60%;

        .dividerBox {
          width: 25%;
          justify-content: space-between;
        }
      }
    }
  `}
`

export const AgeDiv = styled.div`
  display: flex;
  align-items: center;
`
