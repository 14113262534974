import { ShowRequest as ShowRequestTablet } from './Tablet/ShowRequest'
import { ShowRequest as ShowRequestMobile } from './Mobile/ShowRequest'
import { Hidden } from 'applaus-ui-kit'

export const ShowRequest = () => {
  return (
    <>
      <Hidden mobile>
        <ShowRequestTablet />
      </Hidden>
      <Hidden desktop tablet>
        <ShowRequestMobile />
      </Hidden>
    </>
  )
}
