import styled, { css } from 'styled-components'

import { ReactComponent as IconExit } from '../../../assets/icons/multiply.svg'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 25%;
    max-height: 80vh;
    border-radius: ${theme.border.radius.large};
    background: ${theme.color.neutral.nth4};
    .form-content {
      padding: 0 1rem 1rem 0;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      @media (max-width: 767px) {
        padding: 0;
      }
    }
    @media (min-width: 768px and max-width: 1170px) {
      margin: 0 6.25%;
    }
    @media (max-width: 767px) {
      margin: 0;
      max-height: none;
      height: 100%;
      border-radius: 0;
    }
  `}
`

export const ModalTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing.stack.xxs};
  `}
`

export const IconExitModal = styled(IconExit)`
  cursor: pointer;
`

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.stack.xxs};
    overflow-y: scroll;
  `}
`

export const PersonTypeBox = styled.div`
  display: flex;
  column-gap: 2rem;
  .person {
    display: flex;
    align-items: center;
    white-space: nowrap;
    column-gap: 1rem;
  }
`

export const ButtonBox = styled.div`
  display: flex;
  column-gap: 1rem;
  margin-top: 24px;
`

export const TitleConfigFee = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.stack.xxs};
  `}
`

export const Label = styled.p`
  ${({ theme }) => css`
    color: #39383a;
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.regular};
    line-height: 24px;
    margin-bottom: 2px;
  `}
`

export const customStyles = {
  clearIndicator: () => ({
    border: '1px solid blue'
  }),
  control: (provided: any) => ({
    ...provided,
    display: 'flex',
    padding: '2px 16px',
    background: '#F4F4F4',
    outline: 'none',
    border: '1px solid transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: '6px',
    '&:focus-within': {
      borderColor: '#8601C9'
    }
  }),
  valueContainer: (provided: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }),
  indicatorSeparator: (provided: any) => ({
    display: 'none'
  }),
  singleValue: (provided: any) => ({
    color: '#39383A',
    fontSize: '16px',
    lineHeight: '20px'
  }),
  placeholder: (provided: any) => ({
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    backgroundColor: state.isSelected ? '#F4F4F4' : 'white'
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  })
}

export const Error = styled.p`
  ${({ theme }) => css`
    color: #fc3e3e;
    font-size: ${theme.font.size.xxs};
    min-height: ${theme.spacing.stack.xxs};
  `}
`
