import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Pagination,
  TableBody,
  TableContainer,
  TableData,
  TableFooter,
  TableRow
} from '../../../components/table'
import TableHeader from '../../../components/table/TableHeader/TableHeader'
import { getCreatorsRequest } from '../../../redux/store/Creators/Actions/creatorActions'
import { Creator } from '../../../redux/store/Creators/types'
import { ReduxState } from '../../../redux/store/rootReducer'

import * as S from './CreatorTable.styles'
import { useParams } from 'react-router-dom'

export type Headers = {
  name: string
  field: string
  align: 'center' | 'right' | 'left'
  sortable: boolean
}

type CreatorTableProps = {
  setEditCreator: (creator: Creator) => void
  deleteCreator: (creator: Creator) => void
  searchInput: string
}

export const CreatorTable = ({
  setEditCreator,
  deleteCreator,
  searchInput
}: CreatorTableProps) => {
  const { data, currentPage, take } = useSelector(
    (state: ReduxState) => state.Creator
  )
  const dispatch = useDispatch()

  const paginationData = useMemo(() => {
    return data.items
  }, [data, currentPage])

  const headers: Headers[] = [
    { name: 'Nome', field: 'name', align: 'left', sortable: false },
    { name: 'Documento', field: 'document', align: 'left', sortable: false },
    { name: 'Email', field: 'email', align: 'left', sortable: false },
    { name: 'Ações', field: 'actions', align: 'left', sortable: false }
  ]

  const { creatorId } = useParams<{ creatorId?: string }>()

  useEffect(() => {
    if (searchInput === '') {
      dispatch(
        getCreatorsRequest((currentPage - 1) * take, currentPage, creatorId)
      )
    }
    if (creatorId !== undefined && data.items.length > 0) {
      const findCreatorIndex = data.items.findIndex(
        (creator) => creator.id === creatorId
      )
      setEditCreator(data.items[findCreatorIndex])
    }
  }, [data.items.length, searchInput])

  return (
    <>
      <S.Container>
        <TableContainer
          table={
            <>
              <TableHeader
                headers={headers}
                type="manage"
                onSorting={() => {}}
              />

              <TableBody>
                {paginationData.map((creator) => {
                  return (
                    <TableRow key={creator.id} selected={false}>
                      <TableData>{creator.firstName}</TableData>
                      <TableData>
                        {creator.document.replace(
                          /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                          '$1.$2.$3-$4'
                        )}
                      </TableData>
                      <TableData>{creator.email}</TableData>
                      <TableData>
                        <S.IconBox>
                          <S.EditIcon onClick={() => setEditCreator(creator)} />
                          <S.ExcludeIcon
                            onClick={() => deleteCreator(creator)}
                          />
                        </S.IconBox>
                      </TableData>
                    </TableRow>
                  )
                })}
              </TableBody>
            </>
          }
          footer={
            <>
              <TableFooter>
                <p>{data.total} produtores cadastrados</p>
                <Pagination
                  currentPage={currentPage}
                  itemsPerPage={take}
                  onPageChange={(page) =>
                    dispatch(getCreatorsRequest((page - 1) * take, page))
                  }
                  totalItems={data.total}
                />
              </TableFooter>
            </>
          }
        />
      </S.Container>
    </>
  )
}
