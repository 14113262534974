import { ButtonHTMLAttributes } from 'react'

import * as S from './Tab.styles'

export interface TabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title?: string
  colorTab?: boolean
  widthTab?: {
    mobile: number
    tablet: number
    desktop: number
  }
  click?: () => void
}

const Tab = ({ title, colorTab, value, widthTab, click }: TabProps) => {
  return (
    <S.Tab onClick={click} widthTab={widthTab}>
      <S.Title colorTab={colorTab}>{title}</S.Title>
      <S.Line colorTab={colorTab} />
    </S.Tab>
  )
}

export default Tab
