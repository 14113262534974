import {
  Button,
  RadioButton,
  Input,
  Paragraph,
  InputMask
} from 'applaus-ui-kit'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { messageHelper } from '../../../utils/messageHelper'
import { createCreatorSchema, editCreatorSchema } from './validationSchema'
import * as S from './CreatorModal.styles'
import ContentModal from '../../../components/contentModal/ContentModal'
import { ModalState } from '../Creators'
import { useDispatch } from 'react-redux'
import {
  createCreatorRequest,
  editCreatorRequest
} from '../../../redux/store/Creators/Actions/creatorActions'
import { EditCreatorRequest } from '../../../api/services/creators/editCreatorService'
import _ from 'lodash'
import { viaCepService } from '../../../api/services/viaCepService'
import { CurrencyInput } from '../../../components/CurrencyInput/CurrencyInput'

import jsonStates from '../../../utils/estados-cidades.json'
import ReactSelect from 'react-select'
import { BankOptions } from '../../../utils/banks'

type CreatorModalProps = {
  modalState: ModalState
  closeModal: () => void
}

const mappedBanks = Object.entries(BankOptions).map((values) => ({
  value: values[0],
  label: values[1]
}))

const accountOptions = [
  { value: 'Corrente', label: 'Conta Corrente' },
  { value: 'Poupança', label: 'Conta Poupança' }
]

export const CreatorModal = ({ closeModal, modalState }: CreatorModalProps) => {
  const dispatch = useDispatch()
  const [personType, setPersonType] = useState<'CPF' | 'CNPJ'>(
    modalState.creator ? modalState.creator.documentType : 'CPF'
  )

  const handleResetForm = () => {
    setPersonType('CPF')
    formik.resetForm()
  }

  const formik = useFormik({
    initialValues: {
      firstName: modalState.creator?.firstName || '',
      lastName: modalState.creator?.lastName || '',
      corporateName: modalState.creator?.corporateName || '',
      document: modalState.creator?.document || '',
      email: modalState.creator?.email || '',
      phone: modalState.creator?.phone || '',
      zipCode: modalState.creator?.address?.zipCode || '',
      street: modalState.creator?.address?.street || '',
      state: modalState.creator?.address?.state || '',
      city: modalState.creator?.address?.city || '',
      number: modalState.creator?.address?.number || '',
      country: 'BR',
      complement: modalState.creator?.address?.complement || '',
      passwordCreator: '',
      appFee: modalState.creator?.creatorPaymentSetting.appFee || '',
      pixFee: modalState.creator?.creatorPaymentSetting.pixFee || '',
      creditCardFee:
        modalState.creator?.creatorPaymentSetting.creditCardFee || '',
      bank: modalState.creator?.creatorPaymentSetting.bank || '',
      bankAg: modalState.creator?.creatorPaymentSetting.bankAg || '',
      bankAccountType:
        modalState.creator?.creatorPaymentSetting.bankAccountType || '',
      bankCc: modalState.creator?.creatorPaymentSetting.bankCc || '',
      softDescriptor:
        modalState.creator?.creatorPaymentSetting.softDescriptor || '',
      maxInstallments:
        modalState.creator?.creatorPaymentSetting.maxInstallments || 0,
      paymentEmailNotificationReceiver:
        modalState.creator?.creatorPaymentSetting
          .paymentEmailNotificationReceiver || ''
    },
    enableReinitialize: true,
    validationSchema:
      modalState.type === 'EDIT' ? editCreatorSchema : createCreatorSchema,
    onSubmit: (values) => {
      try {
        formik.setErrors({})
        closeModal()
        if (modalState.type === 'CREATE') {
          dispatch(
            createCreatorRequest({
              document: values.document.replace(/[^\d]/g, ''),
              documentType: personType,
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              password: values.passwordCreator,
              corporateName:
                personType === 'CNPJ' ? values.corporateName : undefined,
              address: {
                state: values.state,
                city: values.city,
                country: 'Brasil',
                number: Number(values.number),
                street: values.street === '' ? undefined : values.street,
                complement:
                  values.complement === '' ? undefined : values.complement,
                zipCode: values.zipCode === '' ? undefined : values.zipCode
              },
              customField: undefined,
              phone: values.phone
            })
          )
          formik.resetForm()
        } else if (modalState.type === 'EDIT' && modalState.creator) {
          const newAddress = {
            street: values.street,
            state: values.state,
            city: values.city,
            number:
              Number(values.number) === 0 ? undefined : Number(values.number),
            country: values.city ? 'Brasil' : undefined,
            complement:
              values.complement === null ? undefined : values.complement,
            zipCode: values.zipCode
          }

          const creatorAddress = {
            street:
              modalState.creator.address!.street === null ||
              modalState.creator.address!.street === undefined
                ? ''
                : modalState.creator.address!.street,
            state:
              modalState.creator.address!.state === null ||
              modalState.creator.address!.state === undefined
                ? ''
                : modalState.creator.address!.state,
            city:
              modalState.creator.address!.city === null ||
              modalState.creator.address!.city === undefined
                ? ''
                : modalState.creator.address!.city,
            number:
              Number(modalState.creator.address!.number) === 0
                ? undefined
                : Number(modalState.creator.address!.number),
            country: modalState.creator.address!.city ? 'Brasil' : undefined,
            complement:
              modalState.creator.address!.complement === null ||
              modalState.creator.address!.complement === undefined
                ? ''
                : modalState.creator.address!.complement,
            zipCode:
              modalState.creator.address!.zipCode === null ||
              modalState.creator.address!.zipCode === undefined
                ? ''
                : modalState.creator.address!.zipCode
          }

          const requestValue: EditCreatorRequest = {
            address:
              _.isEqual(creatorAddress, newAddress) ||
              values.city === '' ||
              modalState.creator.address === null
                ? undefined
                : newAddress,
            corporateName:
              personType === 'CNPJ'
                ? modalState.creator.corporateName !==
                  formik.values.corporateName
                  ? formik.values.corporateName
                  : undefined
                : undefined,
            customField: undefined,
            documentType:
              modalState.creator.documentType !== personType
                ? personType
                : undefined,
            document:
              modalState.creator.document !== values.document
                ? values.document
                : undefined,
            email:
              modalState.creator.email !== values.email
                ? values.email
                : undefined,
            firstName:
              modalState.creator.firstName !== values.firstName
                ? values.firstName
                : undefined,
            lastName:
              modalState.creator.lastName !== values.lastName
                ? values.lastName
                : undefined,
            phone:
              modalState.creator.phone !== values.phone
                ? values.phone
                : undefined
          }

          let paymentData = {}

          if (formik.values.appFee !== formik.initialValues.appFee) {
            paymentData = {
              ...paymentData,

              appFee: Number(formik.values.appFee)
            }
          }

          if (formik.values.pixFee !== formik.initialValues.pixFee) {
            paymentData = {
              ...paymentData,
              pixFee: Number(formik.values.pixFee)
            }
          }

          if (
            formik.values.creditCardFee !== formik.initialValues.creditCardFee
          ) {
            paymentData = {
              ...paymentData,
              creditCardFee: Number(formik.values.creditCardFee)
            }
          }

          if (formik.values.bank !== formik.initialValues.bank) {
            paymentData = {
              ...paymentData,
              bank: formik.values.bank
            }
          }

          if (formik.values.bankAg !== formik.initialValues.bankAg) {
            paymentData = {
              ...paymentData,
              bankAg: formik.values.bankAg
            }
          }

          if (
            formik.values.bankAccountType !==
            formik.initialValues.bankAccountType
          ) {
            paymentData = {
              ...paymentData,
              bankAccountType: formik.values.bankAccountType
            }
          }

          if (formik.values.bankCc !== formik.initialValues.bankCc) {
            paymentData = {
              ...paymentData,
              bankCc: formik.values.bankCc
            }
          }

          if (
            formik.values.paymentEmailNotificationReceiver !==
            formik.initialValues.paymentEmailNotificationReceiver
          ) {
            paymentData = {
              ...paymentData,
              paymentEmailNotificationReceiver:
                formik.values.paymentEmailNotificationReceiver
            }
          }

          if (
            formik.values.softDescriptor !== formik.initialValues.softDescriptor
          ) {
            paymentData = {
              ...paymentData,
              softDescriptor: formik.values.softDescriptor
            }
          }

          if (!_.isEmpty(paymentData)) {
            const { bank, bankAccountType, bankAg, bankCc } =
              modalState.creator.creatorPaymentSetting
            paymentData = {
              ...paymentData,
              status: modalState.creator.creatorPaymentSetting.status,
              dataIsNull:
                bank === null &&
                bankAccountType === null &&
                bankAg === null &&
                bankCc === null
            }
          }

          dispatch(
            editCreatorRequest(
              Object.fromEntries(
                Object.entries(requestValue).filter(([_, v]) => v !== undefined)
              ),
              modalState.creator.id,
              modalState.creator.creatorPaymentSetting.id,
              !_.isEmpty(paymentData) ? paymentData : undefined
            )
          )

          formik.resetForm()
        }
      } catch {}
    }
  })

  const [state] = useState(
    jsonStates.estados.map((state) => ({
      value: state.sigla,
      label: state.nome
    }))
  )

  const [cities, setCities] = useState<{ value: string; label: string }[]>(
    jsonStates.estados
      .find(({ sigla }) => sigla === formik.values.state)
      ?.cidades.map((city) => ({ value: city, label: city })) ?? []
  )

  const handleCep = async (value: string) => {
    const cepFormated = value.replaceAll(/[^\d]/g, '').trim()

    if (cepFormated.length === 8) {
      try {
        const data = await viaCepService(cepFormated)
        if (data.erro !== true) {
          formik.setFieldValue('street', data.logradouro)
          formik.setFieldValue('state', data.uf)
          formik.setFieldValue('city', data.localidade)
        }
      } catch (err) {}
    }
  }

  useEffect(() => {
    if (formik.values.state !== '') {
      const stateIndex = jsonStates.estados.findIndex(
        (state) => state.sigla === formik.values.state
      )
      if (stateIndex !== -1) {
        setCities(
          jsonStates.estados[stateIndex].cidades.map((city) => ({
            value: city,
            label: city
          }))
        )
      }
    }
  }, [formik.values.state])

  useEffect(() => {
    if (modalState.creator) setPersonType(modalState.creator.documentType)
  }, [modalState.creator])

  return (
    <ContentModal open={modalState.open}>
      <S.Container>
        <S.ModalTitle>
          <Paragraph variant="large" type="bold">
            {modalState.type === 'CREATE'
              ? 'Cadastrar produtor'
              : 'Editar produtor'}
          </Paragraph>
          <S.IconExitModal
            onClick={() => {
              closeModal()
              handleResetForm()
            }}
          />
        </S.ModalTitle>

        <div className="form-content">
          <S.Form onSubmit={formik.handleSubmit}>
            <S.PersonTypeBox>
              <div className="person">
                <Paragraph variant="large" type="bold">
                  Pessoa física
                </Paragraph>
                <RadioButton
                  name="legalPerson"
                  checked={personType === 'CPF'}
                  readOnly
                  onClick={() => {
                    formik.setFieldValue('document', '')
                    formik.setFieldValue('corporateName', '')
                    setPersonType('CPF')
                  }}
                />
              </div>

              <div className="person">
                <Paragraph variant="large" type="bold">
                  Pessoa jurídica
                </Paragraph>
                <RadioButton
                  name="isLegalPerson"
                  readOnly
                  checked={personType === 'CNPJ'}
                  onClick={() => {
                    formik.setFieldValue('document', '')
                    formik.setFieldValue('corporateName', '')
                    setPersonType('CNPJ')
                  }}
                />
              </div>
            </S.PersonTypeBox>

            <Input
              name="firstName"
              label="Nome"
              placeholder={
                personType === 'CNPJ'
                  ? messageHelper.input.creator.enterCreatorNameLP
                  : messageHelper.input.creator.enterCreatorName
              }
              value={formik.values.firstName ?? ''}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              texterror={formik.errors.firstName}
              onChange={formik.handleChange}
              fullWidth
            />

            <Input
              name="lastName"
              label="Sobrenome"
              placeholder="Digite o sobrenome"
              value={formik.values.lastName ?? ''}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              texterror={formik.errors.lastName}
              onChange={formik.handleChange}
              fullWidth
            />

            {personType === 'CNPJ' ? (
              <Input
                name="corporateName"
                label="Nome da compania"
                placeholder="Digite o nome da compania"
                value={formik.values.corporateName ?? ''}
                error={
                  formik.touched.corporateName &&
                  Boolean(formik.errors.corporateName)
                }
                texterror={formik.errors.corporateName}
                onChange={formik.handleChange}
                fullWidth
              />
            ) : null}

            <InputMask
              name="document"
              fullWidth
              label={personType}
              mask={
                personType === 'CNPJ' ? '99.999.999/9999-99' : '999.999.999-99'
              }
              placeholder={
                personType === 'CNPJ' ? '00.000.000/0000-00' : '000.000.000-00'
              }
              onChange={formik.handleChange}
              value={formik.values.document ?? ''}
              error={formik.touched.document && Boolean(formik.errors.document)}
              texterror={formik.errors.document}
            />

            <Input
              name="email"
              label="Email"
              placeholder="Digite o email"
              value={formik.values.email ?? ''}
              error={formik.touched.email && Boolean(formik.errors.email)}
              texterror={formik.errors.email}
              onChange={formik.handleChange}
              fullWidth
            />

            <InputMask
              name="phone"
              label="Telefone"
              mask="(99) 9 9999-9999"
              placeholder="(00) 0 0000-0000"
              value={formik.values.phone ?? ''}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              texterror={formik.errors.phone}
              onChange={formik.handleChange}
              fullWidth
            />

            <InputMask
              mask="99999-999"
              name="zipCode"
              label="CEP"
              placeholder="Digite o CEP"
              value={formik.values.zipCode ?? ''}
              error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
              texterror={formik.errors.zipCode}
              onChange={(e) => {
                formik.handleChange(e)
                handleCep(e.target.value)
              }}
              fullWidth
            />

            <Input
              name="street"
              label="Endereço"
              placeholder="Digite o endereço"
              value={formik.values.street ?? ''}
              error={formik.touched.street && Boolean(formik.errors.street)}
              texterror={formik.errors.street}
              onChange={formik.handleChange}
              fullWidth
            />

            <Input
              name="number"
              label="Número"
              placeholder="Digite o número"
              value={formik.values.number ?? ''}
              error={formik.touched.number && Boolean(formik.errors.number)}
              texterror={formik.errors.number}
              onChange={formik.handleChange}
              fullWidth
            />

            <div>
              <S.Label>*Estado</S.Label>
              <ReactSelect
                onChange={(selectValue) => {
                  if (selectValue && selectValue.value) {
                    formik.setFieldValue('state', selectValue.value)
                    formik.setFieldValue('city', '')
                  }
                }}
                value={
                  state.find(
                    (option) => option.value === formik.values.state
                  ) ?? {
                    value: '',
                    label: ''
                  }
                }
                placeholder="Estado"
                styles={S.customStyles}
                options={state}
                noOptionsMessage={() => 'Cidade não encontrada'}
              />
              <S.Error>
                {formik.touched.state && Boolean(formik.errors.state)
                  ? formik.errors.state
                  : null}
              </S.Error>
            </div>

            <div>
              <S.Label>*Cidade</S.Label>
              <ReactSelect
                value={
                  cities.find(
                    (option) => option.value === formik.values.city
                  ) ?? {
                    value: '',
                    label: ''
                  }
                }
                onChange={(selectValue) => {
                  if (selectValue && selectValue.value) {
                    formik.setFieldValue('city', selectValue.value)
                  }
                }}
                placeholder="Cidade"
                styles={S.customStyles}
                options={cities}
                noOptionsMessage={() => 'Cidade não encontrada'}
              />
              <S.Error>
                {formik.touched.city && Boolean(formik.errors.city)
                  ? formik.errors.city
                  : null}
              </S.Error>
            </div>

            <Input
              name="complement"
              label="Complemento"
              placeholder="Digite o complemento"
              value={formik.values.complement ?? ''}
              error={
                formik.touched.complement && Boolean(formik.errors.complement)
              }
              texterror={formik.errors.complement}
              onChange={formik.handleChange}
              fullWidth
            />

            {modalState.creator ? null : (
              <Input
                type="password"
                name="passwordCreator"
                label="Senha"
                placeholder="Digite sua senha"
                value={formik.values.passwordCreator ?? ''}
                error={
                  formik.touched.passwordCreator &&
                  Boolean(formik.errors.passwordCreator)
                }
                texterror={formik.errors.passwordCreator}
                onChange={formik.handleChange}
                fullWidth
              />
            )}

            {modalState.type === 'EDIT' ? (
              <>
                <S.TitleConfigFee>
                  <Paragraph variant="large" type="bold">
                    Configurações de taxas
                  </Paragraph>
                </S.TitleConfigFee>
                <CurrencyInput
                  name="appFee"
                  label="Taxa do app"
                  error={formik.touched.appFee && Boolean(formik.errors.appFee)}
                  texterror={formik.errors.appFee}
                  value={formik.values.appFee ?? ''}
                  onChange={(e) => {
                    if (
                      e.target.value !== undefined &&
                      e.target.value.replace(/[a-zA-Z, %]/g, '').length > 0
                    ) {
                      formik.setFieldValue(
                        'appFee',
                        e.target.value.replace(/[a-zA-Z, %]/g, '')
                      )
                    }

                    if (
                      e.target.value !== undefined &&
                      e.target.value.replace(/[a-zA-Z, %]/g, '').length === 0
                    ) {
                      formik.setFieldValue('appFee', '')
                    }
                  }}
                  groupSeparator=","
                  decimalSeparator="."
                  suffix=" %"
                  placeholder="Digite a % da taxa do app"
                />
                <CurrencyInput
                  name="pixFee"
                  label="Taxa do pix"
                  error={formik.touched.pixFee && Boolean(formik.errors.pixFee)}
                  texterror={formik.errors.pixFee}
                  value={formik.values.pixFee ?? ''}
                  onChange={(e) => {
                    if (
                      e.target.value !== undefined &&
                      e.target.value.replace(/[a-zA-Z, %]/g, '').length > 0
                    ) {
                      formik.setFieldValue(
                        'pixFee',
                        e.target.value.replace(/[a-zA-Z, %]/g, '')
                      )
                    }

                    if (
                      e.target.value !== undefined &&
                      e.target.value.replace(/[a-zA-Z, %]/g, '').length === 0
                    ) {
                      formik.setFieldValue('pixFee', '')
                    }
                  }}
                  groupSeparator=","
                  decimalSeparator="."
                  suffix=" %"
                  placeholder="Digite a % da taxa do pix"
                />
                <CurrencyInput
                  name="creditCardFee"
                  label="Taxa do cartão de crédito"
                  error={
                    formik.touched.creditCardFee &&
                    Boolean(formik.errors.creditCardFee)
                  }
                  texterror={formik.errors.creditCardFee}
                  value={formik.values.creditCardFee ?? ''}
                  onChange={(e) => {
                    if (
                      e.target.value !== undefined &&
                      e.target.value.replace(/[a-zA-Z, %]/g, '').length > 0
                    ) {
                      formik.setFieldValue(
                        'creditCardFee',
                        e.target.value.replace(/[a-zA-Z, %]/g, '')
                      )
                    }

                    if (
                      e.target.value !== undefined &&
                      e.target.value.replace(/[a-zA-Z, %]/g, '').length === 0
                    ) {
                      formik.setFieldValue('creditCardFee', '')
                    }
                  }}
                  groupSeparator=","
                  decimalSeparator="."
                  suffix=" %"
                  placeholder="Digite a % da taxa do cartão de crédito"
                />
                <S.TitleConfigFee>
                  <Paragraph variant="large" type="bold">
                    Configurações bancárias
                  </Paragraph>
                </S.TitleConfigFee>
                <Input
                  value={formik.values.bankAg}
                  label="Agência"
                  name="bankAg"
                  type="text"
                  fullWidth
                  onChange={formik.handleChange}
                />
                <div>
                  <S.Label>Banco</S.Label>
                  <ReactSelect
                    id="banks"
                    placeholder="Banco"
                    options={mappedBanks}
                    onChange={(e) => formik.setFieldValue('bank', e?.value)}
                    // @ts-ignore
                    styles={S.customStyles}
                    value={mappedBanks.find(
                      (option) =>
                        option.label === formik.values.bank ||
                        option.value === formik.values.bank
                    )}
                  />
                  <S.Error>
                    {formik.touched.bank && Boolean(formik.errors.bank)
                      ? formik.errors.bank
                      : null}
                  </S.Error>
                </div>
                <Input
                  value={formik.values.bankCc}
                  label="Conta"
                  name="bankCc"
                  onChange={formik.handleChange}
                  type="text"
                  fullWidth
                />
                <div>
                  <S.Label>Tipo de Conta</S.Label>
                  <ReactSelect
                    id="accountType"
                    value={accountOptions.find(
                      (option) => option.value === formik.values.bankAccountType
                    )}
                    placeholder="Tipo de conta"
                    options={accountOptions}
                    onChange={(e) =>
                      formik.setFieldValue('bankAccountType', e?.value)
                    }
                    // @ts-ignore
                    styles={S.customStyles}
                  />
                  <S.Error>
                    {formik.touched.bankAccountType &&
                    Boolean(formik.errors.bankAccountType)
                      ? formik.errors.bankAccountType
                      : null}
                  </S.Error>
                </div>
                <S.TitleConfigFee>
                  <Paragraph variant="large" type="bold">
                    Outras configurações
                  </Paragraph>
                </S.TitleConfigFee>
                <Input
                  value={formik.values.softDescriptor}
                  label="Mensagem padrão ao cliente"
                  name="softDescriptor"
                  onChange={formik.handleChange}
                  type="text"
                  fullWidth
                />
                <Input
                  value={formik.values.paymentEmailNotificationReceiver}
                  label="Email para receber notificações de compra"
                  name="paymentEmailNotificationReceiver"
                  onChange={formik.handleChange}
                  type="email"
                  fullWidth
                />
              </>
            ) : undefined}

            <S.ButtonBox>
              <Button
                color="primary"
                size="large"
                variant="text"
                fullWidth
                type="button"
                onClick={() => {
                  closeModal()
                  handleResetForm()
                }}>
                Cancelar
              </Button>
              <Button
                color="primary"
                size="large"
                variant="contained"
                type="submit"
                fullWidth>
                {modalState.type === 'CREATE' ? 'Cadastrar' : 'Editar'}
              </Button>
            </S.ButtonBox>
          </S.Form>
        </div>
      </S.Container>
    </ContentModal>
  )
}
