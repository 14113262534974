import { Hidden } from 'applaus-ui-kit'
import { MobileTicketCard } from '../../MobileTicketCard/Ticket'
import * as S from './Purchases.styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  viewMorePurchaseOrderRequest,
  setPurchaseOrderCustomer,
  showRequest
} from '../../../../redux/store/Purchases/actions'
import { ReduxState } from '../../../../redux/store/rootReducer'
import TicketImage from '../../../../assets/icons/coupon.svg'
import { ThemeProvider } from 'styled-components'
import { useParams } from 'react-router-dom'

const customConf: any = {
  mediaQuery: 'only screen',
  columns: {
    xs: 4,
    sm: 8,
    md: 8,
    lg: 12,
    xl: 12
  },
  gutterWidth: {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 2,
    xl: 2
  },
  paddingWidth: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1.5,
    xl: 1.5
  },
  container: {
    xs: 'full', // 'full' = max-width: 100%
    sm: 'full', // 'full' = max-width: 100%
    md: 'full', // 'full' = max-width: 100%
    lg: 90, // max-width: 1440px
    xl: 90 // max-width: 1440px
  },
  breakpoints: {
    xs: 1,
    sm: 48, // 768px
    md: 64, // 1024px
    lg: 90, // 1440px
    xl: 120 // 1920px
  }
}

export const Purchases = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()

  const { purchasesState } = useSelector(
    (state: ReduxState) => state.PurchasesReducer
  )

  return (
    // @ts-ignore
    <ThemeProvider theme={{ awesomegrid: customConf }}>
      <S.Container>
        <S.Wrapper>
          <S.TotalRequest>
            <S.TextRequest>Pedidos</S.TextRequest>

            <S.TextTotalRequest>
              {purchasesState.items.length}
            </S.TextTotalRequest>
          </S.TotalRequest>

          {purchasesState.items.length === 0 ? (
            <S.ContentNoTicket>
              <S.TicketImage src={TicketImage} />
              <S.TitleNoTicket>
                Cliente ainda não possui nenhum ingresso
              </S.TitleNoTicket>
            </S.ContentNoTicket>
          ) : null}

          <S.Cards>
            {purchasesState.items.map((purchase, index) => (
              <div key={purchase.id}>
                <Hidden tablet>
                  <MobileTicketCard
                    image={purchase.eventItem.event.banners.mobileUrl}
                    dateEvent={purchase.eventItem.dates.map((date) => date)}
                    locationEvent={purchase.eventItem.event.address.street}
                    titleEvent={purchase.eventItem.event.name}
                    click={() => {
                      dispatch(setPurchaseOrderCustomer(purchase.id))
                      dispatch(showRequest())
                    }}
                  />
                </Hidden>
                <Hidden mobile>
                  <img
                    src={purchase.eventItem.event.banners.tabletUrl}
                    onClick={() => {
                      dispatch(setPurchaseOrderCustomer(purchase.id))
                      dispatch(showRequest())
                    }}
                  />
                </Hidden>
              </div>
            ))}
          </S.Cards>

          {purchasesState.items.length > 0 &&
          purchasesState.items.length < purchasesState.total ? (
            <S.SeeMore
              onClick={() => dispatch(viewMorePurchaseOrderRequest(id))}>
              Ver mais
            </S.SeeMore>
          ) : null}
        </S.Wrapper>
      </S.Container>
    </ThemeProvider>
  )
}
