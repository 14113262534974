import styled, { css } from 'styled-components'

import { ReactComponent as RemoveFile } from '../../../../../../assets/icons/removeIcon.svg'

export const CustomStyles = {
  option: (provided: any) => ({
    ...provided,
    fontSize: 16,
    '& input': {
      font: 'inherit'
    }
  }),
  multiValue: (styles: any) => {
    return {
      ...styles,
      fontSize: 16
    }
  },
  control: (provided: any) => ({
    ...provided,
    background: '#F4F4F4',
    border: 0,
    boxShadow: 'none',
    padding: '2px 16px',
    borderRadius: '8px'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontFamily: 'Poppins',
    fontSize: 16,
    '& input': {
      font: 'inherit'
    }
  }),
  input: (provided: any) => ({
    ...provided,
    fontFamily: 'Poppins',
    fontSize: 16,
    width: 'max-content',
    '& input': {
      font: 'inherit'
    }
  })
}

export const RemoveIcon = styled(RemoveFile)`
  cursor: pointer;
`

export const Container = styled.div`
  @media (max-width: 675px) {
    label {
      width: 450px;
    }
  }

  @media (max-width: 500px) {
    label {
      width: 275px;
    }
  }
`

export const SelectedSections = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    margin: ${theme.spacing.stack.small} 0 ${theme.spacing.stack.sm};
    > div {
      margin: ${theme.spacing.stack.small} ${theme.spacing.stack.small} 0;
    }
  `}
`

export const SelectedSectionCard = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing.stack.small} ${theme.spacing.inline.xxxs};
    background: #8601c9;
    border-radius: ${theme.border.radius.medium};
    > p {
      color: white;
      margin-right: ${theme.spacing.stack.small};
    }
  `}
`

export const TicketPerSession = styled.div`
  display: flex;
  flex-direction: column;
  .sessionInputs {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    > div {
      margin-right: 2rem;
    }
    @media (max-width: 1170px) {
      width: 100%;
      flex-direction: column;
      > div {
        margin-right: 0;
      }
    }
  }
`

export const TicketTypeSelection = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: max-content;
    flex-direction: column;
    .optionsWrapper {
      margin-top: 2rem;
      display: flex;
      > div:first-of-type {
        margin-right: ${theme.spacing.stack.xl};
      }
    }
    @media (max-width: 1170px) {
      .optionsWrapper {
        flex-direction: column;
      }
    }
  `}
`

export const RadioButtonBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    > p {
      margin-left: ${theme.spacing.stack.small};
    }
    @media (max-width: 1170px) {
      margin-top: ${theme.spacing.stack.small};
    }
  `}
`

export const TicketInfoBox = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacing.stack.xxs} 0;
    display: flex;
    flex-direction: column;
    @media (min-width: 1170px) {
      width: max-content;
      > div {
        width: max-content;
      }

      .inputType {
        display: flex;
        gap: ${theme.spacing.stack.xxxs};
      }
    }
  `}
`

export const PriceBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacing.stack.xxxs};
    width: 244px;

    @media (max-width: 1170px) {
      flex-direction: column;
      > div {
        width: 100%;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  `}
`

export const customStylesType = {
  clearIndicator: () => ({
    border: '1px solid blue'
  }),
  control: (provided: any) => ({
    ...provided,
    display: 'flex',
    padding: '2px 16px',
    background: '#F4F4F4',
    outline: 'none',
    border: '1px solid transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: '6px',
    '&:focus-within': {
      borderColor: '#8601C9'
    }
  }),
  valueContainer: (provided: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }),
  indicatorSeparator: (provided: any) => ({
    display: 'none'
  }),
  singleValue: (provided: any) => ({
    color: '#39383A',
    fontSize: '16px',
    lineHeight: '20px'
  }),
  placeholder: (provided: any) => ({
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    backgroundColor: state.isSelected ? '#F4F4F4' : 'white'
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  })
}

export const Label = styled.p`
  ${({ theme }) => css`
    color: #39383a;
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.regular};
    line-height: 24px;
    margin-bottom: 2px;
  `}
`

export const Error = styled.p`
  color: #fc3e3e;
  font-size: 12px;
  min-height: 24px;
`
