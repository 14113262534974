import * as S from './Purchases.styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  viewMorePurchaseOrderRequest,
  setPurchaseOrderCustomer,
  showRequest
} from '../../../../redux/store/Purchases/actions'
import { ReduxState } from '../../../../redux/store/rootReducer'
import TicketImage from '../../../../assets/icons/coupon.svg'
import { ThemeProvider } from 'styled-components'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

const customConf: any = {
  mediaQuery: 'only screen',
  columns: {
    xs: 4,
    sm: 8,
    md: 8,
    lg: 12,
    xl: 12
  },
  gutterWidth: {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 2,
    xl: 2
  },
  paddingWidth: {
    xs: 1,
    sm: 1,
    md: 0,
    lg: 0,
    xl: 0
  },
  container: {
    xs: 'full', // 'full' = max-width: 100%
    sm: 'full', // 'full' = max-width: 100%
    md: 'full', // 'full' = max-width: 100%
    lg: 90, // max-width: 1440px
    xl: 90 // max-width: 1440px
  },
  breakpoints: {
    xs: 1,
    sm: 48, // 768px
    md: 64, // 1024px
    lg: 90, // 1440px
    xl: 120 // 1920px
  }
}

export const Pucharses = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const [eventSearch, setEventSearch] = useState('')

  const { purchasesState } = useSelector(
    (state: ReduxState) => state.PurchasesReducer
  )

  const events = useMemo(
    () =>
      purchasesState.items.filter(({ eventItem }) =>
        eventItem.event.name
          .toLowerCase()
          .includes(eventSearch.toLocaleLowerCase())
      ),
    [eventSearch, purchasesState]
  )

  return (
    // @ts-ignore
    <ThemeProvider theme={{ awesomegrid: customConf }}>
      <S.Container>
        {purchasesState.items.length === 0 ? (
          <S.ContentNoTicket>
            <S.TicketImage src={TicketImage} />
            <S.TitleNoTicket>
              Cliente ainda não possui nenhum ingresso
            </S.TitleNoTicket>
          </S.ContentNoTicket>
        ) : (
          <>
            <S.InputWrapper>
              <S.SearchIcon />
              <S.Input
                placeholder="Buscar pelo nome do evento"
                onChange={(e) => setEventSearch(e.target.value)}
              />
            </S.InputWrapper>

            <S.CardEvent>
              {events.map((purchase, index) => (
                <img
                  key={index}
                  style={{
                    width: '100%',
                    borderRadius: '12px',
                    cursor: 'pointer'
                  }}
                  src={purchase.eventItem.event.banners.desktopUrl}
                  onClick={() => {
                    dispatch(setPurchaseOrderCustomer(purchase.id))
                    dispatch(showRequest())
                  }}
                />
              ))}
            </S.CardEvent>
          </>
        )}

        {purchasesState.items.length > 0 &&
        purchasesState.items.length < purchasesState.total ? (
          <S.SeeMore onClick={() => dispatch(viewMorePurchaseOrderRequest(id))}>
            Ver mais
          </S.SeeMore>
        ) : null}
      </S.Container>
    </ThemeProvider>
  )
}
