import { useEffect, useMemo, useState } from 'react'

import * as S from './Pagination.styles'

type PaginationProps = {
  totalItems: number
  itemsPerPage: number
  currentPage: number
  onPageChange: (page: number) => void
}

export const Pagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange
}: PaginationProps) => {
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalItems / itemsPerPage))
  }, [totalItems, itemsPerPage])

  const PaginationItems = useMemo(() => {
    const pages = []
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <S.PaginationItem
          key={i}
          active={i === currentPage}
          onClick={() => onPageChange(i)}>
          {i}
        </S.PaginationItem>
      )
    }
    return pages
  }, [totalPages, currentPage])

  if (totalPages === 0) return null

  return (
    <S.Container>
      <S.Prev
        className="uil uil-angle-left-b"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {PaginationItems}
      <S.Next
        className="uil uil-angle-right-b"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
    </S.Container>
  )
}
