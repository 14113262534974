import { AxiosResponse } from 'axios'
import { api } from '../../api'

export type BannersResponse = {
  id: string
  name: string
  description: string
  link: string
  position: number
  status: boolean
  linkTarget: string
  desktopUrl: string
  mobileUrl: string
  tabletUrl: string
  createdAt: string
  updatedAt: string
}

export const getBannersService = async () => {
  const { data }: AxiosResponse<BannersResponse[]> = await api.get(
    '/api/backoffice/banners'
  )

  return data
}
