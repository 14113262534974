import { AxiosResponse } from 'axios'
import { api } from '../../api'

export type CreateChartRequest = {
  name: string
  address: {
    street: string
    number: number
    city: string
    state: string
    complement?: string
    country: string
    zipCode: string
  }
  seatChartId: string
}

export type CreateChartResponse = {
  id: string
  name: string
  seatChartId: string
  address: {
    city: string
    country: string
    number: number
    state: string
    street: string
    complement: string
    zipCode: string
  }
  createdAt: string
  updateAt: string | null
}

export const createChartService = async (dataChart: CreateChartRequest) => {
  const { data }: AxiosResponse<CreateChartResponse> = await api.post(
    '/api/backoffice/seatMaps',
    dataChart
  )

  return data
}
