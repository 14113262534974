import { Editor } from '@tinymce/tinymce-react'
import { MutableRefObject } from 'react'

type Props = {
  initialValue?: string
  editorRef: MutableRefObject<any>
  onLoadContent: () => void
}

export const RichText = ({ initialValue, editorRef, onLoadContent }: Props) => {
  return (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE_KEY}
      onInit={(evt, editor) => (editorRef.current = editor)}
      initialValue={initialValue}
      onLoadContent={onLoadContent}
      init={{
        branding: false,
        language: 'pt_BR',
        height: 500,
        menubar: false,
        code_dialog_width: 300,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
          'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
        content_style:
          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
    />
  )
}
