import { BackButton } from '../../../../../components/BackButton/BackButton'
import * as S from './ShowRequest.styles'
import { Button, Heading, Paragraph } from 'applaus-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import {
  defaultPurchase,
  showTicket
} from '../../../../../redux/store/Purchases/actions'
import { ReduxState } from '../../../../../redux/store/rootReducer'
import { motion } from 'framer-motion'
import moment from 'moment'
import { formatPrice } from '../../../../../utils/formatPrice'
import { BrandCreditCard } from '../../../../../components/brandCreditCard/BrandCreditCard'

export const ShowRequest = () => {
  const dispatch = useDispatch()

  const { selectedPurchaseOrder } = useSelector(
    (state: ReduxState) => state.PurchasesReducer
  )
  return (
    <motion.div animate={{ opacity: [0, 1], transition: { duration: 0.5 } }}>
      <S.Container>
        <BackButton
          title={
            'Pedido: ' + selectedPurchaseOrder?.orderId.toLocaleUpperCase()
          }
          subTitle={
            'Comprado em ' +
            moment(
              selectedPurchaseOrder?.updatedAt ||
                selectedPurchaseOrder?.createdAt
            ).format('DD/MM/YYYY') +
            ' às ' +
            moment(
              selectedPurchaseOrder?.updatedAt ||
                selectedPurchaseOrder?.createdAt
            ).format('LT')
          }
          onClick={() => dispatch(defaultPurchase())}
        />

        <S.Wrapper>
          <S.CoverUrl
            src={selectedPurchaseOrder?.eventItem.event.banners.mobileUrl}
            alt=""
          />

          <S.PaymentStatus>
            <S.TextStatus status={selectedPurchaseOrder!.status}>
              Pagamento:{' '}
              <p className="approvedText">
                {selectedPurchaseOrder?.status === 'WAITING'
                  ? 'EM ANÁLISE'
                  : 'APROVADO'}
              </p>
            </S.TextStatus>

            <S.PaymentData>
              Realizado em{' '}
              {moment(
                selectedPurchaseOrder?.updatedAt ||
                  selectedPurchaseOrder?.createdAt
              ).format('DD/MM/YYYY') +
                ' às ' +
                moment(
                  selectedPurchaseOrder?.updatedAt ||
                    selectedPurchaseOrder?.createdAt
                ).format('LT')}
            </S.PaymentData>
          </S.PaymentStatus>

          {selectedPurchaseOrder?.payment.cashBackDiscountCents === null ||
          selectedPurchaseOrder?.payment.cashBackDiscountCents === 0 ? (
            <S.PaymentValue>
              <S.TextValue>
                {formatPrice(selectedPurchaseOrder!.payment.totalPrice / 100)}{' '}
                {selectedPurchaseOrder?.payment.installments === undefined ||
                selectedPurchaseOrder?.payment.installments === null
                  ? 'à vista'
                  : `${selectedPurchaseOrder?.payment.installments}x`}
              </S.TextValue>
              <BrandCreditCard
                brandIcon={
                  selectedPurchaseOrder!.payment.paymentMethod === 'PIX'
                    ? selectedPurchaseOrder!.payment.paymentMethod
                    : selectedPurchaseOrder!.payment.brand
                }
                brand={
                  selectedPurchaseOrder!.payment.paymentMethod === 'PIX'
                    ? ''
                    : selectedPurchaseOrder!.payment.brand
                }
                paymentMethod={selectedPurchaseOrder!.payment.paymentMethod}
                lastNumbers={selectedPurchaseOrder!.payment.lastCardNumbers}
                final
              />
            </S.PaymentValue>
          ) : (
            <div style={{ marginTop: '16px' }} />
          )}

          {selectedPurchaseOrder?.payment.cashBackDiscountCents !== null &&
          selectedPurchaseOrder?.payment.cashBackDiscountCents! > 0 ? (
            <S.CashBackPaid>
              <div className="cashbackBox">
                <Paragraph variant="small" className="bold">
                  {formatPrice(
                    selectedPurchaseOrder?.payment.cashBackDiscountCents! / 100
                  )}
                </Paragraph>

                <div>
                  <S.IconCashback />
                  <Paragraph variant="small">Cashback</Paragraph>
                </div>
              </div>
              <div className="paymentBox">
                <Paragraph variant="small" className="bold">
                  {formatPrice(
                    (selectedPurchaseOrder?.payment.totalPrice! -
                      selectedPurchaseOrder?.payment.cashBackDiscountCents!) /
                      100
                  )}
                </Paragraph>

                <div>
                  <BrandCreditCard
                    brandIcon={
                      selectedPurchaseOrder!.payment.paymentMethod === 'PIX'
                        ? selectedPurchaseOrder!.payment.paymentMethod
                        : selectedPurchaseOrder!.payment.brand
                    }
                    brand={
                      selectedPurchaseOrder!.payment.paymentMethod === 'PIX'
                        ? selectedPurchaseOrder!.payment.brand
                        : selectedPurchaseOrder!.payment.brand
                    }
                    paymentMethod={selectedPurchaseOrder!.payment.paymentMethod}
                    lastNumbers={selectedPurchaseOrder!.payment.lastCardNumbers}
                    final
                  />
                </div>
              </div>

              <div className="divider" />

              <Heading variant="h3">
                Total:{' '}
                {formatPrice(selectedPurchaseOrder?.payment.totalPrice! / 100)}
              </Heading>
            </S.CashBackPaid>
          ) : null}

          {selectedPurchaseOrder?.eventItem.event.cashBackPercent !== null &&
          selectedPurchaseOrder?.eventItem.event.cashBackPercent! > 0 ? (
            <S.CashBackReceived>
              <Paragraph variant="large" className="textCashback">
                Você ganhou!
              </Paragraph>
              <Paragraph variant="regular">
                {formatPrice(
                  (selectedPurchaseOrder?.eventItem.event.cashBackPercent! *
                    selectedPurchaseOrder?.payment.totalPrice!) /
                    10000
                )}{' '}
                de cashback com essa compra
              </Paragraph>
            </S.CashBackReceived>
          ) : null}

          {selectedPurchaseOrder?.status === 'SUCCESS' ? (
            <Button
              fullWidth
              variant="contained"
              size="small"
              color="primary"
              onClick={() => dispatch(showTicket())}>
              Ver ingressos
            </Button>
          ) : null}
        </S.Wrapper>
      </S.Container>
    </motion.div>
  )
}
