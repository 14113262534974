import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { InputHTMLAttributes } from 'react'
import * as S from './PriceInput.styles'
import { ErrorIcon } from 'assets/icons/ErrorIcon'

const defaultMaskOptions = {
  prefix: 'R$ ',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
  requireDecimal: true
}

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  value: string
  error?: boolean
  label: string
  texterror?: string
}

const PriceInput = ({ error, label, texterror, ...props }: Props) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions
  })

  return (
    <>
      <S.Label>{label}</S.Label>
      <S.Wrapper>
        <S.Input mask={currencyMask} error={error} {...props} />
        {error ? (
          <S.IconSvg error>
            <ErrorIcon />
          </S.IconSvg>
        ) : undefined}
      </S.Wrapper>
      <S.Error>{error ? texterror : ''}</S.Error>
    </>
  )
}

export default PriceInput
