import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Pagination,
  TableBody,
  TableContainer,
  TableData,
  TableFooter,
  TableRow
} from '../../../components/table'
import TableHeader from '../../../components/table/TableHeader/TableHeader'
import { ReduxState } from '../../../redux/store/rootReducer'
import { getBackofficeRequest } from '../../../redux/store/UserBackoffice/actions'
import { Backoffice } from '../../../redux/store/UserBackoffice/types'

import * as S from './UsersBackofficeTable.styles'

export type Headers = {
  name: string
  field: string
  align: 'center' | 'right' | 'left'
  sortable: boolean
}

type UserBackofficeProps = {
  editBackoffice: (userBackoffice: Backoffice) => void
  deleteUserBackoffice: (userBackoffice: Backoffice) => void
}

export const UsersBackofficeTable = ({
  editBackoffice,
  deleteUserBackoffice
}: UserBackofficeProps) => {
  const { data, currentPage, take } = useSelector(
    (state: ReduxState) => state.Backoffice
  )

  const dispatch = useDispatch()

  const headers: Headers[] = [
    { name: 'Nome', field: 'name', align: 'left', sortable: false },
    { name: 'Sobrenome', field: 'position', align: 'left', sortable: false },
    { name: 'Email', field: 'email', align: 'left', sortable: false }
  ]

  const paginationData = useMemo(() => {
    const computedData = data.items

    return computedData
  }, [data, currentPage])

  useEffect(() => {
    dispatch(getBackofficeRequest((currentPage - 1) * take, currentPage))
  }, [])

  return (
    <>
      <S.Container>
        <TableContainer
          table={
            <>
              <TableHeader
                headers={headers}
                type="manage"
                onSorting={() => {}}
              />

              <TableBody>
                {paginationData.map((userBackoffice) => {
                  return (
                    <TableRow key={userBackoffice.id} selected={false}>
                      <TableData>{userBackoffice.firstName}</TableData>
                      <TableData>{userBackoffice.lastName}</TableData>
                      <TableData>{userBackoffice.email}</TableData>
                      <TableData align="right">
                        <S.IconBox>
                          <S.EditIcon
                            onClick={() => editBackoffice(userBackoffice)}
                          />
                          <S.ExcludeIcon
                            onClick={() => deleteUserBackoffice(userBackoffice)}
                          />
                        </S.IconBox>
                      </TableData>
                    </TableRow>
                  )
                })}
              </TableBody>
            </>
          }
          footer={
            <>
              <TableFooter>
                <p>{data.total} usuários cadastrados</p>
                <Pagination
                  currentPage={currentPage}
                  itemsPerPage={take}
                  onPageChange={(page) =>
                    dispatch(getBackofficeRequest((page - 1) * take, page))
                  }
                  totalItems={data.total}
                />
              </TableFooter>
            </>
          }
        />
      </S.Container>
    </>
  )
}
