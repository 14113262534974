import { action } from 'typesafe-actions'
import { Events, EventsAction } from './types'

export const listEventsRequestAction = (skip: number, currentPage: number) =>
  action(EventsAction.loadEventRequest, { skip, currentPage })

export const listEventsSuccessAction = (
  data: Events,
  skip: number,
  currentPage: number
) => action(EventsAction.loadEventSuccess, { data, skip, currentPage })

export const removeEventRequest = (eventId: string) =>
  action(EventsAction.removeEventRequest, eventId)

export const removeEventSuccess = (eventId: string) =>
  action(EventsAction.removeEventSuccess, eventId)
