import { AlertModal } from 'applaus-ui-kit'
import { useState } from 'react'
import { AuthenticateTemplate } from '../../components/templates/AuthenticateTemplate'
import * as S from './Events.styles'
import { EventsTable } from './EventsTable/EventsTable'
import { Event } from '../../redux/store/Events/types'
import { removeEventRequest } from '../../redux/store/Events/actions'
import { useDispatch } from 'react-redux'

export type ModalState = {
  open: boolean
  event?: Event
}

type AlertModalState = {
  buttonText: string
  click: () => void
  message: string
  open: boolean
  variant: 'error' | 'success'
  cancelClick?: () => void
  description?: string
}

export const Events = () => {
  const dispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  const [modalState, setModalState] = useState<ModalState>({ open: false })
  const [alertModalState, setAlertModalState] = useState<AlertModalState>({
    buttonText: '',
    click: () => {},
    message: '',
    open: false,
    variant: 'error'
  })

  const resetAlertModalState = () => {
    setAlertModalState({
      buttonText: '',
      click: () => {},
      message: '',
      open: false,
      variant: 'error'
    })
  }

  const setEventEdit = (event: Event) => {
    setModalState({ open: true, event })
  }

  const deleteEvents = (eventId: string) => {
    setAlertModalState({
      buttonText: 'Deletar',
      click: () => {
        dispatch(removeEventRequest(eventId))
        resetAlertModalState()
      },
      message: 'Deseja mesmo deletar o Evento?',
      open: true,
      variant: 'error',
      cancelClick: resetAlertModalState
    })
  }

  return (
    <>
      <AuthenticateTemplate>
        <S.Container>
          <S.Header>
            <div>
              <S.CourseIcon />
              <S.Title>Eventos</S.Title>
            </div>
          </S.Header>
          <div className="inputBox">
            <S.SearchIcon />
            <S.Searchnput placeholder="Procure eventos por nome ou status" />
          </div>
          <EventsTable
            setEventEdit={setEventEdit}
            deleteEvents={deleteEvents}
          />
        </S.Container>
      </AuthenticateTemplate>
      <AlertModal
        buttonText={alertModalState.buttonText}
        click={alertModalState.click}
        message={alertModalState.message}
        open={alertModalState.open}
        variant={alertModalState.variant}
        cancelClick={alertModalState.cancelClick}
        description={alertModalState.description}
      />
    </>
  )
}
