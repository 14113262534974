import styled, { css } from 'styled-components'

import { ReactComponent as BackFile } from '../../../../assets/icons/backIcon.svg'

export const BackIcon = styled(BackFile)`
  cursor: pointer;
  transition: 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
`

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxs};
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    > div:first-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: ${theme.spacing.stack.xxs};
      > p {
        font-size: ${theme.font.size.sm};
      }
    }
    .backbutton {
      margin: 0 ${theme.spacing.stack.small} 0 0;
    }
  `}
`

export const TicketBox = styled.section`
  ${({ theme }) => css`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    filter: drop-shadow(0px -1px 2px rgba(0, 0, 0, 0.2));
    display: flex;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxs};
  `}
`

export const TicketWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    position: relative;
    > button {
      background: ${theme.color.alert.success.nth1};
    }
  `}
`

export const TicketAmount = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${theme.spacing.inline.small};
    background: ${theme.color.alert.success.nth2};
    padding: 2px ${theme.spacing.inline.small};
    border-radius: ${theme.border.radius.medium};
    > p {
      color: white;
    }
  `}
`
