import { AxiosResponse } from 'axios'
import { api } from '../../api'

export type CreateCreatorRequest = {
  firstName: string
  lastName: string
  corporateName?: string
  address?: {
    street?: string
    state: string
    city: string
    number?: number
    country?: string
    complement?: string
    zipCode?: string
  }
  email: string
  document: string
  documentType: 'CPF' | 'CNPJ'
  phone?: string
  password: string
  customField?: string
}

export type CreateCreatorResponse = {
  id: string
  firstName: string
  lastName: string
  corporateName: string | null
  document: string
  documentType: string
  email: string
  phone: string | null
  userId: string
  createdAt: string
  updatedAt: string | null
  customField: string | null
  address: {
    country: string
    state: string
    city: string
    street: string
    number: number
    zipCode: string
    complement: string | null
  } | null
}

export const createCreatorService = async (
  dataCreator: CreateCreatorRequest
) => {
  const { data }: AxiosResponse<CreateCreatorResponse> = await api.post(
    '/api/backoffice/creators',
    dataCreator
  )

  return data
}
