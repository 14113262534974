import { api } from '../../api'
import { AxiosResponse } from 'axios'
import { Withdrawals } from '../../../redux/store/Withdrawals/types'

export const updateWithdrawService = async (
  withdrawId: string,
  comments: string,
  status: 'REJECTED' | 'APPROVED' | 'WAITING'
) => {
  const { data }: AxiosResponse<Withdrawals> = await api.put(
    `api/backoffice/balance/withdrawal/${withdrawId}`,
    { comments, status }
  )

  return data
}
