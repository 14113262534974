import { all } from 'redux-saga/effects'
import { sagaBanner } from './Banners/saga'
import { sagaCustomer } from './Customers/saga'
import { sagaCreator } from './Creators/saga'
import { sagaCourses } from './Courses/saga'
import { sagaCharts } from './Charts/saga'
import { sagaBackoffice } from './UserBackoffice/saga'
import { ContentsSaga } from './Events/saga'
import { EventSaga } from './EditEvent/saga'
import { ManageEvent } from './ManageEvent/sagas'
import { PurchaseSaga } from './Purchases/saga'
import { WithdrawalsSaga } from './Withdrawals/saga'

export function* rootSaga(): any {
  return yield all([
    sagaBanner,
    sagaCustomer,
    sagaCreator,
    sagaCourses,
    sagaCharts,
    sagaBackoffice,
    EventSaga,
    ContentsSaga,
    ManageEvent,
    PurchaseSaga,
    WithdrawalsSaga
  ])
}
