import { AxiosResponse } from 'axios'
import { api } from 'api/api'
import { isEmpty } from 'lodash'

export type SocialLinks = {
  facebookLink: string
  youtubeLink: string
  instagramLink: string
  tiktokLink: string
}

type Request = {
  facebookLink?: string
  youtubeLink?: string
  instagramLink?: string
  tiktokLink?: string
}

export const updateSocialLinks = async (
  eventId: string,
  origin: SocialLinks | null,
  requestData: Request
) => {
  const changes: any = {}

  for (const prop in origin) {
    const propName = prop as
      | 'facebookLink'
      | 'youtubeLink'
      | 'instagramLink'
      | 'tiktokLink'
    if (
      propName.indexOf('$') !== 0 &&
      origin[propName] !== requestData[propName]
    ) {
      changes[propName] = requestData[propName]
    }
  }

  if (!isEmpty(changes) || origin === null) {
    const { data }: AxiosResponse<{ socialLinks: SocialLinks }> = await api.put(
      `/api/backoffice/events/${eventId}`,
      {
        socialLinks: requestData
      }
    )
    return data.socialLinks
  }
}
