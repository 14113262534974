import axios, { AxiosResponse } from 'axios'
import { store } from '../../../redux/store/store'
import { setModalState } from '../../../redux/store/Modal/Actions/actionsModal'
import { EventItems } from '../../../redux/store/EditEvent/types'
import { api } from '../../api'

type CreateTicketRequest = {
  name: string
  description: string
  priceCents?: number
  discountCents?: number
  type: string
  category?: string
  isFree?: boolean
  rule?: string
  eventItemsTicket: {
    eventItemId: string
    ticketQuantity: number
  }[]
}

export type CreateTicketResponse = {
  id: string
  name: string
  description: string
  priceCents: number
  isFree?: boolean
  discountCents: number
  maxCustomerTickets: number
  type: string
  category: string
  rule: string
  eventItems: EventItems[]
  createdAt: string
}

export const createTicketService = async (
  request: CreateTicketRequest,
  creatorId: string
) => {
  const { dispatch } = store

  if (request.rule === '') {
    delete request.rule
  }

  try {
    const { data }: AxiosResponse<CreateTicketResponse> = await api.post(
      `/api/backoffice/creators/${creatorId}/tickets`,
      request
    )
    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      switch (error.response?.status) {
        case 400:
          switch (error.response.data.message) {
            case 'price cents cannot be less than discount cents':
              dispatch(
                setModalState({
                  open: true,
                  variant: 'error',
                  message: 'Preço não pode ser menor que preço com desconto',
                  buttonText: 'Fechar'
                })
              )
              break

            default:
              break
          }
          break

        default:
          break
      }
    }
  }
}
