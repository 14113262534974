import produce from 'immer'
import { Reducer } from 'redux'
import { WithdrawalsActions, WithdrawalsState } from './types'

const initialState: WithdrawalsState = {
  data: []
}

export const WithdrawalsReducer: Reducer<WithdrawalsState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case WithdrawalsActions.loadWithdrawalsSuccess:
      return produce(state, (draft) => {
        draft.data = action.payload
      })
    case WithdrawalsActions.updateWithdrawSuccess:
      return produce(state, (draft) => {
        const withDrawIndex = state.data.findIndex(
          (withdraw) => withdraw.id === action.payload.id
        )
        if (withDrawIndex !== -1) {
          draft.data[withDrawIndex] = {
            ...draft.data[withDrawIndex],
            ...action.payload
          }
        }
      })
    default:
      return state
  }
}
