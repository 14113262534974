import { action } from 'typesafe-actions'
import { ListCustomerResponse } from '../../../../api/services/filter/listCustomerFilter'
import {
  CreateCustomerRequest,
  CreateCustomerResponse
} from '../../../../api/services/customers/createCustomerService'
import {
  EditCustomerRequest,
  EditCustomerResponse
} from '../../../../api/services/customers/editCustomerService'
import { CustomerResponse } from '../../../../api/services/customers/getCustomersService'
import { CustomersActions } from '../types'

export const getCustomersRequest = (skip: number, currentPage: number) =>
  action(CustomersActions.GET_CUSTOMERS_REQUEST, { skip, currentPage })

export const getCustomersSuccess = (
  data: CustomerResponse,
  skip: number,
  currentPage: number
) => action(CustomersActions.GET_CUSTOMERS_SUCCESS, { data, skip, currentPage })

export const createCustomerRequest = (data: CreateCustomerRequest) =>
  action(CustomersActions.CREATE_CUSTOMER_REQUEST, data)

export const createCustomerSuccess = (data: CreateCustomerResponse) =>
  action(CustomersActions.CREATE_CUSTOMER_SUCCESS, data)

export const editCustomerRequest = (
  data: EditCustomerRequest,
  customerId: string
) => action(CustomersActions.EDIT_CUSTOMER_REQUEST, { data, customerId })

export const editCustomerSuccess = (data: EditCustomerResponse) =>
  action(CustomersActions.EDIT_CUSTOMER_SUCCESS, data)

export const deleteCustomerRequest = (data: string[]) =>
  action(CustomersActions.DELETE_CUSTOMER_REQUEST, data)

export const deleteCustomerSuccess = (data: string[]) =>
  action(CustomersActions.DELETE_CUSTOMER_SUCCESS, data)

export const getSearchCustomerRequest = (data: string) =>
  action(CustomersActions.GET_SEARCH_CUSTOMER_REQUEST, data)

export const getSearchCustomerSuccess = (data: ListCustomerResponse[]) =>
  action(CustomersActions.GET_SEARCH_CUSTOMER_SUCCESS, data)
