import * as _ from 'lodash'
import { updateTicketSectionsService } from './updateTicketSections'
import axios from 'axios'
import { store } from '../../../redux/store/store'
import { setModalState } from '../../../redux/store/Modal/Actions/actionsModal'
import { api } from '../../api'

export const updateTicketService = async (
  ticketId: string,
  requestBody: any,
  creatorId: string,
  ticket?: any
) => {
  const { dispatch } = store
  if (ticket) {
    if (requestBody.name && _.isEqual(ticket.name, requestBody.name)) {
      delete requestBody.name
    }
    if (
      requestBody.category &&
      _.isEqual(ticket.category, requestBody.category)
    ) {
      delete requestBody.category
    }
    if (
      requestBody.description &&
      _.isEqual(ticket.description, requestBody.description)
    ) {
      delete requestBody.description
    }
    if (
      requestBody.priceCents &&
      _.isEqual(ticket.priceCents, requestBody.priceCents)
    ) {
      delete requestBody.priceCents
    }
    if (
      requestBody.discountCents &&
      _.isEqual(ticket.discountCents, requestBody.discountCents)
    ) {
      delete requestBody.discountCents
    }
    if (requestBody.rule && _.isEqual(ticket.rule, requestBody.rule)) {
      delete requestBody.rule
    }
    if (requestBody.type && _.isEqual(ticket.type, requestBody.type)) {
      delete requestBody.type
    }
    if (
      requestBody.eventItemsTicket &&
      _.isEqual(ticket.eventItemsTicket, requestBody.eventItemsTicket)
    ) {
      delete requestBody.eventItemsTicket
    }
  }

  if (!_.isEmpty(requestBody)) {
    if (requestBody.eventItemsTicket) {
      for (const eventItemsTickets of requestBody.eventItemsTicket) {
        const alreadyExists = _.find(ticket.eventItemsTicket, function (e) {
          return eventItemsTickets.eventItemId === e.eventItemId
        })
        if (_.isUndefined(alreadyExists)) {
          await updateTicketSectionsService(ticket.id, [eventItemsTickets])
        }
      }
      for (const eventItemTicket of ticket.eventItemsTicket) {
        const eventTicketSection = _.find(
          requestBody.eventItemsTicket,
          function (eventItem) {
            return eventItem.eventItemId === eventItemTicket.eventItemId
          }
        )

        if (_.isUndefined(eventTicketSection)) {
          await updateTicketSectionsService(ticket.id, [eventItemTicket])
        }
      }
    }
    try {
      const { data } = await api.put(
        `api/backoffice/creators/${creatorId}/tickets/${ticketId}`,
        requestBody
      )
      return data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        switch (error.response?.status) {
          case 400:
            switch (error.response.data.message) {
              case 'price cents cannot be less than discount cents':
                dispatch(
                  setModalState({
                    open: true,
                    variant: 'error',
                    message: 'Preço não pode ser menor que preço com desconto',
                    buttonText: 'Fechar'
                  })
                )
                break

              default:
                break
            }
            break

          default:
            break
        }
      }
    }
  }
}
