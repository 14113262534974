import _ from 'lodash'
import { api } from '../../api'

export type EditCreatorRequest = {
  firstName?: string
  lastName?: string
  corporateName?: string
  address?: {
    street?: string
    state?: string
    city?: string
    number?: number | undefined
    country?: string
    complement?: string
    zipCode?: string
  }
  documentType?: 'CPF' | 'CNPJ'
  email?: string
  document?: string
  phone?: string
  customField?: string
}

export type EditCreatorResponse = {
  id: string
  firstName: string
  lastName: string
  corporateName: string | null
  document: string
  documentType: string
  email: string
  phone: string | null
  userId: string
  createdAt: string
  updatedAt: string | null
  customField: string | null
  address: {
    id: string
    country: string
    state: string
    city: string
    street: string
    number: number
    zipCode: string
  } | null
}

export type PaymentSettingsResponse = {
  creditCardFee: number
  pixFee: number
  appFee: number
}

export type EditCreatorAndPaymentSettingsResponse = {
  id: string
  firstName: string
  lastName: string
  corporateName: string | null
  document: string
  documentType: string
  email: string
  phone: string | null
  userId: string
  createdAt: string
  updatedAt: string | null
  customField: string | null
  address: {
    id: string
    country: string
    state: string
    city: string
    street: string
    number: number
    zipCode: string
  } | null
  creatorPaymentSetting: PaymentSettingsResponse
}

export const editCreatorService = async (
  dataCreator: EditCreatorRequest,
  creatorId: string,
  paymentSettingsId: string,
  paymentSettings?: {
    appFee?: number
    bank?: string
    bankAccountType?: string
    bankAg?: string
    bankCc?: string
    creditCardFee?: number
    maxInstallments?: null
    paymentEmailNotificationReceiver?: string
    pixFee?: number
    softDescriptor?: string
    dataIsNull?: boolean
    status?: 'ACCEPTED' | 'WAITING' | 'REJECTED'
  }
) => {
  if (dataCreator.address?.city === '') {
    delete dataCreator.address.city
  }
  if (!dataCreator.address) {
    delete dataCreator.address
  }
  if (dataCreator.address?.complement === '') {
    delete dataCreator.address?.complement
  }
  if (dataCreator.address?.country === '') {
    delete dataCreator.address?.country
  }
  if (
    dataCreator.address?.number === 0 ||
    dataCreator.address?.number === null
  ) {
    delete dataCreator.address?.number
  }
  if (dataCreator.address?.state === '') {
    delete dataCreator.address?.state
  }
  if (dataCreator.address?.street === '') {
    delete dataCreator.address?.street
  }
  if (dataCreator.address?.zipCode === '') {
    delete dataCreator.address?.zipCode
  }
  if (dataCreator.corporateName === '') {
    delete dataCreator.corporateName
  }
  if (dataCreator.customField === '') {
    delete dataCreator.customField
  }
  if (dataCreator.document === '') {
    delete dataCreator.document
  }
  if (dataCreator.email === '') {
    delete dataCreator.email
  }
  if (dataCreator.firstName === '') {
    delete dataCreator.firstName
  }
  if (dataCreator.lastName === '') {
    delete dataCreator.lastName
  }
  if (dataCreator.phone === '') {
    delete dataCreator.phone
  }

  let dataCreatorResponse: EditCreatorResponse = {} as EditCreatorResponse

  if (!_.isEmpty(dataCreator)) {
    const data = await api.put(
      `/api/backoffice/creators/${creatorId}`,
      dataCreator
    )
    dataCreatorResponse = data.data
  }

  let paymentSettingsData: PaymentSettingsResponse =
    {} as PaymentSettingsResponse

  if (!_.isEmpty(paymentSettings)) {
    if (paymentSettings!.status === 'ACCEPTED') {
      const { data } = await api.put(
        `/api/backoffice/paymentSettings/fees/${paymentSettingsId}`,
        paymentSettings
      )
      paymentSettingsData = data
    } else {
      const { data } = await api.post(
        `/api/backoffice/creators/${creatorId}/accounts/verification`,
        paymentSettings
      )
      paymentSettingsData = data
    }
  }

  if (_.isEmpty(paymentSettingsData)) {
    return {
      ...dataCreatorResponse
    }
  } else
    return {
      ...dataCreatorResponse,
      creatorPaymentSetting: { ...paymentSettingsData }
    }
}
