import { all, call, put, select, takeLatest, delay } from 'redux-saga/effects'
import {
  purchaseOrderByCustomer,
  PurchaseOrderByCustomerResponse
} from '../../../api/services/customers/purchases/purchaseOrderByCustomer'
import { clearLoading, setLoading } from '../Loading/actions'
import { ReduxState } from '../rootReducer'
import {
  viewMorePurchaseOrderSuccess,
  purchasesOrderCustomerSuccess
} from './actions'
import { PurchasesActions, PurchaseOrder, PurchasesState } from './types'
import { PayloadAction } from 'typesafe-actions'
import history from '../../../routes/services/history'
import { setModalState } from '../Modal/Actions/actionsModal'

export function* getPurchaseOrderCustomer(
  action: PayloadAction<
    PurchasesActions.PURCHASE_ORDER_CUSTOMER_REQUEST,
    string
  >
) {
  try {
    yield put(setLoading(true))
    const data: PurchaseOrderByCustomerResponse = yield call(
      purchaseOrderByCustomer,
      action.payload
    )

    const filteredPurchases = data.items.filter(
      (purchase) =>
        purchase.customerTickets.length > 0 &&
        purchase.customerTickets[0].eventItem !== null &&
        purchase.payment &&
        purchase.customerTickets[0].ticket
    )

    const mapedPurchases = filteredPurchases
      .map((purchase) => {
        if (purchase.customerTickets.length > 0) {
          const { dates, description, event, ticketInfo, title } =
            purchase.customerTickets[0].eventItem
          const { desktopUrl, mobileUrl, tabletUrl, cardUrl } = event.banners[0]
          return {
            eventItem: {
              dates,
              description,
              event: {
                ...event,
                banners: { cardUrl, desktopUrl, mobileUrl, tabletUrl }
              },
              ticketInfo,
              title
            },
            id: purchase.id,
            status: purchase.status,
            orderId: purchase.orderId,
            payment: {
              paymentMethod: purchase.payment.paymentMethod,
              status: purchase.payment.status,
              totalPrice: purchase.payment.totalPrice,
              brand: purchase.payment.brand,
              lastCardNumbers: purchase.payment.lastCardNumbers,
              installments: purchase.payment.installments,
              cashBackDiscountCents: purchase.payment.cashBackDiscountCents
            },
            invoiceId: purchase.invoiceId,
            pixQrCode: purchase.pixQrCode,
            pixQrCodeText: purchase.pixQrCodeText,
            updatedAt: purchase.updatedAt,
            createdAt: purchase.createdAt,
            customerTickets: purchase.customerTickets.map((ticket) => ({
              id: ticket.id,
              seatId: ticket.seatId,
              qrCode: ticket.qrCode,
              customerTicketId: ticket.customerTicketId,
              purchaseOrderId: ticket.purchaseOrderId,
              ticketInfo: ticket.ticketInfo,
              detachedAt: ticket.detachedAt,
              eventItem: {
                dates: ticket.eventItem.dates,
                description: ticket.eventItem.description,
                title: ticket.eventItem.title,
                ticketInfo: ticket.eventItem.ticketInfo.map((info) => ({
                  value: info.value,
                  isRequired: info.isRequired
                })),
                event: {
                  name: ticket.eventItem.event.name,
                  description: ticket.eventItem.event.description,
                  cashBackPercent: ticket.eventItem.event.cashBackPercent,
                  type: ticket.eventItem.event.type,
                  address: {
                    country: ticket.eventItem.event.address.country,
                    state: ticket.eventItem.event.address.state,
                    city: ticket.eventItem.event.address.city,
                    street: ticket.eventItem.event.address.street,
                    number: ticket.eventItem.event.address.number,
                    zipCode: ticket.eventItem.event.address.zipCode
                  },
                  banners: {
                    cardUrl: ticket.eventItem.event.banners[0].cardUrl,
                    desktopUrl: ticket.eventItem.event.banners[0].desktopUrl,
                    mobileUrl: ticket.eventItem.event.banners[0].mobileUrl,
                    tabletUrl: ticket.eventItem.event.banners[0].tabletUrl
                  }
                }
              },
              ticket: {
                name: ticket.ticket.name,
                description: ticket.ticket.description,
                priceCents: ticket.ticket.priceCents,
                discountCents: ticket.ticket.discountCents,
                type: ticket.ticket.type,
                category: ticket.ticket.category
              }
            }))
          }
        } else return undefined
      })
      .filter((item) => item !== undefined) as PurchaseOrder[]

    yield put(
      purchasesOrderCustomerSuccess({
        items: mapedPurchases,
        total: data.total
      })
    )
    yield delay(3000)
    yield put(clearLoading())
  } catch (e: any) {
    history.push('/customers')
    yield put(clearLoading())
    yield put(
      setModalState({
        open: true,
        variant: 'error',
        message: 'Cliente não encontrado',
        buttonText: 'Fechar'
      })
    )
  }
}

export function* viewMorePurchaseOrder(
  action: PayloadAction<
    PurchasesActions.VIEW_MORE_PURCHASE_ORDER_REQUEST,
    string
  >
) {
  try {
    yield put(setLoading())
    const getPurchases = (state: ReduxState) => state.PurchasesReducer
    const purchases: PurchasesState = yield select(getPurchases)

    const data: PurchaseOrderByCustomerResponse = yield call(
      purchaseOrderByCustomer,
      action.payload,
      purchases.itemsPerPage
    )

    const filteredPurchases = data.items.filter(
      (purchase) =>
        purchase.customerTickets &&
        purchase.customerTickets.length > 0 &&
        purchase.customerTickets[0].eventItem !== null &&
        purchase.payment &&
        purchase.customerTickets[0].ticket
    )

    const mapedPurchases = filteredPurchases
      .map((purchase) => {
        if (purchase.customerTickets && purchase.customerTickets.length > 0) {
          const { dates, description, event, ticketInfo, title } =
            purchase.customerTickets[0].eventItem
          const { desktopUrl, mobileUrl, tabletUrl, cardUrl } = event.banners[0]
          return {
            eventItem: {
              dates,
              description,
              event: {
                ...event,
                banners: { cardUrl, desktopUrl, mobileUrl, tabletUrl }
              },
              ticketInfo,
              title
            },
            id: purchase.id,
            status: purchase.status,
            orderId: purchase.orderId,
            payment: {
              paymentMethod: purchase.payment.paymentMethod,
              status: purchase.payment.status,
              totalPrice: purchase.payment.totalPrice,
              brand: purchase.payment.brand,
              lastCardNumbers: purchase.payment.lastCardNumbers,
              installments: purchase.payment.installments,
              cashBackDiscountCents: purchase.payment.cashBackDiscountCents
            },
            invoiceId: purchase.invoiceId,
            pixQrCode: purchase.pixQrCode,
            pixQrCodeText: purchase.pixQrCodeText,
            updatedAt: purchase.updatedAt,
            createdAt: purchase.createdAt,
            customerTickets:
              purchase.customerTickets &&
              purchase.customerTickets.map((ticket) => ({
                id: ticket.id,
                seatId: ticket.seatId,
                qrCode: ticket.qrCode,
                customerTicketId: ticket.customerTicketId,
                purchaseOrderId: ticket.purchaseOrderId,
                ticketInfo: {},
                detachedAt: ticket.detachedAt,
                eventItem: {
                  dates: ticket.eventItem.dates,
                  description: ticket.eventItem.description,
                  title: ticket.eventItem.title,
                  ticketInfo: ticket.eventItem.ticketInfo.map((info) => ({
                    value: info.value,
                    isRequired: info.isRequired
                  })),
                  event: {
                    name: ticket.eventItem.event.name,
                    description: ticket.eventItem.event.description,
                    cashBackPercent: ticket.eventItem.event.cashBackPercent,
                    type: ticket.eventItem.event.type,
                    address: {
                      country: ticket.eventItem.event.address.country,
                      state: ticket.eventItem.event.address.state,
                      city: ticket.eventItem.event.address.city,
                      street: ticket.eventItem.event.address.street,
                      number: ticket.eventItem.event.address.number,
                      zipCode: ticket.eventItem.event.address.zipCode
                    },
                    banners: {
                      cardUrl: ticket.eventItem.event.banners[0].cardUrl,
                      desktopUrl: ticket.eventItem.event.banners[0].desktopUrl,
                      mobileUrl: ticket.eventItem.event.banners[0].mobileUrl,
                      tabletUrl: ticket.eventItem.event.banners[0].tabletUrl
                    }
                  }
                },
                ticket: {
                  name: ticket.ticket.name,
                  description: ticket.ticket.description,
                  priceCents: ticket.ticket.priceCents,
                  discountCents: ticket.ticket.discountCents,
                  type: ticket.ticket.type,
                  category: ticket.ticket.category
                }
              }))
          }
        } else return undefined
      })
      .filter((item) => item !== undefined) as PurchaseOrder[]

    yield put(
      viewMorePurchaseOrderSuccess({
        items: mapedPurchases,
        total: data.total
      })
    )
    yield delay(3000)
    yield put(clearLoading())
  } catch (e) {
    yield delay(3000)
    yield put(clearLoading())
  }
}

export const PurchaseSaga = all([
  takeLatest(
    PurchasesActions.PURCHASE_ORDER_CUSTOMER_REQUEST,
    getPurchaseOrderCustomer
  ),
  takeLatest(
    PurchasesActions.VIEW_MORE_PURCHASE_ORDER_REQUEST,
    viewMorePurchaseOrder
  )
])
