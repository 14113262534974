import { ReactChild } from 'react'
import * as S from './ContentModal.styles'

type ModalProps = {
  open: boolean
  children: ReactChild
}

const ContentModal = ({ open, children }: ModalProps) => {
  return <S.Container open={open}>{children}</S.Container>
}

export default ContentModal
