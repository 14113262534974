import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline:0;
    box-sizing: border-box;
    font-family: Poppins;
    font-size: 8px;
  }
  body, input, button {
    font-family: Poppins;
    font-size: 8px;
  }
  button { 
    cursor: pointer;
    font-family: Poppins;
    font-size: 8px;
  }
  html, body, #root {
      height: 100%;
  }
`
