import produce from 'immer'
import { Reducer } from 'redux'
import { ChartActions, ChartsState } from './types'

const inititalState: ChartsState = {
  data: {
    items: [],
    total: 0
  },
  currentPage: 1,
  skip: 0,
  take: 5
}

export const Charts: Reducer<ChartsState> = (state = inititalState, action) => {
  switch (action.type) {
    case ChartActions.GET_CHARTS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.data = action.payload.data
        draftState.skip = action.payload.skip
        draftState.currentPage = action.payload.currentPage
      })
    case ChartActions.CREATE_CHART_SUCCESS:
      return produce(state, (draftState) => {
        draftState.data.items.push(action.payload)
      })
    case ChartActions.EDIT_CHART_SUCCESS:
      return produce(state, (draftState) => {
        const index = draftState.data.items.findIndex(
          (i) => i.id === action.payload.id
        )
        if (index > -1) {
          draftState.data.items[index] = action.payload
        }
      })
    case ChartActions.DELETE_CHART_SUCCESS:
      return produce(state, (draftState) => {
        action.payload.forEach((seatMapId: string) => {
          const index = draftState.data.items.findIndex(
            (i) => i.id === seatMapId
          )
          if (index > -1) {
            draftState.data.items.splice(index, 1)
          }
        })
      })
    default:
      return state
  }
}
