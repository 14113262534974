export const messageHelper = {
  yup: {
    creator: {
      nameIsMandatory: 'Nome ou razão social é obrigatório.',
      documentIsMandatory: 'CPF/CNPJ é obrigatório.',
      documentIsInvalid: 'CPF/CNPJ inválido.',
      emailIsMandatory: 'Email é obrigatório.',
      emailIsInvalid: 'Digite um email válido.',
      phoneIsMandatory: 'Telefone é obrigatório.',
      phoneIsInvalid: 'Telefone inválido.',
      cepIsMandatory: 'CEP é obrigatório.',
      streetIsMandatory: 'Rua é obrigatória.',
      districtIsMandatory: 'Bairro é obrigatório.',
      cityIsMandatory: 'Cidade é obrigatória.',
      stateIsMandatory: 'Estado é obrigatório.',
      eventShortName: 'Nome deve ter mínimo 2 caracteres.',
      eventLongName: 'Nome deve ter menos do que 128 caracteres.',
      maxTicketsNoZero: 'Quantidade máxima de tickets não pode ser igual a zero'
    },
    banner: {
      nameIsMandatory: 'Nome do banner é obrigatório.',
      descriptionIsMandatory: 'Descrição do banner é obrigatória.'
    },
    customer: {
      nameIsMandatory: 'Nome é obrigatório.',
      documentIsMandatory: 'CPF é obrigatório.',
      documentIsInvalid: 'CPF inválido.',
      emailIsMandatory: 'Email é obrigatório.',
      phoneIsMandatory: 'Telefone é obrigatório.',
      phoneIsInvalid: 'Telefone inválido.',
      cityIsMandatory: 'Cidade é obrigatória.',
      stateIsMandatory: 'Estado é obrigatório.',
      emailIsInvalid: 'Digite um email válido.'
    }
  },
  modal: {
    banner: {
      success: {
        createBanner: 'Banner criado com sucesso.',
        deleteMultiplesBanners: 'Banners deletados com sucesso.',
        deleteBanner: 'Banner deletado com sucesso.'
      },
      error: {
        loadBanner: 'Não foi possivel carregar seus banners.',
        createBanner: 'Não foi possível criar o banner.',
        deleteMultiplesBanners: 'Não foi possível deletar os banners.',
        deleteBanner: 'Não foi possível deletar o banner.'
      }
    },
    creator: {
      success: {
        createCreator: 'Produtor cadastrado com sucesso!',
        editedCreator: 'Produtor alterado com sucesso.'
      },
      error: {
        createCreator: 'Não foi possível cadastrar o produtor.',
        editedCreator: 'Não foi possível editar o produtor.',
        loadCreator: 'Não foi possivel carregar seus usuários creators.'
      }
    },
    customer: {
      success: {
        createCustomer: 'Cliente cadastrado com sucesso.'
      },
      error: {
        loadCustomer: 'Não foi possivel carregar seus usuários clientes.',
        createCustomer: 'Não foi possível cadastrar o cliente.'
      }
    },
    userBackoffice: {
      success: {
        createUserBackoffice: 'Usuário backoffice cadastrado com sucesso.'
      },
      error: {
        loadUserBackoffice:
          'Não foi possivel carregar seus usuários backoffice.',
        createUserBackoffice: 'Não foi possível cadastrar o usuário.'
      }
    },
    charts: {
      success: {
        createCharts: 'Mapa de assentos criado com sucesso.',
        deleteChart: 'Mapa de assentos deletado com sucesso.'
      },
      error: {
        loadCharts: 'Não foi possivel carregar seus mapas de assentos.',
        createCharts: 'Não foi possível criar o mapa de assentos.',
        deleteChart: 'Não foi possível deletar o mapa de assentos.'
      }
    }
  },
  generic: {
    continue: 'Continuar'
  },
  input: {
    banner: {
      enterBannerName: 'Digite o nome do banner',
      enterBannerDescription: 'Digite a descrição do banner'
    },
    creator: {
      enterCreatorNameLP: 'Digite sua razão social',
      enterCreatorName: 'Digite seu nome completo',
      enterCreatorEmail: 'Digite seu email',
      enterCreatorCEP: 'Digite seu CEP',
      enterCreatorStreet: 'Digite sua rua',
      enterCreatorDistrict: 'Digite seu bairro',
      enterCreatorCity: 'Digite sua cidade',
      enterCreatorState: 'Digite seu estado'
    },
    customer: {
      enterCustomerName: 'Digite seu nome',
      enterCustomerCPF: 'Digite seu CPF',
      enterCustomerEmail: 'Digite seu email'
    }
  }
}
