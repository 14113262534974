import styled, { css } from 'styled-components'

import { ReactComponent as LogoutFile } from '../../assets/icons/logout.svg'

export const Container = styled.div`
  ${({ theme }) => css`
    min-width: 320px;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #ebf0f6;
  `}
`

export const Children = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxxs};
    overflow: auto;
    @media (min-width: 1171px) {
      padding: ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxxs}
        ${theme.spacing.stack.xxxs} ${theme.spacing.stack.xxxl};
    }
  `}
`

export const LogoutIcon = styled(LogoutFile)`
  fill: #8601c9;
`
