import styled, { css } from 'styled-components'

import { ReactComponent as EditIconFile } from '../../../assets/icons/edit-alt.svg'
import { ReactComponent as TrashIconFile } from '../../../assets/icons/trash-alt.svg'

export const Container = styled.div`
  ${({ theme }) => css`
    .status {
      text-align: left;
      color: #5c5c5c;
      font-weight: 400;
      font-size: 14px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
    }

    .REJECTED {
      color: ${theme.color.alert.error.nth1};
    }

    .APPROVED {
      color: ${theme.color.alert.success.nth1};
    }

    .WAITING {
      color: ${theme.color.alert.warning.nth1};
    }
  `}
`

export const IconBox = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const EditIcon = styled(EditIconFile)`
  ${({ theme }) => css`
    fill: ${theme.color.base.nth1};
    cursor: pointer;
  `}
`

export const ExcludeIcon = styled(TrashIconFile)`
  ${({ theme }) => css`
    fill: ${theme.color.base.nth1};
    cursor: pointer;
  `}
`
