import { AxiosResponse } from 'axios'
import { api } from '../../../api'

export type EventItemApi = {
  id: string
  dates: string[]
  description: string
  title: string
  maxCustomerTickets: number
  ticketInfo: {
    isRequired: boolean
    value: string
  }[]
  createdAt: string
  eventKey: string
  salesFrom: null
  finalSales: null
  updatedAt: null
  eventId: string
  event: {
    id: string
    name: string
    description: string
    cashBackPercent: number | null
    type: string
    createdAt: string
    updatedAt: string
    creatorId: string
    seatMapId: string
    address: {
      id: string
      country: string
      state: string
      city: string
      street: string
      number: number
      zipCode: string
      latitude: string
      longitude: string
      complement: string
      createdAt: string
      updatedAt: string
      creatorId: string
      customerId: string
      eventId: string
      seatMapId: string
    }
    banners: [
      {
        id: string
        cardUrl: string
        desktopUrl: string
        mobileUrl: string
        tabletUrl: string
        createdAt: string
        updatedAt: null
        eventId: string
      }
    ]
  }
}

export type PurchaseOrderApi = {
  id: string
  status: 'SUCCESS' | 'FAILED' | 'WAITING'
  orderId: string
  invoiceId: string
  pixQrCode?: string
  pixQrCodeText?: string
  payment: {
    paymentMethod: string
    status: string
    totalPrice: number
    brand: string
    lastCardNumbers: string
    installments: string
    cashBackDiscountCents: number | null
  }
  failedCode: null
  customerTickets: [
    {
      id: string
      seatId: string
      qrCode: string
      customerTicketId: string
      ticketInfo: Record<string, string>
      detachedAt: string
      isValid: boolean
      customerId: string
      createdAt: string
      updatedAt: string
      eventItemId: string
      ticketId: string
      purchaseOrderId: string
      eventItem: EventItemApi
      ticket: {
        id: string
        name: string
        description: string
        priceCents: number
        discountCents: number
        type: string
        category: string
        rule: string
        createdAt: string
        updatedAt: string
        customField: null
      }
    }
  ]
  createdAt: string
  updatedAt: string
}

export type PurchaseOrderByCustomerResponse = {
  items: PurchaseOrderApi[]
  total: number
}

export const purchaseOrderByCustomer = async (
  customerId: string,
  skip?: number
) => {
  const { data }: AxiosResponse<PurchaseOrderByCustomerResponse> =
    await api.get(
      skip
        ? `/api/backoffice/customers/${customerId}/orders?take=10&skip=${skip}&status=SUCCESS&status=WAITING`
        : `/api/backoffice/customers/${customerId}/orders?&status=SUCCESS&status=WAITING`
    )

  return data
}
